/** @jsxImportSource @emotion/react */

import {useState} from 'react'
import Table from 'components/Table2/Table.jsx'
import useGlobalData from 'hooks/useGlobalData.mjs'
import ButtonConfig from './ButtonConfig.jsx'

//sumNumberRD 合计行保留的小数位,如 sumNumberRD=2 那么保留两位 1->1.00  2.3-> 2.30
const BizTable = ({
    code: tableCode,
    columns,sumNumberRD ,
    ...props
}) => {
    const [config, setConfig] = useState(
        () => useGlobalData.getTableConfig({tableCode})
    )

    const buttonConfig = tableCode ?
        <ButtonConfig
            config={config}
            columns={columns}
            tableCode={tableCode}
            onChange={setConfig}
        />
        :
        null

    const refinedColumns = (() => {
        if (! config) {
            return columns
        }

        const getColumnConfig = (column) => {
            const {title, titleText} = column
            return config.columns[titleText ?? title] ?? {index: Infinity}
        }

        return columns
            .filter((column) => {
                const {hidden} = getColumnConfig(column)
                return ! hidden
            })
            .map((column) => {
                const {fixed, index} = getColumnConfig(column)
                return [index, {...column, fixed}]
            })
            .sort(([a], [b]) => a - b)
            .map(([, column]) => column)
    })()

    const css = {
        // 兼容旧表格用法，但更推荐使用 Column.align 属性
        '& td.right': {
            textAlign: 'right',
        },

        // 兼容旧表格用法，但更推荐使用 Column.align 属性
        '& td.left': {
            textAlign: 'left',
        },

        // 兼容旧表格用法，但更推荐使用 Column.align 属性
        '& td.center': {
            textAlign: 'center',
        },

        // 兼容旧表格用法，但更推荐在组件上设置 bordered={false}
        '& .ant-input, & .ant-picker, & .ant-table .ant-select .ant-select-selector': {
            background: 'none',
            border: 'none',
            boxShadow: 'none',
        },
    }

    if (config?.striped) {
        Object.assign(css, {
            '& .ant-table-row': {
                '&:nth-child(even)': {
                    backgroundColor: config.oddColor,
                },

                '&:nth-child(even) .ant-table-cell-fix-left': {
                    backgroundColor: 'inherit',
                },

                '&:nth-child(odd)': {
                    backgroundColor: config.evenColor,
                },

                '&:nth-child(odd) .ant-table-cell-fix-left': {
                    backgroundColor: 'inherit',
                },

                '&:hover .ant-table-cell-fix-left': {
                    backgroundColor: '#fafafa',
                }
            }
        })
    }

    return (
        <Table
            css={css}
            columns={refinedColumns}
            pagination={{pageSizeOptions: [5, 10, 20, 50, 100]}}
            rowNumberTitle={buttonConfig}
            sumNumberRD={sumNumberRD}
            {...props}
        />
    )
}

Object.assign(BizTable, Table)
export default BizTable
