import React, {useEffect, useState} from 'react';
import Frame from 'components/Frame/Frame';
import {useMainTabContext} from 'components/MainTabs/MainTabs.jsx'
import {Button, Col, Dropdown, Form, Input, Menu, message, Modal, Radio, Row, Space} from 'antd';
import useGlobalData from "hooks/useGlobalData";
import Tools from 'script/sweet-tools.mjs';
import DraggableModal from 'components/DraggableModal/DraggableModal';
import PurBillAlterLogList from "../../MC/MC20_DT_MC_MPC/PurBillAlterLogList";
import moment from "moment";
import FlowChangeModal from "pages/BizPage/FLOW/AUDIT/FlowChangeModal";
import StartAuditBeforeModal from "pages/BizPage/FLOW/AUDIT/StartAuditBeforeModal";
import {useOpenModal} from 'components/Modal/Modal.jsx'
import ProcessTaskHistoryModal from "../../FLOW/AUDIT/FlowTaskHistoryModal";
import authenticator from "script/sweet-authenticator.mjs";
import FlowTaskActiveModal from "../../FLOW/AUDIT/FlowTaskActiveModal";
import {firstPurDict} from "../../COMM/CurrentComm";

const Pane = ({data, mainPageQuery, ...props}) => {

    const dateFormat = 'YYYY-MM-DD';
    const mainTab = useMainTabContext()
    const {$key} = props; // 当前标签页
    const [form] = Form.useForm();
    const [formAlt] = Form.useForm();
    const {TextArea} = Input;
    const [itemInfo, setItemInfo] = useState(data);
    const [visible, setVisible] = useState(false);
    const [variation, setVariation] = useState("");
    const [purBillAlterLogVisible, setPurBillAlterLogVisible] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [visibleAltDialog, setVisibleAltDialog] = useState(false);
    const [enableDelPc, setEnableDelPc] = useState(true);
    const [billId, setBillId] = useState("");

    const [refreshItem, setRefreshItem] = useState(false);

    //供应商合同文本附件参数
    const [suppDocParam, setSuppDocParam] = useState({});
    //合同附件参数
    const [pcDocParam, setPcDocParam] = useState({});
    //附件编辑控制参数
    const [prDocFlag, setPrDocFlag] = useState("0");
    //语言版本
    const [itemLngTypeCode, setItemLngTypeCode] = useState(data.itemLngTypeCode);

    const [loading, setLoading] = useState(false);

    const [loadingSubmitEvPass,setLoadingSubmitEvPass] = useState(false);

    const [loadingCancelEvPass,setLoadingCancelEvPass] = useState(false);

    const [loadingRepeal,setLoadingRepeal] = useState(false);

    const [visibleHistory, setVisibleHistory] = useState(false);

    const [visibleFlow, setVisibleFlow] = useState(false);

    const openModal = useOpenModal();

	// 打印文件名内部款号
    const [prtInsideStyleNo, setPrtInsideStyleNo]= useState("");

    //当前用户的改单权限
    const [hasChangePerm,setHasChangePerm] =useState(authenticator.hasPermissionChange({
        permissionCode: "MPC",
        billOrgPathNo: data.orgPathNo,
        billPuId: data.pcPuId,
        billUserId: data.crtUserId

    }))

    //当前用户的审核权限
    const [hasVerifyPerm,setHasVerifyPerm] = useState(authenticator.hasPermissionVerify({
        permissionCode: "MPC",
        billOrgPathNo: data.orgPathNo,
        billPuId: data.pcPuId,
        billUserId: data.crtUserId

    }))

    //当前用户的取消审核权限
    const [hasRevokePerm,setHasRevokePerm] = useState(authenticator.hasPermissionRevoke({
        permissionCode: "MPC",
        billOrgPathNo: data.orgPathNo,
        billPuId: data.pcPuId,
        billUserId: data.crtUserId

    }))

    // 权限控制 true 当前用户有编辑权限，false 没有编辑权限
    const [ hasEditPerm,setHasEditPerm] = useState(authenticator.hasPermissionEdit({
        permissionCode: "MPC",
        billOrgPathNo: data.orgPathNo,
        billPuId: data.pcPuId,
        billUserId: data.crtUserId
    }))

    useEffect(() => {
		console.log("进入采购合同,主数据信息="+JSON.stringify(data));
        if (data) {
            setSuppDocParam({
                dsId: data.suppPcDsId,
                fileDir:firstPurDict+data.pcNo
            });
            setPcDocParam({
                dsId: data.pcDsId,
                fileDir:firstPurDict+data.pcNo
            });
            //草稿、审批退回、改单、审批通过，且当前用户是制单人
            if ((data.pcStsCode === 'DRAFT' || data.pcStsCode === 'EA_BACK' || data.pcStsCode === 'ALT' || data.pcStsCode === 'EAOK' ) && hasEditPerm ) {
                setPrDocFlag("1");
            }else{
                setPrDocFlag("0");
            }

            if (data.styleInsideNos && data.styleInsideNos != '') {
                if (data.styleInsideNos.split(',').length > 1) {
                    setPrtInsideStyleNo(data.styleInsideNos.split(',')[0]+"等款");
                } else {
                    setPrtInsideStyleNo(data.styleInsideNos.split(',')[0]);
                }
            }
        }
        //若已审核过
        if (data.pcEvStsCode) {
            // 状态我 审批退回或改单，需要检查合同是否能够删除
            if (data.pcStsCode == 'EA_BACK' || data.pcStsCode == 'ALT') {
                doQueryEnableDelPc();
            }
        }
    }, [itemInfo, refreshItem]);

    //查询采购合同数据
    const doQueryEnableDelPc = async () => {
        try {
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/purpc/queryEnableDelPc", {pcId: data.pcId});
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            //console.log("enableDelPc:"+result);
            setEnableDelPc(result == "1" ? true : false);
        } finally {
        }
    };

    // 删除
    const del = async () => {
        if (itemInfo.pcStsCode != 'DRAFT' && itemInfo.pcStsCode != 'EA_BACK' && itemInfo.pcStsCode != 'ALT') {
            message.warning('只能删除草稿、审批退回、改单状态的采购合同!');
            return;
        }
        Modal.confirm({
            title: '是否删除？',
            okText: '是',
            cancelText: '否',
            okType: 'danger',
            onOk: async () => {
                let prIds = []
                prIds.push(itemInfo.pcId)
                try {
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/removePurPcDRs", prIds)
                        },
                        {
                            error: {content: (err) => `查询失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );
                    if (!result.succ) {
                        Modal.error({title: '提示信息', content: result.errMsg});
                        return;
                    }
                    //关闭当前页面，打开合同列表页面
                    mainTab.close();
                    /* mainTab.openTab({
                         name: '物料采购合同',
                         pane: 'MC/MC20_DT_MC_MPC/MPCList',
                     });*/
                } finally {
                    //mainPageQuery({},{});
                }
            }
        });
    }

    // 曾经审核过，只能作废
    const repeal = async () => {
        if (!(itemInfo.pcStsCode === 'ALT' && itemInfo.pcEvStsCode === 'CANCEL')) {
            message.warning('只能作废取消审核的采购合同!');
            return;
        }
        Modal.confirm({
            title: '是否作废采购合同？',
            okText: '是',
            cancelText: '否',
            okType: 'danger',
            onOk: async () => {
                let param = {pcId: itemInfo.pcId}
                try {
                    setLoadingRepeal(true);
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/repealPurPcDR", param)
                        },
                        {
                            error: {content: (err) => `作废采购合同失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );
                    if (!result.succ) {
                        Modal.error({title: '提示信息', content: result.errMsg});
                        return;
                    }
                    itemInfo.pcStsCode = 'REPEAL';    // 设置为作废
                    setVariation('REPEAL')
                    setPrDocFlag('0');
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingRepeal(false);
                    //mainPageQuery({}, {});
                }
            }
        });
    }

    //审批中，刷新
    const refreshData = async () => {
        let param = {pcId: itemInfo.pcId}
        let purPcData = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/pur/purpc/onePurPcDR", param)
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        )
        if ( purPcData.pcStsCode === 'EAOK'){
            itemInfo.pcStsCode = 'EAOK';    // 设置为审批通过
            setVariation('EAOK');
            setPrDocFlag('0');
        }
    }

    //查看审批流程
    const viewFlow = async () => {
        setVisibleFlow(true);
    }
        // 提交审批
    const submit = async () => {
        let param = {pcId: itemInfo.pcId}
        let purPcData = null;
        //校验
        try {
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/purpc/validateSubmitPurPc", param)
                },
                {
                    error: {content: (err) => `提交审批失败: ${err.message}`},
                    loading: {show: false},
                    success: {show:false},
                }
            );
            if ( result != null && result != ''){
                Modal.error({
                    title: '提交审批失败',
                    content: result,
                });
                return;
            }
        } catch (e) {
            console.log(e);
            return;
        } finally {

        }
        //重新查询合同信息
        try {
            param.queryEvParamFlag = true ;// 查询需要审批的相关数据
            purPcData = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/purpc/onePurPcDR", param)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            )
        }
        catch (e) {
            console.log(e);
            return;
        } finally {
        }
        //审批需要的参数
        let variables= {};
        variables.contractOrg = purPcData.buId;
        variables.contractAmount = purPcData?.currCode == 'CNY' ?  purPcData.txAmt : purPcData.txcRmbAmt;
        variables.legalEvFlag = purPcData.legalEvFlag; // 修了条款内容，需法务审核
        variables.suppPcFlag= purPcData.suppPcFlag;   // 非轻纺版本合同，需法务审核
        variables.fabricEaFlag = purPcData.fabricEaFlag; // 是否需面料岗审核
        variables.accessoryEaFlag = purPcData.accessoryEaFlag; // 是否需辅料岗审核
        variables.overBudgetFlag = purPcData.overBudgetFlag; // 是否超预算
        let flowModuleCode = "PURMATPC";
        if ( purPcData.autoEvFlag){
            flowModuleCode = "PURMATPC_AUTO";
        }
        console.log("提交审批flowModuleCode=" + flowModuleCode);
        console.log("提交审批参数="+JSON.stringify(variables));
        const success = await openModal(
            <StartAuditBeforeModal
                params={{
                    flowModuleCode: flowModuleCode,
                    orgId: purPcData.buId,
                    puId: purPcData.pcPuId,
                    empId: purPcData.crtUserId,
                    billId: purPcData.pcId,
                    billNo: purPcData.pcNo,
                    billKeyInfo: purPcData.styleNos,
                    variables: variables
                }}
            />
        );

        if ( success ){
            console.log("创建审批流成功");
            if ( purPcData.autoEvFlag ){
                console.log("自动审批完成");
                //自动审批通过
                param.autoEvFlag = true;
                param.eaResult = "EAOK";
                try {
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/eaPurPcDR", param)
                        },
                        {
                            error: {content: (err) => `提交审批失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: false},
                        }
                    );
                    itemInfo.pcStsCode = 'EAOK';    // 设置为审批通过
                    setVariation('EAOK');
                    setPrDocFlag('0');
                } catch (e) {
                    console.log(e);
                } finally {
                    // mainPageQuery({},{});
                }
            }else{
                try {
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/submitPurPcDR", param)
                        },
                        {
                            error: {content: (err) => `提交审批失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: false},
                        }
                    );
                    if ( result.pcStsCode === 'EAING'){
                        itemInfo.pcStsCode = 'EAING';    // 设置为审批中
                        setVariation('EAING');
                        setPrDocFlag('0');
                    }else{
                        //重新查询合同信息
                        param.queryEvParamFlag = false;
                        purPcData = await Tools.runAsync(
                            async () => {
                                return await Tools.http.post("/pur/purpc/onePurPcDR", param)
                            },
                            {
                                error: {content: (err) => `查询失败: ${err.message}`},
                                loading: {show: false},
                                success: {show: false},
                            }
                        )
                        if ( purPcData.pcStsCode === 'EAOK'){
                            itemInfo.pcStsCode = 'EAOK';    // 设置为审批通过
                            setVariation('EAOK');
                            setPrDocFlag('0');
                        }
                    }

                } catch (e) {
                    console.log(e);
                } finally {
                    // mainPageQuery({},{});
                }
            }

        }else{
            console.log("创建审批流失败");
        }
    }

    //改单记录弹窗
    const showPurBillAlterLogList = async () => {
        setBillId(itemInfo.pcId)  //单据ID
        setPurBillAlterLogVisible(true)    // 弹窗控制
    }


    // 撤销审批
    const cancelSubmit = async () => {
        if (itemInfo.pcStsCode != 'EAING') {
            message.warning('只能对审批中的采购合同继续撤销!');
            return;
        }
        let param = {pcId: itemInfo.pcId}
        Modal.confirm({
            title: '确定撤销审批采购合同吗？',
            okText: '是',
            cancelText: '否',
            onOk: async () => {
                try {
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/cancelPurPcDR", param)
                        },
                        {
                            error: {content: (err) => `查询失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );
                    itemInfo.pcStsCode = result;
                    setVariation(result)
                    setPrDocFlag('1');
                } finally {
                    //mainPageQuery({}, {});
                }
            }
        })
    }

    // 审批采购合同
    const approval = async () => {
        let formData = await form.validateFields();
        formData.pcId = itemInfo.pcId;
        try {
            // console.log("审批保存数据="+JSON.stringify(formData));
            // 查询 data返回结果
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/purpc/eaPurPcDR", formData)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            )

            itemInfo.pcStsCode = result.pcStsCode;    // 设置状态
            setVariation(result.pcStsCode)
            //审批退回，且制单人就是当前用户
            if (result.selfFlag && result.pcStsCode == 'EA_BACK') {
                setPrDocFlag('1');
            } else {
                setPrDocFlag('0');
            }
        } finally {
            //mainPageQuery({},{});
            setVisible(false);
        }
    }

    // 提交已审核
    const submitEvPass = async () => {
        if (itemInfo.pcStsCode != 'EAOK') {
            message.warning('只能对审批通过的采购合同标记审核!');
            return;
        }
        let param = {pcId: itemInfo.pcId, evResult: 'PASS'}
        Modal.confirm({
            title: '确定审核当前采购合同吗？',
            okText: '是',
            cancelText: '否',
            onOk: async () => {
                setLoadingSubmitEvPass(true);
                try {
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/evPurPcDR", param)
                        },
                        {
                            error: {content: (err) => `查询失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );
                    if (!result.succ) {
                        Modal.error({title: '提示信息', content: result.errMsg});
                        return;
                    }
                    itemInfo.pcEvStsCode = 'PASS';    // 设置审核状态为 审核通过
                    setVariation('PASS');// 刷新首页
                } finally {
                    setLoadingSubmitEvPass(false);
                    //mainPageQuery({},{});
                }
            }
        })

    }

    // 取消已审核标记
    const cancelEvPass = async () => {
        if (itemInfo.pcStsCode != 'EAOK' && itemInfo.pcEvStsCode != 'PASS') {
            message.warning('只能对审批通过且已审核通过的采购合同取消审核!');
            return;
        }
        //检查用户是否具备取消审核权限
        const result = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/pur/purpc/getAltPcPermissionFlag", {pcId: itemInfo.pcId});
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        );
        console.log(">>>getAltPcPermissionFlag="+result);
        if ( result === '2'){
            Modal.error({
                title: '取消审核失败',
                content: '您不具备对当前合同做取消审核的权限！',
            });
            return;
        }

        let param = {pcId: itemInfo.pcId, evResult: 'CANCEL'}
        Modal.confirm({
            title: '确定对采购合同进行取消审核操作吗？',
            okText: '是',
            cancelText: '否',
            onOk: async () => {
                try {
                    setLoadingCancelEvPass(true);
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/evPurPcDR", param)
                        },
                        {
                            error: {content: (err) => `查询失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );
                    if (!result.succ) {
                        Modal.error({title: '提示信息', content: result.errMsg});
                        return;
                    }
                    itemInfo.pcEvStsCode = 'CANCEL';    // 设置审核状态为 取消审核
                    setVariation('CNCEL');// 刷新首页
                } finally {
                    setLoadingCancelEvPass(false);
                }
            }
        })
    }

    // 发起改单操作
    const alterPurPc = async () => {
        if (itemInfo.pcStsCode != 'EAOK') {
            message.warning('只能对审批通过的采购合同进行改单操作!');
            return;
        }
        let formData = await formAlt.validateFields();
        formData.pcId = itemInfo.pcId;
        Modal.confirm({
            title: '确定对采购合同进行改单操作吗？',
            okText: '是',
            cancelText: '否',
            onOk: async () => {
                try {
                    //let param = {pcId: itemInfo.pcId,altMemo:altMemo}
                    console.log("改单保存内容=" + JSON.stringify(formData));
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/purpc/alterPurPcDR", formData)
                        },
                        {
                            error: {content: (err) => `改单操作失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );

                    if (!result.succ) {
                        Modal.error({title: '提示信息', content: result.errMsg});
                        return;
                    }
                    itemInfo.pcStsCode = 'ALT';    // 设置为改单
                    setVariation('ALT');
                    setPrDocFlag('1');
                    if (result.extInfo == "1") {
                        // 允许删除合同
                        setEnableDelPc(true);
                    } else {
                        setEnableDelPc(false);
                    }
                } finally {
                    setVisibleAltDialog(false);
                }
            }
        })
    }
    //显示审批弹窗
    const showApproval = async () => {
        setVisible(true);
        form.resetFields();
        //设置默认为通过
        form.setFieldsValue({"eaResult": "EAOK", "eaMemo": ""});
    }

    //改单
    const showAlt = async () => {
        await openModal(
            <FlowChangeModal
                params={{
                    onSave: async ({commitMessage}) => {
                        let param = {pcId: itemInfo.pcId}
                        param.altMemo = commitMessage;
                        const result = await Tools.runAsync(
                            async () => {
                                return await Tools.http.post("/pur/purpc/alterPurPcDR", param)
                            },
                            {
                                error: {content: (err) => `改单操作失败: ${err.message}`},
                                loading: {show: false},
                                success: {show: true},
                            }
                        );

                        if (!result.succ) {
                            Modal.error({title: '提示信息', content: result.errMsg});
                            return;
                        }
                        itemInfo.pcStsCode = 'ALT';    // 设置为改单
                        setVariation('ALT');
                        setPrDocFlag('1');
                        if (result.extInfo == "1") {
                            // 允许删除合同
                            setEnableDelPc(true);
                        } else {
                            setEnableDelPc(false);
                        }


                    }
                }}
            />
        )

    }
    const pintPurPcMatCn = async () => {
        let fileName = '物料采购合同（中文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatCnColor = async () => {
        let fileName = '物料采购合同带颜色（中文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn_color', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatCnColorSize = async () => {
        let fileName = '物料采购合同带颜色尺码（中文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn_color_size', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatCnAtta = async () => {
        let fileName = '物料采购合同（中文_带附件）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn_atta', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatEn = async () => {
        let fileName = '物料采购合同（英文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_en', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatEnColor = async () => {
        let fileName = '物料采购合同带颜色（英文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_en_color', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatEnColorSize = async () => {
        let fileName = '物料采购合同带颜色尺码（英文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_en_color_size', payload: {pcId: data.pcId, __filename__: fileName}});
    }
	const pintPurPcMatEnSpColor = async () => {
        let fileName = '物料采购合同带颜色（英文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_en_sp_color', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatEnSPColorSize = async () => {
        let fileName = '物料采购合同带颜色尺码（英文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_en_sp_color_size', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatCnSp = async () => {
        let fileName = '物料采购合同（中文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn_sp', payload: {pcId: data.pcId, __filename__: fileName}});
    }

    const pintPurPcMatCnSpColor = async () => {
        let fileName = '物料采购合同带颜色（中文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn_sp_color', payload: {pcId: data.pcId, __filename__: fileName}});
    }
	const pintPurPcMatCnSpColorSize = async () => {
        let fileName = '物料采购合同带颜色尺码（中文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn_sp_color_size', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatCnAttaSp = async () => {
        let fileName = '物料采购合同（中文_带附件）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_cn_atta_sp', payload: {pcId: data.pcId, __filename__: fileName}});
    }
    const pintPurPcMatEnSp = async () => {
        let fileName = '物料采购合同（英文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_en_sp', payload: {pcId: data.pcId, __filename__: fileName}});
    }

    const pintPurPcMatSupp = async () => {
        let fileName = '物料采购合同（补充协议）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        Tools.openReportView({code: 'pur_pc_mat_supp', payload: {pcId: data.pcId, __filename__: fileName}});
    }

    //打印合同
    const printMenu = (
        itemLngTypeCode == 'ZH_CN' ?

            <Menu>
                <Menu.Item key="1">
                    <div onClick={() => {
                        pintPurPcMatCnSp()
                    }}>中文合同（单页）
                    </div>
                </Menu.Item>
                <Menu.Item key="2">
                    <div onClick={() => {
                        pintPurPcMatCn()
                    }}>中文合同
                    </div>
                </Menu.Item>
                <Menu.Item key="1">
                    <div onClick={() => {
                        pintPurPcMatCnSpColor()
                    }}>中文合同带颜色（单页）
                    </div>
                </Menu.Item>
                <Menu.Item key="2">
                    <div onClick={() => {
                        pintPurPcMatCnColor()
                    }}>中文合同带颜色
                    </div>
                </Menu.Item>
                <Menu.Item key="1">
                    <div onClick={() => {
                        pintPurPcMatCnSpColorSize()
                    }}>中文合同带颜色尺码（单页）
                    </div>
                </Menu.Item>
                <Menu.Item key="2">
                    <div onClick={() => {
                        pintPurPcMatCnColorSize()
                    }}>中文合同带颜色尺码
                    </div>
                </Menu.Item>
                <Menu.Item key="3">
                    <div onClick={() => {
                        printHTML("CN")
                    }}>中文合同（浏览器）
                    </div>
                </Menu.Item>
                {/*<Menu.Item key="3">
                    <div onClick={() => {
                        pintPurPcMatCnAttaSp()
                    }}>中文合同加附件（单页）
                    </div>
                </Menu.Item>
                <Menu.Item key="4">
                    <div onClick={() => {
                        pintPurPcMatCnAtta()
                    }}>中文合同加附件
                    </div>
                </Menu.Item>*/}
                <Menu.Item key="5">
                    <div onClick={() => {
                        pintPurPcMatSupp()
                    }}>补充协议
                    </div>
                </Menu.Item>
            </Menu> :
            <Menu>
                <Menu.Item key="6">
                    <div onClick={() => {
                        pintPurPcMatEnSp()
                    }}>英文合同（单页）
                    </div>
                </Menu.Item>
                <Menu.Item key="7">
                    <div onClick={() => {
                        pintPurPcMatEn()
                    }}>英文合同
                    </div>
                </Menu.Item>
                <Menu.Item key="6">
                    <div onClick={() => {
                        pintPurPcMatEnSpColor()
                    }}>英文合同带颜色（单页）
                    </div>
                </Menu.Item>
                <Menu.Item key="7">
                    <div onClick={() => {
                        pintPurPcMatEnColor()
                    }}>英文合同带颜色
                    </div>
                </Menu.Item>
                <Menu.Item key="6">
                    <div onClick={() => {
                        pintPurPcMatEnSPColorSize()
                    }}>英文合同带颜色尺码（单页）
                    </div>
                </Menu.Item>
                <Menu.Item key="7">
                    <div onClick={() => {
                        pintPurPcMatEnColorSize()
                    }}>英文合同带颜色尺码
                    </div>
                </Menu.Item>
                <Menu.Item key="8">
                    <div onClick={() => {
                        printHTML("EN")
                    }}>英文合同（浏览器）
                    </div>
                </Menu.Item>
            </Menu>

    );

    // 打印html
    const printHTML = async (lang) => {
		let resource = "";
		let fileName = "";
		if(lang && lang == "EN") {
			fileName = '物料采购合同（英文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
			resource = new window.URL(window.location.protocol+window.location.host+"/#/print?rtCode=pur_pc_mat_en&&billId="+data.pcId);
		}else {
			fileName = '物料采购合同（中文）_' +
	        data.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        data.suppName + "_" +
	        moment(new Date()).format(dateFormat);
			resource = new window.URL(window.location.protocol+window.location.host+"/#/print?rtCode=pur_pc_mat_cn&&billId="+data.pcId);
		}
        let htmlPrint = window.open(resource);
        htmlPrint.onload = function () {
			htmlPrint.document.title = fileName
		}
    }

    //定义通用导航标题
    const navigation = {
        navigationTitle: '采购合同【' + itemInfo.pcNo + '】',
        navigationDescriptions: [
            {
                label: <span
                    className="text-color-primary">{useGlobalData.getDictDtlCn("PC_STS", itemInfo.pcStsCode)}</span>,
            },
            itemInfo.pcEvStsCode ?
                {
                    label: '（' + useGlobalData.getDictDtlCn("PC_EV_STS", itemInfo.pcEvStsCode) + '）',
                } : {}
        ],
        navigationButtons: [
            // 草稿
            itemInfo.pcStsCode == "DRAFT" ?
                <Space>
                    <Button size="small" type="primary" onClick={() => submit()} disabled={!hasEditPerm}>提交审批</Button>
                    <Button size="small" type="primary" onClick={() => del()} disabled={!hasEditPerm}>删除采购合同</Button>
                </Space> : <Space></Space>,
            // 审批中
            itemInfo.pcStsCode == "EAING" ?
                <Space>
                   <Button size="small" type="primary" onClick={() => cancelSubmit()} disabled={!hasEditPerm}>撤销审批</Button>
                   <Button size="small" type="primary"  onClick={() => viewFlow()}>审批流程</Button>
                   <Button size="small" type="primary"  onClick={() => refreshData()}>刷新</Button>
                </Space> : <Space></Space>,
            // 审批通过 且 审核通过
            itemInfo.pcStsCode == "EAOK" && itemInfo.pcEvStsCode == "PASS" ?
                <Space>
                    <Button size="small" type="primary" loading={loadingCancelEvPass} onClick={() => cancelEvPass()} disabled={!hasRevokePerm}>取消审核</Button>
                   {/*<Button size="small" type="primary"  onClick={() => setVisibleHistory(true)}>审批流程</Button>*/}
                   {/* <Button size="small" type="primary" onClick={() => showPurBillAlterLogList()}>改单记录</Button>*/}
                </Space> : <Space></Space>,
            // 审批通过 且 未审核或审核取消
            itemInfo.pcStsCode == "EAOK" && (!itemInfo.pcEvStsCode || itemInfo.pcEvStsCode == "CANCEL") ?
                <Space>
                    <Button size="small" type="primary" onClick={() => showAlt()} disabled={!hasChangePerm}>改单</Button>
                    <Button size="small" type="primary" loading={loadingSubmitEvPass} onClick={() => submitEvPass()} disabled={!hasVerifyPerm}>审核</Button>
                    {/*<Button size="small" type="primary"  onClick={() => setVisibleHistory(true)}>审批流程</Button>*/}
                    {/*    <Button size="small" type="primary" onClick={() => showPurBillAlterLogList()}>改单记录</Button>*/}
                </Space> : <Space></Space>,
            // 审批退回 能否删除需要根据其他条件进行判断
            itemInfo.pcStsCode == "EA_BACK" ?
                <Space>
                    <Button size="small" type="primary" onClick={() => submit()} disabled={!hasEditPerm}>提交审批</Button>
                   {/* <Button size="small" type="primary"  onClick={() => setVisibleHistory(true)}>审批流程</Button>*/}
                    {/* <Button size="small" type="primary" onClick={() => showPurBillAlterLogList()}>改单记录</Button>*/}
                    {enableDelPc  ? <Button size="small" type="primary" onClick={() => del()} disabled={!hasEditPerm}>删除采购合同</Button> :
                        <Space></Space>}
                </Space> : <Space></Space>,
            // 改单 能否删除需要根据其他条件进行判断
            itemInfo.pcStsCode == "ALT" ?
                <Space>
                    <Button size="small" type="primary" onClick={() => submit()} disabled={!hasEditPerm}>提交审批</Button>
                  {/*  <Button size="small" type="primary"  onClick={() => setVisibleHistory(true)}>审批流程</Button>*/}
                    {/*  <Button size="small" type="primary" onClick={() => showPurBillAlterLogList()}>改单记录</Button>*/}
                    {(enableDelPc)?
                        !itemInfo.pcEvStsCode ?
                            <Button size="small" type="primary" onClick={() => del()} disabled={!hasEditPerm}>删除采购合同</Button>
                            :
                            <Button size="small" type="primary" loading={loadingRepeal} onClick={() => repeal()} disabled={!hasEditPerm}>作废采购合同</Button>
                        : <Space></Space>
                    }
                </Space> : <Space></Space>,
            !itemInfo.suppPcFlag ?
                <Dropdown.Button overlay={printMenu} size="small" type="primary" ghost>
                    打印
                </Dropdown.Button> : <Space></Space>
        ]
    }

    const frames = [
        {
            key: 'Project',
            name: '采购合同详情',
            children: data.suppPcFlag ?
                    [
                        {
                            key: '1',
                            name: '首页',
                            frame: 'MC/MC20_DT_MC_MPC/MPCDtlBase',
                            props: {itemInfo, variation, refreshFlag, refreshItem, setRefreshItem, ...props},
                        }, {
                            key: '2',
                            name: '采购明细',
                            frame: 'MC/MC20_DT_MC_MPC_DTL/MPCDTLList',
                            props: {itemInfo, variation, refreshFlag, setRefreshFlag, ...props},
                        }, {
                            key: '3',
                            name: '合同条款',
                            frame: 'MC/MC20_DT_MC_MPC_ITEM/MPCDTLITEMList',
                            props: {itemInfo, variation, refreshFlag, refreshItem, ...props},
                        }, {
                            key: '4',
                            name: '线下合同PDF附件',
                            frame: 'MC/MC20_DT_MC_MPC_DOC/MPCDTLSUPDOCList',
                            props: {docParam: suppDocParam, prDocFlag: prDocFlag, cardName: "线下合同PDF附件"},
                        }, {
                            key: '5',
                            name: '合同附件',
                            /* frame: 'MC/MC20_DT_MC_MPC_DOC/MPCDTLDOCList',
                             props: {itemInfo,variation, ...props},*/
                            frame: 'MC/MC05_DT_MC_MAT_PD_DTL/PDDTLDOCList',
                            props: {docParam: pcDocParam, prDocFlag: prDocFlag, cardName: "合同附件"},
                        }
                    ]
                    :
                    [
                     {
                         key: '1',
                         name: '首页',
                         frame: 'MC/MC20_DT_MC_MPC/MPCDtlBase',
                         props: {itemInfo, variation, refreshFlag, refreshItem, setRefreshItem, ...props},
                     }, {
                         key: '2',
                         name: '采购明细',
                         frame: 'MC/MC20_DT_MC_MPC_DTL/MPCDTLList',
                         props: {itemInfo, variation, refreshFlag, setRefreshFlag, ...props},
                     }, {
                         key: '3',
                         name: '合同条款',
                         frame: 'MC/MC20_DT_MC_MPC_ITEM/MPCDTLITEMList',
                         props: {itemInfo, variation, refreshFlag, refreshItem, ...props},
                     }, {
                         key: '4',
                         name: '合同附件',
                         /* frame: 'MC/MC20_DT_MC_MPC_DOC/MPCDTLDOCList',
                          props: {itemInfo,variation, ...props},*/
                         frame: 'MC/MC05_DT_MC_MAT_PD_DTL/PDDTLDOCList',
                         props: {docParam: pcDocParam, prDocFlag: prDocFlag, cardName: "合同附件"},
                     }
                 ]
        },
    ];

    return <>
        <Frame frames={frames} name={"QF21010100001"} navigation={navigation}/>

        <DraggableModal
            visible={visible}
            title='审批采购合同'
            okText="确定"
            cancelText="取消"
            onOk={() => {
                approval();
            }}
            width="30%"
            onCancel={() => setVisible(false)}
        >
            <Form form={form} size="small">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="审批结果" name="eaResult" rules={[
                            {
                                required: true,
                                message: '请选择审批结果',
                            },
                        ]}>
                            <Radio.Group>
                                <Radio value="EAOK">通过</Radio>
                                <Radio value="EA_BACK">退回</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Form.Item label="审批意见" name="eaMemo">
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>

        <DraggableModal
            visible={visibleAltDialog}
            title='采购合同改单'
            okText="确定"
            cancelText="取消"
            onOk={() => {
                alterPurPc();
            }}
            width="30%"
            onCancel={() => setVisibleAltDialog(false)}
        >
            <Form form={formAlt} size="small">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="改单备注" name="altMemo">
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>
        <PurBillAlterLogList visible={purBillAlterLogVisible} setVisible={setPurBillAlterLogVisible}
                             billId={billId}></PurBillAlterLogList>
        <ProcessTaskHistoryModal billId={itemInfo.pcId} visible={visibleHistory} setVisible={setVisibleHistory}/>
        <FlowTaskActiveModal billId={itemInfo.pcId} visible={visibleFlow} setVisible={setVisibleFlow}/>
    </>
}

export default Pane;
