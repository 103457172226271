const diffTable = (rowKey, newRows, oldRows) => {
    const created = new Map()
    const deleted = new Map()
    const updated = new Map()

    const newRowMap = new Map(
        newRows.map((row) => [row[rowKey], row])
    )

    const oldRowMap = new Map(
        oldRows.map((row) => [row[rowKey], row])
    )

    for (const newRow of newRows) {
        const rk = newRow[rowKey]
        const oldRow = oldRowMap.get(rk)

        if (oldRow) {
            if (oldRow !== newRow) {
                updated.set(rk, [newRow, oldRow])
            }
        }
        else {
            created.set(rk, newRow)
        }
    }

    for (const oldRow of oldRows) {
        const rk = oldRow[rowKey]

        if (! newRowMap.has(rk)) {
            deleted.set(rk, oldRow)
        }
    }

    return {created, deleted, updated}
}

export default diffTable
