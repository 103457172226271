import {Checkbox, Radio} from 'antd'

const makeColumnSelection = ({rowSelection, table}) => {
    const {
        columnTitle,
        columnWidth = 40,
        fixed = true,
        getCheckboxProps,
        hideSelectAll,
        renderCell,
        selections, // TODO
        type = 'checkbox',
        onChange,
        onSelect,
        onSelectAll,
        onSelectInvert, // TODO
        onSelectNone, // TODO
    } = rowSelection

    const title = (() => {
        if (hideSelectAll) {
            return null
        }

        if (columnTitle) {
            return columnTitle
        }

        if ('radio' === type) {
            return null
        }

        const {
            refinedRows,
            rowSelection: {selectedRowKeys},
        } = table

        const checked = (
            0 < selectedRowKeys.length &&
            selectedRowKeys.length === refinedRows.length
        )

        const disabled = 0 === refinedRows.length

        const indeterminate = (
            0 < selectedRowKeys.length &&
            selectedRowKeys.length < refinedRows.length
        )

        const handleChange = ({target: {checked}}) => {
            if (checked) {
                const rowKeys = refinedRows.map(
                    ({[table.rowKey]: rk}) => rk
                )

                table.selectRows(rowKeys)
                onSelectAll?.(checked)
                onChange?.(rowKeys, refinedRows)
            }
            else {
                table.selectRows([])
                onSelectAll?.(checked)
                onChange?.([], [])
            }
        }

        return (
            <Checkbox
                checked={checked}
                disabled={disabled}
                indeterminate={indeterminate}
                onChange={handleChange}
            />
        )
    })()

    const onCell = () => ({
        style: {
            backgroundColor: '#fafafa',
            textAlign: 'center',
        },
    })

    const getValue = (row) => {
        const rk = row[table.rowKey]
        const {selectedRowKeys} = table.rowSelection
        return selectedRowKeys.includes(rk)
    }

    const render = (value, row, index, table) => {
        const node = (() => {
            const props = getCheckboxProps?.(row)

            if ('radio' === type) {
                const handleChange = () => {
                    const rowKey = row[table.rowKey]
                    table.selectRows([rowKey])
                    onSelect?.(row)
                    onChange?.([rowKey], [row])
                }

                return (
                    <Radio
                        checked={value}
                        onChange={handleChange}
                        {...props}
                    />
                )
            }
            else {
                const handleChange = ({target: {checked}}) => {
                    const rowKey = row[table.rowKey]
                    const {selectedRowKeys} = table.rowSelection

                    const newSelectedRowKeys = checked ?
                        [...selectedRowKeys, rowKey]
                        :
                        selectedRowKeys.filter((k) => k !== rowKey)

                    table.selectRows(newSelectedRowKeys)
                    onSelect?.(row)

                    const newSelectedRows = newSelectedRowKeys.map(
                        table.getRow
                    )

                    onChange?.(newSelectedRowKeys, newSelectedRows)
                }

                return (
                    <Checkbox
                        checked={value}
                        onChange={handleChange}
                        {...props}
                    />
                )
            }
        })()

        return renderCell ? renderCell(value, row, index, node) : node
    }

    return {
        align: 'center',
        dataIndex: '$selection',
        fixed,
        getValue,
        onCell,
        render,
        title,
        width: columnWidth,
    }
}

export default makeColumnSelection
