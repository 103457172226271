/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import {Timeline, Typography, Spin} from 'antd'
import {CloseCircleOutlined, CheckCircleOutlined, WarningOutlined, ClockCircleOutlined} from '@ant-design/icons'
import Tools from "script/sweet-tools.mjs";

const {Text} = Typography;

const Pane = ({
                  billId,
                  ...props
              }) => {
    const [loading, setLoading] = useState(false);

    //表格数据定义
    const [data, setData] = useState([]);

    const doQuery = async () => {
        setLoading(true);
        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/flowAudit/listActiveFlowExecutionTask", {billId})
                },
                {
                    error: {content: (err) => `查询审批流程失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            if (data) {
                setData(data.map(({assigneeName, authorizerName, ...props}) => {
                    return {
                        ...props,
                        userName: authorizerName ? assigneeName + "(" + authorizerName + ")" : assigneeName
                    }
                }));
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        doQuery();
    }, [])

    return (
        <Spin spinning={loading}>
            {/*<div style={{display: 'flex', flexDirection: 'column'}}>*/}
            {/*    {*/}
            {/*        data.map(({taskName, userName, state, commentMessage, endTime}) => {*/}
            {/*            return (*/}
            {/*                <div style={{display: 'flex', alignItems: 'center'}}>*/}
            {/*                    <div>*/}
            {/*                        {state ? <CheckCircleOutlined style={{color: '#52c41a'}}/> : <CloseCircleOutlined style={{color: '#ff4d4f'}}/>}*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                        <div>*/}

            {/*                        </div>*/}
            {/*                        <div>*/}

            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
            <Timeline css={{
                '& .ant-timeline-item': {
                    paddingBottom: 8
                },
                '& .ant-timeline-item.ant-timeline-item-last': {
                    paddingBottom: 0
                }
            }}>
                {
                    data.map(({flowTaskName, userName, state, commentMessage, endTime}) => {
                        let color, dot, stateName;
                        if (state === 'ACTIVE') {
                            dot = <ClockCircleOutlined
                                style={{
                                    fontSize: '16px',
                                }}
                            />;
                            stateName = '审批中'
                        } else if (state === 'FINISHED') {
                            color = 'green';
                            dot = <CheckCircleOutlined/>;
                            stateName = '已审批'
                        } else {
                            color = 'gray';
                            stateName = '待审批'
                        }

                        return (
                            <Timeline.Item color={color} dot={dot}>
                                <div>
                                    <Text strong>{flowTaskName}</Text>
                                    <Text> {userName ?? ''} ({stateName})</Text>
                                </div>
                                {/*<div><Text type='secondary'>{endTime}</Text></div>*/}
                            </Timeline.Item>
                        );
                    })
                }
            </Timeline>
        </Spin>
    )
}

export default Pane
