import {useEffect, useState} from 'react'

const useObjectURL = (obj) => {
    const [url, setUrl] = useState('')

    useEffect(
        () => {
            if (obj) {
                if ('string' === typeof obj) {
                    setUrl(obj)
                }
                else {
                    const url = URL.createObjectURL(obj)
                    setUrl(url)

                    return () => URL.revokeObjectURL(url)
                }
            }
            else {
                setUrl('')
            }
        },

        [obj]
    )

    return url
}

export default useObjectURL
