const merge = (...objs) => {
    const merged = {}

    for (const obj of objs) {
        for (const [key, value] of Object.entries(obj)) {
            if (
                Object === value?.constructor &&
                Object === merged[key]?.constructor
            ) {
                merged[key] = merge(merged[key], value)
            }
            else {
                merged[key] = value
            }
        }
    }

    return merged
}

export default merge
