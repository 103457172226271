/** @jsxImportSource @emotion/react */
;
import {useEffect, useState} from 'react';
import {Select} from 'antd';
import Tools from "script/sweet-tools.mjs";

/**
 * 销售年份选择组件
 * 组件类型：标准下拉框，
 * 内容:当前年份后两年，当前年份，当前年份前两年
 * 内容标准：四位年份
 * @param searchParam
 * @param searchMode
 * @param selectProps
 * @param value
 * @param onChange
 * @param multiple
 * @param onExtraChange
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Pane = ({searchParam = {}, searchMode = 1, value, onChange, multiple = false,showArrow=true, ...props}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const {Option} = Select;

    //查询数据
    const doQuery = async () => {
        setLoading(true)
        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/date/saleVintages", {})
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setData(data)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        doQuery();
    }, []);

    return (
        <Select
            loading={loading}
            mode={multiple && "multiple"}
            showSearch
            showArrow={showArrow}
            optionFilterProp="children"
            onChange={onChange}
            allowClear
            value={value}
            filterOption={(input, option) =>
                option && option.children && input ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
            }
            {...props}
        >
            {data.map(item => <Option value={item}>{item}</Option>)}
        </Select>
    )
}
export default Pane;
