/** @jsxImportSource @emotion/react */

import React from 'react'

class ErrorBoundary extends React.Component {
    static getDerivedStateFromError(error) {
        return {error}
    }

    reload() {
        this.setState({error: null})
    }

    render() {
        if (this.state?.error) {
            const styleContainer = {
                height: '100%',
                backgroundColor: '#fff',
            }

            const styleBody = {
                maxWidth: 600,
                margin: '48px auto 0 auto',
            }

            const cssStack = {
                '&::first-line': {
                    color: 'rgb(32, 33, 36)',
                    fontSize: 24,
                },

                color: 'rgb(119, 119, 119)',
                fontSize: 15,
                lineHeight: 1.6,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
            }

            const styleButtons = {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                marginTop: 24,
            }

            const styleButton = {
                fontSize: 13,
                color: '#fff',
                backgroundColor: 'rgb(26, 115, 232)',
                padding: '8px 16px',
                border: 0,
                borderRadius: 4,
                cursor: 'pointer',
                userSelect: 'none',
                margin: 0,
            }

            return (
                <div style={styleContainer}>
                    <div style={styleBody}>
                        <div css={cssStack}>{this.state.error.stack}</div>

                        <div style={styleButtons}>
                            <button
                                style={styleButton}
                                onClick={this.reload.bind(this)}
                            >重新加载</button>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return this.props.children 
        }
    }
}

export default ErrorBoundary
