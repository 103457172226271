/** @jsxImportSource @emotion/react */

import {
    cloneElement,
    createContext,
    Fragment,
    useCallback,
    useState,
} from 'react'

import {nanoid} from 'nanoid'
import throttle from 'raf-throttle'
import {defer} from 'hopedove-dom'

export const ModalManagerContext = createContext()

const ModalManager = ({children}) => {
    const [drag, setDrag] = useState(null)
    const [modals, setModals] = useState([])

    const openModal = useCallback(
        (modal) => {
            const deferred = defer()
            const key = nanoid()

            const close = () => {
                setModals((modals) => modals.map(
                    (modal) => {
                        if (modal.key === key) {
                            return cloneElement(modal, {visible: false})
                        }
                        else {
                            return modal
                        }
                    })
                )
            }

            const afterClose = () => {
                setModals((modals) => modals.filter(
                    (modal) => modal.key !== key)
                )
            }

            const onCancel = () => {
                close()
                deferred.resolve()
            }

            const onOk = (result) => {
                close()
                deferred.resolve(result)
            }

            const startDrag = (position) => setDrag([key, position])

            const m = cloneElement(modal, {
                afterClose,
                destroyOnClose: true,
                key,
                onCancel,
                onOk,
                openModal,
                startDrag,
                visible: true,
            })

            setModals((modals) => [...modals, m])
            return deferred.promise
        },

        []
    )

    const handleMouseMove = throttle((e) => {
        const {clientX: x, clientY: y} = e
        const [key, [lastX, lastY]] = drag
        const modal = modals.find((modal) => modal.key === key)
        const {translateX = 0, translateY = 0} = modal.props

        setModals((modals) => modals.map(
            (modal) => {
                if (modal.key === key) {
                    return cloneElement(modal, {
                        translateX: translateX + x - lastX,
                        translateY: translateY + y - lastY,
                    })
                }
                else {
                    return modal
                }
            })
        )

        setDrag([key, [x, y]])
    })

    const handleMouseUp = () => setDrag(null)

    const cssDrag = {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0,
        zIndex: 10000,
        pointerEvents: drag ? 'unset' : 'none',
    }

    return (
        <Fragment>
            <ModalManagerContext.Provider value={openModal}>
                {children}
            </ModalManagerContext.Provider>

            {modals}

            <div
                css={cssDrag}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            ></div>
        </Fragment>
    )
}

export default ModalManager
