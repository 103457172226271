/** @jsxImportSource @emotion/react */

import Input from 'components/Form/DatePicker.jsx'

const InputDate = (props) => {
    return (
        <Input
            bordered={false}
            suffixIcon={null}
            {...props}
        />
    )
}

export default InputDate
