/** @jsxImportSource @emotion/react */

import {useState} from 'react'
import {Button, Checkbox, Divider, Space, Spin, Typography} from 'antd'
import {EyeOutlined, PushpinOutlined} from '@ant-design/icons'
import {SettingOutlined} from '@ant-design/icons'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import Modal, {useOpenModal} from 'components/Modal/Modal.jsx'
import Toggle from 'components/Form/Toggle.jsx'
import Tools from 'script/sweet-tools.mjs'
import useGlobalData from 'hooks/useGlobalData.mjs'

const ConfigSection = ({children, title, ...props}) => {
    const css = {
        '&:not(:last-child)': {
            marginBottom: 16,
        }
    }

    const styleDivider = {
        margin: '8px 0 12px 0'
    }

    return (
        <div
            css={css}
            {...props}
        >
            <Typography.Text strong>{title}</Typography.Text>
            <Divider style={styleDivider}/>
            {children}
        </div>
    )
}

const ConfigSectionTable = ({config, onChange}) => {
    const handleChangeStriped = ({target: {checked: striped}}) => {
        onChange({...config, striped})
    }

    const handleChangeOddColor = (oddColor) => {
        onChange({...config, oddColor})
    }

    const handleChangeEvenColor = (evenColor) => {
        onChange({...config, evenColor})
    }

    return (
        <ConfigSection title="表级配置">
            <Space>
                <Checkbox
                    checked={config.striped}
                    onChange={handleChangeStriped}
                >奇偶行</Checkbox>

                <ColorPicker
                    title="奇数行颜色"
                    value={config.oddColor}
                    onChange={handleChangeOddColor}
                />

                <ColorPicker
                    title="偶数行颜色"
                    value={config.evenColor}
                    onChange={handleChangeEvenColor}
                />
            </Space>
        </ConfigSection>
    )
}

const ConfigColumn = ({column, onChange, ...props}) => {
    const handleChangeFixed = (checked) => {
        onChange({...column, fixed: checked})
    }

    const handleChangeHidden = (checked) => {
        onChange({...column, hidden: ! checked})
    }

    const cssDraggable = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 72,
        height: '100%',
        backgroundColor: '#fff',
        padding: 8,
        borderRadius: 4,
    }

    const styleFoot = {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: 8,
    }

    return (
        <div css={cssDraggable}>
            <Typography.Text>{column.title}</Typography.Text>

            <Space style={styleFoot}>
                <Toggle
                    checked={! column.hidden}
                    title="显示"
                    onChange={handleChangeHidden}
                >
                    <EyeOutlined />
                </Toggle>

                <Toggle
                    checked={column.fixed}
                    title="固定"
                    onChange={handleChangeFixed}
                >
                    <PushpinOutlined />
                </Toggle>
            </Space>
        </div>
    )
}

const ConfigSectionColumns = ({config, onChange}) => {
    const handleDragEnd = ({destination, source}) => {
        if (
            ! destination ||
            destination.index == source.index
        ) {
            return
        }

        const {fixed} = Object.values(config.columns)[source.index]

        if (destination.index < source.index) {
            onChange({
                ...config,

                columns: Object.fromEntries(
                    Object.values(config.columns).map((e) => {
                        if (e.index === source.index) {
                            return [
                                e.title,
                                {...e, index: destination.index}
                            ]
                        }
                        else if (
                            destination.index <= e.index &&
                            e.index < source.index
                        ) {
                            return [
                                e.title,

                                {
                                    ...e,
                                    fixed,
                                    index: e.index + 1,
                                }
                            ]
                        }
                        else {
                            return [e.title, e]
                        }
                    })
                ),
            })
        }
        else {
            onChange({
                ...config,

                columns: Object.fromEntries(
                    Object.values(config.columns).map((e) => {
                        if (e.index === source.index) {
                            return [
                                e.title,
                                {...e, index: destination.index}
                            ]
                        }
                        else if (
                            source.index < e.index &&
                            e.index <= destination.index
                        ) {
                            return [
                                e.title,

                                {
                                    ...e,
                                    fixed,
                                    index: e.index - 1,
                                }
                            ]
                        }
                        else {
                            return [e.title, e]
                        }
                    })
                ),
            })
        }
    }

    const handleChangeColumn = (column) => {
        onChange({
            ...config,

            columns: Object.fromEntries(
                Object.values(config.columns).map((e) => {
                    const {fixed, index} = column

                    if (e.index === index) {
                        return [e.title, column]
                    }
                    else {
                        // 固定/非固定的列必须连续
                        if (
                            (e.index < index && fixed && ! e.fixed) ||
                            (index < e.index && ! fixed && e.fixed)
                        ) {
                            return [e.title, {...e, fixed}]
                        }
                        else {
                            return [e.title, e]
                        }
                    }
                })
            ),
        })
    }

    const draggables = Array.from(Object.values(config.columns))
        .sort((a, b) => a.index - b.index)
        .map((column) => {
            const draggable = (provided, {isDragging}) => (
                <div
                    ref={provided.innerRef}
                    style={{...provided.draggableProps.style}}
                    className={isDragging ? 'shadow' : ''}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ConfigColumn
                        column={column}
                        onChange={handleChangeColumn}
                    />
                </div>
            )

            return (
                <Draggable
                    key={column.title}
                    draggableId={column.title}
                    index={column.index}
                >
                    {draggable}
                </Draggable>
            )
        })

    const droppable = (provided, snapshot) => {
        const css = {
            display: 'flex',
            gap: 8,
            backgroundColor: snapshot.isDragging ? '#ffccc7' : '#f0f0f0',
            padding: 8,
            overflow: 'auto',
        }

        return (
            <div
                ref={provided.innerRef}
                css={css}
                {...provided.droppableProps}
            >
                {draggables}
                {provided.placeholder}
            </div>
        )
    }

    return (
        <ConfigSection title="列级配置">
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="columns"
                    direction="horizontal"
                >
                    {droppable}
                </Droppable>
            </DragDropContext>
        </ConfigSection>
    )
}

const initLocalConfig = (config, tableColumns) => {
    const columnsConfig = config?.columns ?? []

    // 去重
    const columnSet = new Set(
        tableColumns.map((column) => {
            const {title, titleText} = column
            return titleText ?? title
        })
    )

    const columns = Object.fromEntries(
        Array.from(columnSet)
            .map((title) => {
                const {index, ...columnConfig} = columnsConfig[title] ??
                    {index: Infinity, title}

                return [index, columnConfig]
            })
            .sort(([a], [b]) => a - b)
            .map(([, columnConfig], index) => [
                columnConfig.title,
                {index, ...columnConfig}
            ])
    )

    return {
        evenColor: '#f0f0f0',
        oddColor: '#fff',
        striped: false,
        ...config,
        columns,
    }
}

const removeConfig = async (tableCode) => {
    await Tools.runAsync(
        async () => Tools.http.post(
            '/bas/sysTableConfig/removeSysTableConfig', 
            {tableCode}
        ),

        {
            error: {content: (err) => `删除失败: ${err.message}`},
            loading: {show: false},
            success: {show: true},
        }
    )

    await useGlobalData.refreshTableConfig()
}

const saveConfig = async (tableCode, config) => {
    await Tools.runAsync(
        async () => Tools.http.post(
            '/bas/sysTableConfig/saveSysTableConfig', 

            {
                tableCode,
                tcContent: JSON.stringify(config)
            }
        ),

        {
            error: {content: (err) => `保存失败: ${err.message}`},
            loading: {show: false},
            success: {show: true},
        }
    )

    await useGlobalData.refreshTableConfig()
}

const ModalConfig = ({
    columns,
    config,
    tableCode,
    onChange,
    onOk,
    ...props
}) => {
    const [localConfig, setLocalConfig] = useState(
        () => initLocalConfig(config, columns)
    )

    const [loading, setLoading] = useState(false)

    const handleClear = async () => {
        setLoading(true)

        try {
            await removeConfig(tableCode)
            setLocalConfig(initLocalConfig(config, columns))
            onChange(null)
        }
        finally {
            setLoading(false)
        }
    }

    const handleOk = async () => {
        setLoading(true)

        try {
            await saveConfig(tableCode, localConfig)
            onChange(localConfig)
            onOk()
        }
        finally {
            setLoading(false)
        }
    }

    const footer = (
        <Space>
            <Button
                loading={loading}
                size="small"
                onClick={handleClear}
            >清空</Button>

            <Button
                size="small"
                onClick={onOk}
            >关闭</Button>

            <Button
                loading={loading}
                size="small"
                type="primary"
                onClick={handleOk}
            >保存并应用</Button>
        </Space>
    )

    return (
        <Modal
            draggable={false}
            footer={footer}
            title="表格配置"
            width={'90vw'}
            {...props}
        >
            <Spin spinning={loading}>
                <ConfigSectionTable
                    config={localConfig}
                    onChange={setLocalConfig}
                />

                <ConfigSectionColumns
                    config={localConfig}
                    onChange={setLocalConfig}
                />
            </Spin>
        </Modal>
    )
}

const ButtonConfig = ({
    columns,
    config,
    tableCode,
    onChange,
    ...props
}) => {
    const openModal = useOpenModal()

    const css = {
        '&:hover': {
            color: '#1890ff',
            cursor: 'pointer',
        }
    }

    const handleClick = () => {
        openModal(
            <ModalConfig
                columns={columns}
                config={config}
                tableCode={tableCode}
                onChange={onChange}
            />
        )
    }

    return (
        <SettingOutlined
            css={css}
            title="表格配置"
            onClick={handleClick}
            {...props}
        />
    )
}

export default ButtonConfig
