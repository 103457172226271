/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import {Timeline, Typography, Spin, Empty} from 'antd'
import {CloseCircleOutlined, CheckCircleOutlined, WarningOutlined, ClockCircleOutlined} from '@ant-design/icons'
import Tools from "script/sweet-tools.mjs";

const {Text} = Typography;

const Pane = ({
                  billId,
                  reload,
                  ...props
              }) => {
    const [loading, setLoading] = useState(false);

    //表格数据定义
    const [data, setData] = useState([]);

    const doQuery = async () => {
        setLoading(true);
        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/flowInstanceHistory/listFlowInstanceHistory", {billId})
                },
                {
                    error: {content: (err) => `查询审批历史失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            if (data) {
                setData(data);
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        doQuery();
    }, [billId, reload])

    return (
        <Spin spinning={loading}>
            {
                data.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            }
            {
                data.length > 0 && (
                    <Timeline css={{
                        '& .ant-timeline-item': {
                            paddingBottom: 8
                        },
                        '& .ant-timeline-item.ant-timeline-item-last': {
                            paddingBottom: 0
                        }
                    }}>
                        {
                            data.map(({taskName, userName, progressState, state, commentMessage, endTime}) => {
                                let color, dot;

                                if (progressState == 'FINISHED') {
                                    //对于完成的节点，根据审批结果来展示图标
                                    if (state === null) {
                                        color = 'yellow';
                                        dot = <WarningOutlined/>;
                                    } else if (state === true) {
                                        color = 'green';
                                        dot = <CheckCircleOutlined/>;
                                    } else {
                                        color = 'red';
                                        dot = <CloseCircleOutlined/>;
                                    }
                                } else if (progressState == 'ACTIVE') {
                                    //对于当前审批中的节点
                                    dot = <ClockCircleOutlined
                                        style={{
                                            fontSize: '16px',
                                        }}
                                    />;
                                } else {
                                    //未开始审批的节点
                                    dot = <ClockCircleOutlined/>;
                                    color = 'gray';
                                }

                                return (
                                    <Timeline.Item color={color} dot={dot}>
                                        <div>
                                            <Text strong type={state === false ? 'danger' : null}>{taskName}</Text>
                                            <Text type={state === false ? 'danger' : null}> {userName ?? ''} {commentMessage ? `:${commentMessage}` : ''}</Text>
                                        </div>
                                        <div><Text type='secondary'>{endTime ?? ''}</Text></div>
                                    </Timeline.Item>
                                );
                            })
                        }
                    </Timeline>
                )
            }
        </Spin>
    )
}

export default Pane
