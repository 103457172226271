/** @jsxImportSource @emotion/react */
;
import { useEffect, useState} from 'react';
import {Button, Space, Popover, Input, Checkbox, Typography, Divider} from 'antd';
import { FilterOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import CheckboxGroup from './CheckboxGroup.jsx'
import getColumnValue from './getColumnValue.mjs'

const Pane = ({column, dataSource, onChange, disabledSort = false, disabledSearch = false, disabledFilter = false, ...props}) => {
    //const CheckboxGroup = Checkbox.Group;
    const {Text} = Typography;

    //图标是否高亮
    const [highlight, setHighlight] = useState(false)
    const [visible, setVisible] = useState(false)
    const [sortOrder, setSortOrder] = useState(false)
    //过滤条件可选择项
    const [checkOptions, setCheckOptions] = useState([]);
    //过滤条件选择结果
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    //搜索关键字
    const [searchWord, setSearchWord] = useState('');

    const onSearchChange = ({target: {value}}) => {
        setSearchWord(value);
    }

    const onCheckChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < checkOptions.length);
        setCheckAll(list.length === checkOptions.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? checkOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    useEffect(() => {
        (async () => {
            //重置内部展示列
            //补充：datasource 变动时，会导致过滤条件变动，所以也需要重置列数据，以此触发列数据的重新转换
            if (visible) {
                const checkList = new Set();
                dataSource.forEach((data) => {
                    checkList.add(
                        getColumnValue(data, column.dataIndex ?? column.key)
                    )
                })
                setCheckOptions([...checkList]);
            }
        })();
    }, [visible]);

    const handlerSortClick = sortOrder => {
        setSortOrder(sortOrder);
    }

    const handlerReset = () => {
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);

        setSearchWord('')
        setSortOrder(false)
    }

    const handlerCancel = () => {
        setVisible(false);
    }

    const handlerOk = () => {
        setVisible(false);
        if (checkedList.length > 0 || sortOrder || searchWord !== '') {
            setHighlight(true)
        } else {
            setHighlight(false)
        }
        if (onChange) {
            const config = {
                sortOrder,
                checkedList,
                searchWord,
            }
            onChange(column.dataIndex ?? column.key, config)
        }
    }

    //    border-color: #d9d9d9;
    const content = (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {
                !disabledSort && (
                    <>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button css={{cursor: 'default!important',}} block disabled={sortOrder === 'ascend'} size="small" icon={<SortAscendingOutlined/>} onClick={() => handlerSortClick('ascend')}>
                                升序
                            </Button>
                            <Button css={{cursor: 'default!important', marginLeft: '8px'}} block disabled={sortOrder === 'descend'} size="small" icon={<SortDescendingOutlined/>} onClick={() => handlerSortClick('descend')}>
                                降序
                            </Button>
                        </div>
                        <Divider style={{margin: '8px 0'}}/>
                    </>
                )
            }
            {
                !disabledSearch && (
                    <Input  size="small" onChange={onSearchChange} value={searchWord} style={{marginBottom: '8px'}}/>
                )
            }
            {
                !disabledFilter && (
                    <>
                        <div style={{display: 'flex', flexDirection: 'column', height: '200px', width: '200px', marginBottom: '8px', padding: '6px', border: '1px solid #f0f0f0', overflow: 'hidden'}}>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                全选
                            </Checkbox>
                            <Divider style={{margin: '2px 0'}}/>
                            <CheckboxGroup style={{display: 'flex', flexDirection: 'column'}} options={checkOptions} value={checkedList} onChange={onCheckChange}/>
                        </div>
                    </>
                )
            }
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <a onClick={handlerReset}>清除</a>
                <Space>
                    <Button size="small" onClick={handlerCancel}>
                        取消
                    </Button>
                    <Button type={"primary"} size="small" onClick={handlerOk}>
                        确定
                    </Button>
                </Space>
            </div>
        </div>
    )

    const style = {
        color: highlight ? '#1890ff' : ''
    }

    return (
        <Popover placement="bottomRight"
                 content={content}
                 trigger="click"
                 visible={visible}
                 onVisibleChange={visible => setVisible(visible)}
        >
            <Text strong {...props}><FilterOutlined style={style}/></Text>
            {/*<FunnelPlotOutlined style={style}/>*/}
        </Popover>
    )
}

export default Pane;
