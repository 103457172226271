import {useContext, useEffect, useState} from 'react';
import Frame from 'components/Frame/Frame';
import {useMainTabContext} from 'components/MainTabs/MainTabs.jsx'
import {
    Menu,
    Button,
    Space,
    Dropdown,
    message,
    Modal,
    Form,
    Input,
    Col, Radio, Row
} from 'antd';
import useGlobalData from "hooks/useGlobalData.mjs";
import Tools from "script/sweet-tools.mjs";
import PurBillAlterLogList from "pages/BizPage/MC/MC20_DT_MC_MPC/PurBillAlterLogList";
import DraggableModal from "components/DraggableModal/DraggableModal";
import FlowChangeModal from "pages/BizPage/FLOW/AUDIT/FlowChangeModal";
import StartAuditBeforeModal from "pages/BizPage/FLOW/AUDIT/StartAuditBeforeModal";
import {useOpenModal} from 'components/Modal/Modal.jsx'
import ProcessTaskHistoryModal from "../../FLOW/AUDIT/FlowTaskHistoryModal";
import moment from "moment";

// 增加权限控制
import authenticator from "script/sweet-authenticator.mjs";
import FlowTaskActiveModal from "../../FLOW/AUDIT/FlowTaskActiveModal";
import {firstPurDict} from "../../COMM/CurrentComm";

const Pane = ({itemInfo, mainPageQuery, ...props}) => {

	const dateFormat = 'YYYY-MM-DD';
    const { TextArea } = Input;
    const [formSubmit] = Form.useForm();//提交
    const [formEa] = Form.useForm();//审批
    const [formEv] = Form.useForm();//审核
    const [formChange] = Form.useForm();//改单

    const openModal = useOpenModal()

    // 已关联生产计划明细数据
    const [dataRelPP, setDataRelPP] = useState([]);
    // 合同明细loading
    const [loading, setLoading] = useState(false);
    // 关联生产计划明细loading
    const [loadingPP, setLoadingPP] = useState(false);


    // 弹窗显示控制
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [visibleEa, setVisibleEa] = useState(false);
    const [visibleEv, setVisibleEv] = useState(false);
    const [visibleChange, setVisibleChange] = useState(false);
    const [visibleFlow, setVisibleFlow] = useState(false);

    const mainTab = useMainTabContext()
    const { $key } = props; // 当前标签页
    const [purPc, setPurPc] = useState(itemInfo);
    const [pcRow, setPcRow] = useState([]);
    // Loading 控制变量
    const [loadingDel, setLoadingDel] = useState(false);
    const [loadingSubmitApproval, setLoadingSubmitApproval] = useState(false);
    const [loadingWithdrawApproval, setLoadingWithdrawApproval] = useState(false);
    const [loadingAudit, setLoadingAudit] = useState(false);
    const [loadingWithdrawAudit, setLoadingWithdrawAudit] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState(false);

    const [purBillAlterLogVisible, setPurBillAlterLogVisible] = useState(false);
    const [billId,setBillId] = useState("");
    //查询接口数据
    const [data, setData] = useState([]);
    // 选择的合同明细行
    const [selPcRow, setSelPcRow] = useState({});
    // 已关联生产计划明细loading
    const [loadingRelPP, setLoadingRelPP] = useState(false);

    //供应商合同文本附件参数
    const [suppDocParam, setSuppDocParam] = useState({});
    //合同附件参数
    const [pcDocParam, setPcDocParam] = useState({});
    //附件编辑控制参数
    const [prDocFlag, setPrDocFlag] = useState("0");
	// 打印文件名内部款号
    const [prtInsideStyleNo, setPrtInsideStyleNo]= useState("");

    // const [variation, setVariation] = useState("");
    // const [enableDelPc, setEnableDelPc] = useState(true);

    // 查看审批历史
    const [visibleHistory, setVisibleHistory] = useState(false);

    // 子页面是否需要设置编辑模式
    const [ selfControlEditMode,setSelfControlEditMode] = useState({
        "base": false, // 首页是否需要
        "dtl": false, // 明细是否需要
        "item": false // 条款是否需要
    })

    useEffect(() => {
        init();
    }, []);

    // edit权限控制
    const ppcEdit = authenticator.isPermissionDeniedEdit({
            permissionCode: "PPC",
            billOrgPathNo: purPc.orgPathNo,
            billPuId: purPc.pcPuId,
            billUserId: purPc.pcEmpId});

    // change权限控制
    const ppcChange = authenticator.isPermissionDeniedChange({
            permissionCode: "PPC",
            billOrgPathNo: purPc.orgPathNo,
            billPuId: purPc.pcPuId,
            billUserId: purPc.pcEmpId});

 // verify权限控制
    const ppcVerify = authenticator.isPermissionDeniedVerify({
            permissionCode: "PPC",
            billOrgPathNo: purPc.orgPathNo,
            billPuId: purPc.pcPuId,
            billUserId: purPc.pcEmpId});

    // revoke权限控制
    const ppcRevoke = authenticator.isPermissionDeniedRevoke({
        permissionCode: "PPC",
        billOrgPathNo: purPc.orgPathNo,
        billPuId: purPc.pcPuId,
        billUserId: purPc.pcEmpId});

    /**
     * 合同是否编辑状态：草稿、审批退回、改单
     * @param purPc
     * @return
     */
    const isEditPcSts = (purPc) => {
        return purPc.pcStsCode == "DRAFT" || purPc.pcStsCode == "EA_BACK" || purPc.pcStsCode == "ALT";
    }

    /**
     * 合同附件是否编辑状态：除了作废、审批中，都可以上传附件
     * @param purPc
     * @return
     */
    const isDocEditPcSts = (purPc) => {
        return purPc.pcStsCode !== "REPEAL" && purPc.pcStsCode !== "EAING";
    }

    const init = async () => {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/pur/procpurpc/onePurPcDR",
                    {
                        pcId: itemInfo.pcId
                    })
            },
            {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            }
        );
        if(data!=null){
            /**
             * 可删除：草稿、审批退回、改单，且未审核过的
             */
            if( (isEditPcSts(data) && data.pcEvStsCode == null)){
                data.canDelete = true;
            } else{
                data.canDelete = false;
            }

            /**
             * 可作废：改单, 审核取消 且 合同明细都可以改单
             */
            if( isEditPcSts(data) && data.pcEvStsCode != null && data.itsGdFlag!=null && data.itsGdFlag == '1'){
                data.canRepeal = true;
            } else{
                data.canRepeal = false;
            }

            /**
             * 可编辑
             */
            if(isEditPcSts(data)){
                data.canEdit = true;
            } else{
                data.canEdit = false;
            }

            // 合同附件是否编辑状态：除了作废、审批中，都可以上传附件
            if(isDocEditPcSts(data)){
                setPrDocFlag("1");
            }else{
                setPrDocFlag("0");
            }

            if (ppcEdit) {
                data.canEdit = false;
                setPrDocFlag("0");
            }

            setPurPc(data)

            setPcDocParam({
                dsId: data.pcDsId,
                fileDir:firstPurDict+data.pcNo
            });

            setSuppDocParam({
                dsId: data.suppPcDsId,
                fileDir:firstPurDict+data.pcNo
            });

            if (itemInfo.styleInsideNos && itemInfo.styleInsideNos != '') {
                if (itemInfo.styleInsideNos.split(',').length > 1) {
                    setPrtInsideStyleNo(itemInfo.styleInsideNos.split(',')[0]+"等款");
                } else {
                    setPrtInsideStyleNo(itemInfo.styleInsideNos.split(',')[0]);
                }
            }
        }
    }

    // 删除
    const del = async () => {
        try{
            setLoadingDel(true);
            Modal.confirm({
                title: '是否删除？',
                okText: '是',
                cancelText: '否',
                okType: 'danger',
                confirmLoading: {loadingConfirm},
                onOk: async () => {
                    try{
                        if(ppcEdit) {
                            Modal.info({title: '提示信息', content: '编制权限不足'});
                            return;
                        }

                        setLoadingConfirm(true);
                        let prIds = []
                        prIds.push(purPc.pcId)

                        const result = await Tools.runAsync(
                            async () => {
                                return await Tools.http.post("/pur/procpurpc/removePurPcDRs",prIds)
                            },
                            {
                                error: { content: (err) => `删除失败: ${err.message}` },
                                loading: { show: false },
                                /*success: { show: true },*/
                            }
                        );
                        if (result??''!==''){
                            Modal.error({title: '提示信息', content: result});
                            return;
                        }
                        // 删除成功后，刷新列表，并跳转
                        if(mainPageQuery)
                            mainPageQuery();
                        skip();
                    } catch (e) {
                        Modal.destroyAll
                    } finally {
                        setLoadingConfirm(false);
                    }

                }
            });
        }catch (err){
            console.log(err);
        }finally {
            setLoadingDel(false);
        }
    }

    // 曾经审核过，只能作废
    const repeal = async () => {
        Modal.confirm({
            title: '是否作废生产合同？',
            okText: '是',
            cancelText: '否',
            okType: 'danger',
            confirmLoading: {loadingConfirm},
            onOk: async () => {
                let param = {pcId: purPc.pcId}
                try {
                    if(ppcEdit) {
                        Modal.info({title: '提示信息', content: '编制权限不足'});
                        return;
                    }
                    setLoadingConfirm(true);
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/procpurpc/repealPurPcDR",param)
                        },
                        {
                            error: { content: (err) => `作废生产合同失败: ${err.message}` },
                            loading: { show: false },
                            /*success: { show: true },*/
                        }
                    );
                    if ( result??''!='' ){
                        Modal.error({title: '提示信息', content: result});
                        return;
                    }else{
                        message.success("作废成功");
                    }
                    if(mainPageQuery)
                        mainPageQuery();
                    init();
                } catch (e) {
                    Modal.destroyAll
                }
                finally {
                    setLoadingConfirm(false);
                }
            }
        });
    }


    // 跳转列表
    const skip = async () => {
        //关闭当前页面
        mainTab.close();
        /*mainTab.openTab({
            name: '生产合同',
            pane: 'PC/PC30_DT_PC_GPC/GPCList',
        });*/
    }

    const menu = (
        <Menu >
            <Menu.Item key="1">
                <div onClick={() => { printSP() }}>不分批（单页）</div>
            </Menu.Item>
            {/*<Menu.Item key="2">
                <div onClick={() => { print() }}>不分批</div>
            </Menu.Item>*/}
            <Menu.Item key="3">
                <div onClick={() => { printPPSP() }}>分批（单页）</div>
            </Menu.Item>
            {/*<Menu.Item key="4">
                <div onClick={() => { printPP() }}>分批</div>
            </Menu.Item>*/}
            <Menu.Item key="5">
                <div onClick={() => { printHTML() }}>不分批（浏览器）</div>
            </Menu.Item>
            <Menu.Item key="6">
                <div onClick={() => { printPPHTML() }}>分批（浏览器）</div>
            </Menu.Item>
        </Menu>
    );

    // 不分批次打印(单页）
    const printSP = async () => {
        let cont = 'EN_GB'?'英文':'中文';
        let fileName = '生产合同（'+cont+'）_' +
	        itemInfo.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        itemInfo.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        // 加工合同
        if(purPc.pcTypeCode=='PROC'){
            Tools.openReportView({code: purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_en_sp':'pur_pc_prod_sp', payload: {pcId:purPc.pcId, __filename__: fileName}});
        }
        // 经销合同
        else{
            Tools.openReportView({code: purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_gar_en_sp':'pur_pc_prod_gar_sp', payload: {pcId:purPc.pcId, __filename__: fileName}});
        }
    }

    // 不分批次打印
    const print = async () => {
		let cont = 'EN_GB'?'英文':'中文';
		let fileName = '生产合同（'+cont+'）_' +
	        itemInfo.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        itemInfo.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        // 加工合同
        if(purPc.pcTypeCode=='PROC'){
            Tools.openReportView({code: purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_en':'pur_pc_prod', payload: {pcId:purPc.pcId, __filename__: fileName}});
        }
        // 经销合同
        else{
            Tools.openReportView({code: purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_gar_en':'pur_pc_prod_gar', payload: {pcId:purPc.pcId, __filename__: fileName}});
        }
    }

    // 分批次打印(单页）
    const printPPSP = async () => {
		let cont = 'EN_GB'?'英文':'中文';
		let fileName = '生产合同（'+cont+'）_' +
	        itemInfo.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        itemInfo.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        // 分批次加工、经销合同用同一个模板，中英文版本分开
        Tools.openReportView({code: purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_pp_en_sp':'pur_pc_prod_pp_sp', payload: {pcId:purPc.pcId, __filename__: fileName}});
    }

    // 分批次打印
    const printPP = async () => {
		let cont = 'EN_GB'?'英文':'中文';
		let fileName = '生产合同（'+cont+'）_' +
	        itemInfo.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        itemInfo.suppName + "_" +
	        moment(new Date()).format(dateFormat);
       // 分批次加工、经销合同用同一个模板，中英文版本分开
       Tools.openReportView({code: purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_pp_en':'pur_pc_prod_pp', payload: {pcId:purPc.pcId, __filename__: fileName}});
    }

    // 打印html
    const printHTML = async () => {
        let rtCode = null;
        let cont = 'EN_GB'?'英文':'中文';
		let fileName = '生产合同（'+cont+'）_' +
	        itemInfo.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        itemInfo.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        // 加工合同
        if(purPc.pcTypeCode=='PROC'){
            rtCode = purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_en':'pur_pc_prod';
        }
        // 经销合同
        else{
            rtCode = purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_gar_en':'pur_pc_prod_gar';
        }
        let resource = new window.URL(window.location.protocol+window.location.host+"/#/print?rtCode="+rtCode+"&billId="+itemInfo.pcId);
        let htmlPrint = window.open(resource);
        htmlPrint.onload = function () {
			htmlPrint.document.title = fileName
		}
    }

    // 分批打印html
    const printPPHTML = async () => {
		let cont = 'EN_GB'?'英文':'中文';
		let fileName = '生产合同（'+cont+'）_' +
	        itemInfo.pcNo + "_" +
	        prtInsideStyleNo + "_" +
	        itemInfo.suppName + "_" +
	        moment(new Date()).format(dateFormat);
        let rtCode = purPc.itemLngTypeCode=='EN_GB'?'pur_pc_prod_pp_en':'pur_pc_prod_pp';
        let resource = new window.URL(window.location.protocol+window.location.host+"/#/print?rtCode="+rtCode+"&billId="+itemInfo.pcId);
        let htmlPrint = window.open(resource);
        htmlPrint.onload = function () {
			htmlPrint.document.title = fileName
		}
    }

    const changeOrder = async () => {
        if (ppcChange) {
            Modal.info({title: '提示信息', content: '改单权限不足'});
            return;
        }
        await openModal(
            <FlowChangeModal
                params={{
                    onSave: async ({commitMessage}) => {
                        let param = {pcId: itemInfo.pcId}
                        param.altMemo = commitMessage;
                        const result = await Tools.runAsync(
                            async () => {
                                return await Tools.http.post("/pur/procpurpc/changeOrder", param)
                            },
                            {
                                error: {content: (err) => `改单操作失败: ${err.message}`},
                                loading: {show: false},
                                success: {show: true},
                            }
                        );
                        if (!result.succ) {
                            Modal.error({title: '提示信息', content: result.errMsg});
                            return;
                        }
                        init();
                    }
                }}
            />
        )

    }

    const withdrawApproval = async () => {
        let param = {pcId: itemInfo.pcId}
        Modal.confirm({
            title: '确定撤销审批生产采购合同吗？',
            okText: '是',
            cancelText: '否',
            onOk: async () => {
                try {
                    setLoadingWithdrawApproval(true);
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/procpurpc/cancelPurPcDR", param)
                        },
                        {
                            error: {content: (err) => `查询失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );
                } finally {
                    setLoadingWithdrawApproval(false);
                    init();
                }
            }
        })
    }

    //查看审批流程
    const viewFlow = async () => {
        setVisibleFlow(true);
    }

    // 提交审批
    const submitApproval = async () => {
        let purPcData = null;
        let contractAmount = purPc?.pcAmt ? purPc?.pcAmt : purPc?.txcRmbAmt;
        let param = {pcId: itemInfo.pcId,contractAmount:contractAmount}
        //校验
        try {
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/validateSubmitPurPc", param)
                },
                {
                    error: {content: (err) => `提交审批失败: ${err.message}`},
                    loading: {show: false},
                    success: {show:false},
                }
            );
            if ( result != null && result != ''){
                Modal.error({
                    title: '提交审批失败',
                    content: result,
                });
                return;
            }
        } catch (e) {
            console.log(e);
            return;
        } finally {

        }

        //重新查询合同信息
        try {
            param.queryEvParamFlag = true;
            purPcData = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/purpc/onePurPcDR", param)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            )
        }
        catch (e) {
            console.log(e);
            return;
        } finally {
        }
        let variables= {};
        variables.contractOrg = purPcData.buId;
        variables.contractAmount = purPcData?.currCode == 'CNY' ?  purPcData.txAmt : purPcData.txcRmbAmt;
        variables.legalEvFlag = purPcData.legalEvFlag;
        variables.suppPcFlag= purPcData.suppPcFlag;
        variables.foreignFlag= purPcData.foreignFlag; // 是否海外加工
        variables.overBudgetFlag= purPcData.overBudgetFlag; //是否超预算
        let flowModuleCode = "PURPRODPC";
        if ( purPcData.autoEvFlag ){
            flowModuleCode = "PURPRODPC_AUTO";
        }
        const success = await openModal(
            <StartAuditBeforeModal
                params={{
                    flowModuleCode: flowModuleCode,
                    orgId: purPcData.buId,
                    puId: purPcData.pcPuId,
                    empId: purPcData.crtUserId,
                    billId: purPcData.pcId,
                    billNo: purPcData.pcNo,
                    billKeyInfo: purPcData.styleNos,
                    variables: variables
                }}
            />
        );
        if ( success ){
            try {
                if ( purPcData.autoEvFlag ){
                    param.eaResult="EAOK";
                    param.autoEvFlag=true;
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/procpurpc/approval", param)
                        },
                        {
                            error: {content: (err) => `提交审批失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: false},
                        }
                    );
                }else {
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/procpurpc/submitApproval", param)
                        },
                        {
                            error: {content: (err) => `提交审批失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: false},
                        }
                    );
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoadingSubmitApproval(false);
                init();
            }
        }
    }

    // 提交审批
    /*const submitApproval = async () => {
        // 如果没有明细不能提交
        let param = {
            pcId: purPc.pcId
        }
        if () {
				Modal.info({title: '提示信息', content: '编制权限不足'});
	        	return;
	    }

        const val = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/pur/purpcprodrow/listPurPcProdRowDRs", param)
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
            }
        )
        if (val == null || val == undefined || val.length <= 0) {
            message.warning('生产合同还没有明细不能提交!');
            return
        } else {
            //formSubmit.resetFields();
            //setVisibleSubmit(true);

            doSubmitApproval();
        }
    }*/
    // 执行提交审批
    /*const doSubmitApproval = async () => {
        try {
            setLoadingSubmitApproval(true);
            //setLoadingConfirm(true);
            let param = await formSubmit.validateFields();
            param.pcId = purPc.pcId;
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/submitApproval", param)
                },
                {
                    error: {content: (err) => `提交审批失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: "成功"},
                }
            )
        } finally {
            setLoadingSubmitApproval(false);
            //setLoadingConfirm(false);
            //setVisibleSubmit(false);
            init();
        }
    }*/

    // 审批
    const approval = async () => {
        formEa.resetFields();
        formEa.setFieldsValue({
            eaResult:'EAOK'
        })
        setVisibleEa(true);
        /*Modal.confirm({
            icon: <></>,
            title:<div className={'ant-modal-title'}>审批生产合同</div>,
            okText:"保存",
            cancelText:"取消",
            destroyOnClose: true,
            content: (
                <Form form={formEa} size="small">
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="审批结果" name="eaResult" rules={[
                                {
                                    required: true,
                                    message: '请选择审批结果',
                                },
                            ]} >
                                <Radio.Group>
                                    <Radio value="EAOK">通过</Radio>
                                    <Radio value="EA_BACK">退回</Radio>
                                </Radio.Group>
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item label="审批意见" name="eaMemo">
                                <TextArea rows={4}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            ),
            onOk:async () => {
                try {
                    let param = await formEa.validateFields();
                    param.pcId = purPc.pcId;
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/procpurpc/approval", param)
                        },
                        {
                            error: { content: (err) => `审批失败: ${err.message}` },
                            loading: { show: false },
                            success: { show: "审批成功" },
                        }
                    )
                } finally {
                    init();
                }
            },
        })*/
    }

    // 执行审批
    const doApproval = async () => {
        try {
            setLoadingConfirm(true);
            let param = await formEa.validateFields();
            param.pcId = purPc.pcId;
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/approval", param)
                },
                {
                    error: { content: (err) => `审批失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: "审批成功" },
                }
            )
        } finally {
            setLoadingConfirm(false);
            setVisibleEa(false);
            init();
        }
    }

    /*// 撤销审批
    const withdrawApproval = async () => {
        try {
            setLoadingWithdrawApproval(true);
            let param = {pcId:purPc.pcId}

            if (ppcEdit) {
					Modal.info({title: '提示信息', content: '编制权限不足'});
		        	return;
	    	}

            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/withdrawApproval", {
                        pcId:purPc.pcId
                    })
                },
                {
                    error: { content: (err) => `撤销审批失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: "成功" },
                }
            )
        } finally {
            setLoadingWithdrawApproval(false);
            init();
        }
    }*/

    // 审核
    const audit = async () => {
        //formEv.resetFields();
        //setVisibleEv(true);
        if (ppcVerify) {
            Modal.info({title: '提示信息', content: '审核权限不足'});
            return;
        }

        if(purPc.pcStsCode != 'EAOK'){
            message.warning('只能对审批通过的生产合同标记审核!');
            return ;
        }
        Modal.confirm({
            title: '确定标记生产合同审核通过吗？',
            okText: '是',
            cancelText: '否',
            onOk: async () => {
                doAudit();
            }
        })
    }

    // 执行审核
    const doAudit = async () => {
        try {
            setLoadingAudit(true);
            //setLoadingConfirm(true);
            let param = await formEv.validateFields();
            param.pcId = purPc.pcId;
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/audit", param)
                },
                {
                    error: { content: (err) => `审核失败: ${err.message}` },
                    loading: { show: false },
                    //success: { show: "审核成功" },
                }
            )

            if(result && !result.succ){
                message.error(result.errMsg?result.errMsg:'审核失败');
            }else{
                message.success("审核成功");
            }
        } finally {
            setLoadingAudit(false);
            //setLoadingConfirm(false);
            //setVisibleEv(false);
            init();
        }
    }

    // 取消审核
    const withdrawAudit = async () => {
        if(ppcRevoke) {
            Modal.info({title: '提示信息', content: '审核权限不足'});
            return;
        }

        if(purPc.pcStsCode != 'EAOK' && purPc.pcEvStsCode !='PASS'){
            message.warning('只能对审批通过且已审核通过的生产合同取消审核!');
            return ;
        }

        const result = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/pur/purpc/getAltPcPermissionFlag", {pcId: purPc.pcId});
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        );
        console.log(">>>getAltPcPermissionFlag=" + result);
        if (result === '2') {
            Modal.error({
                title: '取消审核失败',
                content: '您不具备给对前合同做取消审核的权限！',
            });
            return;
        }

        Modal.confirm({
            title: '确定对生产合同进行取消审核操作吗？',
            okText: '是',
            cancelText: '否',
            onOk: async () => {
                try {
                    setLoadingWithdrawAudit(true);
                    let param = {pcId:purPc.pcId}
                    const result = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/pur/procpurpc/withdrawAudit", {
                                pcId:purPc.pcId
                            })
                        },
                        {
                            error: { content: (err) => `取消审核失败: ${err.message}` },
                            loading: { show: false },
                            //success: { show: "成功" },
                        }
                    )
                    if(result && !result.succ){
                        message.error(result.errMsg?result.errMsg:'取消审核失败');
                    }else{
                        message.success("取消审核成功");
                    }
                } finally {
                    setLoadingWithdrawAudit(false);
                    init();
                }
            }
        })
    }

    // 改单
  /*  const changeOrder = async () => {
        formChange.resetFields();
        setVisibleChange(true);

    }*/

    // 执行改单
   /* const doChangeOrder = async () => {
        try {
            if (ppcChange) {
                    Modal.info({title: '提示信息', content: '改单权限不足'});
                    return;
            }
            setLoadingConfirm(true);
            let param = await formChange.validateFields();
            param.pcId = purPc.pcId;
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/changeOrder", param)
                },
                {
                    error: { content: (err) => `改单失败: ${err.message}` },
                    loading: { show: false },
                    //success: { show: "改单成功" },
                }
            )
            if(result && !result.succ){
                message.error(result.errMsg?result.errMsg:'改单失败');
            }else{
                message.success("改单成功");
            }
        } finally {
            setLoadingConfirm(false);
            setVisibleChange(false);
            init();
        }
    }
*/
    //改单记录弹窗
    const showPurBillAlterLogList = async () => {
        setBillId(purPc.pcId)  //单据ID
        setPurBillAlterLogVisible(true)    // 弹窗控制
    }

    //定义通用导航标题
    const navigation = {
        navigationTitle: <div>生产合同【<span className="text-color-primary">{purPc.pcNo}</span>】</div>,
        navigationDescriptions: [
            {
                label: <span className="text-color-primary">{useGlobalData.getDictDtlCn("PC_STS",purPc.pcStsCode)}</span>,
            },
            purPc.pcEvStsCode ?
                {
                    label: <div style={{color:"#000000"}}><span className="text-color-primary">（{useGlobalData.getDictDtlCn("PC_EV_STS",purPc.pcEvStsCode)}）</span> </div>,
                }:{}
        ],
        navigationButtons: [

            /*// 草稿
            purPc.pcStsCode == "DRAFT" ?
                <Space>
                    <Button size="small" type="primary"
                    disabled={ppcEdit} onClick={()=>submitApproval()}>提交审批</Button>
                    <Button size="small" type="primary" onClick={() => del()}
                    	disabled={ppcEdit && loadingDel} loading={loadingDel}>删除生产合同</Button>
                </Space> : <Space></Space>,
            // 待审批
            purPc.pcStsCode == "TOEA" ?
                <Space>
                    <Button size="small" type="primary"
                    	disabled={ppcEdit} onClick={()=>withdrawApproval()}>撤销审批</Button>
                    <Button size="small" type="primary" onClick={()=>approval()}>审批</Button>
                    <Button size="small" type="primary">审批流程</Button>
                </Space> : <Space></Space>,
            // 审批中
            purPc.pcStsCode == "EAING" ?
                <Space>
                    <Button size="small" type="primary">审批流程</Button>
                </Space> : <Space></Space>,
            // 审批通过
            purPc.pcStsCode == "EAOK" ?
                <Space>
                    <Button size="small" type="primary">审批流程</Button>
                    <Button size="small" type="primary" onClick={()=>submitAudit()}>提交审核</Button>
                    <Button size="small" type="primary">改单</Button>
                    <Button size="small" type="primary">改单记录</Button>
                </Space> : <Space></Space>,
            // 审批退回
            purPc.pcStsCode == "EA_BACK" ?
                <Space>
                    <Button size="small" type="primary"
                    	disabled={ppcEdit} onClick={()=>submitApproval()}>提交审批</Button>
                    <Button size="small" type="primary">审批流程</Button>
                </Space> : <Space></Space>,
            // 待审核
            purPc.pcStsCode == "TOEV" ?
                <Space>
                    <Button size="small" type="primary">审批流程</Button>
                    <Button size="small" type="primary" onClick={()=>withdrawAudit()}>撤销审核</Button>
                    <Button size="small" type="primary" onClick={()=>audit()}>审核</Button>
                </Space> : <Space></Space>,
            // 审核中
            purPc.pcStsCode == "EVING" ?
                <Space>
                    <Button size="small" type="primary">审批流程</Button>
                </Space> : <Space></Space>,
            // 审核退回
            purPc.pcStsCode == "EV_BACK" ?
                <Space>
                    <Button size="small" type="primary">审批流程</Button>
                    <Button size="small" type="primary">改单</Button>
                    <Button size="small" type="primary">改单记录</Button>
                </Space> : <Space></Space>,
            // 合同生效
            purPc.pcStsCode == "RLS" ?
                <Space>
                    <Button size="small" type="primary" onClick={()=>withdraw()}>撤销</Button>
                    <Button size="small" type="primary">审批流程</Button>
                    <Button size="small" type="primary">改单</Button>
                    <Button size="small" type="primary">改单记录</Button>
                </Space> : <Space></Space>,*/
            <Space>

                {(purPc.pcStsCode == "DRAFT" || purPc.pcStsCode == "EA_BACK" || purPc.pcStsCode == "ALT")&&
                  <>
                    <Button size="small" type="primary" onClick={()=>submitApproval()} disabled={ppcEdit || loadingSubmitApproval} loading={loadingSubmitApproval}>提交审批</Button>
                  </>
                }
                {purPc.pcStsCode == "EAING" &&
                    <>
                     <Button size="small" type="primary" onClick={()=>withdrawApproval()} disabled={ppcEdit || loadingWithdrawApproval} loading={loadingWithdrawApproval}>撤销审批</Button>
                    </>
                }
                {purPc.pcStsCode == "EAING" &&
                    <>
                        <Button size="small" type="primary"  onClick={() => viewFlow()}>审批流程</Button>
                    </>
                }
                {(purPc.pcStsCode == "EAOK" && (purPc.pcEvStsCode==null || purPc.pcEvStsCode == "CANCEL")) &&
                    <Button size="small" type="primary" onClick={()=>audit()}  disabled={ppcVerify || loadingAudit} loading={loadingAudit}>审核</Button> }
                {purPc.pcEvStsCode == "PASS" &&
                    <Button size="small" type="primary" onClick={()=>withdrawAudit()} disabled={ppcRevoke || loadingWithdrawAudit} loading={loadingWithdrawAudit}>取消审核</Button>}
                {(purPc.pcStsCode == "EAOK" && (!itemInfo.pcEvStsCode || purPc.pcEvStsCode == "CANCEL")) &&
                 <>
                    <Button size="small" type="primary" disabled={ppcChange} onClick={()=>changeOrder()}>改单</Button>
                  </>
                }
                {purPc.canDelete &&
                    <Button size="small" type="primary" onClick={() => del()}
                        disabled={ppcEdit || loadingDel} loading={loadingDel}>删除生产合同</Button>}
                {purPc.canRepeal &&
                    <Button size="small" type="primary" disabled={ppcEdit} onClick={() => repeal()}>作废生产合同</Button>}
                {!purPc.suppPcFlag &&
                <Dropdown.Button overlay={menu} type="primary" size="small">
                    打印
                </Dropdown.Button>
                }
            </Space>

        ]
    }

    const frames = [
        {
            key: 'Project',
            name: '生产合同详情',
            children: [
                {
                    key: '1',
                    name: '首页',
                    frame: 'PC/PC30_DT_PC_GPC/GPCDTLbase',
                    props: {itemInfo:purPc,onFrameCall:init,ppcEdit:ppcEdit, selfControlEditMode, setSelfControlEditMode, ...props},
                },{
                    key: '2',
                    name: '生产合同明细',
                    frame: 'PC/PC30_DT_PC_GPC/GPCDTLDetail',
                    props: {itemInfo:purPc,onFrameCall:init,ppcEdit:ppcEdit, selfControlEditMode, setSelfControlEditMode, ...props},
                },{
                    key: '3',
                    name: '合同条款',
                    frame: 'PC/PC30_DT_PC_GPC/GPODTLITEMList',
                    props: {itemInfo:purPc,onFrameCall:init,ppcEdit:ppcEdit, selfControlEditMode, setSelfControlEditMode, ...props},
                },
                purPc.suppPcFlag && {
                    key: '4',
                    name: '线下合同PDF附件',
                    frame: 'MC/MC20_DT_MC_MPC_DOC/MPCDTLSUPDOCList',
                    props: {docParam: suppDocParam, prDocFlag: prDocFlag, cardName: "线下合同PDF附件"},
                }, {
                    key: '5',
                    name: '合同附件',
                    /*frame: 'PC/PC30_DT_PC_GPC/GPCDTLDoc',
                    props: {itemInfo:purPc,onFrameCall:init, ...props},*/
                    frame: 'MC/MC05_DT_MC_MAT_PD_DTL/PDDTLDOCList',
                    props: {docParam:pcDocParam,prDocFlag:prDocFlag,cardName:"合同附件"},
                },
            ].filter((r) => { return r }),
        },
    ];

    return <>
        <Frame frames={frames} name={purPc.pcNo} navigation={navigation}/>
        <DraggableModal
            visible={visibleSubmit}
            title='提交审批'
            okText="确定"
            cancelText="取消"
            onOk={() => doSubmitApproval()}
            width  = "30%"
            onCancel={() => setVisibleSubmit(false)}
            confirmLoading={loadingConfirm}
        >
            <Form form={formSubmit} size="small">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name='submitMemo' label='提交说明' layout="vertical">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>

        <DraggableModal
            visible={visibleEa}
            title='审批'
            okText="确定"
            cancelText="取消"
            onOk={() => doApproval()}
            width  = "30%"
            onCancel={() => setVisibleEa(false)}
            confirmLoading={loadingConfirm}
        >
            <Form form={formEa} size="small">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="审批结果" name="eaResult" rules={[
                            {
                                required: true,
                                message: '请选择审批结果',
                            },
                        ]} >
                            <Radio.Group>
                                <Radio value="EAOK">通过</Radio>
                                <Radio value="EA_BACK">退回</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Form.Item label="审批意见" name="eaMemo">
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>

        <DraggableModal
            visible={visibleEv}
            title='审核'
            okText="确定"
            cancelText="取消"
            onOk={() => doAudit()}
            width  = "30%"
            onCancel={() => setVisibleEv(false)}
            confirmLoading={loadingConfirm}
        >
            <Form form={formEv} size="small">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name='evMemo' label='审核说明' layout="vertical">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>

        <DraggableModal
            visible={visibleChange}
            title='改单'
            okText="确定"
            cancelText="取消"
            onOk={() => doChangeOrder()}
            width  = "30%"
            onCancel={() => setVisibleChange(false)}
            confirmLoading={loadingConfirm}
        >
            <Form form={formChange} size="small">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name='altMemo' label='改单说明' layout="vertical">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>

        <PurBillAlterLogList visible={purBillAlterLogVisible} setVisible={setPurBillAlterLogVisible} billId={billId}></PurBillAlterLogList>
        <ProcessTaskHistoryModal billId={itemInfo.pcId} visible={visibleHistory} setVisible={setVisibleHistory}/>
        <FlowTaskActiveModal billId={itemInfo.pcId} visible={visibleFlow} setVisible={setVisibleFlow}/>
    </>;
}

export default Pane;
