/** @jsxImportSource @emotion/react */

import {useTableContext} from '../TableContext.mjs'
import TopButton from './TopButton.jsx'

const ButtonCreateRows = ({
    children = '新增',
    count = 1,
    makeRows = () => Array.from(new Array(count)).map(() => ({})),
    ...props
}) => {
    const table = useTableContext()

    const handleClick = () => {
        const {selectedRowKeys = []} = table.rowSelection ?? {}

        if (0 < selectedRowKeys.length) {
            const pairs = selectedRowKeys.map(
                (key) => [key, makeRows()]
            )

            table.insertRowsAfter(pairs)
        }
        else {
            table.appendRows(makeRows())
        }
    }

    return (
        <TopButton
            onClick={handleClick}
            {...props}
        >{children}</TopButton>
    )
}

export default ButtonCreateRows
