/** @jsxImportSource @emotion/react */

import {useEffect, useState} from 'react'
import {Table as AntdTable} from 'antd'
import VTable from 'components/VirtualTable/VirtualTable.jsx'

const appendClassName = (props, className) => {
    if (className) {
        if (props.className) {
            props.className += ` ${className}`
        }
        else {
            props.className = className
        }
    }
}

const useFixes = (elTable, columns) => {
    useEffect(
        () => {
            if (! elTable) {
                return
            }

            const table = elTable.querySelector('.ant-table')
            const vTable = elTable.querySelector('.ant-table-body')

            /**
             * 修复表格标题固定列位置
             */
            const fixColFixed = () => {
                {
                    const ths = elTable.querySelectorAll(
                        '.ant-table-thead .ant-table-cell-fix-left'
                    )

                    let left = 0

                    for (let i = 0; i < ths.length; i += 1) {
                        ths[i].style.left = `${left}px`

                        // th has 1px right border
                        left += ths[i].clientWidth + 1
                    }
                }

                {
                    const ths = elTable.querySelectorAll(
                        '.ant-table-thead .ant-table-cell-fix-right'
                    )

                    let right = 0

                    for (let i = ths.length - 1; -1 < i; i -= 1) {
                        ths[i].style.right = `${right}px`

                        // th has 1px right border
                        right += ths[i].clientWidth + 1
                    }
                }
            }

            /**
             * 修复表格 Ping 状态
             */
            const fixPing = () => {
                const tableHeader = table.querySelector('.ant-table-header')
                const tableHeaderContent = tableHeader.querySelector('table')

                if (tableHeader.clientWidth < tableHeaderContent.clientWidth) {
                    table.classList.add('ant-table-ping-right')
                }
                else {
                    table.classList.remove('ant-table-ping-right')
                }
            }

            /**
             * 修复因滚动条造成的表格头部和内容的错位
             */
            const fixScrollBar = () => {
                // 等表格内容加载完
                setTimeout(() => {
                    const scrollbar = elTable.querySelector(
                        '.ant-table-cell-scrollbar'
                    )

                    if (scrollbar) {
                        const colLast = elTable.querySelector(
                            '.ant-table-header col:nth-last-child(2)'
                        )

                        const {width} = columns.at(-1)
                        colLast.style.width = `${width}px`
                    }
                })
            }

            const observer = new window.ResizeObserver(() => {
                fixColFixed()
                fixPing()
                fixScrollBar()
            })

            observer.observe(vTable)
            return () => observer.unobserve(vTable)
        },

        [columns, elTable]
    )
}

const VirtualTable = ({
    columns,
    rowHeight,
    rowKey: keyProp,
    onRow,
    ...props
}) => {
    const [elTable, setElTable] = useState()

    const callbackRefTable = (elTable) => {
        if (elTable) {
            setElTable(elTable)
        }
    }

    const [fixedLeftCount, fixedRightCount] = (() => {
        let leftCount = 0
        let rightCount = 0

        for (const {fixed} of columns) {
            if (true === fixed || 'left' === fixed) {
                leftCount += 1
            }
            else if ('right' === fixed) {
                rightCount += 1
            }
        }

        return [leftCount, rightCount]
    })()

    useFixes(elTable, columns)

    const VirtualBody = (rows, {onScroll}) => {
        const contentBodyProps = {
            className: 'ant-table-tbody',
        }

        const render = (rowIndex, colIndex) => {
            const {render} = columns[colIndex]
            return render(null, rows[rowIndex], rowIndex)
        }

        const rowKey = (rowIndex) => rows[rowIndex][keyProp]

        const rowProps = (rowIndex) => {
            let className = 'ant-table-row ant-table-row-level-0'

            if (onRow) {
                const props = onRow(rows[rowIndex], rowIndex) ?? {}
                appendClassName(props, className)
                return props
            }
            else {
                return {className}
            }
        }

        const cellProps = (rowIndex, colIndex) => {
            const column = columns[colIndex]

            const className = (() => {
                const classNames = ['ant-table-cell']

                if (colIndex <= fixedLeftCount - 1) {
                    classNames.push('ant-table-cell-fix-left')

                    if (colIndex === fixedLeftCount - 1) {
                        classNames.push('ant-table-cell-fix-left-last')
                    }
                }
                else if (columns.length - fixedRightCount <= colIndex) {
                    classNames.push('ant-table-cell-fix-right')

                    if (colIndex === columns.length - fixedRightCount) {
                        classNames.push('ant-table-cell-fix-right-first')
                    }
                }

                if (column.className) {
                    classNames.push(column.className)
                }

                return classNames.join(' ')
            })()

            if (column.onCell) {
                const props = column.onCell(rows[rowIndex], rowIndex) ?? {}
                appendClassName(props, className)
                return props
            }
            else {
                return {className}
            }
        }

        const handleScroll = ({
            target: {clientWidth, scrollLeft, scrollWidth}
        }) => {
            onScroll({scrollLeft})

            // 补齐样式，使之与 AntD 表格默认样式一致

            const table = elTable.querySelector('.ant-table')

            if (0 < scrollLeft && 0 < fixedLeftCount) {
                table.classList.add('ant-table-ping-left')
            }
            else {
                table.classList.remove('ant-table-ping-left')
            }

            if (scrollLeft + clientWidth < scrollWidth) {
                table.classList.add('ant-table-ping-right')
            }
            else {
                table.classList.remove('ant-table-ping-right')
            }
        }

        return (
            <VTable
                className="ant-table-body"
                cellProps={cellProps}
                colCount={columns.length}
                colWidth={(i) => columns[i].width}
                contentBodyProps={contentBodyProps}
                fixedLeftCount={fixedLeftCount}
                fixedRightCount={fixedRightCount}
                render={render}
                rowCount={rows.length}
                rowHeight={rowHeight}
                rowKey={rowKey}
                rowProps={rowProps}
                onScroll={handleScroll}
                allColRender = {props.allColRender}
            />
        )
    }

    return (
        <AntdTable
            ref={callbackRefTable}
            columns={columns}
            components={{body: VirtualBody}}
            {...props}
        />
    )
}

export default VirtualTable
