/** @jsxImportSource @emotion/react */
import DraggableModal from "components/DraggableModal/DraggableModal";
import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Space, Tabs, Typography} from 'antd';


;

//nowDate 参数数据
const Pane = ({
                  onRefresh,
                  visible,
                  setVisible,
                  soId,
                  doCheckedSmSoDR
              }) => {
    //主界面 Tab 控件对象，用于打开 Tab 页
    const {Text, Title} = Typography;
    const [form] = Form.useForm();
    // 加载显示
    const [loadingOk, setLoadingOk] = useState(false);
    const [loadingNot, setLoadingNot] = useState(false);
    // 组件初始化
    useEffect(() => {

    }, [visible]);

    return (
        <>
            <DraggableModal
                title='是否确认审批订单预算?'
                visible={visible}
                width={"20%"}
                onCancel={() => {
                    setVisible(false);
                }}
                destroyOnClose={true}
                maskClosable={false}
                closable={true}
                footer={
                    null
                }
            >

                <Form  layout="vertical" bordered={false} >
                    <Form.Item wrapperCol={{ offset: 11, span: 8 }}>
                        <Space>
                            <Button type={'primary'} loading={loadingNot} danger onClick={() => {
                                doCheckedSmSoDR('RETURN', 'RETURN',setLoadingNot,setVisible )
                            }}>不通过</Button>
                            <Button type={'primary'} loading={loadingOk} onClick={() => {
                                doCheckedSmSoDR('PASS', 'EAOK', setLoadingOk , setVisible)
                            }}>通过</Button>
                        </Space>
                    </Form.Item>
                </Form>

            </DraggableModal>
        </>

    );
}

export default Pane;
