const getColumnValue = (record, dataIndex) => {
    if ('string' === typeof dataIndex) {
        return record[dataIndex]
    }
    else if (Array.isArray(dataIndex)) {
        let value = record

        for (const index of dataIndex) {
            value = value[index]

            if (! value) {
                return value
            }
        }

        return value
    }
    else {
        return undefined
    }
}

export default getColumnValue
