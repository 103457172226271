/** @jsxImportSource @emotion/react */
import DraggableModal from "components/DraggableModal/DraggableModal";
import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Space, Tabs, Typography} from 'antd';


;

//nowDate 参数数据
const Pane = ({
                  onRefresh,
                  visible,
                  setVisible,
                  soId,
                  cancelSmSoBudgetDRs
              }) => {
    //主界面 Tab 控件对象，用于打开 Tab 页
    const {Text, Title} = Typography;
    const [form] = Form.useForm();
    // 加载显示
    const [loadingOk, setLoadingOk] = useState(false);
    // 组件初始化
    useEffect(() => {

    }, [visible]);

    return (
        <>
            <DraggableModal
                title='是否撤销提交预算?'
                okText="保存"
                visible={visible}
                width={"20%"}
                //height={100}
                //onOk={doAdd}
                onCancel={() => {
                    setVisible(false);
                }}
                destroyOnClose={true}
                maskClosable={false}
                closable={true}
                footer={
                    null
                }
            >

                <Form  layout="vertical" bordered={false} >

                    <Form.Item wrapperCol={{ offset: 15, span: 8 }}>
                        <Space>
                            <Button type={'primary'}   danger onClick={() => {
                                setVisible(false);
                            }}>否</Button>
                            <Button type={'primary'}  loading={loadingOk} onClick={() => {
                                cancelSmSoBudgetDRs(setLoadingOk ,setVisible );
                            }}>是</Button>
                        </Space>
                    </Form.Item>
                </Form>

            </DraggableModal>
        </>

    );
}

export default Pane;
