/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal,} from 'antd';
import 'moment/locale/zh-cn';
import Table from 'components/Bizs/Table/Table';
import 'style/main.css';
import ToolBar from "components/ToolBar/ToolBar";
import {nanoid} from "nanoid";
import Tools from "script/sweet-tools.mjs";
import DraggableModal from "components/DraggableModal/DraggableModal";
import DlgLabelModal from "./DlgLabelModal";

// 物料出运-出运登记-商品明细:唛头
const DlgLabel = (
	{onRefresh,
    visible,
    setVisible,
        record,
    ...props}) => {

    // 删除行
    const [deleteData, setDeleteData] = useState([]);

    //表格 Loading 控制变量
    const [loading, setLoading] = useState(false);

    //表格多选功能
    const [selectedRows, setSelectedRows] = useState([]);

    // 保存 Loading 控制变量
    const [saveLoading, setSaveLoading] = useState(false);

	const [rows, setRows] = useState([]);

    // 从其他出运单复制
    const [copyLabelVisible, setCopyLabelVisible] = useState(false);

    //组件挂载执行一次
	useEffect(() => {
	    if(visible){
            queryMark();
        }
	}, [visible]);

    const sortCompare = (a,b)=>{
        if (Number(a?.bosMarkHead ?? 0) > Number(b?.bosMarkHead ?? 0)) {
            return 1;
        }else if(Number(a?.bosMarkHead ?? 0) < Number(b?.bosMarkHead ?? 0)){
            return -1
        }else{
            return 0;
        }
    }

	const onInputChange = (e, index, key) => {
        const rowsNew = [...rows];
        rowsNew[index] = {...rowsNew[index], [key]: (e && e.target ? e.target.value : e)};
	    setRows(rowsNew.sort(sortCompare));
    }

    /**
     * 上移
     *
     * @param {*} selectedRows 选中行
     * @param {*} rows 所有行
     * @param {*} setRows
     * @param {*} rowKey 行主键
     */
    const moveUp = (selectedRows, rows, setRows, rowKey) => {
        selectedRows.forEach((item) => {
            item.seq = rows.findIndex((row) => row[rowKey] == item[rowKey]);
        });

        // 上移：升序
        selectedRows.sort((a, b) => a.seq - b.seq);

        selectedRows.forEach((item) => {
            const index = rows.findIndex((row) => row[rowKey] == item[rowKey]);
            const pre = index - 1;
            if (pre >= 0) {
                const upRow = rows[pre];
                rows[pre] = item;
                rows[index] = upRow;
            }
        });

        let rowIndex = 1;
        rows.forEach((item) => {
            item.bosMarkHead = rowIndex++;
        })
        setRows([...rows]);
    }

    /**
     * 下移
     *
     * @param {*} selectedRows 选中行
     * @param {*} rows 所有行
     * @param {*} setRows
     * @param {*} rowKey 行主键
     */
     const moveDown = (selectedRows, rows, setRows, rowKey) => {
        selectedRows.forEach((item) => {
            item.seq = rows.findIndex((row) => row[rowKey] == item[rowKey]);
        });

        // 下移：降序
        selectedRows.sort((a, b) => b.seq - a.seq);

        selectedRows.forEach((item) => {
            const index = rows.findIndex((row) => row[rowKey] == item[rowKey]);
            const next = index + 1;
            if (next < rows.length) {
                const downRow = rows[next];
                rows[next] = item;
                rows[index] = downRow;
            }
        });

        let rowIndex = 1;
        rows.forEach((item) => {
            item.bosMarkHead = rowIndex++;
        })
        setRows([...rows]);
    }

    // 移动行
    const moveRow = (op) => {
        if (selectedRows.length == 0) {
            return Modal.info({ content: '请至少选择一条数据' });
        }

        if (op == 'up') {
            moveUp(selectedRows, rows, setRows, 'bosMarkId');
        }

        if (op == 'down') {
            moveDown(selectedRows, rows, setRows, 'bosMarkId');
        }
    }

	const cssTable = {

	    '& .row-green > td': {color: 'green'},

	    '& .row-pink > td': {color: 'RGB(255,105,180)'},

	    '& .row-red > td': {color: '#ff2121'},

	    '& .row-blue > td': {color: '#FACD91'},
	    // 黑色
	    '& thead tr .header-blue1 ': {background: '#DCE2F1'},
		'& .column-blue': {color: 'blue'},

	}

    const columns = [
		{
			title: '唛头序号',
			dataIndex: 'bosMarkHead',
			key: 'bosMarkHead',
			width: '20%',
			render: (text, record, index) => (
				<div onClick={(e) => { e.stopPropagation() }}>
                    <Table.InputNumber value={text} size="small"
                                       onChange={(e) => onInputChange(e, index, 'bosMarkHead')}
                    />
				</div>
			),
            filter:<Table.FilterText/>,
		},
		{
			title: '唛头内容',
			dataIndex: 'bosMarkCont',
			key: 'bosMarkCont',
            width: '80%',
			render: (text, record, index) => {
				const rowsCnt =  text?.split("\n")?.length??3;
				return <div onClick={(e) => { e.stopPropagation() }}>
						<Input.TextArea rows={rowsCnt} defaultValue={text} size="small"
										onChange={(e) => onInputChange(e, index, 'bosMarkCont')} />
					</div>
					/*<div dangerouslySetInnerHTML={{ __html: text.replaceAll("\n","<br\>") }} />*/
			},
            filter:<Table.FilterText/>,
		}
    ];


    // 删除商品明细
    const del = () => {
        if(selectedRows == null || selectedRows == undefined || selectedRows.length<=0){
            message.warning('至少选择一条数据!');
            return ;
        }

        selectedRows.forEach(item => {
            let index = rows.findIndex((row) => row.bosMarkId === item.bosMarkId);
            // 非新增行删除，标记为删除
            if(!(rows[index].operationStatus && rows[index].operationStatus =='1')){
                rows[index].operationStatus = "2"
                deleteData.push(rows[index])
            }
            rows.splice(index, 1);
        });

        // 重置序号
        let rowIndex = 1;
        rows.forEach((item) => {
            item.bosMarkHead = rowIndex++;
        })
        setRows([...rows]);

        setRows([...rows])
        //多选取消
        setSelectedRows([]);
    }

	const rowSelection = {
        selectedRowKeys: selectedRows.map(({ bosMarkId }) => bosMarkId),
	    onChange: (keys, rows) => {
	        setSelectedRows((oldRow) => {
	            console.log("当前选中的")
	            console.log(selectedRows)
	            return rows;
	        })
	    },
	}

    // 查询唛头信息
    const queryMark = async () => {
        setLoading(true)
        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/dlv/bosmark/queryMarkByBosId", {
                        bosId: record.bosId
                    })
                }, {
                    error : {content : (err) => `查询失败: ${err.message}`},
                    loading : {show : false},
                    success : {show : false},
                } );

            if (data != null) {
                for(let i = 0; i<data.length; i++){
                    //默认给修改状态
                    data[i].operationStatus = "0";
                }
            }
            setRows(data)
        } catch (err) {
            console.log(err);
        }finally {
            setLoading(false)
        }
    }


    // 新增五行数据
    const newly = (addCnt=1) => {
         // 获取最大唛头序号
        let maxHead = 0;
        if(rows && rows.length>0){
            const headArr = rows.map((item)=>Number(item?.bosMarkHead ?? 0));
            maxHead = Math.max(...headArr);
        }
        console.log("maxHead=", maxHead)

        const newRows = [];
        for (let i = 0; i < addCnt; i++) {
            newRows.push({
                bosMarkId: nanoid(),
                bosId: record.bosId,
                // 唛头
                bosMarkHead: ++maxHead,
                // 唛头内容
                bosMarkCont: "",
                // 新增标记
                operationStatus: "1"
            });
        }
        setRows([...rows, ...newRows]);
        //模拟pkid 真实开发是pkid 可以设置特有的pkid 来标识 传到后端的数据是否是新增的数据
    }


	const cancel =() => {
		setVisible(false);
	}

	const noEdit = () => {
	    //初始化行数据
	    doQuery();

	    //多选取消
	    setSelectedRows([]);
	}

	// 保存唛头
	const save = async () => {
        let rowNew = [];
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].bosMarkHead != null && rows[i].bosMarkHead != undefined && rows[i].bosMarkHead != '' &&
                rows[i].bosMarkCont != null && rows[i].bosMarkCont != undefined && rows[i].bosMarkCont != '') {
                rows[i].markSeq = i;
                rowNew.push(rows[i]);
            }else{
                let index = rows.findIndex((row) => row.bosMarkId === rows[i].bosMarkId);
                // 非新增行删除，标记为删除
                if(!(rows[index].operationStatus && rows[index].operationStatus =='1')){
                    rows[index].operationStatus = "2"
                    deleteData.push(rows[index])
                }
            }
        }

        if(rowNew.length <= 0){
            message.warning('至少需要一条数据不为空的记录!');
            return;
        }

        // 加上删除的商品明细
        if(deleteData != null && deleteData != undefined && deleteData.length>0){
            for (let i = 0; i<deleteData.length; i++){
                rowNew.push(deleteData[i])
            }
        }

        setSaveLoading(true);
        try {
            const item = await Tools.runAsync(
                async () => {
                        return await Tools.http.post("/dlv/bosmark/saveDlvBosMarkDTO", rowNew)
                },
                {
                        error: {content: (err) => `操作失败: ${err.message}`},
                        loading: { show: false },
                        success: { show: true },
                }
            )
            setVisible(false);
        } catch (err) {
            console.error(err);
        } finally {
            setSaveLoading(false);
        }
    }

    const toolButtons = {
        left: [
            // <Button type="primary" size="small" htmlType="button" ghost onClick={() => copyFromBos()}>从通知单复制</Button>,
            // <Button type="primary" size="small" htmlType="button" ghost onClick={() => newly()}>新增</Button>,
			/*<Button size="small" type="primary"  onClick={() => newly(5)} ghost>新增5行</Button>,*/
            <Button size='small' type="primary" ghost onClick={() => moveRow('up')}>上移</Button>,
            <Button size='small' type="primary" ghost onClick={() => moveRow('down')}>下移</Button>,
            <Button type="primary" size="small" htmlType="button" ghost onClick={() => del()}>
                删除
            </Button>,
        ],
		right:[
          /*  <Button type="primary" size="small" htmlType="button" ghost>
                唛头序号
            </Button>,
			<Input size="small" defaultValue={"M1"}/>*/
		]
    };

    // 从出运单复制
    const copyFromBos = () => {
        setCopyLabelVisible(true);
    };

    //弹窗确定回调
    const onCopyHsOk = (labelRows) => {
        console.log("labelRows=", labelRows)
        setCopyLabelVisible(false);

        // 获取最大唛头序号
        let maxHead = 0;
        if(rows && rows.length>0){
            const headArr = rows.map((item)=>Number(item?.bosMarkHead ?? 0));
            maxHead = Math.max(...headArr);
        }
        console.log("maxHead=", maxHead)

        const newRows = [];
        for (let i = 0; i < labelRows.length; i++) {
            newRows.push({
                bosMarkId: nanoid(),
                bosId: record.bosId,
                // 唛头
                bosMarkHead: ++maxHead,
                // 唛头内容
                bosMarkCont: labelRows[i].bosMarkCont,
                // 新增标记
                operationStatus: "1"
            });
        }
        setRows([...rows, ...newRows]);
    };


    //弹窗取消回调
    const onCopyHsCancel = (e) => {
        setCopyLabelVisible(false);
    };

    return (
		<DraggableModal
		    title="唛头"
		    okText="确定"
		    cancelText="取消"
		    width="45%"
		    visible={visible}
		    onOk={save}
		    onCancel={cancel}
		    destroyOnClose={true}
            confirmLoading={saveLoading}
            // bodyStyle={{padding: '8px',overflow: 'hidden', display: 'flex', flexDirection: 'column', height:modalHeight+'px'}}
		>

			<ToolBar buttons={toolButtons}></ToolBar>
			<Table
				autoHeight
				columns={columns}
                noRowNumber={true}
				dataSource={rows}
				rowKey={"bosMarkId"}
				rowSelection={{
					type: "checkbox",
					...rowSelection,
				}}
                laoding={loading}
				css={cssTable}
				pagination={false}
				 />

            <DlgLabelModal
                record={record}
                visible={copyLabelVisible}
                onOk={onCopyHsOk}
                onCancel={onCopyHsCancel}
            />

		</DraggableModal>
    );
}
export default DlgLabel;

