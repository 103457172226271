/** @jsxImportSource @emotion/react */

import {
    createContext,
    lazy,
    memo,
    Suspense,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from 'react'

import {Spin} from 'antd'
import {ActiveProvider} from 'hooks/useActive.mjs'
import ErrorBoundary from 'components/ErrorBoundry/ErrorBoundry.jsx'
import useEventTarget from 'hooks/useEventTarget.mjs'

const FrameContentContext = createContext()
export const useFrameContentContext = () => useContext(FrameContentContext)

const FrameContentFallback = () => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    }

    return (
        <Spin
            style={style}
            spinning
            tip="加载组件..."
        />
    )
}

const FrameContentProvider = ({frame, frameKey, ...props}) => {
    const eventTarget = useEventTarget()

    useEffect(
        () => {
            const handleHide = async (key) => {
                if (key === frameKey) {
                    await eventTarget.dispatchEvent('hide')
                }
            }

            frame.addEventListener('hide', handleHide)
            return () => frame.removeEventListener('hide', handleHide)
        },

        [eventTarget, frame, frameKey]
    )

    return (
        <FrameContentContext.Provider
            value={eventTarget}
            {...props}
        />
    )
}

const FrameContent = ({
    activeKey,
    component: [path, props],
    frame,
    frameKey,
}) => {
    const refIsLoaded = useRef(false)

    const Component = useMemo(
        () => memo(lazy(() => import(`pages/BizPage/${path}.jsx`))),
        [path]
    )

    const isActive = frameKey === activeKey

    if (! (isActive || refIsLoaded.current)) {
        return null
    }

    refIsLoaded.current = true

    const style = {
        display: isActive ? 'block' : 'none',
        height: '100%',
    }

    return (
        <div style={style}>
            <ActiveProvider value={isActive}>
                <FrameContentProvider
                    frame={frame}
                    frameKey={frameKey}
                >
                    <Suspense fallback={<FrameContentFallback />}>
                        <ErrorBoundary>
                            <Component {...props} />
                        </ErrorBoundary>
                    </Suspense>
                </FrameContentProvider>
            </ActiveProvider>
        </div>
    )
}

export default FrameContent
