/** @jsxImportSource @emotion/react */

import {useContext} from 'react'
import {Modal as AntdModal} from 'antd'
import {ModalManagerContext} from './ModalManager.jsx'

const Modal = ({
    draggable = true,
    height,
    openModal,
    startDrag,
    translateX = 0,
    translateY = 0,
    ...props
}) => {
    const closable = props.closable ?? false

    const handleMouseDown = (e) => {
        const leftButton = (e.buttons & 1) === 1

        if (leftButton) {
            startDrag([e.clientX, e.clientY])
        }
    }

    const cssModal = {
        display: 'flex',
        flexDirection: 'column',
        top: '10vh',
        left: 0,
        right: 0,
        height,
        maxHeight: '80vh',
        paddingBottom: 0,
        margin: 'auto',
        overflow: 'hidden',
        boxShadow: '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',

        transform: draggable ?
            `translate(${translateX}px, ${translateY}px)` : '',

        '& .ant-modal-content': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
        },

        '& .ant-modal-header': {
            padding: 0,
        },

        '& .ant-modal-body': {
            flexGrow: 1,
            padding: '10px 16px',
            overflow: 'auto',
        },
    }

    const cssTitle = {
        fontSize: 14,
        lineHeight: 1,
        padding: '10px 16px',
        marginRight: closable ? 56 : 0,
        userSelect: 'none',
        cursor: draggable ? 'move' : '',
    }

    const title = (
        <div
            css={cssTitle}
            onMouseDown={draggable ? handleMouseDown : null}
        >
            {props.title}
        </div>
    )

    const buttonProps = {size: 'small'}

    return (
        <ModalManagerContext.Provider value={openModal}>
            <AntdModal
                css={cssModal}
                cancelButtonProps={buttonProps}
                mask={false}
                maskClosable={false}
                okButtonProps={buttonProps}
                {...props}
                closable={closable}
                title={title}
            />
        </ModalManagerContext.Provider>
    )
}

const {
    confirm,
    error,
    info,
    success,
    warning,
} = AntdModal

Object.assign(Modal, {
    confirm,
    error,
    info,
    success,
    warning,
})

export default Modal

export const useOpenModal = () => useContext(ModalManagerContext)
export {default as Alert} from './Alert.jsx'
export {default as Confirm} from './Confirm.jsx'
export {default as Prompt} from './Prompt.jsx'
