/** @jsxImportSource @emotion/react */

import {useEffect, useState} from 'react'
import {Checkbox} from 'antd';
import List from 'rc-virtual-list'

const CheckboxGroup = ({options, value, onChange, ...props}) => {
    const [checkedOptions, setCheckedOptions] = useState(new Set())

    useEffect(
        () => setCheckedOptions(new Set(value)),
        [value]
    )

    const handleChange = (e) => {
        const {'data-value': value, checked} = e.target

        setCheckedOptions((options) => {
            const newOptions = new Set(options)

            if (checked) {
                newOptions.add(value)
            }
            else {
                newOptions.delete(value)
            }

            onChange(Array.from(newOptions))
            return newOptions
        })
    }

    const css = {
        '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
            marginLeft: 0,
        }
    }

    return (
        <List
            css={css}
            data={options}
            height={200}
            itemHeight={30}
            {...props}
        >
            {
                (value) => (
                    <Checkbox
                        data-value={value}
                        checked={checkedOptions.has(value)}
                        onChange={handleChange}
                    >{value}</Checkbox>
                )
            }
        </List>
    )
}

export default CheckboxGroup
