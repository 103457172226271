/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react'
import {Checkbox} from 'antd'
import stopPropagation from '../../stopPropagation.mjs'

const cssInputBoolean = css({
    display: 'block',
    textAlign: 'center',
})

const InputBoolean = ({value, onChange, ...props}) => {
    const handleChange = ({target: {checked}}) => onChange(checked)

    return (
        <div css={cssInputBoolean}>
            <Checkbox
                bordered={false}
                checked={value}
                size="small"
                onChange={handleChange}
                onClick={stopPropagation}
                {...props}
            />
        </div>
    )
}

export default InputBoolean
