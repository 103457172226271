/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react';
import {Form, Row, Col, Button, Space,} from 'antd';
import {SearchOutlined, UpOutlined, DownOutlined} from '@ant-design/icons';
import Tools from "script/sweet-tools.mjs";

/**
 * 查询表单组件, 分默认展示区域和折叠区域，折叠区域用来摆放更多的额外条件；
 *
 * @param loading
 * @param onForm
 * @param onFinish 表单回调
 * @param children 默认展示区域子元素
 * @param extra 折叠区域展示子元素，扩展元素
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SearchForm = ({
                        permissionCode,
                        permissionOptCode = "query",
                        form = Form.useForm()[0],
                        loading,
                        onForm,
                        onFinish,
                        children,
                        extra,
                        bodyStyle,
                        ...props
                    }) => {
    //控制搜索区域展开/收缩
    const [visible, setVisible] = useState(false);

    //组件挂载执行一次
    useEffect(() => {
        (async () => {
            if (onForm) {
                onForm(form);
            }
        })();
    }, []);

    const cssForm = {
        '& .ant-form-item': {marginBottom: 0},
    }

    return (
        <Form
            css={cssForm}
            form={form}
            onFinish={(values) => {
                if (onFinish) {
                    onFinish(values, form);
                }
            }}
            layout="vertical"
            colon={false}
            {...props}
            size="small"
        >
            {/*主体 body*/}
            <div style={{padding: '12px', background: '#fff', display: 'flex', flexDirection: 'column', ...bodyStyle}}>

                {/*default 默认行*/}
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{flexGrow: 1, marginRight: 12}}>
                        {children}
                    </div>

                    <Space style={{flexShrink: 0, alignItems: 'flex-end'}}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon={<SearchOutlined/>}
                            loading={loading}
                            disabled={permissionCode && Tools.authenticator.isPermissionDenied({permissionCode, permissionOptCode})}
                        >
                            查询
                        </Button>
                        {visible && extra && <a onClick={() => setVisible(!visible)}>收起 <UpOutlined/></a>}
                        {!visible && extra && <a onClick={() => setVisible(!visible)}>展开 <DownOutlined/></a>}
                    </Space>
                </div>

                {/*扩展区域，高级搜索去*/}
                {
                    visible && extra &&
                    <div style={{marginTop: 12, paddingTop: 12, borderTop: '1px dashed #f0f0f0'}}>
                        {extra}
                    </div>
                }
            </div>
        </Form>
    )
}

export default SearchForm;
