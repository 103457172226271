/** @jsxImportSource @emotion/react */

const Toggle = ({checked, children, onChange, ...props}) => {
    const handleClick = () => onChange(! checked)

    const style = {
        color: checked ? '#1890ff' : '#cecece',
    }

    return (
        <span
            style={style}
            onClick={handleClick}
            {...props}
        >
            {children}
        </span>
    )
}

export default Toggle
