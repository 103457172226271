import Icon from '@ant-design/icons';


const LeftLayoutSvg = () => (
    <svg t="1644323986583" className="icon" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2005" width="1em" height="1em">
        <path d="M288 416v320h448V416H288z m0-64h448V288H288v64zM256 224h512a32 32 0 0 1 32 32v512a32 32 0 0 1-32 32H256a32 32 0 0 1-32-32V256a32 32 0 0 1 32-32z m128 224a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0v-192a32 32 0 0 1 32-32z" p-id="2006"></path>
    </svg>
)

const LeftLayoutIcon = ({style, ...props}) => <Icon component={LeftLayoutSvg} style={{fontSize: '24px', ...style}} {...props} />

export {LeftLayoutIcon};
