/** @jsxImportSource @emotion/react */

import Input from './InputText.jsx'

const InputNumber = ({
    max = Infinity,
    min = 0,
    int = false,
    ...props
}) => {
    const toNumber = (s) => {
        const parse = int ? Number.parseInt : Number.parseFloat
        return parse(s)
    }

    const parse = (s) => {
        const n = toNumber(s)
        return Number.isNaN(n) ? null : n
    }

    const test = (s) => {
        const re = (() => {
            if (null === min || min < 0) {
                if (int) {
                    return /^-?\d*$/
                }
                else {
                    return /^-?\d*\.?\d*$/
                }
            }
            else {
                if (int) {
                    return /^\d+$/
                }
                else {
                    return /^\d+\.?\d*$/
                }
            }
        })()

        return re.test(s)
    }

    const transform = (s) => {
        const n = toNumber(s)

        // 比最小值更小时返回最小值
        if (n < (min ?? -Infinity)) {
            return min
        }
        // 比最大值更大时返回最大值
        else if ((max ?? Infinity) < n) {
            return max
        }
        else {
            return s
        }
    }

    const style = {
        textAlign: 'right',
        ...props.style,
    }

    return (
        <Input
            parse={parse}
            test={test}
            transform={transform}
            {...props}
            style={style}
        />
    )
}

export default InputNumber
