/** @jsxImportSource @emotion/react */

import {Image as AntdImage} from 'antd'
import Tools from 'script/sweet-tools.mjs'
import useObjectURL from 'hooks/useObjectURL.mjs'

const Image = ({
    value,
    width = 50,
    height = 50,
    className,
    style,
    ...props
}) => {
    const url = useObjectURL(value)

    if (! url) {
        return null
    }

    const src = url.startsWith('blob:') ? url : Tools.convertToUrl(url)
    return (
        <div
            className={className}
            style={style}
        >
            <AntdImage
                height={height}
                src={src}
                width={width}
                {...props}
            />
        </div>
    )
}

export default Image
