/** @jsxImportSource @emotion/react */
import DraggableModal from "components/DraggableModal/DraggableModal";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Space, Tabs, Typography} from 'antd';


;

//nowDate 参数数据
const Pane = ({
                  onRefresh,
                  visible,
                  setVisible,
                  soId,
                  judgeSmSoRepealDR
              }) => {
    //主界面 Tab 控件对象，用于打开 Tab 页
    const {Text, Title} = Typography;
    const [form] = Form.useForm();
    // 加载显示
    const [loadingOk, setLoadingOk] = useState(false);
    const defRef = useRef({});

    // 组件初始化
    useEffect(() => {

    }, [visible]);

    const onChange = (e) => {
        defRef.current.memo = e.target.value;
    } // 改变事件


    return (
        <>
            <DraggableModal
                title='是否确认作废销售订单?'
                okText="保存"
                visible={visible}
                width={"20%"}
                onCancel={() => {
                    setVisible(false);
                }}
                destroyOnClose={true}
                maskClosable={false}
                closable={true}
                footer={
                    null
                }
            >

                <Form  layout="vertical" bordered={false} >
                    <Text><div>没有发生采购、发票、付款、来款、出运业务的订单才可作废</div></Text>
                    <Form.Item wrapperCol={{ offset: 15, span: 8 }}>
                        <Space>
                            <Button type={'primary'} danger onClick={() => {
                                setVisible(false);
                            }}>否</Button>
                            <Button type={'primary'}  loading={loadingOk} onClick={() => {
                                judgeSmSoRepealDR(setLoadingOk , setVisible);
                            }}>是</Button>
                        </Space>
                    </Form.Item>
                </Form>







            </DraggableModal>
        </>

    );
}

export default Pane;
