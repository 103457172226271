import {cloneElement} from 'react'
import getColumnValue from './getColumnValue.mjs'
import makeColumnRowNumber from './makeColumnRowNumber.mjs'
import makeColumnSelection from './makeColumnSelection.mjs'

const getColumnUpdates = (value, dataIndex) => {
    if ('string' === typeof dataIndex) {
        return {[dataIndex]: value}
    }

    if (Array.isArray(dataIndex)) {
        const updates = {}
        let cursor = updates

        for (const key of dataIndex.slice(0, -1)) {
            cursor[key] = {}
            cursor = cursor[key]
        }

        cursor[dataIndex.at(-1)] = value
        return updates
    }
}

const enhanceColumnFilter = ({column, filter, getValue, table}) => {
    if (! filter) {
        return
    }

    const {dataIndex} = column

    const setFilter = (filter) => {
        table.setFilter(dataIndex, filter, getValue)
    }

    const setSort = (sort) => {
        table.setSort(dataIndex, sort, getValue)
    }

    const getValues = () => {
        const {filters, rows} = table
        const key = Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex
        const otherFilters = filters.filter(([k]) => k !== key)

        return Array.from(
            new Set(
                Array.from(table.iterRows(rows))
                    .filter(
                        (row) => otherFilters.every(
                            ([, filter, getValue]) => {
                                const value = getValue(row)
                                return filter(value)
                            }
                        )
                    )
                    .map(getValue)
            )
        )
    }

    column.filterDropdown = ({confirm}) => {
        return cloneElement(filter, {
            confirm,
            getValues,
            setFilter,
            setSort,
        })
    }

    // 控制过滤图标亮/灭
    column.filtered = table.hasFilter(dataIndex) || table.hasSort(dataIndex)

    // 设为受控模式，否则过滤图标不会变化
    column.filteredValue = false
}

const enhanceColumnRender = ({
    column,
    component,
    getUpdates,
    getValue,
    render,
    table,
}) => {
    if (render) {
        column.render = (dummy, row, index) => {
            const value = getValue(row)
            return render(value, row, index, table)
        }
    }
    else if (component) {
        column.render = (dummy, row, index) => {
            const value = getValue(row)

            const onChange = async (value) => {
                const key = row[table.rowKey]
                const updates = await getUpdates(value, row, table.rows)
                table.updateRows([[key, updates]])
            }

            const element = 'function' === typeof component ?
                component(value, row, index, table)
                :
                component

            if (element) {
                const props = {}

                if (! Object.hasOwn(element.props, 'value')) {
                    props.value = value
                }

                if (! Object.hasOwn(element.props, 'onChange')) {
                    props.onChange = onChange
                }

                return cloneElement(element, props)
            }
        }
    }
    else {
        column.render = (dummy, row) => {
            return getValue(row)
        }
    }
}

const enhanceColumn = (
    table,

    {
        component,
        dataIndex,
        filter,
        getValue = (row) => getColumnValue(row, dataIndex),
        getUpdates = (value, row, rows) => getColumnUpdates(value, dataIndex),
        render,
        ...column
    }
) => {
    column.dataIndex = dataIndex

    enhanceColumnFilter({
        column,
        filter,
        getValue,
        table,
    })

    enhanceColumnRender({
        column,
        component,
        getUpdates,
        getValue,
        render,
        table,
    })

    return column
}

const enhanceColumns = ({
    columns,
    noRowNumber,
    rowHeight,
    rowNumberTitle = null,
    rowSelection,
    table,
}) => {
    const extraCols = []

    if (rowSelection && rowHeight) {
        const colSelection = makeColumnSelection({rowSelection, table})
        extraCols.push(colSelection)
    }

    if (! noRowNumber) {
        const colRowNumber = makeColumnRowNumber({rowNumberTitle, table})
        extraCols.push(colRowNumber)
    }

    const enhancedColumns = [...extraCols, ...columns].map(
        (column) => enhanceColumn(table, column)
    )

    return enhancedColumns
}

export default enhanceColumns
