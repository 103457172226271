import Frame from 'components/Frame/Frame';
import {Button, Dropdown, Menu} from "antd";
import React, {useState, useEffect} from 'react';
import useGlobalData from "../../../../hooks/useGlobalData.mjs";
import Tools from "../../../../script/sweet-tools.mjs";
import authenticator from "script/sweet-authenticator.mjs";
import {firstBodDict} from "pages/BizPage/COMM/CurrentComm.mjs";

const Pane = ({record, isShow, mainPageQuery, ...props}) => {
    const authCode = 'DBOD';
    //是否没有编辑权限
    const [editPermission, setEditPermission] = useState(
        authenticator.isPermissionDeniedEdit(
            {
                permissionCode: authCode,
                // billOrgPathNo: info.orgPathNo,
                // billUserId: info.crtUserId
            })
    );


    const {$key} = props; // 当前标签页
    const {onRefresh} = props; // 刷新数据表列表

    const [bodNo, setBodNo] = useState(record.bodNo);
    const [dlvBod, setDlvBod] = useState({record});
    const [dlvBodChanged, setDlvBodChanged] = useState(false);
    const [bodStsCodeName, setBodStsCodeName] = useState("");
    // 上传文件目录
    const [filePath, setFilePath] = useState(firstBodDict + record.bodNo);

    //是否可以编辑附件集
    const [prDocFlag, setPrDocFlag] = useState('0');
    const [docToFlag, setDocToFlag] = useState(false);


    const dlvBodChange = async () => {
        console.log("收到dlvBod需要更新消息，dlvBodChanged:" + !dlvBodChanged);
        setDlvBodChanged(!dlvBodChanged);
        await init();
    };


    const init = async () => {
        let param = {
            bodId: record.bodId
        }
        const result = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/dlv/bod/oneDlvBodDR", param)
            },
            {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        )
        setBodNo(result.bodNo);
        setBodStsCodeName(useGlobalData.getDictDtlCn("BOD_STS", result.bodStsCode));
        setPrDocFlag(result.bodStsCode !== 'RETURN' && result.docToFlag !== true ? "1" :'0');
        if (result.docToFlag){
            setDocToFlag(true)
        }
    }

    useEffect(() => {
        init();
    });

    const frames = [
        {
            key: 'DBODFrame',
            name: '内贸出库详情',
            children: [
                {
                    key: 'DBODFrmHome',
                    name: '首页',
                    frame: 'GAR_DY/DBOD/DBODFrmHome',
                    props: {
                        bodId: record.bodId,
                        record: dlvBod,
                        activeKey: $key,
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange
                    },
                },
                {
                    key: 'DBODFrmRow',
                    name: '出库明细',
                    frame: 'GAR_DY/DBOD/DBODFrmRow',
                    props: {
                        bodId: record.bodId,
                        isShow: isShow,
                        controlFlag: "1",
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange
                    },
                },
                {
                    key: 'DBODFrmReview',
                    name: '托单评审',
                    frame: 'GAR_DY/DBOD/DBODFrmReview',
                    props: {
                        bodId: record.bodId,
                        isShow: isShow,
                        controlFlag: "1",
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange
                    },
                },
                {
                    key: 'DBODFrmBsn',
                    name: '订仓信息',
                    frame: 'GAR_DY/DBOD/DBODFrmBsn',
                    props: {
                        bodId: record.bodId,
                        isShow: isShow,
                        record: dlvBod,
                        controlFlag: "1",
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange,
                        filePath:filePath
                    },
                },
                {
                    key: 'DBODReconCiliation',
                    name: '签收信息',
                    frame: 'GAR_DY/DBOD/DBODReconCiliation',
                    props: {
                        fileDir: filePath,
                        bodId: record.bodId,
                        isShow: isShow,
                        record: dlvBod,
                        dsId: record?.svDsId,
                        controlFlag: "1",
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange,
                        prDocFlag: prDocFlag,
                        editAuthen: !editPermission,
                        editMode:!docToFlag,
                    },
                },
                {
                    key: 'DBODFrmAccounts',
                    name: '应收账款',
                    frame: 'GAR_DY/DBOD/DBODFrmAccounts',
                    props: {
                        bodId: record.bodId,
                        isShow: isShow,
                        controlFlag: "1",
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange
                    },
                },
                {
                    key: 'DBODCINFrmList',
                    name: '开票信息',
                    frame: 'GAR_DY/DBOD/DBODCINFrmList',
                    props: {
                        bodNo: record.bodNo,
                        isShow: isShow,
                        controlFlag: "1",
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange
                    },
                },
                {
                    key: 'DBODFrmInCome',
                    name: '来款信息',
                    frame: 'GAR_DY/DBOD/DBODFrmInCome',
                    props: {
                        bodId: record.bodId,
                        isShow: isShow,
                        controlFlag: "1",
                        mainPageQuery: mainPageQuery,
                        dlvBodChanged: dlvBodChanged,
                        onFrameCall: dlvBodChange
                    },
                },
                {
                    key: 'DBODFrmDoc',
                    name: '上传附件',
                    frame: 'GAR_DY/DBOD/DBODPIDOCList',
                    props: {
                        docParam: record?.piDsId,
                        fileDir: filePath,
                        prDocFlag: prDocFlag,
                        cardName: "上传附件",
                        editAuthen: !editPermission && !docToFlag,
                    },
                },
            ],
        },
    ];


    const navigation = {
        navigationTitle: '内贸出库【' + bodNo + '】',
        navigationDescriptions: [
            {
                label: <span
                    className="text-color-primary">{bodStsCodeName}</span>,
            },
        ],
        navigationButtons: []
    }

    return <Frame frames={frames} navigation={navigation}/>;
}

export default Pane;
