const TableConst = () => {

}
//列宽定义
TableConst.Widths = {
    //单价
    UNIT_PRICE: 80,

    //汇率
    EXCHANGE_RATE: 75,

    //单耗
    UNIT_CONSUMPTION: 75,

    //净单耗
    NET_UNIT_CONSUMPTION: 75,

    //金额
    MONEY: 100,

    //服装数量
    GARMENT_NUM: 70,

    //物料数量
    MAT_NUM: 120,

    //计量单位
    UNIT: 70,

    //尺码
    SIZE: 70,

    //物料品名
    MAT_NAME: 150,

    //物料规格
    MAT_SPEC: 200,

    //原币制
    CURRENCY:70,

    //外币对本币的采购汇率
    EXCHANGE_RATE_EXT: 100,

}

export default TableConst;
