/** @jsxImportSource @emotion/react */
;
import { Space} from 'antd';


/**
 * 横向工具栏组件
 * @param toolButton
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ToolBar = ({buttons, children, ...props}) => {
    const toolButton = buttons;

    if (children) {
        return <ToolBarPane children={children} {...props} />
    } else {
        if (Array.isArray(toolButton)) {
            return (
                <ToolBarPane {...props}>
                    <Space>
                        {toolButton}
                    </Space>
                </ToolBarPane>
            )
        } else {
            return (
                <ToolBarPane {...props}>
                    {
                        toolButton.left &&
                        <div style={{flexGrow: '1', display: "flex",}}>
                            <Space>
                                {toolButton.left}
                            </Space>
                        </div>
                    }
                    {
                        toolButton.right &&
                        <div style={{flexGrow: '1', display: "flex", justifyContent:'flex-end'}}>
                            <Space>
                                {toolButton.right}
                            </Space>
                        </div>
                    }
                </ToolBarPane>
            )
        }
    }
}

const ToolBarPane = ({children, ...props}) => {
    return (
        <div {...props} style={{display: 'flex', alignItems: 'center', marginBottom: '12px', ...props.style}}>
            {children}
        </div>
    )
}
export default ToolBar;
