import {createContext, useContext} from 'react'

const ActiveContext = createContext(true)

export const ActiveProvider = ({value, ...props}) => {
    const isActive = useActive()

    return (
        <ActiveContext.Provider
            value={isActive && value}
            {...props}
        />
    )
}

const useActive = () => useContext(ActiveContext)

export default useActive
