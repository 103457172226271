import {message, Modal} from 'antd'

const defaultErrorContent = (err) => err.message
const defaultErrorDuration = 5
const defaultLoadingContent = '请稍候'
const defaultShowError = true
const defaultShowLoading = true
const defaultShowSuccess = false
const defaultSuccessContent = () => '操作成功'
const defaultSuccussDuration = 2

const normalizeOptions = (options) => {
    return {
        error: {
            content: options.error?.content ?? defaultErrorContent,
            duration: options.error?.duration ?? defaultErrorDuration,
            show: options.error?.show ?? defaultShowError,
        },

        loading: {
            content: options.loading?.content ?? defaultLoadingContent,
            show: options.loading?.show ?? defaultShowLoading,
        },

        success: {
            content: options.success?.content ?? defaultSuccessContent,
            duration: options.success?.duration ?? defaultSuccussDuration,
            show: options.success?.show ?? defaultShowSuccess,
        },
    }
}

const runAsync = async (fn, options = {}) => {
    const opt = normalizeOptions(options)

    const hideLoading = opt.loading.show ?
        message.loading(opt.loading.content, 0)
        :
        () => {}

    try {
        const result = await fn(opt)
        hideLoading()

        if (opt.success.show) {
            message.success({
                content: opt.success.content(result),
                duration: opt.success.duration,

                style: {
                    position: 'relative',
                    top: '45vh',
                },
            })
        }

        return result
    }
    catch (err) {
        hideLoading()

        if (opt.error.show) {
            Modal.error({content: opt.error.content(err)})
            //message.error(opt.error.content(err), opt.error.duration)
        }

        throw err
    }
}

export const batchRunAsync = async (fn, options = {}) => {
    const opt = normalizeOptions(options)

    const result = await runAsync(
        (options) => Promise.allSettled(fn(options)),
        {...opt, success: {...opt.success, show: false}},
    )

    const [fulfilled, rejected] = result.reduce(
        ([fulfilled, rejected], {reason, status, value}, i) => {
            if ('fulfilled' === status) {
                fulfilled.push([i, value])
            }
            else {
                rejected.push([i, reason])
            }

            return [fulfilled, rejected]
        },

        [[], []],
    )

    if (0 === rejected.length) {
        const values = fulfilled.map(([, value]) => value)

        if (opt.success.show) {
            message.success(
                opt.success.content(values),
                opt.success.duration
            )
        }
    }

    return [fulfilled, rejected]
}

export default runAsync
