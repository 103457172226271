/** @jsxImportSource @emotion/react */

import 'antd/dist/antd.css'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'

import moment from 'moment'
import 'moment/locale/zh-cn'

const RootPage = (props) => {
    moment.locale('zh-cn')

    return (
        <ConfigProvider
            {...props}
            locale={zhCN}
        />
    )
}

export default RootPage
