import {Table} from 'antd'
import fixNum from 'script/fixNum.mjs'
import getColumnValue from './getColumnValue.mjs'
import toGroup from "./toGroup.mjs";
import React from "react";

const enhanceSummary = ({
    columns,
    noRowNumber,
    rows,
    rowSelection,
    summary,
                            sumNumberRD,
}) => {
    if (summary) {
        return (rows) => summary(rows, columns)
    }
    else {
        const Summary = () => {
            if (0 === rows.length) {
                return null
            }

            const cells = []
            let index = 0

            if (! noRowNumber || rowSelection) {
                const colSpan = ! noRowNumber && rowSelection ? 2 : 1

                cells.push(
                    <Table.Summary.Cell
                        align="center"
                        colSpan={colSpan}
                        index={index}
                    >
                        合计
                    </Table.Summary.Cell>
                )

                index += colSpan
            }

            let hasSummary = false

            for (const column of columns) {
                const {align, dataIndex, summary, summaryFormat} = column

                let txAmtShow = [];
                let currTotal = null;

                const value = (() => {
                    if (summary) {
                        hasSummary = true

                        const values = rows.map(
                            (row) => getColumnValue(row, dataIndex)
                        )

                        if ('function' === typeof summary) {
                            return summary(values)
                        }
                        else {

                            // 按照币制汇总
                            if (summaryFormat){
                               const newCurrRow  =  toGroup(rows, 'prCurrCode');

                                for (let curr in newCurrRow) {
                                    let txAmt = 0.00;//币种合计金额
                                    let currData = newCurrRow[curr];
                                    for (const currRow of currData) {
                                        txAmt += currRow.purSuppPiAmt ? Number(currRow.purSuppPiAmt) : 0;
                                    }
                                    const dr = currData.find(i=> i.currNameEn);
                                    const amt = (dr?.currNameEn + ":" + txAmt.toFixed(2));
                                    txAmtShow.push(amt)
                                }

                                if (txAmtShow.length > 0 ){
                                    currTotal = (<div style={{ align: 'right' }}>
                                        {txAmtShow.map(amt => (<text style={{ align: 'right' }}>{amt}<br/></text>))}
                                    </div>)
                                }


                            }

                            return fixNum(
                                values.reduce(
                                    (sum, value) => sum + (value ?? 0),
                                    0
                                ),

                                3
                            )
                        }
                    }
                    else {
                        return ''
                    }
                })()




                cells.push(
                    <Table.Summary.Cell
                        align={align}
                        index={index}
                    >
                        { summaryFormat?  ( currTotal ) : ( (value||value===0) ?(sumNumberRD? Number(value).toFixed(sumNumberRD):value) : "")  }
                    </Table.Summary.Cell>
                )

                index += 1
            }

            if (hasSummary) {
                return (
                    <Table.Summary>
                        <Table.Summary.Row>{cells}</Table.Summary.Row>
                    </Table.Summary>
                )
            }
            else {
                return null
            }
        }

        return Summary
    }
}

export default enhanceSummary
