
const roundUp = (data, size,zeroFlag=true) => {
    if (!(data || data === 0)) {
        return '';
    }

    let abs = 1;
    if (data < 0) abs = -1;
    const _data = Math.abs(data);
    let factor = Math.pow(10, size);
    let result = Math.round(_data * factor) / factor;
    // 这里使用toFixed的原因是为了防止舍入后round自动去除多余0的情况，
    //这样达不到要求的小数位数，例如Math.round(1.4101, 3)为1.41而不是1.410，
    //因此使用toFixed的作用仅仅是补充缺失的0
    return zeroFlag?(Number(result) * abs).toFixed(size).toString():(Number(result) * abs).toString();
}

export default roundUp
