/** @jsxImportSource @emotion/react */

import {Button, Space} from 'antd'
import {SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons'

const SortButton = ({currentOrder, order, onChange, ...props}) => {
    const handleClick = (e) => {
        const selectedOrder = e.currentTarget.dataset.order

        if (currentOrder === selectedOrder) {
            onChange('')
        }
        else {
            onChange(order)
        }
    }

    return (
        <Button
            data-order={order}
            block
            size="small"
            type={currentOrder === order ? 'primary' : ''}
            onClick={handleClick}
            {...props}
        />
    )
}

const FilterHead = ({order, onChange, ...props}) => {
    const css = {
        display: 'flex',

        '&>.ant-space-item': {
            flexGrow: 1,
        }
    }

    return (
        <Space
            css={css}
            {...props}
        >
            <SortButton
                currentOrder={order}
                icon={<SortDescendingOutlined />}
                order="asc"
                onChange={onChange}
            >升序</SortButton>

            <SortButton
                currentOrder={order}
                icon={<SortAscendingOutlined />}
                order="desc"
                onChange={onChange}
            >降序</SortButton>
        </Space>
    )
}

export default FilterHead
