/** @jsxImportSource @emotion/react */

import {Modal} from 'antd'
import {useTableContext} from '../TableContext.mjs'
import TopButton from './TopButton.jsx'

const ButtonMoveRowsUp = ({children = '上移', ...props}) => {
    const table = useTableContext()

    const handleClick = () => {
        if (
            0 === table.filters.length &&
            0 === table.sorts.length
        ) {
            table.moveRowsUp(table.rowSelection?.selectedRowKeys)
        }
        else {
            Modal.error({content: '请先取消筛选和排序'})
        }
    }

    return (
        <TopButton
            minRows={1}
            onClick={handleClick}
            {...props}
        >{children}</TopButton>
    )
}

export default ButtonMoveRowsUp
