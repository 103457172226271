/** @jsxImportSource @emotion/react */

import {useEffect, useRef, useState} from 'react'

const useContainer = () => {
    const refContainer = useRef()
    const [scroll, setScroll] = useState({scrollLeft: 0, scrollTop: 0})
    const [box, setBox] = useState({width: 0, height: 0})

    const handleScroll = ({target: {scrollLeft, scrollTop}}) => {
        //const el = refContainer.current
        //const maxScrollLeft = el.scrollWidth - el.clientWidth
        //const maxScrollTop = el.scrollHeight - el.clientHeight

        //setScroll({
            //scrollLeft: Math.min(maxScrollLeft, scrollLeft),
            //scrollTop: Math.min(maxScrollTop, scrollTop),
        //})
        setScroll({scrollLeft, scrollTop})
    }

    useEffect(
        () => {
            const el = refContainer.current

            if (! el) {
                return
            }

            const observer = new window.ResizeObserver((entries) => {
                const {width, height} = entries[0].contentRect
                setBox({width, height})
            })

            observer.observe(el)
            el.addEventListener('scroll', handleScroll, {passive: true})

            return () => {
                observer.unobserve(el)
                el.removeEventListener('scroll', handleScroll)
            }
        },

        []
    )

    return [refContainer, {...box, ...scroll}]
}

const prependClassName = (props = {}, className = '') => {
    if (props.className) {
        return {
            ...props,
            className: `${className} ${props.className}`
        }
    }
    else {
        return {
            ...props,
            className,
        }
    }
}

const TableContent = ({
    cellProps,
    colBegin,
    colEnd,
    colWidths,
    contentBodyProps,
    contentLeft,
    contentProps,
    contentTop,
    fixedLeftCount,
    fixedRightCount,
    invisibleLeftWidth,
    invisibleRightWidth,
    render,
    rowBegin,
    rowEnd,
    rowHeights,
    rowKey,
    rowProps,
    allColRender = false,//所有列均渲染，列渲染不使用虚拟滚动
}) => {
    if (
        -1 === colBegin ||
        -1 === rowBegin ||
        colBegin === colEnd
    ) {
        return null
    }

    const trs = []

    for (let i = rowBegin; i < rowEnd; i += 1) {
        const props = prependClassName(rowProps(i), 'hd-virtual-table__row')

        props.style = {
            ...props.style,
            height: rowHeights[i],
        }

        const makeTd = (i, j, className, style) => {
            const props = prependClassName(cellProps(i, j), className)

            props.style = {
                ...props.style,
                ...style,
            }

            const content = render(i, j)

            return (
                <td
                    key={j}
                    {...props}
                >
                    {content}
                </td>
            )
        }

        const tds = []

        // 左固定列
        {
            let left = 0

            for (let j = 0; j < fixedLeftCount; j += 1) {
                const width = colWidths[j]

                const td = makeTd(
                    i,
                    j,
                    'hd-virtual-table__cell hd-virtual-table__cell--fixed hd-virtual-table__cell--fixed-left',
                    {left, width}
                )

                tds.push(td)
                left += width
            }
        }

        // 默认列渲染时，也使用虚拟滚动
        if(!allColRender){
            // 不可见列占位，防止固定列在滚动中抖动。
            //
            // 宽度设为 0 的单元格也有实际宽度，这会对宽度相关计算造成影响，
            // 故宽度为 0 的情况不添加单元格
            if (invisibleLeftWidth) {
                tds.push(
                    <td
                        key="invisible-left"
                        style={{width: invisibleLeftWidth}}
                    />
                )
            }

            // 非固定列
            for (let j = colBegin; j < colEnd; j += 1) {
                const width = colWidths[j]
                const td = makeTd(i, j, 'hd-virtual-table__cell', {width})
                tds.push(td)
            }

            // 不可见列占位，防止固定列在滚动中抖动。
            //
            // 宽度设为 0 的单元格也有实际宽度，这会对宽度相关计算造成影响，
            // 故宽度为 0 的情况不添加单元格
            if (invisibleRightWidth) {
                tds.push(
                    <td
                        key="invisible-right"
                        style={{width: invisibleRightWidth}}
                    />
                )
            }
        }else{
            for (let j = fixedLeftCount; j <colWidths.length; j += 1) {
                const width = colWidths[j]
                const td = makeTd(i, j, 'hd-virtual-table__cell', {width})
                tds.push(td)
            }
        }

        {
            const rightTds = []
            let right = 0

            // 右固定列
            for (
                let j = colWidths.length - 1;
                colWidths.length - fixedRightCount - 1 < j;
                j -= 1
            ) {
                const width = colWidths[j]

                const td = makeTd(
                    i,
                    j,
                    'hd-virtual-table__cell hd-virtual-table__cell--fixed hd-virtual-table__cell--fixed-right',
                    {right, width}
                )

                rightTds.unshift(td)
                right += width
            }

            tds.push(...rightTds)
        }

        const key = rowKey(i)

        trs.push(
            <tr
                key={key}
                {...props}
            >
                {tds}
            </tr>
        )
    }

    const style = {
        position: 'absolute',
        top: contentTop,
        left: contentLeft - invisibleLeftWidth,
        tableLayout: 'fixed',
    }

    return (
        <table
            {
                ...prependClassName(
                    contentProps,
                    'hd-virtual-table__content'
                )
            }

            style={style}
        >
            <tbody
                {
                    ...prependClassName(
                        contentBodyProps,
                        'hd-virtual-table__body'
                    )
                }
            >
                {trs}
            </tbody>
        </table>
    )
}

const VirtualTable = ({
    cellProps = (rowIndex, colIndex) => ({}),
    colCount = 0,
    colWidth = (colIndex) => 0,
    contentBodyProps,
    contentProps,
    fixedLeftCount = 0,
    fixedRightCount = 0,
    render = (rowIndex, colIndex) => null,
    rowCount = 0,
    rowHeight = (rowIndex) => 0,
    rowKey,
    rowProps = (rowIndex) => ({}),
    ...props
}) => {
    const [refContainer, container] = useContainer()

    const colWidths = [] // 各列宽度
    let colBegin = -1 // 可见的非固定列起始索引
    let colEnd = -1 // 可见的非固定列结束索引
    let contentLeft = 0 // 可见表格内容左定位
    let contentWidth = 0 // 可见表格内容宽度
    let fixedLeftWidth = 0 // 左固定列总宽度
    let fixedRightWidth = 0 // 右固定列总宽度
    let invisibleLeftWidth = 0 // 左侧不可见列总宽度
    let invisibleRightWidth = 0 // 左侧不可见列总宽度
    let tableWidth = 0 // 全表格宽度

    // 容器加载前相关计算无意义
    if (container.width && container.height) {
        // 可见表格内容左边界
        const viewportLeft = container.scrollLeft

        // 可见表格内容右边界
        // 当 1 !== window.devicePixelRatio 时，右边界往往不是整数，
        // 要对其进行向下取整以保证能计算出正确的列结束索引
        const viewportRight = Math.floor(viewportLeft + container.width)

        // 左固定列
        for (let i = 0; i < fixedLeftCount; i += 1) {
            const w = colWidth(i)
            colWidths[i] = w
            tableWidth += w
            fixedLeftWidth += w
        }

        // 右固定列
        for (
            let i = colCount - fixedRightCount;
            i < colCount;
            i += 1
        ) {
            const w = colWidth(i)
            colWidths[i] = w
            tableWidth += w
            fixedRightWidth += w
        }

        let colLeft = fixedLeftWidth // 当前列左边界

        // 非固定列
        for (
            let i = fixedLeftCount;
            i < colCount - fixedRightCount;
            i += 1
        ) {
            const w = colWidth(i)
            const colRight = colLeft + w // 当前列右边界
            colWidths[i] = w
            tableWidth += w

            if (
                -1 === colBegin &&
                colLeft <= viewportLeft + fixedLeftWidth &&
                viewportLeft + fixedLeftWidth < colRight
            ) {
                contentLeft = colLeft - fixedLeftWidth
                colBegin = i
            }

            if (-1 === colBegin) {
                invisibleLeftWidth += w
            }
            else if (-1 === colEnd) {
                contentWidth += w
            }
            else {
                invisibleRightWidth += w
            }

            if (
                -1 === colEnd &&
                colLeft < viewportRight - fixedRightWidth &&
                viewportRight - fixedRightWidth <= colRight
            ) {
                colEnd = i + 1
            }

            colLeft = colRight
        }

        if (-1 === colEnd) {
            colEnd = colCount - fixedRightCount
        }
    }
    // 容器加载前先计算实际表格尺寸可以节省一次渲染
    else {
        for (let i = 0; i < colCount; i += 1) {
            tableWidth += colWidth(i)
        }
    }

    const rowHeights = [] // 各列高度
    let contentHeight = 0 // 可见表格内容高度
    let contentTop = 0 // 可见表格内容上定位
    let rowBegin = -1 // 可见的行起始索引
    let rowEnd = -1 // 可见的行结束索引
    let tableHeight = 0 // 全表格高度

    // 容器加载前相关计算无意义
    if (container.width && container.height) {
        let rowTop = 0 // 当前行上边界

        // 可见表格内容上边界
        const viewportTop = container.scrollTop

        // 可见表格内容下边界
        // 当 1 !== window.devicePixelRatio 时，下边界往往不是整数，
        // 要对其进行向下取整以保证能计算出正确的行结束索引
        const viewportBottom = Math.floor(viewportTop + container.height)

        for (let i = 0; i < rowCount; i += 1) {
            const h = rowHeight(i)
            const rowBottom = rowTop + h // 当前行下边界
            tableHeight += h
            rowHeights.push(h)

            if (
                -1 === rowBegin &&
                rowTop <= viewportTop &&
                viewportTop < rowBottom
            ) {
                rowBegin = i
                contentTop = rowTop
            }

            if (-1 < rowBegin && -1 === rowEnd) {
                contentHeight += h
            }

            if (
                -1 === rowEnd &&
                rowTop < viewportBottom &&
                viewportBottom <= rowBottom
            ) {
                rowEnd = i + 1
            }

            rowTop = rowBottom
        }

        if (-1 === rowEnd) {
            rowEnd = rowCount
        }
    }
    // 容器加载前先计算实际表格尺寸可以节省一次渲染
    else {
        for (let i = 0; i < rowCount; i += 1) {
            tableHeight += rowHeight(i)
        }
    }

    const css = {
        position: 'relative',
        backgroundColor: '#fff',
        overflow: 'auto',
        transform: 'scale(1)',

        '&::before': {
            content: '""',
            display: 'block',
            width: tableWidth,
            height: tableHeight,
        },

        '.hd-virtual-table__content': {
            backgroundColor: 'inherit',
        },

        '.hd-virtual-table__body': {
            backgroundColor: 'inherit',
        },

        '.hd-virtual-table__row': {
            position: 'relative',
            backgroundColor: 'inherit',
        },

        '.hd-virtual-table__cell': {
            backgroundColor: 'inherit',
        },

        '.hd-virtual-table__cell--fixed': {
            position: 'sticky',
        },
    }

    return (
        <div
            {...props}
            ref={refContainer}
            css={css}
        >
            <TableContent
                cellProps={cellProps}
                colBegin={colBegin}
                colEnd={colEnd}
                colWidths={colWidths}
                contentBodyProps={contentBodyProps}
                contentLeft={contentLeft}
                contentProps={contentProps}
                contentTop={contentTop}
                fixedLeftCount={fixedLeftCount}
                fixedRightCount={fixedRightCount}
                fixedRightWidth={fixedRightWidth}
                invisibleLeftWidth={invisibleLeftWidth}
                invisibleRightWidth={invisibleRightWidth}
                render={render}
                rowBegin={rowBegin}
                rowEnd={rowEnd}
                rowHeights={rowHeights}
                rowKey={rowKey}
                rowProps={rowProps}
                allColRender={props.allColRender}
            />
        </div>
    )
}

export default VirtualTable
