import {ConfigProvider} from 'antd'

const AntdConfig = (props) => {
    const config = {
        //componentSize: 'small',
    }

    return (
        <ConfigProvider
            {...config}
            {...props}
        />
    )
}

export default AntdConfig
