/** @jsxImportSource @emotion/react */
import InputText from "../../Form/InputText";

;
import {useEffect, useMemo, useState} from 'react';
import {Checkbox, Typography, Divider, Tree, Spin, Input, Space, Tag, Row, Col, Button} from 'antd';
import {AppstoreTwoTone, ExclamationCircleOutlined} from '@ant-design/icons'
import Tools from "script/sweet-tools.mjs";
import List from 'rc-virtual-list'
import _ from "lodash";
import useGlobalData from "hooks/useGlobalData.mjs";
import DraggableModal from "components/DraggableModal/DraggableModal.jsx";

const {Text} = Typography;

/**
 * 弹出式权限组选择组件
 * @param extra 自定义扩展属性
 * @param props Modal 组件原生属性
 * @returns {JSX.Element}
 * @constructor
 */
const Pane = ({uuDataIsolation = true, permissionCode, permissionOptCode = "query", searchParam = {}, value, visible, multiple = false, onOk, onCancel, modalProps}) => {
    //表格 Loading 控制变量
    const [loading, setLoading] = useState(false);

    //原始数据源
    const [dataSource, setDataSource] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [keyWord, setKeyWord] = useState(null);

    const [orgData, setOrgData] = useState([]);
    const [orgKeyWord, setOrgKeyWord] = useState(null);
    const [orgCheckedKeys, setOrgCheckedKeys] = useState([]);
    const [orgExpandedKeys, setOrgExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const _props = {
        width: '800px',
        ...modalProps
    }

    //组织树展开回调
    const onOrgExpand = (expandedKeysValue) => {
        setOrgExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false)
    };

    //组织搜索算法
    const orgQueryMap = ({key, pKey, title, children, ...props}) => {
        if (orgKeyWord && orgKeyWord.trim() !== '') {
            if (children && children.length > 0) {
                const children_ = children.map(orgQueryMap).filter(f => f);
                if (children_.length > 0) {
                    return {
                        key, pKey, title, children: children_, ...props
                    };
                } else {
                    if (title.indexOf(orgKeyWord) > -1) {
                        return {
                            key, pKey, title, ...props
                        };
                    } else {
                        return null;
                    }
                }
            } else {
                if (title.indexOf(orgKeyWord) > -1) {
                    return {
                        key, pKey, title, children, ...props
                    };
                } else {
                    return null;
                }
            }
        } else {
            return {
                key, pKey, title, children, ...props
            };
        }
    }

    //选中组织
    const onOrgSelect = (selectedKeys, {selected, selectedNodes, node, event}) => {
        if (selected) {
            setOrgCheckedKeys(selectedKeys);
        } else {
            setOrgCheckedKeys([])
        }
    }

    //选中变更
    const onCheckChange = e => {
        const {'data-value': value, checked} = e.target

        if (multiple) {
            setCheckedKeys((options) => {
                const newOptions = new Set(options)

                if (checked) {
                    newOptions.add(value)
                } else {
                    newOptions.delete(value)
                }

                const list = Array.from(newOptions);
                return list
            });
        } else {
            //单选直接关闭弹窗
            if (checked) {
                setCheckedKeys([value]);
                const rows = dataSource.filter(item => item.puId == value);
                onOk([value], rows)
            } else {
                setCheckedKeys((options) => {
                    const newOptions = new Set(options)

                    if (checked) {
                        newOptions.add(value)
                    } else {
                        newOptions.delete(value)
                    }

                    const list = Array.from(newOptions);
                    return list
                });
            }
        }
    };

    //全选
    const onCheckAllChange = e => {
        const data = dataSource.filter(({orgId}) => {
            if (orgCheckedKeys && orgCheckedKeys.length > 0) {
                return orgCheckedKeys[0] == orgId;
            } else {
                return true;
            }
        }).filter(({puName}) => {
            if (keyWord && keyWord.trim() !== '') {
                return puName.indexOf(keyWord) > -1;
            } else {
                return true;
            }
        })
        setCheckedKeys(data.map(({puId}) => puId));
    };

    const _onOk = (e) => {
        const rows = dataSource.filter(item => checkedKeys.includes(item.puId));
        onOk(checkedKeys, rows)
    };

    const _onCancel = (e) => {
        onCancel();
    };

    //查询 组织
    const doQueryOrg = async (puOrgPaths) => {
        const data = useGlobalData.getOrgs()
        if (data) {
            const orgMap = new Map();

            //结构化数据：填充树组件需要的 key pKey title children
            let orgs = data.map(({orgId, orgPId, orgName, ...props}) => {
                const node = {
                    ...props,
                    key: orgId,
                    pKey: orgPId,
                    title: orgName,
                    icon: <AppstoreTwoTone twoToneColor='#fa8c16'/>,
                    orgName,
                    children: [],
                };
                orgMap.set(orgId, node);
                return node;
            });

            //根据权限组来过滤组织，只展示权限组所属的组织路径
            if (puOrgPaths && puOrgPaths.length > 0) {
                orgs = orgs.filter(({orgPathNo}) => {
                    for (let i = 0; i < puOrgPaths.length; i++) {
                        if (puOrgPaths[i].startsWith(orgPathNo)) {
                            return true;
                        }
                    }
                    return false;
                })
            }

            //数据转为树形
            let treeData = [];
            const expandedKeys = [];
            orgs.forEach(({key, pKey, title, children, ...props}) => {
                const treeNode = {key, pKey, title, children, ...props};

                if (!pKey) {
                    treeData.push(treeNode);
                } else {
                    const pTreeNode = orgMap.get(pKey);
                    if (pTreeNode) {
                        pTreeNode.children.push(treeNode);
                    }
                }

                //初始化默认全展开
                expandedKeys.push(key)
            })

            //用户单位数据隔离
            if (uuDataIsolation) {
                // 当前用户
                const user = Tools.authenticator.getUser();
                if (user.uu && user.uu.uuOrgId) {
                    treeData = treeData.filter(({key}) => key == user.uu.uuOrgId);
                }
            }

            setOrgData(treeData);
            setOrgExpandedKeys(treeData.map(({key}) => key))
        }
    }

    //查询数据
    useEffect(() => {
        (async () => {
            if (visible) {
                //设置默认选中值
                if (value instanceof Array) {
                    setCheckedKeys([...value]);
                } else {
                    if (value) {
                        setCheckedKeys([value]);
                    }
                }

                setOrgCheckedKeys([])
                setOrgKeyWord(null)
                setKeyWord(null)

                setLoading(true)
                try {
                    const data = useGlobalData.getPus();
                    if (permissionCode && permissionOptCode) {
                        const pus = data.filter(item => {
                            return Tools.authenticator.checkComponentPermissionScope({
                                permissionCode,
                                permissionOptCode,
                                sourceObject: item,
                                sourceType: 'PU'
                            })
                        });

                        setDataSource(pus)

                        //获取 pu 对应的 orgPath
                        if (pus.length > 0) {
                            const orgPaths = useGlobalData.getOrgPathNo(pus.map(({orgId}) => orgId));
                            await doQueryOrg(orgPaths);

                        }
                    } else {
                        setDataSource(data);
                        await doQueryOrg();
                    }

                } finally {
                    setLoading(false)
                }
            }
        })();
    }, [visible]);

    return (
        <DraggableModal style={{top: 20}} bodyStyle={{padding: '12px', overflow: 'hidden', display: 'flex', height: '80vh'}} title="请选择权限组" visible={visible} onOk={_onOk}
                        onCancel={_onCancel} {..._props} confirmLoading={loading}>
            {
                loading &&
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, height: 654}}>
                    <Spin spinning={loading}></Spin>
                </div>
            }
            {
                !loading &&
                (
                    <>
                        <div style={{width: 350, display: 'flex', flexShrink: '0', flexDirection: 'column', overflow: 'hidden',}}>
                            <Space style={{marginBottom: 12,}}>
                                <Text>组织树</Text>
                                <Text type="secondary" style={{fontSize: 12}}><ExclamationCircleOutlined/> 通过选择组织可以对可选权限组列表进行筛选</Text>
                            </Space>
                            <InputText
                                size="small"
                                onPressEnter={({target: {value}}) => {
                                    setOrgKeyWord(value)
                                }}
                                onBlur={({target: {value}}) => {
                                    setOrgKeyWord(value)
                                }}
                                allowClear
                                placeholder='输入组织名称关键词，按回车键进行搜索'
                                style={{marginBottom: 12,}}
                                value={orgKeyWord}
                            />
                            <div style={{height: 560, overflow: 'hidden', border: '1px solid #f0f0f0'}}>
                                <Tree
                                    height={560}
                                    motion={null}
                                    defaultExpandAll={false}
                                    showLine={{showLeafIcon: false,}}
                                    showIcon={true}
                                    selectedKeys={orgCheckedKeys}
                                    treeData={orgData.map(orgQueryMap).filter(f => f)}
                                    onSelect={onOrgSelect}
                                    onExpand={onOrgExpand}
                                    expandedKeys={orgExpandedKeys}
                                    autoExpandParent={autoExpandParent}
                                />
                            </div>
                        </div>

                        <div style={{display: 'flex', marginLeft: '12px', flexDirection: 'column', flexGrow: 1, overflow: 'hidden'}}>
                            <Text style={{marginBottom: 12,}}>可选权限组列表</Text>
                            <InputText
                                size="small"
                                onPressEnter={({target: {value}}) => {
                                    setKeyWord(value)
                                }}
                                onBlur={({target: {value}}) => {
                                    setKeyWord(value)
                                }}
                                allowClear
                                placeholder='输入权限组名称关键词，按回车键进行搜索'
                                style={{marginBottom: 12,}}
                                value={orgKeyWord}
                            />
                            <div style={{padding: 4, marginBottom: 12, border: '1px solid #f0f0f0'}}>
                                {
                                    multiple &&
                                    <>
                                        <Space>
                                            <Text>快捷操作:</Text>
                                            <Button size="small" onClick={onCheckAllChange}>全选</Button>
                                            <Button size="small" onClick={() => setCheckedKeys([])}>取消</Button>
                                        </Space>
                                        <Divider style={{marginBottom: 2, marginTop: 2}}></Divider>
                                    </>
                                }
                                <List
                                    data={
                                        dataSource.filter(({orgId}) => {
                                            if (orgCheckedKeys && orgCheckedKeys.length > 0) {
                                                return orgCheckedKeys[0] == orgId;
                                            } else {
                                                return true;
                                            }
                                        }).filter(({puName}) => {
                                            if (keyWord && keyWord.trim() !== '') {
                                                return puName.indexOf(keyWord) > -1;
                                            } else {
                                                return true;
                                            }
                                        })
                                    }
                                    height={300}
                                    itemHeight={24}
                                    css={{
                                        '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
                                            marginLeft: 0,
                                        }
                                    }}
                                >
                                    {
                                        (item) => (
                                            <Checkbox
                                                data-value={item.puId}
                                                checked={checkedKeys.includes(item.puId)}
                                                onChange={onCheckChange}
                                            >{item.puName}</Checkbox>
                                        )
                                    }
                                </List>
                            </div>
                            <div style={{flexGrow: '1', border: '1px solid #f0f0f0', overflow: 'auto', padding: 4}}>
                                {
                                    checkedKeys.filter(value => dataSource.find(item => item.puId == value)).map(value => {
                                        const item = dataSource.find(item => item.puId == value);
                                        return (
                                            <Tag
                                                key={item.puId}
                                                color="blue"
                                                style={{marginRight: 8, marginBottom: 8}}
                                                closable
                                                onClose={(e) => {
                                                    setCheckedKeys((options) => {
                                                        const list = options.filter(puId => puId != item.puId);
                                                        return list
                                                    })
                                                }}
                                            >
                                                {item.puName}
                                            </Tag>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </DraggableModal>
    )
}

export default Pane;
