/** @jsxImportSource @emotion/react */
;
import {useEffect, useState} from 'react';
import {Form, Button} from 'antd';
import Table from 'components/Table/Table';
import Tools from "script/sweet-tools.mjs";
import DraggableModal from 'components/DraggableModal/DraggableModal';
import moment from "moment";

/**
 * 采购合同改单记录
 * @param extra 自定义扩展属性
 * @param props Modal 组件原生属性
 * @returns {JSX.Element}
 * @constructor
 */
const PurBillAlterLogList = ({billId, visible, setVisible, modalProps}) => {
    //表单对象
    const [form] = Form.useForm();
    //表格数据定义
    const [data, setData] = useState([]);
    //表格 Loading 控制变量
    const [loading, setLoading] = useState(false);

    const _props = {
        width: 1200,
        ...modalProps
    }
    //查询数据
    useEffect(() => {
        if (visible) {
            doQuery();
        } else {
            form.resetFields();
        }
    }, [visible]);


    //查询表格数据
    const doQuery = async () => {
        //开启 Loading
        setLoading(true);
        try {
            const data = await Tools.runAsync(
                async () => {
                    let params = {billId: billId};
                    return await Tools.http.post("/pur/purbillalterlog/listPurBillAlterLogDRs", params)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setData(data)
        } finally {
            //关闭 Loading
            setLoading(false);
        }
    }

    const _onCancel = (e) => {
        setVisible(false);
    };

    const cssTable = {
        '& thead tr .header-blue1 ': {background: '#DCE2F1'},
    }
    //表格列头定义
    const columns = [
        {
            title: '改单时间',
            dataIndex: 'crtTime',
            key: 'crtTime',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => (
                <text>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</text>
            )
        }, {
            title: '改单人',
            dataIndex: 'crtUserName',
            key: 'crtUserName',
            width: 60,
        }, {
            title: '改单备注',
            dataIndex: 'altMemo',
            key: 'altMemo',
            width: 400,
            ellipsis: true,
        },
    ];

    const extra = {
        disabledSettingControl: true,
        disabledColumnControl: true
    }


    return (
        <DraggableModal
            bodyStyle={{padding: '0', overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '600px'}}
            width={'90%'}
            title="改单记录"
            visible={visible}
            onCancel={_onCancel}
            destroyOnClose={true} {..._props}
            footer={[
                <Button key="close" onClick={_onCancel}>
                    关闭
                </Button>
            ]}>
            <div className="components-table-container-all">
                <Table columns={columns}
                       css={cssTable}
                       dataSource={data}
                       size="small"
                       loading={loading}
                       rowKey="mrpDtlId"
                       bordered
                       scroll={{y: 500}}
                       extra={extra}
                />
            </div>
        </DraggableModal>
    )
}

export default PurBillAlterLogList;
