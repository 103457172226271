/** @jsxImportSource @emotion/react */

import {useState} from 'react'
import {Checkbox} from 'antd'
import List from 'rc-virtual-list'

const defaultOptions = []
const defaultValue = []
const defaultOnChange = () => {}

const CheckboxList = ({
    options = defaultOptions,
    value = defaultValue,
    onChange = defaultOnChange,
    numberRD,
    ...props
}) => {
    const [height, setHeight] = useState(0)

    const ref = (el) => {
        if (el) {
            setTimeout(() => setHeight(el.clientHeight), 1)
        }
    }

    // 空白始终排在最后
    const _options = options.slice().sort(
        ([valueA, labelA = valueA], [valueB, labelB = valueB]) => {
            if (! labelA) {
                return 1
            }

            if (! labelB) {
                return -1
            }

            return 0
        }
    )

    const checkedValues = new Set(value)

    const handleChange = (e) => {
        const {'data-value': value, checked} = e.target

        if (checked) {
            checkedValues.add(value)
        }
        else {
            checkedValues.delete(value)
        }

        onChange(Array.from(checkedValues))
    }

    const css = {
        overflow: 'hidden',

        '& .ant-checkbox-wrapper': {
            overflow: 'hidden',
        },

        '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
            marginLeft: 0,
        },

        '& .ant-checkbox + span': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

            '&:empty::before': {
                content: '"(空白)"',
            }
        },
    }

    return (
        <div
            ref={ref}
            css={css}
        >
            <List
                data={_options}
                height={height}
                itemHeight={22}
                {...props}
            >
                {
                    ([value, label = value]) => (
                        <Checkbox
                            data-value={value}
                            checked={checkedValues.has(value)}
                            onChange={handleChange}
                        >{ (value||value===0) ?(numberRD? Number(value).toFixed(numberRD):value) : ""}</Checkbox>
                    )
                }
            </List>
        </div>
    )
}

export default CheckboxList
