/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react'
const defaultGetText = (a) => a

const cssViewLongText = css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const ViewLongText = ({
    getText = defaultGetText,
    value,
    ...props
}) => {
    const text = (() => {
        if (value || 0 === value) {
            return getText(value)
        }
        else {
            return ''
        }
    })()

    return (
        <div
            css={cssViewLongText}
            title={text}
            {...props}
        >
            {text}
        </div>
    )
}

export default ViewLongText
