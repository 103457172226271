/** @jsxImportSource @emotion/react */
import React, {useEffect, useRef, useState} from 'react';
import Frame from 'components/Frame/Frame';
import {Button, Modal} from "antd";
import useGlobalData from "hooks/useGlobalData";
import Tools from "script/sweet-tools.mjs";
import {useMainTabContext} from 'components/MainTabs/MainTabs.jsx'
import StartAuditBeforeModal from "pages/BizPage/FLOW/AUDIT/StartAuditBeforeModal";
import {useOpenModal} from 'components/Modal/Modal.jsx'
import CheckSmSoModal from "./CheckSmSoModal";
import SubmitSmSoModal from "./SubmitSmSoModal";
import EvSmSoModal from "./EvSmSoModal";
import CancelBudgetModal from "./CancelBudgetModal";
import ChangeSmSoModal from "./ChangeSmSoModal";
import JudgeRepealModal from "./JudgeRepealModal";
import FlowChangeModal from "../../FLOW/AUDIT/FlowChangeModal";
import authenticator from "../../../../script/sweet-authenticator";
import FlowTaskActiveModal from "../../FLOW/AUDIT/FlowTaskActiveModal";
import SubmitDoneModal from "./SubmitDoneModal";
import CancelDoneModal from "./CancelDoneModal";
import CancelSmSoModal from "./CancelSmSoModal";
import {itsDone, oneFlowBillState, validCaseDone, validSoPay} from "pages/BizPage/SM/SM30_DT_SM_CSO_LIST/Valid/soApi";
import {dictSoAttribute} from "pages/BizPage/SM/SM30_DT_SM_CSO_LIST/Valid/SoValid";
import ButtonCreateBeSo from "./ButtonCreateBeSo";


const CSODtlFrame = ({record, initMainQry, defaultPaneKey, ...props}) => {

    const {$key} = props;
    const mainTab = useMainTabContext()
    const [rows, setRows] = useState({});
    const {so} = rows;
    const crtUserFlag = so?.crtUserFlag;
    const [soStyleQty, setSoStyleQty] = useState("");
    const defRef = useRef({});
    const [scgss, setScgss] = useState()
    const [styleSgs, setStyleSgs] = useState()
    const [visibleCheck, setVisibleCheck] = useState(false);
    const [visibleSubmitSmSo, setVisibleSubmitSmSo] = useState(false);
    const [visibleEvSmSo, setVisibleEvSmSo] = useState(false);
    const [visibleCancelBudget, setVisibleCancelBudget] = useState(false);
    const [visibleChange, setVisibleChange] = useState(false);
    const [visibleRepeal, setVisibleRepeal] = useState(false);
    const [auditSmSoBudgetLoading, setAuditSmSoBudgetLoading] = useState(false);
    const [visibleBudgetFlow, setVisibleBudgetFlow] = useState(false);
    const [visibleFinalFlow, setVisibleFinalFlow] = useState(false);
    const [visibleSubmitDone, setVisibleSubmitDone] = useState(false);
    const [visibleCancelCaseDone, setVisibleCancelCaseDone] = useState(false);
    const [visibleCancelSmSo, setVisibleCancelSmSo] = useState(false);
    const [syncItsOrderLoading, setSyncItsOrderLoading] = useState(false);
    const [itsDoneLoading, setItsDoneLoading] = useState(false);

    const [crtOprtnStyle, setCrtOprtnStyle] = useState(""); // 当前操作的款

    //--------------------------权限------------------------------------------

    // 编辑
    const [soEdit, setSoEdit] = useState(false);
    // 审核
    const [soVerify, setSoVerify] = useState(false);
    // 改单
    const [soChange, setSoChange] = useState(false);
    // 人工同步
    const [soSy, setSoSy] = useState(false);

    const soType = record?.soTypeCode; // 外贸单款：FSS  外贸多款：FMS    内贸单款：DSS  内贸多款：DMS
    const more = ['FMS', 'DMS'].includes(soType);
    const soFabricFlag = ['FMM', 'DMM'].includes(soType); // 外贸面辅料订单
    const isDSO = ['DSS', 'DMS', 'DMM', 'DO'].includes(soType);//是否是内贸订单
    const smSoType = (['FSS', 'FMS', 'FMM', 'FO'].includes(soType)) == true ? 'F' : 'D' // 外贸单子

    const soAttribute = record?.soAttribute; // 订单属性
    const [revbomEdit, setRevbomEdit] = useState(false);

    const [ppEdit, setPpEdit] = useState(false); // 排产计划权限

    const [fftFlag ,setFftFlag] = useState(false) // 外贸经销模式
    const [srSoFlag , setSrSoFlag] = useState(false);



    //回调修改标签页 hint
    useEffect(() => {
        init();
    }, []);
    useEffect(() => {
        getPermission();
    }, [rows]);

    // 获取权限状态
    const getPermission = () => {

        setSoEdit(authenticator.hasPermissionEdit({
            permissionCode: smSoType == 'F' ? 'FSO' : 'DSO',
            billPuId: record?.soPuId,
            billUserId: record?.crtUserId,
            billOrgId: record?.bdId,
        }) == true ? (['CDING', 'DONE', 'REPEAL'].includes(so?.soStsCode) ? false : true) : false);

        setSoVerify(authenticator.hasPermissionVerify({
            permissionCode: smSoType == 'F' ? 'FSO' : 'DSO',
            billPuId: record?.soPuId,
            billUserId: record?.crtUserId,
            billOrgId: record?.bdId,
        }) == true ? 'REPEAL' != so?.soStsCode ? true : false : false);
        setSoChange(authenticator.hasPermissionChange({
            permissionCode: smSoType == 'F' ? 'FSO' : 'DSO',
            billPuId: record?.soPuId,
            billUserId: record?.crtUserId,
            billOrgId: record?.bdId,
        }) == true ? 'REPEAL' != so?.soStsCode ? true : false : false);

        setSoSy(authenticator.hasPermissionEdit({permissionCode: "SOSY"}) == true ? 'REPEAL' != so?.soStsCode ? true : false : false
        )

        // 订单BOM 权限
        setRevbomEdit(authenticator.hasPermissionEdit({permissionCode: "REVBOM"}));

        // 排产计划权限
        setPpEdit(authenticator.hasPermissionEdit({
            permissionCode: "SMCMPP",
        }))

    }


    const init = async () => {

        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/oneSmSoDTO", {soId: record.soId})
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            console.log("---------------frame-----------------------", data);

            setRows(data);
            if (data) {
                setSoStyleQty(data.so.soStyleQty)

                if (crtOprtnStyle == null || crtOprtnStyle == '') {
                    setCrtOprtnStyle(data.soStyles[0].soStyle?.sosId)
                }

            }
            let styleStr = "";
            data.soStyles.map(m=>{
                if (!m.soStyle?.suiteSeq) {
                    m.soStyle.suiteSeq = '';
                }
                styleStr = styleStr + m.soStyle?.styleNo + " " + m.soStyle?.suiteSeq + " ,";
            })
            styleStr = styleStr.substring(0,styleStr.length -1);

            mainTab.config({
                title: smSoType == 'F' ?
                    (['FMS', 'FMM'].includes(record?.soTypeCode) ? `款号${styleStr}  ${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}]`
                        : `款号${styleStr}  ${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}]`)
                    : (['DMS', 'DMM'].includes(record?.soTypeCode) ? `款号${styleStr}  ${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}]`
                        : `款号${styleStr}  ${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}]`)
            })

            setFftFlag(['FDT'].includes(data?.so?.soModeCode))
            setSrSoFlag(!data?.so?.sourceSoId);

            // mainTab.config({
            //     title: smSoType == 'F' ?
            //         (['FMS', 'FMM'].includes(record?.soTypeCode) ? `${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}] 款数 ${data.so.styleNos != null ? data.so.styleNos.split(",").length : ""}`
            //             : `${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}] 款号${data.so.styleNos}`)
            //         : (['DMS', 'DMM'].includes(record?.soTypeCode) ? `${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}] 款数 ${data.so.styleNos != null ? data.so.styleNos.split(",").length : ""}`
            //             : `${dictSoAttribute(record?.soAttribute)}【${data.so.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", data.so.soStsCode)}] 款号${data.so.styleNos} `)
            // })

        } finally {

        }

    }

    // 提交销售订单准入审核DRs
    const submitSmSoRoDRs = async (setLoading, setVisible) => {

        try {
            setLoading(true);
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/submitSmSoRoDRs", {
                        soIds: [record.soId],
                        evState: "TOEV",
                        soState: "DOING"
                    })
                },
                {
                    error: {content: (err) => `提交准入失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );

            // 提交完开始审核
            const evData = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/evSmSoRoDRs", {
                        soIds: [record.soId],
                        evState: 'PASS',
                        soState: ''
                    })
                },
                {
                    error: {content: (err) => `准入审核失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            setVisible(false);
            init();

        } catch (err) {
            console.log(err)
        } finally {
            setVisible(false);
            setLoading(false);
        }

    }

    // 准入审核
    const evSmSoRoDRs = () => {
        setVisibleEvSmSo(true);
    }

    const doEvSmSoRoDRs = async (evState, soState, setLoading, setVisible) => {
        // 一、准入通过 准入审核状态=审核通过（PASS）
        // 二、准入不通过 准入审核状态= 退回（RETURN）销售订单状态 = 草稿（DRAFT）
        setLoading(true);
        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/evSmSoRoDRs", {
                        soIds: [record.soId],
                        evState: evState,
                        soState: soState
                    })
                },
                {
                    error: {content: (err) => `准入审核失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            setVisible(false);
            //initMainQry();
            init();

        } catch (err) {
            console.log(err)
        } finally {
            setVisible(false);
            setLoading(false);
        }

    }

    const delSo = async () => {

        Modal.confirm({
            title: <div>是否确认删除整个订单？
                <div>删除后数据无法恢复，请谨慎操作！</div>
            </div>,
            okText: '是',
            cancelText: '否',
            okType: 'danger',
            onOk: async () => {

                try {
                    const data = await Tools.runAsync(
                        async () => {
                            return await Tools.http.post("/sm/so/removeSmSoDRs", {soIds: [record.soId]})
                        },
                        {
                            error: {content: (err) => `删除失败: ${err.message}`},
                            loading: {show: false},
                            success: {show: true},
                        }
                    );
                    mainTab.close();

                } catch (err) {
                    //Modal.destroyAll();
                    console.log(err);
                } finally {

                }

            }
        });
    }

    const openModal = useOpenModal()

    const auditSmSoBudget = async () => {


        // 审批
        try {
            setAuditSmSoBudgetLoading(true);

            const data = await oneFlowBillState({billId: record.soId});
            if (data) {
                if (data?.billState == "DOING") {
                    Modal.error({content: '重复发起审批！'})
                    return;
                }
            }

            const validData = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/sobudget/validSmSoStsToFlow", {
                        soId: record.soId,
                    })
                },
                {
                    error: {
                        content: (err) => {
                            return <div dangerouslySetInnerHTML={{__html: `数据异常: ${err.message}`}}/>
                        }
                    },
                    loading: {show: false},
                    success: {show: false},
                }
            );


            //TODO 打开发起审批界面
            const success = await openModal(
                <StartAuditBeforeModal
                    params={{
                        "flowModuleCode": smSoType == 'F' ? "CSO" : "GSO",
                        "orgId": so?.buId,
                        "puId": so?.soPuId,
                        "empId": so?.crtUserId,
                        // "orgId": "83cbe9c9fe234bb98ce523d209fc0e90",
                        // "puId": "ac1157a28e484f3f9132f0dc04a9290c",
                        // "empId": "e3a6bea00e8944b68a20a8ca056294f7",
                        "billId": so?.soId,
                        "billNo": so?.soNo,
                        'billKeyInfo': so?.styleNos,
                        "variables": smSoType == 'F' ? {
                            //"price": 35000,
                            'hasBD': true, // 有无项目部
                            'dollarProfit': validData?.budgetNppu, // 每美元利润（每美元净利）
                            'unitExportCost': validData?.budgetCpu, // 换汇成本
                            'lossAmount': validData?.budgetTnp,// 亏损金额 （净利润总额）
                        } : {
                            //"price": 35000,
                            'hasBD': true, // 有无项目部
                            'orderProfitRate': validData?.budgetNpr, // 订单利润率
                            'lossAmount': validData?.budgetTnp,// 亏损金额 （净利润总额）
                        }

                    }}
                />
            )

            // 成功执行(后台也有队列执行)
            if (success) {
                // 成功执行状态
                const submitEaData = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/sm/sobudget/submitEaSmSoBudgetDR", {
                            soId: record.soId,
                        })
                    },
                    {
                        error: {content: (err) => `提交审批: ${err.message}`},
                        loading: {show: false},
                        success: {show: false},
                    }
                );

            }
            // setVisibleAudit(true);

        } catch (err) {
            console.log(err);
        } finally {
            //initMainQry();
            init();
            setAuditSmSoBudgetLoading(false);
        }

    }

    const checkedSmSoDR = async () => {
        setVisibleCheck(true);
    }

    const doCheckedSmSoDR = async (budgetState, soState, setLoadingOk, setVisible) => {

        // 一、审批通过销售订单状态=预算通过（EAOK）销售订单预算单状态 = 审批通过（PASS）
        // 二、退回销售订单状态=预算退回（RETURN）销售订单预算单状态 = 退回（ RETURN）
        setLoadingOk(true);
        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/checkedSmSoDR", {
                        soIds: [record.soId],
                        budgetState: budgetState,
                        soState: soState
                    })
                },
                {
                    error: {content: (err) => `审批订单预算失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            setVisible(false);
            //initMainQry();
            init();

        } catch (err) {
            console.log(err);
        } finally {
            setLoadingOk(false);
        }

    }

    // 改单（改造）
    const changeSmSoDR1 = async () => {

        // TODO
        await openModal(
            <FlowChangeModal
                params={{
                    onSave: async ({commitMessage}) => {

                        //业务改单方法
                        try {
                            const data = await Tools.runAsync(
                                async () => {
                                    return await Tools.http.post("/sm/so/changeSmSoDR", {
                                        soIds: [record.soId],
                                        budgetAltMemo: commitMessage,
                                        soState: 'ALT'
                                    })
                                },
                                {
                                    error: {content: (err) => `改单失败: ${err.message}`},
                                    loading: {show: false},
                                    success: {show: true},
                                }
                            );

                            //initMainQry();
                            init();

                        } catch (err) {
                            console.log(err);
                        } finally {

                        }
                    }
                }}
            />
        )


    }

    // 是否能结案
    const caseDone = async () => {


        Modal.confirm({
            title: '是否确认提交订单结案？',
            okText: '是',
            cancelText: '否',
            okType: 'danger',
            onOk: async () => {

                await validSoPay({soId: so?.soId});

                try {
                    await validCaseDone({soId: so?.soId});
                    submitCaseDone()

                } catch (err) {
                    console.log(err);
                    const errMsg = (err.toString()).replace("ServerError:", "");
                    const msg = (errMsg.toString()).replace("n:", "");

                    Modal.confirm({
                        content: `${msg}`,
                        onOk: () => {
                            submitCaseDone()
                        }
                    });

                } finally {

                }

            }

        });

    }

    const submitCaseDone = async () => {

        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/sm/sobudget/submitCaseDoneSmSoDRs", {
                    soIds: [record.soId],
                })
            },
            {
                error: {content: (err) => `提交订单结案失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            }
        );


        //TODO 打开发起审批界面
        const success = await openModal(
            <StartAuditBeforeModal
                params={{
                    "flowModuleCode": smSoType == 'F' ? "CDCSO" : "CDGSO",
                    "orgId": so?.buId,
                    "puId": so?.soPuId,
                    "empId": so?.crtUserId,
                    "billId": so?.finalId,
                    "billNo": so?.soNo,
                    'billKeyInfo': so?.styleNos,
                    "variables": smSoType == 'F' ? {
                        //"price": 35000,
                        'hasBD': true, // 有无项目部
                        'dollarProfit': data?.budgetNppu, // 每美元利润（每美元净利）
                        'unitExportCost': data?.budgetCpu, // 换汇成本
                        'lossAmount': data?.budgetTnp,// 亏损金额 （净利润总额）

                        'tnpReduce': data?.tnpReduce, // 净利润减少额tnpReduce

                    } : {
                        //"price": 35000,
                        'hasBD': true, // 有无项目部
                        'orderProfitRate': data?.budgetNpr, // 订单利润率
                        'lossAmount': data?.budgetTnp,// 亏损金额 （净利润总额）

                        'tnpReduce': data?.tnpReduce, // 净利润减少额tnpReduce
                    }

                }}
            />
        )

        // 成功执行(后台也有队列执行)
        if (success) {
            // 成功执行状态
            const eaDoneData = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/sobudget/flowAuditCheckFinalSo", {
                        soId: record.soId,
                    })
                },
                {
                    error: {content: (err) => `更新决算单状态失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            init();
        }


    }

    const submitCaseDoneSmSoDRs = async (setVisible) => {

        try {


        } catch (err) {
            console.log(err);
        } finally {

        }


    }

    const changeSmSoDR = async (memo, setLoading, setVisible) => {

        // Modal.confirm({
        //     title: '是否确认改单？',
        //     okText: '是',
        //     cancelText: '否',
        //     okType: 'danger',
        //     content: <Form size='small'><Form.Item name='memo' label='备注'><Input.TextArea onBlur={(e) => {
        //         onChange(e)
        //     }} rows={5}/></Form.Item></Form>,
        //
        //     onOk: async () => {
        //
        //         const data = await Tools.runAsync(
        //             async () => {
        //                 return await Tools.http.post("/sm/so/changeSmSoDR", {
        //                     soIds: [record.soId],
        //                     budgetAltMemo: defRef.current.memo,
        //                     soState: 'ALT'
        //                 })
        //             },
        //             {
        //                 error: {content: (err) => `改单失败: ${err.message}`},
        //                 loading: {show: false},
        //                 success: {show: true},
        //             }
        //         );
        //         initMainQry();
        //         init();
        //
        //     }
        // });

        try {
            setLoading(true);
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/changeSmSoDR", {
                        soIds: [record.soId],
                        budgetAltMemo: memo,
                        soState: 'ALT'
                    })
                },
                {
                    error: {content: (err) => `改单失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            setVisible(false);
            //initMainQry();
            init();

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }


    }

    // 作废
    const judgeSmSoRepealDR = async (setLoading, setVisible) => {
        // Modal.confirm({
        //     title: '是否确认作废销售订单？',
        //     okText: '是',
        //     cancelText: '否',
        //     okType: 'danger',
        //     content: <div>没有发生采购、发票、付款、来款、出运业务的订单才可作废</div>,
        //     onOk: async () => {
        //
        //
        //     }
        // });

        try {
            setLoading(true);
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/judgeSmSoRepealDR", {soIds: [record.soId]})
                },
                {
                    error: {content: (err) => `作废订单失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            setVisible(false);
            //initMainQry();
            init();

        } catch (err) {
            console.log(err)

        } finally {
            setLoading(false);
        }

    }

    // 撤销销售订单
    const cancelSmSoBudgetDRs = async (setLoading, setVisible) => {

        try {
            setLoading(true);
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/sobudget/cancelSmSoBudgetDRs", {
                        soIds: [record.soId],
                        budgetIds: [so?.lastBudgetId]
                    })
                },
                {
                    error: {content: (err) => `撤销提交预算失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            setVisible(false);
            // initMainQry();
            init();

        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }

    }

    const cancelCaseDone = async (setVisible) => {

        try {
            //setLoading(true);
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/sobudget/cancelCaseDoneSmSoDRs", {
                        soId: record?.finalId,
                    })
                },
                {
                    error: {content: (err) => `撤销决算审批失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            setVisible(false);
            init();

        } catch (err) {
            console.log(err)
        } finally {
            //setLoading(false);
        }

    }

    // 撤销准入审核
    const canCelSmSoRoDRs = async (setVisible) => {
        try {

            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/cancelSubmitSmSoRoDRs", {
                        soIds: [record.soId],
                    })
                },
                {
                    error: {content: (err) => `撤回提交准入审核失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );
            init();
        } catch (err) {
            console.log(err);
        } finally {
            setVisible(false);
        }

    }

    //查看审批流程
    const viewBudgetFlow = async () => {
        setVisibleBudgetFlow(true);
    }
    //
    const viewFinalFlow = async () => {
        setVisibleFinalFlow(true);
    }

    // ITS 结案状态
    const syncItsDone = async () => {

        try {
            setItsDoneLoading(true);
            const data = await itsDone({soId: record.soId})
            init();
        } catch (err) {
            console.log(err);
        } finally {
            setItsDoneLoading(false);
        }

    }

    //定义通用导航标题
    const navigation = {
        navigationTitle: `${dictSoAttribute(soAttribute)}【${record.soNo}】`,
        navigationDescriptions: ['FMS', 'DMS'].includes(record?.soTypeCode) ? [
                {
                    label: <span className="text-color-primary">状态</span>,
                    value: <span
                        className="text-color-primary">{`[${useGlobalData.getDictDtlCn("SO_STS", so?.soStsCode)}]`}</span>,
                },
                {
                    label: <span className="text-color-primary">款数</span>,
                    value: <span className="text-color-primary">{so?.soStyleQty}</span>,
                },

                !srSoFlag && {
                    label: <span className="text-color-primary">关联订单</span>,
                    value: <span className="text-color-primary">{so?.srNo}</span>,
                },


            ] :
            [
                {
                    label: <span className="text-color-primary">状态</span>,
                    value: <span
                        className="text-color-primary">{`[${useGlobalData.getDictDtlCn("SO_STS", so?.soStsCode)}]`}</span>
                },
                !soFabricFlag && {
                    label: <span className="text-color-primary">款号</span>,
                    value: <span className="text-color-primary">{so?.styleNos}</span>,

                },
                soFabricFlag && {
                    label: <span className="text-color-primary">产品数</span>,
                    value: <span className="text-color-primary">{so?.soStyleQty}</span>,
                },

                !srSoFlag && {
                    label: <span className="text-color-primary">关联订单</span>,
                    value: <span className="text-color-primary">{rows?.so?.srNo}</span>,
                },


            ],

        navigationButtons: [

            // !['DRAFT', 'CDING', 'CDRETURN', 'DONE', 'REPEAL'].includes(so?.soStsCode) && (so?.tnpChgWarnFlag == true) &&
            // <RotationPane value={so?.tnpChgWarn}/>,


            ( ['FDT'].includes(so?.soModeCode) && <ButtonCreateBeSo record={rows?.so}>生成关联订单</ButtonCreateBeSo>),

            (['DRAFT', 'RETURN', 'ALT'].includes(so?.soStsCode) || (['PASS', 'EVING'].includes(so?.soEvStsCode) && ['DOING'].includes(so?.soStsCode))) && soEdit && !['DONE', 'REPEAL'].includes(so?.soStsCode) &&

            <Button type="primary" size="small" loading={auditSmSoBudgetLoading} onClick={() => {

                auditSmSoBudget()
            }}>提交预算</Button>,

            ['TOEA', 'EAING'].includes(so?.soStsCode) && soEdit && !['DONE', 'REPEAL'].includes(so?.soStsCode) &&
            <Button type="primary" size="small" onClick={() => {
                //cancelSmSoBudgetDRs();
                setVisibleCancelBudget(true);
            }}>撤销提交预算</Button>,

            ['EAOK', 'CDRETURN'].includes(so?.soStsCode) && soChange && !['DONE', 'REPEAL'].includes(so?.soStsCode) &&
            <Button type="primary" size="small" onClick={() => {
                changeSmSoDR1();
            }}>改单</Button>,

            ['EAOK', 'CDRETURN'].includes(so?.soStsCode) && soEdit && !['DONE'].includes(so?.soStsCode) &&
            <Button type="primary" size="small" onClick={() => {
                // submitCaseDone()
                caseDone();
            }}>提交结案</Button>,

            ['CDING'].includes(so?.soStsCode) && soEdit && !['DONE'].includes(so?.soStsCode) &&
            <Button type="primary" size="small" onClick={() => {
                //cancelCaseDone()
                setVisibleCancelCaseDone(true);
            }}>撤销决算审批</Button>,

            ['DRAFT'].includes(so?.soStsCode) && soEdit && !['DONE', 'REPEAL'].includes(so?.soStsCode) &&
            <Button type="primary" size="small" onClick={() => {
                delSo();
            }}>删除订单</Button>,

            ['DOING', 'TOEA', 'EAING', 'EAOK', 'RETURN', 'ALT'].includes(so?.soStsCode) && soEdit && !['DONE', 'REPEAL'].includes(so?.soStsCode) &&
            <Button type="primary" size="small" onClick={() => {
                setVisibleRepeal(true);
            }}>作废订单</Button>,

            ['EAING', 'TOEA'].includes(so?.soStsCode) && soEdit &&
            <Button size="small" type="primary" onClick={() => viewBudgetFlow()}>预算审批流程</Button>,

            ['CDING'].includes(so?.soStsCode) && soEdit &&
            <Button size="small" type="primary" onClick={() => viewFinalFlow()}>决算审批流程</Button>,

            ['DONE'].includes(so?.soStsCode) &&
            <Button size="small" type="primary" loading={itsDoneLoading} onClick={syncItsDone}>获取ITS取消结案状态</Button>,

        ].filter((v) => {
            return v;
        }),
        navigationTitleStlye: {flexShrink: 0},

    }
    const frames = [
        {
            key: 'CSODtlFrame',
            name: `${dictSoAttribute(soAttribute)}详情`,
            children:

                [
                    !soFabricFlag && {
                        key: 'CSODtlBase',
                        name: '首页',
                        frame: ['FMS', 'DMS'].includes(record.soTypeCode) ? 'SM/SM30_DT_SM_CSO_LIST/CSODtlMSBase' : 'SM/SM30_DT_SM_CSO_LIST/CSODtlBase',
                        props: {
                            initMainQry: init,
                            record: rows,
                            smSoType: smSoType,
                            setScgss,
                            setStyleSgs,
                            soEdit: soEdit, ...props
                        },
                    },

                    !soFabricFlag && (['FMS', 'DMS'].includes(record.soTypeCode)) && {

                        key: 'CSODtlMStyle',
                        name: '款式信息',
                        frame: 'SM/SM30_DT_SM_CSO_LIST/CSODtlMStyle',
                        props: {
                            record: rows,
                            smSoType: smSoType,
                            initMainQry: init,
                            setScgss,
                            setStyleSgs,
                            soEdit: soEdit,
                            crtOprtnStyle,
                            setCrtOprtnStyle,
                        },
                    },

                    soFabricFlag && {
                        key: 'CSODtFabricBase',
                        name: '首页',
                        frame: 'SM/SM30_DT_SM_CSO_LIST/CSODtFabricBase',
                        props: {record: rows, initMainQry: init, smSoType: smSoType, soEdit: soEdit, ...props},
                    },

                    soFabricFlag && {
                        key: 'CSODtlFabric',
                        name: '面辅料产品',
                        frame: 'SM/SM30_DT_SM_CSO_LIST/CSODtlFabric',
                        props: {record: rows, initMainQry: init, smSoType: smSoType, soEdit: soEdit, ...props},
                    },

                    !soFabricFlag && {
                        key: 'CSODtlOrderDetail',
                        name: '接单计划',
                        frame: 'SM/SM30_DT_SM_CSO_PO/CSODtlOrderDetail',
                        props: {record: rows, initMainQry: init, smSoType: smSoType, soEdit: soEdit, ...props},
                    },

                    {
                        key: 'CSODtlPo',
                        name: ['FSS', 'FMS', 'FMM'].includes(record?.soTypeCode) ? 'PO明细' : 'PO明细',
                        frame: 'SM/SM30_DT_SM_CSO_PO/CSODtlPo',
                        props: {record: rows, initMainQry: init, soEdit: soEdit, crtOprtnStyle, ...props},
                    },
                    // 0 < soStyleQty &&
                    // {
                    //     key: 'CSODtlBom',
                    //     name: '大货BOM',
                    //     frame: 'SM/SM30_DT_SM_CSO_BOM/CSODtlBom',
                    //     props: {
                    //         scgss,
                    //         styleSgs,
                    //         soId: record.soId,
                    //         controlFlag: '1',
                    //         initMainQry: init,
                    //         soEdit: soEdit,
                    //         bill: 'so',
                    //         crtOprtnStyle,
                    //
                    //     },
                    // },

                    !fftFlag && 0 < soStyleQty &&
                    {
                        key: 'CSODtlBomBsd',
                        name: '订单BOM',
                        frame: 'SM/SM30_DT_SM_CSO_BOM_BSD/CSODtlBom',
                        props: {
                            scgss,
                            styleSgs,
                            soId: record.soId,
                            controlFlag: '1',
                            initMainQry: init,
                            soEdit: revbomEdit,
                            bill: 'so',
                            crtOprtnStyle,

                        },
                    },

                    // {
                    //     key: 'CSODtlLatestMRP',
                    //     name: '采购计划（临时）',
                    //     frame: 'SM/SM30_DT_SM_CSO_MRP/CSODtlLatestMRP',
                    //     props: {isShow: "0", record: record, initMainQry: init, soEdit: soEdit, ...props},
                    // },

                    !fftFlag && {
                        key: 'CSODtlBsdMRP',
                        name: '采购计划',
                        frame: 'SM/SM30_DT_SM_CSO_MRP_BSD/CSODtlBsdMRP',
                        props: {isShow: "0", record: record, initMainQry: init, soEdit: soEdit, ...props},
                    },

                    !fftFlag && !soFabricFlag && {
                        key: 'CSODtlPP',
                        name: '排产计划',
                        frame: 'SM/SM30_DT_SM_CSO_PP/CSODtlPP',
                        props: {record: rows, initMainQry: init, soEdit: ppEdit, crtOprtnStyle, ...props},
                    },

                    {
                        key: 'CSODtlPOShipment',
                        name: ['FSS', 'FMS', 'FMM'].includes(record?.soTypeCode) ? '出运计划' : '出库计划',
                        frame: 'SM/SM30_DT_SM_CSO_PO_SHIPMENT/CSODtlPOShipment',
                        props: {record: rows, initMainQry: init, soEdit: soEdit, crtOprtnStyle, ...props},
                    },

                    {
                        key: 'CSODtCustBudget',
                        name: '订单预算',
                        frame: 'SM/SM30_DT_SM_CSO_BUDGET_NEW/NEW/BudgetPane',
                        props: {
                            record: defaultPaneKey === "CSODtCustBudget" ? props.soDto : rows,
                            initMainQry: init,
                            smSoType: smSoType,
                            soEdit, ...props
                        },
                    },

                    !soFabricFlag && {
                        key: 'CSODtlLatestDanJian',
                        name: '单件成本',
                        frame: 'SM/SM30_DT_SM_CSO_PP_NEW/CSODtlLatestDanJian',
                        props: {
                            isShow: "0",
                            so: {...record, ...rows?.so, crtUserFlag: crtUserFlag, soEdit,},
                            smSoType: smSoType,
                            rows: rows,
                            soEdit,
                            crtOprtnStyle,

                            ...props
                        },
                    },

                    {
                        key: 'CSODtSoFinal',
                        name: '订单决算',
                        frame: 'SM/SM30_DT_SM_CSO_FINAL/FinalPane',
                        props: {record: rows, initMainQry: init, smSoType: smSoType, soEdit, ...props},
                    },
                    {
                        key: '包装箱贴',
                        name: '包装箱贴',
                        frame: 'SM/SM30_DT_SM_CSO_OBS_DATA/Basic',
                        props: {record: rows, soEdit: soEdit,},
                    },

                    // !soFabricFlag && {
                    //     key: '大货样品',
                    //     name: '大货样品',
                    //     frame: 'DM/DM30_DT_DM_STYLE_TECH_ORDER/SSOList_Uc',
                    //     props: {
                    //         billId: record.soId,
                    //         styleNumFlag: more,
                    //         record: record,
                    //         controlFlag: soEdit ? '1' : "0",
                    //         soEdit: soEdit,
                    //         permissionCode: smSoType == 'F' ? 'FSO' : 'DSO', ...props
                    //     },
                    // },
                    //
                    // !soFabricFlag && {
                    //     key: '技术资料',
                    //     name: '技术资料',
                    //     children: [
                    //         {
                    //             key: 'CSODtlSst',
                    //             name: '大货尺寸表',
                    //             frame: 'SM/SM30_DT_SM_CSO_SST/CSODtlSst',
                    //             props: {record: rows, initMainQry: init, soEdit: soEdit, crtOprtnStyle, ...props},
                    //         },
                    //         {
                    //             key: 'CSODtlPack',
                    //             name: '包装箱唛',
                    //             frame: 'SM/SM30_DT_SM_CSO_PACK/CSODtlPack',
                    //             props: {record: rows, isShow: "0", soEdit: soEdit, crtOprtnStyle, ...props},
                    //         },
                    //
                    //         {
                    //             key: 'CSODtlmaincard',
                    //             name: '主辅料卡',
                    //             frame: 'SM/SM30_DT_SM_CSO_MAT_CARD/CSODtlmaincard',
                    //             props: {record: rows, isShow: "0", soEdit: soEdit, crtOprtnStyle, ...props},
                    //         },
                    //         {
                    //             key: 'CSODtlBop',
                    //             name: '样板数据',
                    //             frame: 'SM/SM30_DT_SM_CSO_BOP/CSODtlBop',
                    //             props: {record: rows, initMainQry: init, soEdit: soEdit, crtOprtnStyle, ...props},
                    //         },
                    //
                    //         {
                    //             key: 'CSODtlBot',
                    //             name: '工艺要求',
                    //             frame: 'SM/SM30_DT_SM_CSO_BOT/CSODtlBot',
                    //             props: {record: rows, initMainQry: init, soEdit: soEdit, crtOprtnStyle, ...props},
                    //         },
                    //
                    //         {
                    //             key: '大货核料单',
                    //             name: '大货核料单',
                    //             frame: 'SM/SM30_DT_SM_CSO/CSODtlMuc',
                    //             props: {
                    //                 record: rows,
                    //                 handleFlag: false,
                    //                 doneButFlag: false,
                    //                 dsEditFlag: soEdit,
                    //                 soEdit: soEdit,
                    //                 crtOprtnStyle,
                    //                 ...props
                    //             },
                    //         },
                    //
                    //         {
                    //             key: 'CSODtFile',
                    //             name: '技术资料附件',
                    //             frame: 'SM/SM30_DT_SM_CSO/CSODtFile',
                    //             props: {record: rows, initMainQry: init, soEdit: soEdit, crtOprtnStyle, ...props},
                    //         },
                    //
                    //
                    //     ],
                    // },
                    //
                    // !soFabricFlag && {
                    //     key: '生产资料',
                    //     name: '生产资料',
                    //     children: [
                    //         {
                    //             key: '生产订单',
                    //             name: '生产订单',
                    //             frame: 'SM/SM30_DT_SM_CSO_PROD/CSODtlProd',
                    //             props: {record: rows, initMainQry: init, soEdit: soEdit, ...props},
                    //         },
                    //         {
                    //             key: '面辅料平衡表',
                    //             name: '面辅料平衡表',
                    //             frame: 'SM/SM30_DT_SM_CSO/CSODtDoc',
                    //             props: {record: rows, initMainQry: init, soEdit: soEdit, ...props},
                    //         },
                    //         {
                    //             key: 'CSODtlTechPack',
                    //             name: '大货资料包',
                    //             frame: 'SM/SM30_DT_SM_CSO/CSODtlTechPack',
                    //             props: {record: rows, initMainQry: init, soEdit: soEdit, ...props},
                    //         },
                    //     ]
                    // },

                    {
                        key: '订单跟踪',
                        name: '订单跟踪',
                        children: [

                            {
                                key: '订单状态',
                                name: '订单状态',
                                frame: 'SM/SM30_DT_SM_CSO_LIST/CSOStsNode',
                                props: {
                                    soId: record.soId,
                                    soEdit: soEdit,
                                    isForeign: ['FSS', 'FMS', 'FMM', 'FO'].includes(soType)
                                },
                            },
                            {
                                key: 'PO物料需求',
                                name: 'PO物料需求',
                                frame: 'SM/SM30_DT_SM_CSO_LIST/POMatOrdList',
                                props: {
                                    soId: record.soId,
                                    record:record,
                                },
                            },


                            {
                                key: '采购信息',
                                name: '采购信息',
                                frame: 'SM/SM30_DT_SM_CSO_LIST/CSOTRACKDTL/CSOTRACKDtlPhContract',
                                props: {record: record, soEdit: soEdit,},

                            },


                            
                            {
                                key: '出运信息',
                                name: smSoType == 'F' ? '出运信息' : "出库信息",
                                frame: 'SM/SM30_DT_SM_CSO_LIST/CSOTRACKDTL/CSOTRACKDtlMTShipment',
                                props: {record: {...record, soModeCode: rows?.so?.soModeCode,}, soEdit: soEdit,},
                            },

                            {
                                key: 'CSOTRACKDtlOrderFee',
                                name: '费用信息',
                                frame: 'SM/SM30_DT_SM_CSO_LIST/CSOTRACKDTL/CSOTRACKDtlOrderFee',
                                props: {record: {...record, soModeCode: rows?.so?.soModeCode}, soEdit: soEdit,},
                            },

                            {
                                key: 'CSOTRACKDtlRevenue',
                                name: '收款信息',
                                frame: isDSO ? 'SM/SM30_DT_SM_CSO_LIST/DSOTRACKDTL/DSOTRACKDtlRevenue' : 'SM/SM30_DT_SM_CSO_LIST/CSOTRACKDTL/CSOTRACKDtlRevenue',
                                props: {record: record, soEdit: soEdit,},
                            },

                            // {
                            //     key: 'CSOTRACKDtlFCLAccount',
                            //     name: '做账信息',
                            //     frame: 'SM/SM30_DT_SM_CSO_LIST/CSOTRACKDTL/CSOTRACKDtlFCLAccount',
                            //     props: {record: record, soEdit: soEdit,},
                            // },

                        ].filter((v) => {
                            return v;
                        }),
                    },

                ].filter((v) => {
                    return v;
                }),

        },
    ];


    return <><Frame frames={frames} defaultKey={defaultPaneKey} navigation={navigation}/>
        <CheckSmSoModal visible={visibleCheck} setVisible={setVisibleCheck} doCheckedSmSoDR={doCheckedSmSoDR}/>
        <SubmitSmSoModal visible={visibleSubmitSmSo} setVisible={setVisibleSubmitSmSo}
                         submitSmSoRoDRs={submitSmSoRoDRs}/>
        <EvSmSoModal visible={visibleEvSmSo} setVisible={setVisibleEvSmSo} doEvSmSoRoDRs={doEvSmSoRoDRs}/>
        <CancelBudgetModal visible={visibleCancelBudget} setVisible={setVisibleCancelBudget}
                           cancelSmSoBudgetDRs={cancelSmSoBudgetDRs}/>
        <ChangeSmSoModal visible={visibleChange} setVisible={setVisibleChange} changeSmSoDR={changeSmSoDR}/>
        <JudgeRepealModal visible={visibleRepeal} setVisible={setVisibleRepeal} judgeSmSoRepealDR={judgeSmSoRepealDR}/>

        <FlowTaskActiveModal billId={so?.soId} visible={visibleBudgetFlow} setVisible={setVisibleBudgetFlow}/>
        <FlowTaskActiveModal billId={so?.finalId} visible={visibleFinalFlow} setVisible={setVisibleFinalFlow}/>

        <SubmitDoneModal visible={visibleSubmitDone} setVisible={setVisibleSubmitDone}
                         submitCaseDoneSmSoDRs={submitCaseDoneSmSoDRs}/>
        <CancelDoneModal visible={visibleCancelCaseDone} setVisible={setVisibleCancelCaseDone}
                         cancelCaseDone={cancelCaseDone}/>
        <CancelSmSoModal visible={visibleCancelSmSo} setVisible={setVisibleCancelSmSo}
                         canCelSmSoRoDRs={canCelSmSoRoDRs}/>
    </>;
}

export default CSODtlFrame;
