/** @jsxImportSource @emotion/react */

import {Space, Typography} from 'antd'

const Navigation = ({navigation = {}, ...props}) => {
    const {
        navigationTitle,
        navigationTitleStlye,
        navigationDescriptions = [],
        navigationButtons = [],
        navigationButtonsStyle,
    } = navigation

    const desc = navigationDescriptions
        .map(({label, value}) => [label, value])
        .flat()
        .map((text, key) => (
            <Typography.Text
                key={key}
                type="secondary"
                strong
            >
                {text}
            </Typography.Text>
        ))

    const css = {
        display: 'flex',
        justifyContent: 'space-between',
    }

    return (
        <div
            css={css}
            {...props}
        >
            <Space style={{...navigationTitleStlye}}>
                <Typography.Text strong>{navigationTitle}</Typography.Text>
                {desc}
            </Space>

            <Space style={navigationButtonsStyle}>{navigationButtons}</Space>
        </div>
    )
}

export default Navigation
