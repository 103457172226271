/** @jsxImportSource @emotion/react */

import {useCallback, useReducer} from 'react'
import {nanoid} from 'nanoid'
import deepEqual from 'script/deepEqual.mjs'
import useEventTarget from 'hooks/useEventTarget.mjs'
import {MainTabsContext} from './contexts.mjs'
import {Modal} from "antd";
import useGlobalData from "hooks/useGlobalData.mjs";


const reducer = (state, action) => {
    const maxTabNum =   useGlobalData.getTabMax()
    const fn = {
        'activate-tab': ({key}) => ({
            ...state,
            activeKey: key,
        }),

        'close-tab': ({key}) => {
            const i = state.tabs.findIndex(
                (tab) => tab.key === key
            )

            const {closable} = state.tabs[i]

            if (! closable) {
                return state
            }

            const activeKey = (() => {
                if (state.activeKey === key) {
                    if (0 < i) {
                        return state.tabs[i - 1]?.key
                    }
                    else {
                        return state.tabs[i + 1]?.key
                    }
                }
                else {
                    return state.activeKey
                }
            })()

            const tabs = state.tabs.filter((e) => e.key !== key)
            return {...state, activeKey, tabs}
        },

        'config-tab': ({key, options}) => ({
            ...state,

            tabs: state.tabs.map(
                (e) => e.key === key ? {...e, ...options} : e
            ),
        }),

        'open-tab': ({
            background,
            closable,
            component,
            name,
            nextToActive,
            singleton,
            title
        }) => {
            const openTab = () => {
                let key = nanoid()
                const tab = {closable, component, key, name, title}

                let tabFlag=false;
                let nowTab=state.tabs
                if (nowTab.length>maxTabNum){
                    tabFlag=true;
                }


                let tabs = (() => {
                    if (nextToActive) {

                        const i = state.tabs.findIndex(
                            (e) => e.key === state.activeKey
                        )

                        if (- 1 < i) {
                            return [
                                ...state.tabs.slice(0, i + 1),
                                tab,
                                ...state.tabs.slice(i + 1)
                            ]
                        }
                    }

                    return [...state.tabs, tab]
                })()

                if (tabFlag){
                    Modal.info({title: '提示信息', content: '系统最多只能打开'+maxTabNum+'个标签页'});
                    tabs=[...state.tabs]
                    key = state.activeKey
                }


                if (background) {
                    return {...state, tabs}
                }
                else {
                    return {...state, activeKey: key, tabs}
                }
            }

            if (singleton) {
                const tab = state.tabs.find(
                    (tab) => deepEqual(tab.component, component)
                )

                if (tab) {
                    return {...state, activeKey: tab.key}
                }
                else {
                    return openTab()
                }
            }
            else {
                return openTab()
            }
        },
    }[action.type]

    if (fn) {
        return fn(action.data)
    }
    else {
        throw new Error('unknown action')
    }
}

const MainTabsProvider = (props) => {
    const eventTarget = useEventTarget()
    const [state, dispatch] = useReducer(reducer, {activeKey: '', tabs: []})

    const activateTab = useCallback(
        (key) => {
            dispatch({
                type: 'activate-tab',
                data: {key}
            })
        },

        []
    )

    const configTab = useCallback(
        (key, options) => {
            dispatch({
                type: 'config-tab',
                data: {key, options}
            })
        },

        []
    )

    const closeTab = useCallback(
        (key) => {
            dispatch({
                type: 'close-tab',
                data: {key}
            })
        },

        []
    )

    const isTabActive = (key) => key === state.activeKey

    const openTab = useCallback(
        ({
            component,
            background = false,
            closable = true,
            name,
            nextToActive = true,
            singleton = true,
            title,
        }) => {
            dispatch({
                type: 'open-tab',

                data: {
                    component,
                    background,
                    closable,
                    name,
                    nextToActive,
                    singleton,
                    title,
                }
            })
        },

        []
    )

    const mainTabs = {
        ...state,
        ...eventTarget,
        activateTab,
        closeTab,
        configTab,
        isTabActive,
        openTab,
    }

    return (
        <MainTabsContext.Provider
            value={mainTabs}
            {...props}
        />
    )
}

export default MainTabsProvider
