
const TabRoute = () => {

}

TabRoute.loadModule = async (path) => {
    if (path) {
        const module = await import(`./pages/BizPage/${path}.jsx`);
        const Pane = module.default;
        return Pane;
    } else {
        return null;
    }
}

export default TabRoute;
