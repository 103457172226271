/** @jsxImportSource @emotion/react */

import {Checkbox, Divider, Input, Space} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import {makeKeywordsMatcher} from 'hopedove-dom/str'
import CheckboxList from './CheckboxList.jsx'

const InputKeywords = ({
    value,
    onChange,
    ...props
}) => {
    const handleChange = ({target: {value}}) => {
        onChange(value)
    }

    return (
        <Input
            allowClear
            placeholder='输入关键字检索'
            prefix={<SearchOutlined />}
            size="small"
            value={value}
            onChange={handleChange}
            {...props}
        />
    )
}

const Checkboxes = ({
    value,
    values,
    onChange,
    numberRD,
    ...props
}) => {
    const options = values.map((value) => [value])

    const isCheckedAll = (
        options.length <= value.length &&
        options.every(([v]) => value.includes(v))
    )

    const isCheckedSome = ! isCheckedAll && 0 < value.length

    const handleChangeSelectAll = ({target: {checked}}) => {
        if (checked) {
            onChange(values)
        }
        else {
            onChange([])
        }
    }

    const styleCheckboxes = {
        display: 'flex',
        flexDirection: 'column',
        height: 200,
        padding: 6,
        border: '1px solid #d9d9d9',
        overflow: 'hidden',
    }

    const styleList = {
        overflow: 'hidden',
    }

    return (
        <div
            style={styleCheckboxes}
            {...props}
        >
            <Checkbox
                checked={isCheckedAll}
                indeterminate={isCheckedSome}
                onChange={handleChangeSelectAll}
            >全选</Checkbox>

            <Divider style={{margin: '8px 0'}} />

            <CheckboxList
                style={styleList}
                options={options}
                value={value}
                onChange={onChange}
                numberRD={numberRD}
            />
        </div>
    )
}

const CheckboxFilter = ({
    keyword,
    value,
    values,
    onChange,
    onChangeKeyword,
    numberRD,
}) => {
    const match = makeKeywordsMatcher(keyword)
    const refinedValues = match ? values.filter(match) : values

    const style = {
        display: 'flex',
    }

    return (
        <Space
            style={style}
            direction="vertical"
        >
            <InputKeywords
                value={keyword}
                onChange={onChangeKeyword}
            />

            <Checkboxes
                value={value}
                values={refinedValues}
                onChange={onChange}
                numberRD={numberRD}
            />
        </Space>
    )
}

export default CheckboxFilter
