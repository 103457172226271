/** @jsxImportSource @emotion/react */

import {DatePicker as AntdDatePicker} from 'antd'
import moment from 'moment'
import stopPropagation from './stopPropagation.mjs'

const nop = () => {}

const DatePicker = ({
    format = 'YYYY-MM-DD',
    placeholder = '',
    value,
    onChange = nop,
    onBlur = nop,
    ...props
}) => {
    const date = value ? moment(value, format) : null

    const handleChange = (date) => {
        if (date) {
            const value = date.format(format)
            onChange(value)
        }
        else {
            onChange(undefined)
        }
    }

    const handleBlur = (e) => {
        const {value} = e.target
        const date = moment(value, format)

        if (date.format(format) === value) {
            onChange(value)
        }

        onBlur(e)
    }

    const css = {
        width: '100%',
        color: 'inherit',

        '& input': {
            color: 'inherit',
            textAlign: 'inherit',
        }
    }

    return (
        <div
            style={{display: 'contents'}}
            onClick={stopPropagation}
        >
            <AntdDatePicker
                css={css}
                allowClear
                placeholder={placeholder}
                size="small"
                {...props}
                value={date}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </div>
    )
}

export default DatePicker
