import {useEffect, useState} from 'react'

const useCachedValue = (...args) => {
    const [init, deps] = 1 < args.length ?
        args
        :
        [
            () => args[0],
            [args[0]]
        ]

    const [cache, setCache] = useState(init)

    useEffect(
        () => setCache(init()),
        deps,
    )

    return [cache, setCache]
}

export default useCachedValue
