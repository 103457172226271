import { Modal} from 'antd';
import {useRef, useState} from 'react';
import Draggable from 'react-draggable';

const Pane = ({title, bodyStyle, okButtonProps, cancelButtonProps, ...props}) => {
    const [disabled, setDisabled] = useState(true);
    const draggleRef = useRef(null);

    return (
        <Modal
            mask={false}
            maskClosable={false}
            closable={false}
            wrapClassName="draggable-modal-wrap"
            bodyStyle={{padding: 12, ...bodyStyle}}
            okButtonProps={{size: "small", ...okButtonProps}}
            cancelButtonProps={{size: "small", ...cancelButtonProps}}
            {...props}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                        padding: '12px',
                        fontSize: '14px',
                        lineHeight: '14px',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                    // fix eslintjsx-a11y/mouse-events-have-key-events
                    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                    onFocus={() => {
                    }}
                    onBlur={() => {
                    }} // end
                >
                    {title}
                </div>
            }
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        />
    );
};

export default Pane;
