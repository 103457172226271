/** @jsxImportSource @emotion/react */

import {Fragment, useEffect, useState} from 'react'
import {Spin} from 'antd'
import {DashboardOutlined} from '@ant-design/icons'
import MainTabs, {MainTabsProvider, useMainTabsContext} from 'components/MainTabs/MainTabs.jsx'
import {useOpenModal} from "components/Modal/Modal.jsx";
import ReleaseVersionModal from 'pages/ReleaseNotePage/ReleaseVersionModal.jsx'
import MainHeader from './MainHeader.jsx'
import useGlobalData from 'hooks/useGlobalData.mjs'
import Tools from "../../script/sweet-tools.mjs";

const MainPageContent = () => {
    
    const user = Tools.authenticator.getUser();
    
    const mainTabs = useMainTabsContext()
    
    const openModal = useOpenModal()
    
    useEffect(() => {
        init();
        }, []
    )
    
    const init =async () => {
        
        // 检查用户查看版本配置项
        const chkViewRls = await initChkViewRlsConfig();
        
        // 用户是否有需要展示的版本
        if (chkViewRls && chkViewRls == "1") {
            const userUnReadReleaseNote =  await queryUserUnReadVersion();
           if (userUnReadReleaseNote) {
               await showUserUnreadReleaseNotes(userUnReadReleaseNote);
           }
        }
        
        mainTabs.openTab({
            closable: false,
            component: ['Workbench/Workbench'],
            name: <span><DashboardOutlined/> 工作台</span>,
        })
        
        if (user.firstMenu) {
            mainTabs.openTab({
                name: user.firstMenu.menuName,
                component: [user.firstMenu.uri],
            })
        }
    
    }
    // 查询版本控制配置项
    const initChkViewRlsConfig = async () => {
        try {
            const basBusDefConfigDR = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/bas/busdefcfg/oneBasBusDefConfigDR", {bdcCode: "CHK_VIEW_RLS"})
                    },
                    {
                        error: {content: (err) => `查询失败: ${err.message}`},
                        loading: {show: false},
                        success: {show: false},
                    }
                );
            
            if (basBusDefConfigDR) {
                return basBusDefConfigDR.bdcValue;
            }
        } catch(err) {
            console.error(err);
        }
    }
    
    // 查询用户未查看的版本
    const queryUserUnReadVersion = async () => {
        
        try {
            const userUnReadReleaseNote = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/bas/sysreleaselog/listUserUnReadSysReleaseLogDRs", {userId: user.userId})
                    },
                    {
                        error: {content: (err) => `查询失败: ${err.message}`},
                        loading: {show: false},
                        success: {show: false},
                    }
                );
            
            return userUnReadReleaseNote;
        } catch (err) {
            console.error(err);
        } 
    }
    
    const showUserUnreadReleaseNotes = async (userUnreadReleaseNotes) => {
        try {
            
            if (userUnreadReleaseNotes && userUnreadReleaseNotes.length > 0) {
                
                console.log("用户未读版本日志：" + userUnreadReleaseNotes);
                
                // 判断是否包含前端
                let includeWebFlag = false;
                 
                 userUnreadReleaseNotes.forEach(note => {
                     includeWebFlag = includeWebFlag ? true : note.includeWebFlag ? true : false;
                 })
                 
                // 显示用户未阅读版本信息
                await openModal(<ReleaseVersionModal showWarning={includeWebFlag} 
                                                                                            warningMsg="最新版本包含前端页面修改，系统将自动刷新，加载最新页面！" 
                                                                                            releaseNotes={userUnreadReleaseNotes} />)
                
                // 插入用户阅读记录
                await insertSysReleaseLogView(userUnreadReleaseNotes);
                 // 如果包含前端刷新窗口，true：不走缓存从服务端获取数据
                 if (includeWebFlag) {
                     window.location.reload(true);
                 }
            }
        } catch (err) {
            console.log
        }
    }
    
    // 查看完后，插入用户已查看日志
    const insertSysReleaseLogView = async (releaseNote) => {
        
        let params = [];
        
        releaseNote.forEach( note => {
            params.push({
                rlId: note.rlId,
                userId: user.userId
            });
        });
        
        await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/sysreleaselog/addSysReleaseLogViewDRs", params)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
    }
    
    return (
        <Fragment>
            <MainHeader style={{flexShrink: 0}} />
            <MainTabs style={{flexGrow: 1}}/>
        </Fragment>
    )
}

const MainPage = () => {
    const [spinning, setSpinning] = useState(false)

    useEffect(
        () => {
            (async () => {
                try {
                    setSpinning(true)
                    await useGlobalData.init()
                } finally {
                    setSpinning(false)
                }
            })()
        },

        []
    )

    const style = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
    }

    return (
        <Spin
            tip="加载初始数据..."
            spinning={spinning}
        >
            <div style={style}>
                <MainTabsProvider>
                    <MainPageContent />
                </MainTabsProvider>
            </div>
        </Spin>
    )
}

export default MainPage
