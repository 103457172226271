/** @jsxImportSource @emotion/react */

import {Input} from 'antd'
import useInputable from './useInputable.mjs'

const TextArea = ({
    allowClear,
    changeDelay,
    focus,
    parse,
    selectOnFocus,
    test,
    transform,
    value,
    onChange,
    onBlur,
    onInput,
    ...props
}) => {
    const {
        callbackRef,
        handleInput,
        handleClick,
        handleCompositionEnd,
        handleCompositionStart,
        handleBlur,
        inputValue,
    } = useInputable({
        allowClear,
        changeDelay,
        focus,
        parse,
        selectOnFocus,
        test,
        transform,
        value,
        onChange,
        onBlur,
        onInput,
    })
    const css = {
        '& textarea': {
            backgroundColor: 'inherit',
            color: 'inherit',
            textAlign: 'inherit',
        },
    }

    return (
        <div
            ref={callbackRef}
            css={css}
            style={{display: 'contents'}}
            onClick={handleClick}
        >
            <Input.TextArea
                allowClear={allowClear}
                autoSize
                size="small"
                value={inputValue}
                onBlur={handleBlur}
                onCompositionEnd={handleCompositionEnd}
                onCompositionStart={handleCompositionStart}
                onInput={handleInput}
                {...props}
            />
        </div>
    )
}

export default TextArea
