/** @jsxImportSource @emotion/react */
;
import { useEffect, useState } from 'react';
import { Typography, Space, Table as AntdTable } from 'antd';
import TableSetting from "./TableSetting";
import ColumnControl from "./ColumnControl";
import TableConst from "./TableConst";
import useGlobalData from "hooks/useGlobalData.mjs";
import getColumnValue from './getColumnValue.mjs'
const numberSum = (arg1, arg2) => {
    var r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
}

//实现奇偶行样式
const rowClassName = (record, index) => {
    return (index + 1) % 2 === 0 ? 'row-even' : 'row-odd'
}

const TableWrapper = ({ config = {}, ...props }) => {
    const [isAutoHeight, setIsAutoHeight] = useState(false)

    const ref = (el) => {
        if (!el) {
            return
        }

        const parentStyle = window.getComputedStyle(el.parentElement)
        setIsAutoHeight('flex' === parentStyle.display)
    }

    const cssFlex = {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
    }

    const css = {
        ...cssFlex,
        height: '100%',

        '& .ant-table-wrapper': {
            ...cssFlex,
        },

        '& .ant-spin-nested-loading': {
            ...cssFlex,
        },

        '& .ant-spin-container': {
            ...cssFlex,
        },

        '& .ant-table': {
            ...cssFlex,
        },

        //滚动条整体部分
        // '& ::-webkit-scrollbar': {
        //     width: '6px',
        //     height: '6px',
        // },

        // //滚动条里面的小方块，能上下左右移动（取决于是垂直滚动条还是水平滚动条）
        // '& ::-webkit-scrollbar-thumb': {
        //     borderRadius: '10px',
        //     WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
        //     background: 'rgba(0, 0, 0, 0.2)',
        // },
        // //滚动条的轨道（里面装有thumb）滚动槽
        // '& ::-webkit-scrollbar-track': {
        //     WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
        //     borderRadius: 0,
        //     background: '#fafafa'
        // },

        '& .ant-table-container': {
            ...cssFlex,
            borderBottom: '1px solid #f0f0f0',
            borderRight: '1px solid #f0f0f0',
        },

        '& .ant-table-header': {
            flexShrink: 0,
        },

        '& .ant-table-body': {
            flexGrow: 1,
            maxHeight: 'unset !important',
            // overflow: 'auto !important',
        },

        '& .ant-table-column-title': {
            textAlign: 'center',
        },

        '& .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td': {
            padding: '4px 4px',
        },

        //'& .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover': {
        //background: '#fff',
        //},

        '& tbody tr:not(.ant-table-row-selected) td:first-child': {
            background: '#fafafa !important',
        },

        '& tbody tr:not(.ant-table-row-selected) td.seq-background': {
            background: '#fafafa !important',
            textAlign: 'center!important'
        },

        '& tbody tr td.seq-background': {
            textAlign: 'center!important'
        },

        '& td.right': {
            textAlign: 'right',
        },

        '& td.left': {
            textAlign: 'left',
        },

        '& td.center': {
            textAlign: 'center',
        },

        '& .ant-table-pagination.ant-pagination': {
            margin: '12px 0 1px 0 !important'
        },

        '& .ant-input, & .ant-picker, & .ant-select-selector': {
            background: 'none !important',
            border: 'none !important',
            boxShadow: 'none !important',
        },

        //'& .ant-table-tbody>tr.ant-table-row:not(.ant-table-row-selected):hover>td': {
        //background: 'initial',
        //},
    }

    if (isAutoHeight) {
        Object.assign(css['& .ant-table-body'], { height: 0 })
    }

    if (config?.striped) {
        const { evenColor, oddColor } = config
        Object.assign(css, {
            '& .row-even>td': {
                backgroundColor: evenColor,
            },

            '& .row-odd>td': {
                backgroundColor: oddColor,
            },
        })
    }

    return (
        <div
            css={css}
            ref={ref}
        >
            <AntdTable
                rowClassName={rowClassName}
                {...props}
                scroll={config?.scroll ?? { x: 0, y: 0 }}
            />
        </div>
    )
}

/**
 * 表格组件
 * @param tableCode 表格配置唯一标识符，用于加载表格个性化配置
 * @param columns 表格原始列数据
 * @param rowKey 行标识，单选/多选时用于判断选中项
 * @param rowSelection 行选择自定义配置，详情如下：
 * 扩展属性 extra，用于自定义批处理按钮；（已废弃）
 * 扩展属性 initialValues，用于动态设置选中项；
 * 扩展事件 onChange(selectedRowKeys, selectedRows)，用于外层获取表格选中项
 * @param size 表格大小
 * @param extra 用于扩展属性，比如批处理工具栏是否显示，按钮工具栏是否显示，详情如下：
 * 扩展属性 toolButton ReactNode[]或{}，自定义工具栏
 * 扩展属性 striped Boolean，奇偶行，默认为 true
 * 扩展属性 disabledSeqColumn Boolean，是否禁用序号列，默认为 false
 * 扩展属性 disabledColumnControl Boolean，是否禁用列控制面板，默认为 false
 * 扩展属性 disabledSettingControl Boolean，是否禁用表格配置，默认为 false
 * 扩展属性 statistics {type: 'all|page', sum: [], count: []}，统计行功能，type 可选 all|page 分别表示统计全部数据和当前页数据，sum 表示求和，count 表示计数，数组值传列数据中的 Key
 * @param props 表格原生属性
 * @returns {JSX.Element}
 * @constructor
 */
const Table = ({ tableCode, custId, columns, rowKey, rowSelection = {}, summary, size, extra = {}, ...props }) => {
    const { Paragraph, Text } = Typography;

    const rowSelectionType = rowSelection.type;
    // const rowSelectionExtra = rowSelection.extra; //暂时不用，因批处理栏目已经移除
    const rowSelectionOnChange = rowSelection.onChange;
    const rowInitialValues = rowSelection.initialValues;
    const { toolButton = [], striped = true, disabledSeqColumn = false, disabledColumnControl = false, disabledSettingControl = false, statistics, ...extraProps } = extra;
    //
    //原始数据源
    const originalDataSource = props.dataSource;
    //内部数据源
    const [dataSource, setDataSource] = useState([]); //原始数据
    const [filterDataSource, setFilterDataSousrce] = useState([]); //过滤数据
    const [sortDataSource, setSortDataSource] = useState([]); //排序数据
    const [finalDataSource, setFinalDataSource] = useState([]); //最终数据

    //内部列数组
    const [internalColumns, setInternalColumns] = useState([]);

    //选择行结果
    const [selectedRows, setSelectedRows] = useState([]);
    const [pagination, setPagination] = useState({ defaultPageSize: 50, position: ['bottomRight'], showSizeChanger: true, showQuickJumper: true, showTotal: total => `总记录数 ${total} 条` });

    //表格配置
    const [config, setConfig] = useState(useGlobalData.getTableConfig({ tableCode, custId }));
    //列控制配置，拆分是为了减少不必要的判断循环，提升性能
    const [sortMap, setSortMap] = useState(new Map());
    const [checkedListMap, setCheckedListMap] = useState(new Map());
    const [searchWordMap, setSearchWordMap] = useState(new Map());

    //表格配置变更
    const onConfigsChange = () => {
        setConfig(useGlobalData.getTableConfig({ tableCode, custId }))
    }

    //表格多选功能
    const _rowSelection = rowSelectionType ? {
        ...rowSelection,
        type: rowSelectionType,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
            if (rowSelectionOnChange) {
                rowSelectionOnChange(selectedRowKeys, selectedRows);
            }
        },
        selectedRowKeys: selectedRows.map((n) => {
            return n[rowKey];
        }),
    } : null;

    //行事件处理
    const _onRow = record => {
        return {
            onClick: async event => {
                // if (rowSelectionType == 'radio') {
                //     const newRows = [record]
                //     setSelectedRows(newRows);
                //     if (rowSelectionOnChange) {
                //         rowSelectionOnChange(newRows.map((n) => {
                //             return n[rowKey];
                //         }), newRows);
                //     }
                //     if (rowSelection.onSelect) {
                //         rowSelection.onSelect(
                //             record,
                //             true,
                //             newRows,
                //             event.nativeEvent,
                //         )
                //     }
                // } else if (rowSelectionType == 'checkbox') {
                //     const val = selectedRows.find((n) => n[rowKey] == record[rowKey]);
                //
                //     let newRows = [];
                //     if (val) {
                //         newRows = selectedRows.filter((n) => n[rowKey] != record[rowKey]);
                //     } else {
                //         newRows = [...selectedRows, record];
                //     }
                //     setSelectedRows(newRows);
                //     if (rowSelectionOnChange) {
                //         rowSelectionOnChange(newRows.map((n) => {
                //             return n[rowKey];
                //         }), newRows);
                //     }
                //     if (rowSelection.onSelect) {
                //         rowSelection.onSelect(
                //             record,
                //             selectedRows.length < newRows.length,
                //             newRows,
                //             event.nativeEvent,
                //         )
                //     }
                // } else {
                //
                // }

            }, // 点击行
            onDoubleClick: event => {
                if (rowSelection.onDoubleClick) {
                    if ('radio' == rowSelectionType) {
                        setSelectedRows([record]);
                        rowSelection.onDoubleClick([record]);
                    } else if ('checkbox' == rowSelectionType) {
                        const rs = [...selectedRows, record];
                        setSelectedRows(rs);
                        rowSelection.onDoubleClick(rs);
                    }
                }
            },
            onContextMenu: event => {
            },
            onMouseEnter: event => {
            }, // 鼠标移入行
            onMouseLeave: event => {
            },
        };
    }

    //处理列控制面板的变更回调
    const handlerColumnControlChange = (key, { sortOrder, checkedList, searchWord, }) => {
        if (sortMap.has(key)) {
            //当存在对应的 Key 时，同时要判断值是否变更，变更时才应该更新排序配置
            if (sortMap.get(key) !== sortOrder) {
                if (sortOrder) {
                    sortMap.set(key, sortOrder);
                } else {
                    sortMap.delete(key);
                }
                setSortMap(new Map(sortMap));
            } else {
                //值无变更，不进行更新，减少刷新次数
            }
        } else {
            //不存在对应的 key时，并且 sortOrder 有值，则应该更新排序配置
            if (sortOrder) {
                sortMap.set(key, sortOrder);
                setSortMap(new Map(sortMap));
            } else {
                //sortOrder = false时，不进行更新，减少刷新次数
            }
        }

        if (checkedListMap.has(key)) {
            //数组不好比对，所以不判断2个数组是否完全一样，而是当 checks 有值就覆盖，无值就删除
            if (checkedList.length > 0) {
                checkedListMap.set(key, checkedList);
            } else {
                checkedListMap.delete(key);
            }
            setCheckedListMap(new Map(checkedListMap))
        } else {
            if (checkedList.length > 0) {
                checkedListMap.set(key, checkedList);
                setCheckedListMap(new Map(checkedListMap))
            } else {
                //不更新
            }
        }

        if (searchWordMap.has(key)) {
            if (searchWord !== searchWordMap.get(key)) {
                if (searchWord && searchWord !== '') {
                    searchWordMap.set(key, searchWord);
                } else {
                    searchWordMap.delete(key);
                }
                setSearchWordMap(new Map(searchWordMap))
            } else {
                //值无变更，不进行更新，减少刷新次数
            }
        } else {
            if (searchWord && searchWord !== '') {
                searchWordMap.set(key, searchWord);
                setSearchWordMap(new Map(searchWordMap))
            } else {
                //不更新
            }
        }
    }

    //对列数据应用表格配置：排序、显示/隐藏、奇偶行、添加默认设置等
    const handlerColumns = () => {
        const formatCss = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }

        let columns_ = internalColumns.map((ic) => {
            //列标题 设置居中，列头过滤组件
            // console.log(!disabledColumnControl && (Object.keys(ic).includes("disabledColumnControl") ? !ic.disabledColumnControl : true))
            return {
                ...ic,
                title: (
                    <div css={formatCss}>
                        {(typeof ic.title === "function" && typeof ic.title !== "number") ? ic.title() : ic.title}
                        {(!disabledColumnControl && (Object.keys(ic).includes("disabledColumnControl") ? !ic.disabledColumnControl : true)) && <ColumnControl style={{ marginLeft: '2px' }} column={ic} dataSource={dataSource} onChange={handlerColumnControlChange} />}
                    </div>
                ),
            };
        });

        if (config) {
            const columnMap = new Map();

            for (let i = 0; i < config.columns.length; i++) {
                const cfg = config.columns[i];

                columnMap.set(cfg.key, { ...cfg, sortWeight: i });
            }


            columns_ = columns_.filter((ic) => {
                return !columnMap.get(ic.key).hide;
            }).map((ic) => {
                //合并冻结、列宽数据
                const { title, ...cfg } = columnMap.get(ic.key);
                return {
                    ...ic,
                    ...cfg
                }
            }).sort((a, b) => {
                return a.sortWeight - b.sortWeight
            })
        }

        const result = disabledSeqColumn ? [...columns_] : [
            {
                title: (
                    <div className="table-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {!disabledSettingControl && <TableSetting tableCode={tableCode} custId={custId} columns={columns} onChange={onConfigsChange}></TableSetting>}
                    </div>
                ),
                key: '__seq',
                dataIndex: '__seq',
                width: 46,
                fixed: true,
                className: 'seq-background',
                render: (text, record, index) => {
                    return index + 1;
                }
            },
            ...columns_,
        ];

        return result;
    }

    //封装总结，传递筛选、排序后的数据源
    const _sumamary = pageData => {
        const cells = handlerColumns();
        return summary(pageData, finalDataSource, cells);
    }

    //封装统计
    const _extraSummary = pageData => {
        const { type, columns = [] } = statistics;

        //参与统计的列
        const joinColumnMap = new Map();
        columns.forEach(n => joinColumnMap.set(n.key, n));

        //列的统计结果
        const sumResultMap = new Map();

        //数据源循环一遍，分组汇总出各列的合计
        const source = type != 'page' ? finalDataSource : pageData;
        source.forEach((item, index) => {
            joinColumnMap.forEach((column, key) => {
                //如果结果数据中不存在，则先初始化
                if (sumResultMap.has(key)) {
                    sumResultMap.set(key, 0);
                }
                //取出现存累计数据
                let num1 = sumResultMap.get(key);
                //取出当前行的对应列数据
                let num2 = item[key];

                //累计结果，默认等于上一次结果
                let result = num1;

                if (column.type == 'sum') {
                    //如果是 SUM 算法，则进行累加
                    result = numberSum(num1, num2);
                } else {
                    //如果是 count 算法，则对行数进行累加
                    result = num1 + 1;
                }
                //把结果存入统计 MAP
                sumResultMap.set(key, result);
            })
        })

        //用于渲染统计行的列
        const cells = handlerColumns();

        return (
            <Table.Summary.Row>
                {
                    cells.map(({ key }, index) => {
                        if (sumResultMap.has(key)) {
                            const { formatter } = joinColumnMap.get(key);
                            if (typeof formatter === "function" && typeof formatter.nodeType !== "number") {
                                return (
                                    <Table.Summary.Cell>
                                        {formatter(sumResultMap.get(key))}
                                    </Table.Summary.Cell>
                                )
                            } else {
                                return (
                                    <Table.Summary.Cell>
                                        {sumResultMap.get(key)}
                                    </Table.Summary.Cell>
                                )
                            }
                        } else {
                            return (
                                <Table.Summary.Cell>
                                </Table.Summary.Cell>
                            )
                        }
                    })
                }
            </Table.Summary.Row>
        )
    }

    //其他属性
    let _props = {
        onRow: _onRow,
        ...props,
        columns: handlerColumns(),
        size: 'small',
        summary: summary ? _sumamary : (statistics ? _extraSummary : summary),//原生合计行配置优先级最高，只有当未配置原生合计行时，才会启用内部封装的自定义合计行配置
    };

    //更新默认选中
    useEffect(() => {
        (async () => {
            //为表格设置默认选中项
            if (rowInitialValues && dataSource) {
                const filterMap = new Map();

                rowInitialValues.forEach((s) => {
                    filterMap.set(s, true);
                });

                //这里只设置值，不回调 onChange，防止无限循环
                setSelectedRows(dataSource.filter(row => filterMap.get(row[rowKey])));
            } else {
                setSelectedRows([]);
            }
        })();
    }, [rowInitialValues, dataSource]);

    //实时更新内部列数组
    useEffect(() => {
        (async () => {
            //重置内部展示列
            //补充：datasource 变动时，会导致过滤条件变动，所以也需要重置列数据，以此触发列数据的重新转换
            setInternalColumns([...columns]);
        })();
    }, [columns, dataSource]);

    //更新内部数据源
    useEffect(() => {
        (async () => {
            setDataSource(originalDataSource);
        })();
    }, [originalDataSource]);

    //监听内部数据源与过滤配置，实时更新过滤数据源
    useEffect(() => {
        (async () => {
            if (checkedListMap.size > 0 || searchWordMap.size > 0) {
                const filterDataSource = dataSource.filter((data) => {
                    let res = true;
                    for (let key of checkedListMap.keys()) {
                        const value = getColumnValue(data, key)
                        if (!checkedListMap.get(key).includes(value)) {
                            res = false;
                            break;
                        }
                    }

                    for (let key of searchWordMap.keys()) {
                        if (!data[key]?.includes(searchWordMap.get(key))) {
                            res = false;
                            break;
                        }
                    }
                    return res;
                });

                setFilterDataSousrce(filterDataSource);
            } else {
                setFilterDataSousrce([...dataSource])
            }
        })();
    }, [dataSource, checkedListMap, searchWordMap]);

    //监听过滤数据源与排序配置，实时更新排序数据源
    useEffect(() => {
        (async () => {
            if (sortMap.size > 0) {
                const sortDataSource = [...filterDataSource].sort((item1, item2) => {
                    let sorterResult = 0;
                    for (let i = 0; i < internalColumns.length; i++) {
                        const column = internalColumns[i];
                        const { key } = column;
                        if (sortMap.has(key)) {
                            const order = sortMap.get(key);
                            const a = item1[key] ?? null
                            const b = item2[key] ?? null

                            if (a !== b) {
                                sorterResult = (() => {
                                    if (! a) {
                                        return order === 'ascend' ? -1 : 1
                                    }

                                    if (! b) {
                                        return order === 'ascend' ? 1 : -1
                                    }

                                    if ('number' === column.type) {
                                        const na = Number(a)
                                        const nb = Number(b)

                                        return order === 'ascend' ?
                                            na - nb
                                            :
                                            nb - na
                                    }
                                    else {
                                        const sa = String(a)
                                        const sb = String(b)

                                        return order === 'ascend' ?
                                            sa.localeCompare(sb)
                                            :
                                            sb.localeCompare(sa)
                                        }
                                })()

                                break;
                            }
                        }
                    }

                    return sorterResult;
                });
                setSortDataSource(sortDataSource);
            } else {
                setSortDataSource([...filterDataSource])
            }
        })();
    }, [filterDataSource, sortMap]);

    //监听排序数据源，实时更新最终结果数据源
    useEffect(() => {
        setFinalDataSource([...sortDataSource]);
    }, [sortDataSource])

    const TopToolPanel = () => {
        if (Array.isArray(toolButton) && toolButton.length == 0) {
            return (
                <></>
            )
        }
        return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: '0 0 12px 0' }}>
                <div style={{ flexGrow: '1', display: "flex", justifyContent: Array.isArray(toolButton) ? 'flex-start' : 'space-between' }}>
                    {Array.isArray(toolButton) && <Space>{toolButton}</Space>}
                    {!Array.isArray(toolButton) && <>
                        <div><Space>{toolButton.left}</Space></div>
                        <div><Space>{toolButton.right}</Space></div>
                    </>}
                </div>
            </div>
        )
    }

    return (
        <>
            {/*表格顶部工具栏*/}
            <TopToolPanel />

            <TableWrapper
                rowKey={rowKey}
                rowSelection={_rowSelection}
                pagination={pagination}
                bordered
                config={config}
                {..._props}
                dataSource={finalDataSource}
            />
        </>
    );
};

Table.Widths = TableConst.Widths;

export default Table;
