const getColumnValue = (row, dataIndex) => {
    if ('string' === typeof dataIndex) {
        return row[dataIndex]
    }

    if (Array.isArray(dataIndex)) {
        let value = row

        for (const index of dataIndex) {
            value = value[index]

            if (! value) {
                return value
            }
        }

        return value
    }
}

export default getColumnValue
