/** @jsxImportSource @emotion/react */
import DraggableModal from "components/DraggableModal/DraggableModal";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Space, Tabs, Typography} from 'antd';


;

//nowDate 参数数据
const Pane = ({
                  onRefresh,
                  visible,
                  setVisible,
                  soId,
                  changeSmSoDR
              }) => {
    //主界面 Tab 控件对象，用于打开 Tab 页
    const {Text, Title} = Typography;
    const [form] = Form.useForm();
    // 加载显示
    const [loadingOk, setLoadingOk] = useState(false);
    const defRef = useRef({});

    // 组件初始化
    useEffect(() => {

    }, [visible]);

    const onChange = (e) => {
        defRef.current.memo = e.target.value;
    } // 改变事件

    return (
        <>
            <DraggableModal
                title='是否确认改单?'
                visible={visible}
                width={"20%"}
                onCancel={() => {
                    setVisible(false);
                }}
                destroyOnClose={true}
                maskClosable={false}
                closable={true}
                footer={
                    null
                }
            >

                <Form  layout="vertical" bordered={false} >
                    <Form.Item name='memo' label='备注'><Input.TextArea onBlur={(e) => {
                        onChange(e)
                    }} rows={5}/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 15, span: 8 }}>
                        <Space>
                            <Button type={'primary'} danger onClick={() => {
                                setVisible(false);
                            }}>否</Button>

                            <Button type={'primary'} loading={loadingOk} onClick={() => {
                                changeSmSoDR( defRef.current.memo , setLoadingOk , setVisible);
                            }}>是</Button>
                        </Space>
                    </Form.Item>
                </Form>

            </DraggableModal>
        </>

    );
}

export default Pane;
