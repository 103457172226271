/** @jsxImportSource @emotion/react */
;
import {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Menu} from 'antd';
import Icon, {EllipsisOutlined, UserOutlined} from '@ant-design/icons';
import {useMainTabsContext} from 'components/MainTabs/MainTabs.jsx'
import Tools from 'script/sweet-tools.mjs'

/**
 * 转换菜单数据
 * @returns {*[]}
 */
const mapMenuData = () => {
    const userMenus = Tools.authenticator.getUser().menus??[];

    const menuData = [
        ...userMenus,
        {
            menuId: 'LOGIN_OUT',
            permissionCode: 'LOGIN_OUT',
            menuName: '退出',
            // icon: <Icon component={LoginOutIcon}/>
        }
    ];
    const menuDataMap = new Map();

    const menuItemData = menuData.map(item => {
        const {menuId, menuName, parentMenuId, sortNo, menuType, permissionCode, menuUriType, uri,} = item;
        const menuItem = {
            key: menuId,
            label: menuName,
            name: menuName,
            pkey: parentMenuId,
            type: menuType,
            uritype: menuUriType,
            uri: uri,
            permissioncode: permissionCode
        }
        menuDataMap.set(menuId, menuItem);
        return menuItem;
    })

    return menuItemData.map(menuItem => {
        const {key, label, pkey, type, uritype, permissioncode, uri,} = menuItem;
        if (pkey) {
            const parentMenu = menuDataMap.get(pkey);
            if (!parentMenu.children) {
                parentMenu.children = []
            }
            parentMenu.children.push(menuItem);
        }
        return menuItem;
    }).filter(({pkey}) => !pkey)
}

const MainMenu = () => {
    const [data,] = useState(mapMenuData());
    const mainTabs = useMainTabsContext()
    const navigate = useNavigate();

    const css = {
        display: 'block',
        '& .ant-menu-submenu': {
            '&:hover': {
                backgroundColor: '#e6f7ff',
            },

            '&::after': {
                borderBottom: 'none !important',
            }
        }
    }

    const onMenuClick = async ({item, key, keyPath, domEvent}) => {
        const {name, uritype, uri} = item.props;
        if (key == 'LOGIN_OUT') {
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post(`/bas/auth/dopweb/login/loginOut`);
                },
                {
                    error: {
                        content: (err) => err.message,
                    },

                    loading: {
                        show: false,
                    },

                    success: {
                        show: false,
                    },
                }
            );
            Tools.authenticator.clear();
            navigate('/login', {replace: true})
        } else {
            if (uri) {
                if (uritype == 'INLINE_LINK') {
                    mainTabs.openTab({
                        name,
                        component: [uri],
                        nextToActive: false,
                    });
                } else {
                    window.open(uri);
                }
            }
        }
    }

    return (
        <Menu
            css={css}
            items={data}
            mode="horizontal"
            selectable={false}
            triggerSubMenuAction='click'
            overflowedIndicator={<EllipsisOutlined/>}
            onClick={onMenuClick}
        />
    );
};

export default MainMenu;
