/** @jsxImportSource @emotion/react */
import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Tooltip} from 'antd';

import dictData from "hooks/useGlobalData.mjs";
import DraggableModal from "components/DraggableModal/DraggableModal";
import ReadyInput from "components/ReadyInput/ReadyInput";
import moment from "moment";
import Tools from "script/sweet-tools.mjs";
import PuSelect from "components/Bizs/PuSelect/PuSelect";
import authenticator from "script/sweet-authenticator.mjs";
import SaleVintageSelect from "components/Bizs/SaleVintageSelect/SaleVintageSelect";

/**
 * 修改运编号
 * @param visible
 * @param setVisible
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DlgGBOSPullNumber = ({visible, setVisible, record, onFrameCall, hasEditPerm, billType, ...props}) => {

    const [form] = Form.useForm();
    const regNumber = /^\d*$/; // 数字正则

    // 操作加载
    const [loadingOperate, setLoadingOperate] = useState(false);

    // 运编号
    const [bosNo,setBosNo] =useState(record?.bosNo)

    // 运输方式
    const [transModeCode,setTransModeCode] =useState(null)

    // 加工国别
    const [countryList, setCountryList] = useState(dictData.getDictCountrys() ? dictData.getDictCountrys().filter(c=>c.cntLevel == 2 && c.cntProdFlag==true) : []);

    // 选择的核算组
    const [basPu, setBasPu] = useState(authenticator.getDefaultPu("PBOS", billType=='PBOS'?"PBOS-DS":"MBOS-DS", "edit"));

    useEffect(() => {
        if(visible){
            init();
        }
    }, [visible, record]);

    // 初始化
    const init = async ()=>{
        form.resetFields();
        form.setFieldsValue({
            bnoTransModeCode:record?.bnoTransModeCode,
            bnoBuCode:record?.bnoBuCode,
            buId:record?.buId,
            bnoProdCntCode:record?.bnoProdCntCode,
            bnoProdCntId: record?.bnoProdCntId,
            bnoNo: record?.bnoNo,
            puCode:record?.puCode,
            puId:record?.puId,
            bnoYear: record?.bnoYear,
        })
        setTransModeCode(record?.bnoTransModeCode)
    }

    // 国家搜索
    const countrySearch = (inputValue,option) =>{
        return option?.children?.toLowerCase().indexOf(inputValue?.toLowerCase()) >=0 ? true : false;
    }

    // 属性变更，动态设置运编号预览
    const inputOnChange = async(e, feildName)=>{
        let transModeCode = form.getFieldValue("bnoTransModeCode")??'';
        let buId = form.getFieldValue("buId")??'';

        setTransModeCode(transModeCode)

        // 修改核算组，设置核算单元
        if(feildName == 'puId'){
            console.log("basPu", e)
            if(e && e?.buId != buId){
                message.warning("请选择当前事业部下的核算组！");
                form.setFieldsValue({
                    puId: null,
                    puCode:null,
                })
                return;
            }
            form.setFieldsValue({
                puCode:e?.puCode,
            })
        }

        let buCode = form.getFieldValue("bnoBuCode")??'';
        let prodCntId = form.getFieldValue("bnoProdCntId")??'';
        let bnoNo = form.getFieldValue("bnoNo")??'';
        let puCode = form.getFieldValue("puCode")??'';
        let year = form.getFieldValue("bnoYear")??'';
        // 出运方式转码
        let transModeStr = '';
        if (transModeCode == 'SEA' || transModeCode == 'BA' || transModeCode == 'LA') {
            transModeStr = "SU";
        } else if (transModeCode == 'ROAD' || transModeCode == 'TRAIN') {
            transModeStr = "K17";
        } else {
            transModeStr = "";
        }

        // 国家转码
        let prodCntCode = '';
        // 非陆运，才要加工国别(2023-03-29 年份、核算组可以修改，陆运K17-请取消符号“-”并放开加工国别) --&& transModeCode !== 'ROAD'
        if(prodCntId){
            const cntNameEn = countryList.filter(c=>c.cntId == prodCntId)?.[0].cntNameEn;
            // 中国，首字母固定Z,其他国家取英文首字母
            if(cntNameEn == 'CHINA'){
                prodCntCode = 'Z';
            } else{
                prodCntCode =cntNameEn.substring(0,1);
            }
            form.setFieldsValue({
                bnoProdCntCode: prodCntCode
            })
        }

        console.log("transModeStr=",transModeStr);
        console.log("buCode=",buCode);
        console.log("prodCntCode=",prodCntCode);
        console.log("bnoNo=",bnoNo);
        console.log("puCode=",puCode);
        console.log("year=",year);
        setBosNo(transModeStr+buCode+prodCntCode+bnoNo+puCode+'-'+year)
    }

    const doSaveBosNo = async (formData, overrideFlag)=>{
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/dlv/${billType=='PBOS'?'bos':'mbos'}/addDlvBosNoHisDR`, {
                    ...formData,
                    billTypeCode: billType,
                    bosId: record.bosId,
                    bosNo,
                    bosNoOVFlag: overrideFlag
                })
            },
            {
                error: {content: (err) => `保存失败: ${err.message}`},
                loading: {show: false},
                success: {show: true},
            }
        );
    }

    // 确认
    const doOk = async ()=>{
        try {
            setLoadingOperate(true);
            if (!hasEditPerm) {
                Modal.info({title: '提示信息', content: '编制权限不足'});
                return;
            }

            // 校验
            let formData = await form.validateFields();

            // ^\d{4}$
            if((formData.bnoTransModeCode == 'SEA' || formData.bnoTransModeCode == 'BA' || formData.bnoTransModeCode == 'LA'
                || formData.bnoTransModeCode == 'ROAD' || formData.bnoTransModeCode == 'TRAIN') && formData.bnoNo.length != 4){
                message.warning("海运、陆运、火车、保税园区、物流园区、，请输入四位流水号")
                return
            } else if(formData.bnoTransModeCode == 'AIR' && formData.bnoNo.length != 3){
                message.warning("空运，请输入三位流水号")
                return
            }
            if (!regNumber.test(formData.bnoNo)) {
                message.warning("流水号只能输入数字")
                return
            }

            //年份校验
            if(formData.bnoYear.length != 2){
                message.warning("请输入两位数的年份")
                return
            }
            if (!regNumber.test(formData.bnoYear)) {
                message.warning("年份只能输入数字")
                return
            }

            try{
                await doSaveBosNo(formData, true);

                setVisible(false);
                //刷新frame
                if(onFrameCall)
                    onFrameCall();
            } catch (e) {
                Modal.destroyAll
            } finally {
                setLoadingOperate(false);
            }
        } finally {
            setLoadingOperate(false);
        }
    }

    // 取消
    const doCancel = async ()=>{
        setVisible(false);
    }

    // 核算组
    const onExtraChangePu = async(val) => {
        let data = null;
        if(val){
            data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/basPu/oneBasPu", {puId:val.puId})
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
        }

        if (data) {
            setBasPu(data);
        }else{
            setBasPu(null);
        }
    }

    return (
        <DraggableModal
            visible={visible}
            title={"修改运编号"}
            cancelText="取消"
            width="45%"
            onOk={() => doOk()}
            onCancel={() => doCancel()}
            confirmLoading={loadingOperate}
        >
            <Form form={form} layout="vertical" size="small" preserve={false}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item name='bnoTransModeCode' label={"出运方式"} rules={[{required: true, message: '请选择出运方式!'}]}>
                            <Select allowClear={true} onChange={(e)=>{inputOnChange(e, 'transModeCode')}}>
                                {
                                    dictData.getDictDtls("TRANS_MODE")
                                        //.filter(item => item.dictItemCode=='SEA' || item.dictItemCode=='ROAD' || item.dictItemCode=='AIR')
                                        .map(item => {
                                        return <Option key={item.dictItemCode}
                                                       value={item.dictItemCode}>{item.dictItemNameCn}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name='bnoBuCode' label='事业部'>
                            <ReadyInput/>
                        </Form.Item>
                        <Form.Item name='buId' label='事业部ID' style={{display:"none"}}>
                            <ReadyInput />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name='bnoProdCntId' label={"国家"} rules={[{required: true, message: '请选择国家!'}]}>
                            <Select multiple={false} showSearch={true} allowClear={true} onChange={(e)=>{inputOnChange(e, 'prodCntId')}}
                                    filterOption={(inputValue,option)=>countrySearch(inputValue,option)}
                            >
                                {countryList.map(e => <Option value={e.cntId}>{e.cntNameCn}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item name='bnoProdCntCode' label='国家首字母' style={{display:"none"}}>
                            <ReadyInput />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        {transModeCode == "AIR" ?
                            <Form.Item name='bnoNo' label='流水号' rules={[{required: true, message: '请输入三位流水号!'}]}>
                                <Input allowClear onChange={(e) => {
                                    inputOnChange(e, 'bnoNo')
                                }}/>
                            </Form.Item> :
                            <Form.Item name='bnoNo' label='流水号' rules={[{required: true, message: '请输入四位流水号!'}]}>
                                <Input allowClear onChange={(e) => {
                                    inputOnChange(e, 'bnoNo')
                                }}/>
                            </Form.Item>
                        }
                    </Col>
                    <Col span={4}>
                        {/*<Form.Item name='puCode' label='核算组'>
                            <ReadyInput/>
                        </Form.Item>*/}
                        <Form.Item name='puId' label='核算组' rules={[{required: true, message: '请选择核算组',}]}>
                            <PuSelect permissionCode={billType=='PBOS'?"PBOS-DS":"MBOS-DS"} billTypeCode={"PBOS"}  permissionOptCode='edit' onExtraChange={(basPu)=>inputOnChange(basPu, 'puId')}/>
                        </Form.Item>
                        <Form.Item name='puCode' label='核算组Code' style={{display:"none"}}>
                            <ReadyInput/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        {/*<Form.Item name='bnoYear' label='年份'>
                            <ReadyInput/>
                        </Form.Item>*/}
                        <Form.Item name='bnoYear' label='年份'
                                   rules={[{required: true, message: '请输入两位数年份!'}]}>
                            <Input allowClear onChange={(e) => {
                                inputOnChange(e, 'bnoYear')
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        运编号预览：{bosNo}
                    </Col>
                </Row>
            </Form>
        </DraggableModal>
    );
}

export default DlgGBOSPullNumber;
