/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import {Typography} from 'antd'
import DraggableModal from 'components/DraggableModal/DraggableModal.jsx'
import Tools from "script/sweet-tools.mjs";
import FlowTaskHistory from "./FlowTaskHistory";

const {Text} = Typography;
const Pane = ({
                  billId,
                  visible,
                  setVisible,
                  ...props
              }) => {
    return (
        <DraggableModal
            title="审批历史"
            okText="确定"
            cancelButtonProps={{style: {display: 'none'}}}
            // width={600}
            bodyStyle={{overflow: 'auto', maxHeight: '80vh'}}
            visible={visible}
            onOk={() => setVisible(false)}
            destroyOnClose={true}
        >
            <FlowTaskHistory billId={billId}/>
        </DraggableModal>
    )
}

export default Pane
