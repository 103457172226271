/** @jsxImportSource @emotion/react */

import {cloneElement, useEffect, useState} from 'react'
import Modal from './Modal.jsx'

const Prompt = ({
    children,
    value,
    visible,
    onOk,
    ...props
}) => {
    const [tempValue, setTempValue] = useState(value)

    useEffect(
        () => {
            if (visible) {
                setTempValue(value)
            }
        },

        [visible, value]
    )

    const handleOk = () => onOk(tempValue)

    const input = cloneElement(children, {
        value: tempValue,
        onChange: setTempValue,
    })

    return (
        <Modal
            visible={visible}
            onOk={handleOk}
            {...props}
        >
            {input}
        </Modal>
    )
}

export default Prompt
