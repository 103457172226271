/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import {Form, Input, Spin} from 'antd'
import DraggableModal from 'components/DraggableModal/DraggableModal.jsx'

const FlowChangeModal = ({
                             params,
                             visible,
                             onOk,
                             children,
                             ...props
                         }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {onSave} = params;

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({commitMessage: null})
        }
    }, [visible])

    return (
        <DraggableModal
            title={"改单"}
            // style={{top: 20}}
            // width='80wh'
            bodyStyle={{overflow: 'hidden', display: 'flex', flexDirection: 'column',}}
            visible={visible}
            destroyOnClose={true}
            okText="确定"
            cancelText="取消"
            onOk={() => {
                form.validateFields().then(async values => {
                    setLoading(true)
                    try {
                        await onSave(values)
                        onOk(true)
                    } catch(e) {
                        onOk(false)
                    }finally{
                        setLoading(false)
                    }
                })
            }}
            onCancel={() => onOk(false)}
            {...props}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical" preserve={false} size="small">
                    <Form.Item name='commitMessage' label="改单原因" rules={[{required: true, message: '请输入改单原因!'}]}>
                        <Input.TextArea rows={5}/>
                    </Form.Item>
                </Form>
            </Spin>
        </DraggableModal>
    )
}

export default FlowChangeModal;
