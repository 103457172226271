//开发管理
export let firstDevDict = "dev/";
export let defaultDevDict = "dev/other";//开发模块非他文件目录
export let defaultStyelDict = "dev/style";

//技术管理
export let firstQaDict = "qa/";
export let defaultQaDict = "qa/other";//技术管理非他文件目录

//打样中心
export let firstTechDict = "tech/";
export let defaultTechDict = "tech/other";//打样中心非他文件目录

//销售管理
export let firstSmDict = "sm/";
export let defaultSmDict = "sm/other";//销售管理非他文件目录

//采购管理
export let firstPurDict = "pur/";
export let defaultPurDict = "pur/other";//采购管理非他文件目录

//物料出运
export let firstMbosDict = "mbos/";
export let defaultMbosDict = "mbos/other";//物料出运非他文件目录

//手册办理
export let firstEpimDict = "epim/";
export let defaultEpimDict = "epim/other";//手册办理非他文件目录

//成品出运
export let firstPbosDict = "pbos/";
export let defaultPbosDict = "pbos/other";//成品出运非他文件目录

//单证管理
export let firstDocDict = "doc/";
export let defaultDocsDict = "doc/other";//单证管理非他文件目录

//应收应付
export let firstPayDict = "pay/";
export let defaultPaysDict = "pay/other";//应收应付非他文件目录

//资源管理
export let firstIrDict = "ir/";
export let defaultIrsDict = "ir/other";//资源管理非他文件目录

//客商管理
export let firstPnrDict = "pnr/";
export let defaultPnrsDict = "pnr/other";//商管理非他文件目录

//基础资料及系统管理
export let firstBasDict = "bas/";
export let defaultBassDict = "bas/other";//基础资料及系统管理非他文件目录

//内贸出库
export let firstBodDict = "bod/";
export let defaultBodsDict = "bod/other";//

//内贸开票
export let firstDirDict = "dir/";
export let defaultDirsDict = "dir/other";//

//内贸业务订仓单
export let firstBsnDict ="bsn/";
export let defaultBsnDict = "bsn/other";

export let commonBassDict = "bas/common";//基础资料-公共资源管理文件目录
export let appverBassDict = "bas/appver";//基础资料-应用版本

export let firstProdDict ="produce/";
export let defaultProdDict = "produce/other";
