/** @jsxImportSource @emotion/react */

import {LeftLayoutIcon} from 'components/Icons/Icons'

const ButtonToggleNavOpen = ({isNavOpen, ...props}) => {
    const css = {
        color: isNavOpen ? '#1890ff' : 'unset',
        cursor: 'pointer',
    }

    return (
        <LeftLayoutIcon
            css={css}
            {...props}
        />
    )
}

const FrameHead = ({
    children,
    isNavOpen,
    onClickToggleNavOpen,
    ...props
}) => {
    const css = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#e6f7ff',
        padding: '6px 12px',
        borderTop: '1px solid #f0f0f0',
        borderBottom: '1px solid #f0f0f0',
        overflow: 'hidden',
    }

    return (
        <div
            css={css}
            {...props}
        >
            <ButtonToggleNavOpen
                style={{marginRight: 4}}
                isNavOpen={isNavOpen}
                onClick={onClickToggleNavOpen}
            />

            <div style={{flexGrow: 1}}>{children}</div>
        </div>
    )
}

export default FrameHead
