/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react';
import {Spin, Typography, Form, Tabs, Input, Button, Space, Divider} from 'antd';
import {EyeTwoTone, EyeInvisibleOutlined} from '@ant-design/icons';
import {useNavigate, useLocation} from 'react-router-dom';
import {
    VerifiedOutlined,
    UserOutlined,
    LockOutlined,
    FlagOutlined,
    WechatOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
// import favicon from 'public/favicon.svg';
import favicon from 'public/favicon.ico';
import banner from 'public/login_banner.png'
import backgroundIcon from 'public/login_icon.png'
import backgroundImg from 'public/login_bg.png';
import Tools from "script/sweet-tools.mjs";
import checkRelease from "script/checkRelease";
import md5 from 'crypto-js/md5';
// import useDOM from "hooks/useDOM.mjs";

const Pane = (props) => {
    const {Text, Link,Title} = Typography;
    const {TabPane} = Tabs;
    const navigate = useNavigate();
    //Loading 控制变量
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [testEnv,setTestEvn] = useState(false);
    const [testDevEnv,setTestDevEvn] = useState(false);
    //真实登录
    const handlerLogin = async () => {
        form.validateFields().then(async (values) => {
            setLoading(true);
            try {
                const userPwdSalt = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/bas/auth/dopweb/login/userpwdsalt", {userNo:values.userNo});
                    },
                    {
                        error: {
                            content: (err) => `登录失败: ${err.message}`
                        },
                        loading: {
                            show: false,
                        },
                        success: {
                            show: false,
                        },
                    }
                )
                values.password = md5(values.password + userPwdSalt).toString();
                const loginSession = await Tools.runAsync(
                    async () => {
                        values.appLoginFlag = false;
                        return await Tools.http.post(`/bas/auth/dopweb/login/userNo`, {...values});
                    },
                    {
                        error: {
                            content: (err) => err.message,
                        },

                        loading: {
                            show: false,
                        },

                        success: {
                            show: false,
                        },
                    }
                );
                setLoading(false);
                Tools.authenticator.set(loginSession);
                navigate("/", {replace: true});
            } catch (e) {
                console.log(e)
                setLoading(false);
            }
        })
    }

    //组件挂载执行一次
    useEffect(() => {
        (async () => {
            // useDOM.setTitle({
            //     prefix: "用户登录"
            // })
            let url = window.location.href;
            if ( url.indexOf('itosuat.bsdits.cn') >=0 ){
                setTestEvn(true);
            } else if( url.indexOf('itosdev.bsdits.cn') >= 0 ){
                setTestDevEvn(true);
            }
            console.log(">>>release "+ checkRelease.output);
        })();
    }, [])

    const rootCss = {
        display: 'flex',
        flexDirection: 'column',
        width: '100wh',
        height: '100vh',
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }

    const topbarCss = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 1px 2px -2px rgba(0, 0, 0, 0.16),0px 3px 6px 0px rgba(0, 0, 0, 0.12),0px 5px 12px 4px rgba(0, 0, 0, 0.09)',
        backgroundColor: 'rgb(255,255,255,0.1)',

        '& .topbar-title': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            color: 'white',
            '& img': {
                height: 32,
                margin: '6px 12px'
            }
        },

        '& .topbar-buttons': {
            margin: '0 12px',
            '& .contributor': {
                color: '#C7000B',
                '&:hover': {
                    color: '#C7000B'
                }
            },
            '& .link-mobile': {
                color: 'black',
                '&:hover': {
                    color: '#52c41a'
                }
            },
            '& .help-doc': {
                color: 'black',
                '&:hover': {
                    color: '#1890ff'
                }
            },
        },
    }
    const containerCss = {
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'center',
        alignItems: 'center',

        '& .icon': {
            width: '720px',
            marginRight: '64px'
        },
        '& .login-panel': {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            // background: 'white',
            // border: '1px solid #f0f0f0',
            background: 'rgba(0,0,0, 30%)',
            border: '1px solid rgba(0,0,0, 50%)',
            borderRadius: '4px',
            boxShadow: '0px 1px 2px -2px rgba(0, 0, 0, 0.16),0px 3px 6px 0px rgba(0, 0, 0, 0.12),0px 5px 12px 4px rgba(0, 0, 0, 0.09)',
            '& .logo': {
                display: 'flex',
                justifyContent: 'center',
                padding: '24px 24px 0 24px',
                '& img': {width: '160px'},
            },
            '& .user-panel': {padding: '8px 24px 24px 24px', width: '360px'},
            '& .qr-panel': {padding: '8px 24px 24px 24px', width: '360px', height: '232px'},
        }
    }

    return (
        <div css={rootCss}>
            <div css={topbarCss}>
                <div className="topbar-title">
                    <img
                        alt="logo"
                        src={favicon}
                    />
                    服装贸易数字化运营平台
                </div>
                <Space className="topbar-buttons">
                    {/*<a className="contributor"><FlagOutlined/> 贡献者名单</a>*/}
                    {/*<a className="link-mobile"><WechatOutlined/> 访问小程序</a>*/}
                    {/*<a className="help-doc"><QuestionCircleOutlined/> 帮助文档</a>*/}
                </Space>
            </div>
            <div css={containerCss}>
                {/*<img src={backgroundIcon} className="icon"/>*/}
                <div className="login-panel">
                    <div className="logo">
                        <img src={banner}/>
                    </div>
                    <Divider/>
                    <div style={{padding: '0 24px 24px 24px'}}>
                        <Form
                            form={form}
                            name="normal_login"
                            onFinish={handlerLogin}
                            initialValues={{remember: true}}
                        >
                            {/*<Form.Item*/}
                            {/*    name="uuNo"*/}
                            {/*    rules={[{required: false, message: '请输入用户单位编码!'}]}*/}
                            {/*    initialValue={"QF"}*/}
                            {/*>*/}
                            {/*    <Input style={{width: 310}} prefix={<VerifiedOutlined/>} placeholder='用户单位编码'/>*/}
                            {/*</Form.Item>*/}
                            <Form.Item
                                name="userNo"
                                rules={[{required: true, message: '请输入用户名!'}]}
                                style={{padding: '24px 0 0 0'}}
                                // initialValue={"LOUISMEI"}
                            >
                                <Input style={{width: 380}} prefix={<UserOutlined/>} placeholder='用户名'/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{required: true, message: '请输入密码!'}]}
                                initialValue={"123456"}
                                style={{padding: '12px 0 12px 0'}}
                            >
                                <Input.Password
                                    style={{width: 380}}
                                    prefix={<LockOutlined/>}
                                    placeholder='密码'
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button htmlType='submit' block loading={loading} style={{background: "#B7A568",color:"white",border:"1px solid #B7A568"}}>
                                    快速登录
                                </Button>
                            </Form.Item>
                            {
                                testEnv?
                                    <Form.Item>
                                        <Title level={5}><Text type="warning"></Text></Title>
                                        <Title level={5}><Text type="warning">提醒：当前访问系统为客户【测试】环境！</Text></Title>
                                        <Title level={5}><Text type="warning">说明：仅用于系统功能测试验证！</Text></Title>
                                    </Form.Item> :
                                testDevEnv?
                                    <Form.Item>
                                        <Title level={5}><Text type="warning"></Text></Title>
                                        <Title level={5}><Text type="warning">提醒：当前访问系统为客户【开发】环境！</Text></Title>
                                        <Title level={5}><Text type="warning">说明：仅用于系统功能测试验证！</Text></Title>
                                    </Form.Item> : null
                            }
                        </Form>
                    </div>
                    {/*<Tabs defaultActiveKey="1" centered>*/}
                    {/*    <TabPane tab="密码登录" key="1" className="user-panel">*/}
                    {/*        */}
                    {/*    </TabPane>*/}
                    {/*    <TabPane tab="扫码登录" key="2" className={"qr-panel"}>*/}
                    {/*        <div style={{*/}
                    {/*            width: '100%',*/}
                    {/*            height: '100%',*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'column',*/}
                    {/*            alignItems: 'center'*/}
                    {/*        }}>*/}
                    {/*            <div>请使用 <Text type="warning">数字化运营小程序</Text> 扫描二维码登录</div>*/}
                    {/*            <div style={{*/}
                    {/*                display: 'flex',*/}
                    {/*                flexGrow: 1,*/}
                    {/*                justifyContent: 'center',*/}
                    {/*                alignItems: 'center'*/}
                    {/*            }}>*/}
                    {/*                <img src={qrCode} style={{width: '180px', height: '180px'}}/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </TabPane>*/}
                    {/*</Tabs>*/}
                </div>
            </div>
        </div>

    )
}

export default Pane;
