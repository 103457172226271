import {useRef} from 'react'

const useDebounce = (delay, fn) => {
    const refTimeoutId = useRef()

    if (delay) {
        const cancel = () => clearTimeout(refTimeoutId.current)

        const debounced = (...args) => {
            cancel()

            refTimeoutId.current = setTimeout(
                () => fn(...args),
                delay
            )
        }

        return [debounced, cancel]
    }
    else {
        return [fn, () => {}]
    }
}

export default useDebounce
