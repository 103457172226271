
const validatior = () => {

}

validatior.EmailRegex=/^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;

validatior.isPhoneNumber = (value) => {
    let phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!phoneReg.test(value)) {
        return false
    }
    return true;
}

validatior.isEmail = (value) => {
    let emailReg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
    if (!emailReg.test(value)) {
        return false
    }
    return true;
}

export default validatior;
