/** @jsxImportSource @emotion/react */

import {Tabs} from 'antd'
import TabContent from './TabContent.jsx'
import TabLabel from './TabLabel.jsx'
import {useMainTabsContext} from './contexts.mjs'
export {default as MainTabsProvider} from './MainTabsProvider.jsx'
export {useMainTabContext, useMainTabsContext} from './contexts.mjs'

const MainTabs = (props) => {
    const mainTabs = useMainTabsContext()

    const items = mainTabs.tabs.map(
        ({
            closable,
            component,
            key,
            name,
            title,
        }) => {
            const label = (
                <TabLabel
                    closable={closable}
                    name={name}
                    tabKey={key}
                    title={title}
                />
            )

            const children = (
                <TabContent
                    component={component}
                    tabKey={key}
                />
            )

            return {children, closable, key, label}
        },
    )

    const handleEdit = async (key, action) => {
        if ('remove' === action) {
            await mainTabs.dispatchEvent('close-tab', key)
            mainTabs.closeTab(key)
        }
    }

    const css = {
        backgroundColor: '#f0f2f5',
        overflow: 'hidden',

        '&>.ant-tabs-nav': {
            padding: '2px 2px 0 2px',
            margin: 0,

            '& .ant-tabs-tab': {
                padding: 0,
            },

            '& .ant-tabs-tab-with-remove': {
                paddingRight: 16,
            },

            '& .ant-tabs-tab-active': {
                backgroundColor: '#e6f7ff',
                borderBottomColor: '#e6f7ff !important',
            },
        },

        '&>.ant-tabs-content-holder>.ant-tabs-content': {
            height: '100%',
            backgroundColor: '#fff',

            '&>.ant-tabs-tabpane': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },

            '&>.ant-tabs-tabpane-hidden': {
                display: 'none',
            },
        },
    }

    return (
        <Tabs
            css={css}
            activeKey={mainTabs.activeKey}
            hideAdd
            items={items}
            onChange={mainTabs.activateTab}
            onEdit={handleEdit}
            type="editable-card"
            {...props}
        />
    )
}

export default MainTabs
