/** @jsxImportSource @emotion/react */

import {useEffect, useState} from 'react'
import {Popover, Typography} from 'antd'
import { SwatchesPicker} from 'react-color'

const ColorPicker = ({width = 16, height = 16, title, value, onChange, buttonCss, pickerCss, ...props}) => {
    const [isPanelVisible, setIsPanelVisible] = useState(false)
    const [color, setColor] = useState()
    const {Text, Link} = Typography;
    useEffect(
        () => setColor(value),
        [value]
    )

    const handleChange = ({hex}) => {
        setColor(hex)
        if (onChange) {
            onChange(hex)
        }
        setIsPanelVisible(false);
    }

    const css = {
        display: 'flex',
        alignItems:'center',
        cursor: 'pointer',
    }

    const _buttonCss = {
        // position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width,
        height,
        padding: '2px',
        border: '1px solid #d9d9d9',
        borderRadius: 2,

        ...buttonCss,
    }

    const __buttonCss = {
        flexGrow: 1,
        backgroundColor: color,
    }


    const content = (
        <div style={{display: 'flex', flexShrink: 0}} css={pickerCss} {...props}>
            <SwatchesPicker
                color={value}
                onChange={handleChange}
            />
        </div>
    )

    return (
        <Popover content={content}
                 trigger="click"
                 visible={isPanelVisible}
                 overlayClassName="color-picker-popover"
                 onVisibleChange={visible => setIsPanelVisible(visible)}
        >
            <div css={css}>
                <div css={_buttonCss}>
                    <div css={__buttonCss}></div>
                </div>
                {title && <Text style={{marginLeft: '8px'}}>{title}</Text>}
            </div>
        </Popover>
    )
}

export default ColorPicker
