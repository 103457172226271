/** @jsxImportSource @emotion/react */

import {createContext, useMemo, useState} from 'react'

export const EditContext = createContext({
    isEditable: false,
    isEditing: false,
    setIsEditing: () => {},
})

const Edit = ({
    isEditable = true,
    defaultIsEditing = false,
    ...props
}) => {
    const [isEditing, setIsEditing] = useState()

    const value = useMemo(
        () => ({
            isEditable,
            isEditing: isEditable && (isEditing ?? defaultIsEditing),
            setIsEditing,
        }),

        [defaultIsEditing, isEditable, isEditing]
    )

    return (
        <EditContext.Provider
            {...props}
            value={value}
        />
    )
}

export default Edit
