/** @jsxImportSource @emotion/react */

import {useState} from 'react'
import {Checkbox, Space} from 'antd'
import FilterDropdown from '../FilterDropdown.jsx'

const sort = {
    asc: (a = false, b = false) => a - b,
    desc: (a = false, b = false) => b - a,
}

const SelectValues = ({selectedValues, setSelectedValues, ...props}) => {
    const [t, f] = selectedValues

    const handleChangeTrue = ({target: {checked}}) => {
        setSelectedValues([checked, f])
    }

    const handleChangeFalse = ({target: {checked}}) => {
        setSelectedValues([t, checked])
    }

    const styleList = {
        padding: 6,
        border: '1px solid rgb(217, 217, 217)',
    }

    const styleCheckbox = {
        display: 'flex',
        margin: 0,
    }

    return (
        <div
            style={styleList}
            {...props}
        >
            <Checkbox
                style={styleCheckbox}
                checked={t}
                onChange={handleChangeTrue}
            >是</Checkbox>

            <Checkbox
                style={styleCheckbox}
                checked={f}
                onChange={handleChangeFalse}
            >否</Checkbox>
        </div>
    )
}

const defaultGetBoolean = (a) => a

const FilterBoolean = ({
    confirm,
    getBoolean = defaultGetBoolean,
    setFilter,
    setSort,
}) => {
    const [order, setOrder] = useState('')
    const [selectedValues, setSelectedValues] = useState([false, false])

    const handleConfirm = () => {
        const [t, f] = selectedValues

        if (t === f) {
            setFilter(null)
        }
        else {
            if (t) {
                setFilter((value) => getBoolean(value))
            }
            else {
                setFilter((value) => ! getBoolean(value))
            }
        }

        if (order) {
            setSort((a, b) => {
                const boolA = getBoolean(a)
                const boolB = getBoolean(b)
                return sort[order](boolA, boolB)
            })
        }
        else {
            setSort(null)
        }

        confirm()
    }

    const handleReset = () => {
        setSelectedValues([false, false])
        setOrder('')
    }

    return (
        <FilterDropdown
            order={order}
            onChangeOrder={setOrder}
            onConfirm={handleConfirm}
            onReset={handleReset}
        >
            <SelectValues
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
            />
        </FilterDropdown>
    )
}

export default FilterBoolean
