import {useRef} from 'react'

const useRowNum = () => {
    const refRowKeyNum = useRef(new Map())
    const rowKeyToNum = refRowKeyNum.current
    const getRowNum = (rowKey) => rowKeyToNum.get(rowKey)
    const setRowNum = (rowKey, num) => rowKeyToNum.set(rowKey, num)
    return {getRowNum, setRowNum}
}

export default useRowNum
