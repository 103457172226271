/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import {Modal, Radio, Spin, Timeline, Empty} from 'antd'
import DraggableModal from 'components/DraggableModal/DraggableModal.jsx'
import Tools from "script/sweet-tools.mjs";

/**
 * 发起审批之前弹出
 * 用于确认审批节点、处理人，点击确认后再发起审批
 * @param visible
 * @param setVisible
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Pane = ({
                  params,
                  onOk,
                  visible,
                  setVisible,
                  ...props
              }) => {
    const [loading, setLoading] = useState(false);
    const [loadingTip, setLoadingTip] = useState(null);

    //表格数据定义
    const [data, setData] = useState(null);
    const [errMessage, setErrMessage] = useState(null);

    const doQuery = async () => {
        console.log("---params---",params)
        try {
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/flowAudit/startBefore", params)
                },
                {
                    error: {content: (err) => `${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            console.log("data:" + JSON.stringify(data))
            if (data) {
                const {assignees} = data;
                setData({
                    ...data,
                    assignees,
                    assignee: assignees && assignees.length == 1 ? assignees[0].id : null
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    const doStartAudit = async () => {
        setLoadingTip('提交审批中...')
        setLoading(true);
        try {
            if (!data?.flowExecutionTasks?.length) {
                Modal.info({title: '提示信息', content: '没有审批人信息，请检查审批流程配置'})
                return;
            }

            for (let i = 0; i < data.flowExecutionTasks.length; i++) {
                if (!data.flowExecutionTasks[i].assignee) {
                    Modal.info({title: '提示信息', content: '请选择审批人'})
                    return;
                }
            }

            const resp = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/flowAudit/start", {...params, flowId: data.id, flowKey: data.key, flowModuleCode: data.flowModuleCode, flowExecutionTasks: data.flowExecutionTasks})
                },
                {
                    error: {content: (err) => `提交审批失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );

            //触发自动审批
            if (data.autoCompleteFlag) {
                setLoadingTip('自动完成审批中...')
                const completeResp = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/bas/flowAudit/autoCompleteAll", {flowModuleCode: data.flowModuleCode, billId: params.billId,})
                    },
                    {
                        error: {content: (err) => `自动审批失败: ${err.message}`},
                        loading: {show: false},
                        success: {show: true},
                    }
                );
            }

            if (onOk) {
                onOk(true);
            }
            // setVisible(false)
        } finally {
            setLoadingTip(null)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (visible) {
            // setData([])
            (async () => {
                if (!params) {
                    Modal.info({title: '提示信息', content: '请传入参数'})
                    return;
                }

                const {flowModuleCode, orgId, puId, empId, billId, variables} = params;

                if (!flowModuleCode) {
                    Modal.info({title: '提示信息', content: '请传入审批模块代码'})
                    return;
                }

                if (!orgId) {
                    Modal.info({title: '提示信息', content: '请传入制单组织ID'})
                    return;
                }

                if (!puId) {
                    Modal.info({title: '提示信息', content: '请传入制单权限组ID'})
                    return;
                }

                if (!empId) {
                    Modal.info({title: '提示信息', content: '请传入制单人ID'})
                    return;
                }

                if (!billId) {
                    Modal.info({title: '提示信息', content: '请传入单据ID'})
                    return;
                }

                setLoading(true)
                try {
                    await doQuery();
                } finally {
                    setLoading(false)
                }
            })()
        }
    }, [visible]);

    return (
        <DraggableModal
            title="提交审批确认"
            okText="提交"
            // width={600}
            bodyStyle={{overflow: 'auto', display: 'flex', flexDirection: 'column', maxHeight: '80vh'}}
            visible={visible}
            onOk={doStartAudit}
            onCancel={() => onOk(false)}
            destroyOnClose={true}
            confirmLoading={loading}
        >
            <Spin spinning={loading} tip={loadingTip}>
                {
                    !data?.flowExecutionTasks?.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                }
                {
                    data?.flowExecutionTasks?.length > 0 && (
                        <Timeline css={{'& .ant-timeline-item.ant-timeline-item-last': {paddingBottom: 0}}}>
                            {
                                data?.flowExecutionTasks?.map(({flowTaskId, flowTaskName, assigneeExpression, assignees, assignee}, index) => {
                                    return (
                                        <Timeline.Item color="blue">
                                            <p>{flowTaskName}</p>
                                            <div>
                                                <Radio.Group
                                                    options={assignees?.map(({id, name, authorizerId, authorizerName}) => ({label: name + (authorizerName ? `(${authorizerName})` : ''), value: id}))}
                                                    //defaultValue={assignees.length == 1 ? assignees[0].id : null}
                                                    value={assignee}
                                                    onChange={({target: {value}}) => {
                                                        const assignee = assignees.find(({id}) => id == value);
                                                        data.flowExecutionTasks[index].assignee = assignee.id;
                                                        data.flowExecutionTasks[index].assigneeName = assignee.name;
                                                        data.flowExecutionTasks[index].authorizer = assignee.authorizerId;
                                                        data.flowExecutionTasks[index].authorizerName = assignee.authorizerName;
                                                        setData({...data})
                                                    }}
                                                >
                                                </Radio.Group>
                                            </div>
                                        </Timeline.Item>
                                    )
                                })
                            }
                        </Timeline>
                    )
                }
            </Spin>
        </DraggableModal>
    )
}

export default Pane
