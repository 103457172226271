/** @jsxImportSource @emotion/react */

import {Tree} from 'antd'
import {ProfileOutlined} from '@ant-design/icons'

const FrameNav = ({
    activeKey,
    frames,
    isNavOpen,
    onChange,
    ...props
}) => {
    if (! isNavOpen) {
        return null
    }

    const tree = (() => {
        const mapNode = ({
            children,
            icon = <ProfileOutlined />,
            key,
            name,
            title = name,
        }) => {
            const node = {icon, key, name, title}

            if (children) {
                node.children = children.map(mapNode)
            }

            return node
        }

        return frames.map(mapNode)
    })()

    const handleSelect = (dummy, {node}) => {
        if (! node.children) {
            onChange(node.key)
        }
    }

    const css = {
        minWidth: 240,
        backgroundColor: '#fafafa',
        padding: 4,
        borderRight: '1px solid #f0f0f0',
        overflow: 'auto',
    }

    return (
        <Tree.DirectoryTree
            className="auto-overflow"
            css={css}
            defaultExpandAll
            treeData={tree}
            onSelect={handleSelect}
            selectedKeys={[activeKey]}
            {...props}
        />
    )
}

export default FrameNav
