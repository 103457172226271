/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {Button, Form, Typography,} from 'antd';
import {useNavigate} from 'react-router-dom';
import PURPRODPC from "pages/HtmlPrint/PURPRODPC.jsx";
import MPCDtlFrame from "pages/BizPage/Frames/MPCDtlFrame/MPCDtlFrame.jsx";
import GPCDTLFrame from "pages/BizPage/Frames/GPCDTLFrame/GPCDTLFrame.jsx";
import CSODtlFrame from "pages/BizPage/Frames/SMCSODtlFrame/CSODtlFrame.jsx";
import GBOSFrame from "pages/BizPage/Frames/GBOSFrame/GBOSFrame.jsx";
import DBODFrame from "pages/BizPage/Frames/DBODFrame/DBODFrame.jsx";
import useGlobalData from "../../hooks/useGlobalData";
import Tools from "../../script/sweet-tools";

const {Text} = Typography;

const Pane = () => {
    const [loading, setLoading] = useState(false);
    const [spinTip, setSpinTip] = useState('')
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [param, setParam] = useState({});
    const [soDto, setSoDto] = useState(null);
    const [record, setRecord] = useState(null);

    // 合同二维码
    const [pcQrCode, setPcQrCode] = useState(null);

    // 获取请求URL中的参数
    const getUrlParam = () => {
        let url = String(window.document.location.href);
        /*
            分析：
                最前面是?或&，紧跟着除 ?&#以外的字符若干，然后再等号，最后再跟着除 ?&#以外的字符，并且要分组捕获到【除?&#以外的字符】
        */
        var reg=/[?&]([^?&#]+)=([^?&#]+)/g;
        var param={};
        var ret =  reg.exec(url);
        while(ret){//当ret为null时表示已经匹配到最后了，直接跳出
            param[ret[1]]=ret[2];
            ret = reg.exec(url);
        }
        return param;
    }

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        initSo();
    }, [param]);

    const initSo = async () => {
        if(param?.opnUrl == 'CSODtlFrame'){
            const result = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/sm/so/oneSmSoDTO", {soId: param?.soId})
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            setSoDto(result);
            setRecord(result?.so);
        }
    }

    const init = async () => {
        await useGlobalData.init()
        const param = getUrlParam();
        console.log('---opnUrl---',param)
        setParam(param)
    }

    return (
        <div>
            {param?.opnUrl == 'MPCDtlFrame' &&<MPCDtlFrame pcId={param?.billId} data={{itemLngTypeCode:param?.itemLngTypeCode,pcId:param?.pcId,pcNo:param?.pcNo,pcStsCode:param?.pcStsCode,pcEvStsCode:param?.pcEvStsCode}}/>}
            {param?.opnUrl == 'GPCDTLFrame' &&<GPCDTLFrame pcId={param?.billId} itemInfo={{itemLngTypeCode:param?.itemLngTypeCode,pcId:param?.pcId,pcNo:param?.pcNo,pcStsCode:param?.pcStsCode,pcEvStsCode:param?.pcEvStsCode}}/>}

            {param?.opnUrl == 'GBOSFrame' &&<GBOSFrame pcId={param?.billId} record={{bosId:param?.bosId,bosNo:param?.bosNo}}/>}
            {param?.opnUrl == 'DBODFrame' &&<DBODFrame pcId={param?.billId} record={{bodId:param?.bodId,bodNo:param?.bodNo}}/>}
            {/*{param?.opnUrl == 'CSODtlFrame' &&<CSODtlFrame pcId={param?.billId} defaultPaneKey={param?.defaultPaneKey} soDto={{so:{soId:param?.soId,soModeCode:param?.soModeCode,crtTime:"'"+param?.crtTime+"'",currBudgetDataId:param?.currBudgetDataId,currPayDataId:param?.currPayDataId}}} record={{soId:param?.soId,soTypeCode:param?.soTypeCode,soPuId:param?.soPuId,crtUserId:param?.crtUserId,bdId:param?.bdId,finalId:param?.finalId,soNo:param?.soNo}}/>}*/}
            {param?.opnUrl == 'CSODtlFrame' && record !=null &&<CSODtlFrame pcId={param?.billId} defaultPaneKey={"CSODtCustBudget"} soDto={soDto} record={record} smSoType={['FMS', 'FSS', 'FO', 'FMM'].includes(param?.smSoType)?"F":"D"}/>}
        </div>
    )

}

export default Pane;
