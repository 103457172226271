/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react'
import Input from 'components/Form/TextArea.jsx'
import {DEFAULT_CHANGE_DELAY} from '../config.mjs'

const cssWrapper = css({
    position: 'relative',
    height: 'calc(1em * 1.5715 + 2px)',
    overflow: 'hidden',

    '&:has(:focus)': {
        overflow: 'visible',
    }
})

const cssInput = css({
    position: 'absolute',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    minHeight: 'unset',
    color: 'inherit',
    padding: '1px 7px',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',

    '&:focus': {
        WebkitLineClamp: 'unset',
        backgroundColor: '#fff',
        //boxShadow: '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
        zIndex: 10,//zjx 2023-5-11 : 1-> 10
    }
})

const InputLongText = ({
    changeDelay = DEFAULT_CHANGE_DELAY,
    ...props
}) => {
    return (
        <div
            css={cssWrapper}
            title={props.value}
        >
            <Input
                css={cssInput}
                bordered={false}
                changeDelay={changeDelay}
                {...props}
            />
        </div>
    )
}

export default InputLongText
