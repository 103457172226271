import Tools from 'script/sweet-tools.mjs'

//缓存 Key 集合
const Keys = () => {
}

Keys.TableConfig = 'TABLE_CONFIG';
//字典哈希,适用于获取某个字典的字典值数组,key=dictCode,
Keys.HASH_DICT = 'HASH_DICT';
//字典值哈希,适用于获取某个字典下某个字典值对应的数据,key=dictCode_dictItemCode,
Keys.HASH_DICT_DTL = 'HASH_DICT_DTL';
//国家地区数组
Keys.LIST_DICT_COUNTRY = 'LIST_DICT_COUNTRY';
//币制数组
Keys.LIST_CURR = 'LIST_CURR';
//计量单位转换规则哈希,key=来源单位ID_目标单位ID
Keys.HASH_UT = 'HASH_UT';
//报表模板哈希,key=rtCode_custId
Keys.HASH_RT = 'HASH_RT';
//员工数组
Keys.LIST_EMP = 'LIST_EMP';
//员工哈希
Keys.HASH_EMP = 'HASH_EMP';
//核算组拥有的人员(集合)哈希
Keys.HASH_PU_EMP = 'HASH_PU_EMP';
//组织数组
Keys.LIST_ORG = 'LIST_ORG';
//组织哈希,key=orgId
Keys.HASH_ORG = 'HASH_ORG'
//核算组数组
Keys.LIST_PU = 'LIST_PU';
//核算组哈希
Keys.HASH_PU = 'HASH_PU';
//Tab最多打开个数Hash
Keys.HASH_TAB_MAX = 'HASH_TAB_MAX';
//出运港数组
Keys.LIST_POL = 'LIST_POL';
//目的港数组
Keys.LIST_POD = 'LIST_POD';
//选项配置数组???
Keys.LIST_OPT = 'OPT';
//公共文件哈希,key=cfCode
Keys.HASH_COMMON_FILE = 'HASH_COMMON_FILE';//公共文件
//客户包装箱唛唛头内容
Keys.HASH_CPM_MEMO = 'HASH_CPM_MEMO';

export {Keys};

//缓存结构体
const globalMap = new Map();
const DataSource = () => {
    return globalMap;
};
export {DataSource};

//加载字典
const loadDict = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/dict/listDict`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            const dictMap = new Map();
            const dictDtlMap = new Map();
            data.forEach(({dictCode, childrens = []}) => {
                dictMap.set(dictCode, childrens);

                if (childrens) {
                    childrens.forEach(item => {
                        dictDtlMap.set(item.dictCode + "-" + item.dictItemCode, item);
                    })
                }
            })
            DataSource().set(Keys.HASH_DICT, dictMap);
            DataSource().set(Keys.HASH_DICT_DTL, dictDtlMap);
        }
    } catch (e) {
        console.error(e)
    }
}

//加载选项配置
const loadOpt = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/basOptCfg/listBasOptCfg`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            DataSource().set(Keys.OPT, data);
        }
    } catch (e) {
        console.error(e)
    }
}

//加载国家地区
const loadCountry = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/dictCountry/listDictCountry`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            DataSource().set(Keys.LIST_DICT_COUNTRY, data);
        }
    } catch (e) {
        console.error(e)
    }
}

//加载币制
const loadCurr = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/dictCurr/listDictCurr`, {disabled: false});
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );
        DataSource().set(Keys.LIST_CURR, data ?? []);
    } catch (e) {
        console.error(e)
    }
}

//加载表格配置
const loadTableConfig = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/sysTableConfig/listSysTableConfigByUser`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            const map = new Map();
            data.forEach(({tcId, tableCode, userId, tcContent, ...props}) => {
                const key = tableCode
                map.set(key, JSON.parse(tcContent));
            })
            DataSource().set(Keys.TableConfig, map);
        }
    } catch (e) {
        console.error(e)
    }
}

//加载计量单位转换规则
const loadUT = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/basUomTransform/listBasUomTransform`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        const map = new Map();
        if (data) {
            data.forEach(item => {
                map.set(item.utSourceId + '_' + item.utTargetId, item);
            })
        }
        DataSource().set(Keys.HASH_UT, map);
    } catch (e) {
        console.error(e)
    }
}

//加载报表模板映射
const loadRT = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/sysReportTemplate/listSysReportTemplate`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        const map = new Map();
        if (data) {
            data.forEach(item => {
                map.set(item.rtCode + (item.custId ?? ''), item);
            })
            //console.log("加载报表映射配置", data);
        }
        DataSource().set(Keys.HASH_RT, map);
    } catch (e) {
        console.error(e)
    }
}

//加载公共文件列表
const loadCommonFile = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/sysCommonFile/listSysCommonFile`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        const map = new Map();
        if (data) {
            data.forEach(({cfId, cfCode, fileId, ...values}) => {
                map.set(cfCode, {
                    ...values,
                    cfId,
                    cfCode,
                    fileId,
                    url: Tools.convertToUrl(fileId),
                });
            })
            console.log("加载公共文件", data);
        }
        DataSource().set(Keys.HASH_COMMON_FILE, map);
    } catch (e) {
        console.error(e)
    }
}

//加载员工
const loadEMP = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/basEmp/listBasEmp`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        const map = new Map();
        const empMap = new Map();
        if (data) {
            data.forEach(({empId, basPus, ...props}) => {
                empMap.set(empId, {empId, basPus, ...props});

                if (basPus) {
                    basPus.forEach(({puId}) => {
                        const emps = map.get(puId) ?? [];
                        emps.push(empId);
                        map.set(puId, emps);
                    })
                }
            })
            DataSource().set(Keys.HASH_PU_EMP, map);
            DataSource().set(Keys.HASH_EMP, empMap);
        }
        DataSource().set(Keys.LIST_EMP, data ?? []);

    } catch (e) {
        console.error(e)
    }
}

//加载组织
const loadOrg = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/basOrg/listBasOrg`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        DataSource().set(Keys.LIST_ORG, data);
        const orgMap = new Map();
        data.forEach(item => {
            orgMap.set(item.orgId, item);
        })
        DataSource().set(Keys.HASH_ORG, orgMap);
    } catch (e) {
        console.error(e)
    }
}

//加载tab最多打开个数
const loadTabMax = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/bas/busdefcfg/oneBasBusDefConfigDR", {bdcCode: "TAB_OPEN_NUM"})

            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            DataSource().set(Keys.HASH_TAB_MAX, data?.bdcValue ?? 15);

        }
    } catch (e) {
        console.error(e)
    }
}



//加载核算组
const loadPu = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/basPu/listBasPu`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            const map = new Map();
            data.forEach(item => {
                map.set(item.puId, item);
            })
            DataSource().set(Keys.HASH_PU, map);
        }
        DataSource().set(Keys.LIST_PU, data ?? []);
    } catch (e) {
        console.error(e)
    }
}


//加载tab最多打开个数
const loadCustPackMarkHeadMemo = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post("/bas/busdefcfg/oneBasBusDefConfigDR", {bdcCode: "DLV_MARK_PB"})

            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        if (data) {
            DataSource().set(Keys.HASH_CPM_MEMO , data?.bdcValue ?? "");
        }
    } catch (e) {
        console.error(e)
    }
}

//加载出运港
const loadPol = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/dictPol/listDictPol`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        DataSource().set(Keys.LIST_POL, data ?? []);
    } catch (e) {
        console.error(e)
    }
}

//加载目的港
const loadPod = async () => {
    try {
        const data = await Tools.runAsync(
            async () => {
                return await Tools.http.post(`/bas/dictPod/listDictPod`);
            },
            {
                error: {
                    show: false,
                },

                loading: {
                    show: false,
                },

                success: {
                    show: false,
                },
            }
        );

        DataSource().set(Keys.LIST_POD, data ?? []);
    } catch (e) {
        console.error(e)
    }
}

/**
 * 获取全局数据
 * @returns {Promise<void>}
 */
const useGlobalData = {

    /**
     * 初始化前端缓存
     * @returns {Promise<void>}
     */
    init: async () => {
        if (DataSource().size == 0) {
            const promises = [
                loadDict(),
                loadCurr(),
                loadUT(),
                loadTableConfig(),
                loadRT(),
                loadCountry(),
                loadEMP(),
                loadPol(),
                loadPod(),
                loadOpt(),
                loadCommonFile(),
                loadOrg(),
                loadPu(),
                loadTabMax(),
                loadCustPackMarkHeadMemo(),
            ]
            await Promise.all(promises)
        }
    },

    refreshOrg: async () => {
        await loadOrg();
    },

    refreshEmp: async () => {
        await loadEMP()
    },

    /**
     * 获取指定字典编码的字典值数组
     * @param dictCode
     */
    getDictDtls: (dictCode) => {
        const data = DataSource().get(Keys.HASH_DICT);
        if (data) {
            return data.get(dictCode);
        }
        return [];
    },

    /**
     * 获取指定字典编码、字典值编码的字典值对象
     * @param dictCode
     * @param dictItemCode
     * @returns {string|*|string}
     */
    getDictDtl: (dictCode, dictItemCode) => {
        const data = DataSource().get(Keys.HASH_DICT_DTL);
        if (data) {
            const key = dictCode + '-' + dictItemCode;
            if (data.has(key)) {
                return data.get(key);
            }
        }
        return null;
    },

    /**
     * 获取指定字典编码、字典值编码的中文名称
     * @param dictCode
     * @param dictItemCode
     * @returns {string|*|string}
     */
    getDictDtlCn: (dictCode, dictItemCode) => {
        const data = DataSource().get(Keys.HASH_DICT_DTL);
        if (data) {
            const key = dictCode + '-' + dictItemCode;
            if (data.has(key)) {
                return data.get(key).dictItemNameCn ?? '';
            }
        }
        return '';
    },

    /**
     * 获取指定字典编码、字典值编码的英文名称
     * @param dictCode
     * @param dictItemCode
     * @returns {string|*|string}
     */
    getDictDtlEn: (dictCode, dictItemCode) => {
        const data = DataSource().get(Keys.HASH_DICT_DTL);
        if (data) {
            const key = dictCode + '-' + dictItemCode;
            if (data.has(key)) {
                return data.get(key).dictItemNameEn ?? '';
            }
        }
        return '';
    },

    /**
     * 获取国家地区数组
     * @param level 指定级别
     * @returns {*[]|*}
     */
    getDictCountrys: (level) => {
        const data = DataSource().get(Keys.LIST_DICT_COUNTRY);
        if (data) {
            if (level) {
                return data.filter(({cntLevel}) => cntLevel == level);
            } else {
                return data;
            }
        }
        return [];
    },

    /**
     * 获取付款方式
     * @param type foreign 国外，domestic 国内
     * @returns {*[]|*}
     */
    getPayModes: (type) => {
        const data = DataSource().get(Keys.HASH_DICT);
        if (data) {
            const payModes = data.get("PAY_MODE");
            if (payModes) {
                const foreign = ['PHASE', 'OA', 'TTS', 'TTD', 'LC', 'DP', 'DA', 'FREE']
                const domestic = ['PHASE', 'ACCEPT', 'TT', 'DLC', 'CASH']
                const supp = ['PHASE', '月结']
                if (type == 'foreign') {
                    return payModes.filter(({dictItemCode}) => foreign.includes(dictItemCode))
                } else if (type == 'domestic') {
                    return payModes.filter(({dictItemCode}) => domestic.includes(dictItemCode))
                } else if(type == 'supp'){
                    return payModes.filter(({dictItemCode}) => supp.includes(dictItemCode))
                } else{
                    return payModes;
                }
            }
        }
        return [];
    },

    /**
     * 获取收款节点
     * @param type foreign 国外，domestic 国内
     * @returns {*[]|*}
     */
    getPmpPhases: (type) => {
        const data = DataSource().get(Keys.HASH_DICT);
        if (data) {
            const pmpPhases = data.get("PMP_PHASE");
            if (pmpPhases) {
                if (type == 'foreign') {
                    return pmpPhases.filter(({dictItemCode}) => dictItemCode.startsWith('F'))
                } else if (type == 'domestic') {
                    return pmpPhases.filter(({dictItemCode}) => dictItemCode.startsWith('D'))
                } else {
                    return pmpPhases;
                }
            }
        }
        return [];
    },

    /**
     * // 订单模式
     * @param type foreign 国外，domestic 国内
     * @returns {*[]|*}
     */
    getSoModes: (type) => {
        const data = DataSource().get(Keys.HASH_DICT);
        if (data) {
            const somodes = data.get("SO_MODE");
            if (somodes) {
                if (type == 'foreign') {
                    return somodes.filter(({dictItemCode}) => dictItemCode.startsWith('F'))
                } else if (type == 'domestic') {
                    return somodes.filter(({dictItemCode}) => dictItemCode.startsWith('D'))
                } else {
                    return somodes;
                }
            }
        }
        return [];
    },

    /**
     * 面辅料订单模式
     * @param type
     * @returns {*[]|*}
     */
    getFabricSoModes: (type) => {
        const data = DataSource().get(Keys.HASH_DICT);
        if (data) {
            const somodes = data.get("SO_MODE");
            if (somodes) {
                if (type == 'foreign') {
                    return somodes.filter(({dictItemCode}) => ['FDT', 'FDP'].includes(dictItemCode))
                } else if (type == 'domestic') {
                    return somodes.filter(({dictItemCode}) => ['DDT', 'DDP'].includes(dictItemCode))
                } else {
                    return somodes;
                }
            }
        }
        return [];
    },

    /**
     * 获取表格配置
     * @param tableCode
     * @param userId
     * @param custId
     * @returns {*}
     */
    getTableConfig: ({tableCode}) => {
        const data = DataSource().get(Keys.TableConfig);
        if (data) {
            const value = data.get(tableCode);
            return value ?? null;
        } else {
            return null;
        }

    },

    /**
     * 获取计量单位转换规则
     * @param sourceId
     * @param targetId
     * @returns {*}
     */
    getUT: ({sourceId, targetId}) => {
        const map = DataSource().get(Keys.HASH_UT);
        return map.get(sourceId + '_' + targetId);
    },

    /**
     * 获取报表模板配置
     * @param rtCode
     * @returns {*}
     */
    getRT: ({code, custId}) => {
        const map = DataSource().get(Keys.HASH_RT);
        if (custId) {
            const template = map.get(code + custId)
            if (template) {
                return template;
            }
        }
        return map.get(code);
    },

    /**
     * 刷新表格配置
     * @returns {Promise<void>}
     */
    refreshTableConfig: async () => {
        await loadTableConfig();
    },

    /**
     * 获取员工数组
     * @param dictCode
     */
    getBasEmps: () => {
        const data = DataSource().get(Keys.LIST_EMP);
        if (data) {
            return [...data];
        }
        return [];
    },

    /**
     * 获取某个核算组的人员数据
     * @param puId
     * @returns {*|*[]}
     */
    getBasEmpsByPuId: (puId) => {
        const data = DataSource().get(Keys.HASH_PU_EMP);
        return data.get(puId) ?? [];
    },

    getBasEmp: (empId) => {
        return DataSource().get(Keys.HASH_EMP).get(empId)
    },

    /**
     * 获取币制数组
     * @returns {*[]}
     */
    getCurrs: () => {
        const data = DataSource().get(Keys.LIST_CURR);
        if (data) {
            return [...data];
        }
        return [];
    },

    /**
     * 获取出运港数组
     * @returns {*[]}
     */
    getPols: () => {
        const data = DataSource().get(Keys.LIST_POL);
        if (data) {
            return [...data];
        }
        return [];
    },

    /**
     * 获取目的港数组
     * @returns {*[]}
     */
    getPods: () => {
        const data = DataSource().get(Keys.LIST_POD);
        if (data) {
            return [...data];
        }
        return [];
    },

    /**
     * 获取核算组数组
     * @returns {*[]}
     */
    getPus: () => {
        const data = DataSource().get(Keys.LIST_PU);
        if (data) {
            return [...data];
        }
        return [];
    },

    getPu: (puId) => {
        const data = DataSource().get(Keys.HASH_PU);
        return data.get(puId)
    },

    /**
     * 获取选项配置列表
     * @param optSceneCode_ 选项配置场景CODE
     * @param optDataTypeCode_ 选项配置数据类型CODE
     * @returns {*[]|*}
     */
    getOpts: (optSceneCode_, optDataTypeCode_) => {
        const data = DataSource().get(Keys.OPT);
        if (data) {
            return data.filter(({optSceneCode, optDataTypeCode}) => optSceneCode_ == optSceneCode && optDataTypeCode_ == optDataTypeCode)
        } else {
            return []
        }
    },

    /**
     * 获取公共文件
     * @param cfCode 文件编码
     */
    getCommonFile: (cfCode) => {
        const map = DataSource().get(Keys.HASH_COMMON_FILE);
        if (map) {
            return map.get(cfCode);
        } else {
            return {}
        }
    },

    getOrgs: () => {
        const data = DataSource().get(Keys.LIST_ORG);
        if (data) {
            return [...data];
        }
        return [];
    },

    getOrg: (orgId) => {
        const data = DataSource().get(Keys.HASH_ORG);
        return data.get(orgId);
    },

    getOrgPathNo: (keys) => {
        const data = DataSource().get(Keys.HASH_ORG);
        if (Array.isArray(keys)) {
            const result = [];
            for (let key of keys) {
                if (data && data.has(key)) {
                    result.push(data.get(key).orgPathNo)
                }
            }
            return result;
        } else {
            if (data && data.has(keys)) {
                return data.get(keys).orgPathNo;
            }
        }
        return null;
    },


    getTabMax: () => {
        return DataSource().get(Keys.HASH_TAB_MAX);
    },
    
    getCpmMemo: () => {
        return DataSource().get(Keys.HASH_CPM_MEMO);
    },
}


export default useGlobalData;
