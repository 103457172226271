/** @jsxImportSource @emotion/react */

import {createHashRouter, RouterProvider, Navigate, useSearchParams} from 'react-router-dom';
import Tools from 'script/sweet-tools.mjs'
import LoginPage from 'pages/LoginPage/LoginPage';
import MainPage from 'pages/MainPage/MainPage.jsx';
import GuestLoginPage from "pages/GuestLoginPage/GuestLoginPage";
import GuestPage from "pages/GuestPage/GuestPage";
import TestPage from "pages/TestPage/TestPage";
import ModifyPassword from "pages/ModifyPassword/ModifyPassword";
import HtmlPrint from "pages/HtmlPrint/HtmlPrint";
import OpenFrame from "pages/OpenFrame/OpenFrame";
// import useDOM from "hooks/useDOM.mjs";

const Router = () => {

    /**
     * 正式用户登录校验拦截
     * @param children
     * @returns {JSX.Element|*}
     * @constructor
     */
    const RequireAuth = ({children}) => {
        if (Tools.authenticator.verify()) {
            const user = Tools.authenticator.getUser()
            if (user.initPwdFlag) {
                return <Navigate to="/modifyPassword" replace/>
            } else {
                // useDOM.restoreTitle();
                return children;
            }
        } else {
            return <Navigate to="/login" replace/>;
        }
    }

    /**
     * 访客用户登录校验拦截
     * @returns {JSX.Element|Promise<*|null>}
     * @constructor
     */
    const GuestRequireAuth = () => {
        const [searchParams,] = useSearchParams();
        if (Tools.authenticator.verify()) {
            const navigateUrl = searchParams.get("navigateUrl");
            if (navigateUrl) {
                // useDOM.restoreTitle();
                if (navigateUrl.indexOf('?') > -1) {
                    return <GuestPage router={navigateUrl.split("?")[0]} searchParams={navigateUrl.split("?")[1]}/>
                } else {
                    return <GuestPage router={navigateUrl} searchParams={''}/>
                }

            }
        }

        return <GuestLoginPage/>;
    }

    const router = createHashRouter([
        {
            path: '/',
            element: <RequireAuth><MainPage/></RequireAuth>,
        },

        {
            path: '/print',
            element: <RequireAuth><HtmlPrint/></RequireAuth>,
        },

        {
            path: '/openFrame',
            element: <RequireAuth><OpenFrame/></RequireAuth>,
        },

        {
            path: '/login',
            element: <LoginPage/>,
        },

        {
            path: '/modifyPassword',
            element: <ModifyPassword/>,
        },

        {
            path: '/guest',
            element: <GuestRequireAuth/>,
        },

        {
            path: '/guestLogin',
            element: <GuestLoginPage/>,
        },

        {
            path: '/test',
            element: <TestPage/>,
        },

        {
            path: '*',
            element: <Navigate to="/" replace/>,
        },
    ])

    return <RouterProvider router={router}/>
}

export default Router
