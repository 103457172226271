import {useEffect, useState} from 'react'

const useRowSelection = (options, rows, rowKey, iterRows) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    useEffect(
        () => {
            if (options?.selectedRowKeys) {
                setSelectedRowKeys(options.selectedRowKeys)
            }
        },

        [options?.selectedRowKeys]
    )

    if (! options) {
        return [null, setSelectedRowKeys]
    }

    const rowKeys = new Set(
        Array.from(iterRows(rows)).map(
            ({[rowKey]: key}) => key
        )
    )

    const cleanKeys = selectedRowKeys.filter((key) => rowKeys.has(key))

    const rowSelection = {
        columnWidth: 40,
        ...options,
        selectedRowKeys: cleanKeys,

        onChange: (selectedRowKeys, ...args) => {
            setSelectedRowKeys(selectedRowKeys)
            options.onChange?.(selectedRowKeys, ...args)
        },
    }

    return [rowSelection, setSelectedRowKeys]
}

export default useRowSelection
