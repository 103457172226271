/** @jsxImportSource @emotion/react */

import {lazy, memo, Suspense, useEffect, useMemo} from 'react'
import {Spin} from 'antd'
import ErrorBoundary from 'components/ErrorBoundry/ErrorBoundry.jsx'
import {ActiveProvider} from 'hooks/useActive.mjs'
import useEventTarget from 'hooks/useEventTarget.mjs'
import {MainTabContext, useMainTabsContext} from './contexts.mjs'

const MainTabProvider = ({tabKey, ...props}) => {
    const {
        addEventListener,
        closeTab,
        configTab,
        isTabActive,
        openTab,
        removeEventListener,
    } = useMainTabsContext()

    const eventTarget = useEventTarget()
    const isActive = isTabActive(tabKey)

    useEffect(
        () => {
            const handleClose = async (key) => {
                if (key === tabKey) {
                    await eventTarget.dispatchEvent('close')
                }
            }

            addEventListener('close-tab', handleClose)
            return () => removeEventListener('close-tab', handleClose)
        },

        [addEventListener, removeEventListener, tabKey]
    )

    const mainTab = useMemo(
        () => {
            const {addEventListener, removeEventListener} = eventTarget
            const close = () => closeTab(tabKey)
            const config = (options) => configTab(tabKey, options)

            return {
                addEventListener,
                close,
                config,
                openTab,
                removeEventListener,
            }
        },

        [closeTab, configTab, eventTarget, openTab]
    )

    return (
        <ActiveProvider value={isActive}>
            <MainTabContext.Provider
                value={mainTab}
                {...props}
            />
        </ActiveProvider>
    )
}

const TabContentFallback = () => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    }

    return (
        <Spin
            style={style}
            spinning
            tip="加载组件..."
        />
    )
}

const TabContent = ({component: [path, props], tabKey}) => {
    const Component = useMemo(
        () => memo(lazy(() => import(`pages/BizPage/${path}.jsx`))),
        [path, props]
    )

    return (
        <MainTabProvider tabKey={tabKey}>
            <Suspense fallback={<TabContentFallback />}>
                <ErrorBoundary>
                    <Component {...props} />
                </ErrorBoundary>
            </Suspense>
        </MainTabProvider>
    )
}

export default TabContent
