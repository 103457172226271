/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import {Spin, Typography, Button, Space, Badge } from 'antd'
import DraggableModal from 'components/DraggableModal/DraggableModal.jsx'

// 展示版本信息model框
const ReleaseVersionModal = ({
                               visible,
                               onOk,
                               showWarning,
                               warningMsg,
                               releaseNotes,
                               ...props
                           }) => {
                               
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
    }, [visible])

        
    const cssTitle = {
        fontSize: 16
    }
    
    const cssItem = {
        color: 'rgb(0, 0, 0, 0.45)',
        '&:hover': {color: '#1890ff'},
    }

    return (
        <DraggableModal
            title='系统版本记录'
            className='fill-spin'
            style={{top: 40}}
            width={800}
            bodyStyle={{overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '60vh'}}
            visible={visible}
            destroyOnClose={true}
            footer={
            showWarning ? 
                    <Space>
                        <Badge status="warning" text={warningMsg} />
                        <Button size='small' onClick={() => onOk()}>知道了</Button>
                    </Space>
                        :
                    <Space>
                        <Button size='small' onClick={() => onOk()}>知道了</Button>
                    </Space>
            }
            {...props}
        >
            <Spin spinning={loading}>
                <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
                        {
                            releaseNotes.length > 0 && 
                            releaseNotes.map(({verNo, rlMemo, ...props}) => {
                                let memos = rlMemo?.split('\n');
                                return (
                                        <ul>
                                            <text style={cssTitle}>V{verNo}</text>
                                            {memos?.map((momo) => {
                                                return (<li><text style={cssItem}>{momo}</text></li>)
                                            })}
                                        </ul>)
                            })
                        }
                </div>
            </Spin>
        </DraggableModal>
    )
}

export default ReleaseVersionModal;
