/** @jsxImportSource @emotion/react */

import {Checkbox, Divider, Input, Select, Space} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import {makeKeywordsMatcher} from 'hopedove-dom/str'
import CheckboxList from './CheckboxList.jsx'
import {useState} from "react";

const InputKeywordsText = ({
                               value,
                               onChange,
                               setMode,
                               mode,
                               options,
                               ...props
                           }) => {
    const handleChange = ({target: {value}}) => {
        onChange(value)
    }

    const css = {
        display: 'flex !important',
    }
    return (
        <Input.Group
            css={css}
            compact
        >
            <Input
                allowClear
                placeholder='输入关键字检索'
                prefix={<SearchOutlined/>}
                size="small"
                value={value}
                onChange={handleChange}
                {...props}
            />
        </Input.Group>
    )
}

const InputKeywordsTextSelect = ({
                                     value,
                                     onChange,
                                     setMode,
                                     mode,
                                     options,
                                     ...props
                                 }) => {

    const values = value && value.length > 0 ? value.split(',') : [];
    const handleChange = (e) => {
        //获取输入的内容
        let valueList = (e && e.target ? e.target.value : e);
        let list = [];
        //循环
        for (let value of valueList) {
            if (!value) {
                continue;
            }
            //替换换行符
            value = value.replace(/\r\n/g, ",");
            value = value.replace(/\n/g, ",");
            //替换中英文空格
            value = value ? value.replace(/\s/g, ",") : '';

            //按英文逗号切分
            let values = value.split(',');
            for (let item of values) {
                //去掉前后空格
                if (item.trim().length > 0) {
                    list.push(item.trim());
                }
            }
        }
        onChange(list.join(','))
    };


    const css = {
        display: 'flex !important',
    }
    const cssSelect = {
        width: "100%"
    }
    return (
        <Input.Group
            css={css}
            compact
        >
            <Select
                css={cssSelect}
                mode="tags"
                tokenSeparators={[',']}
                size="small"
                value={values}
                onChange={(e) => {
                    handleChange(e)
                }}
                {...props}
            />
        </Input.Group>
    )
}

const InputKeywordsTextArea = ({
                                     value,
                                     onChange,
                                     setMode,
                                     mode,
                                     options,
                                     ...props
                                 }) => {

    const handleChange = (e) => {
        //获取输入的内容
        let value = (e && e.target ? e.target.value : e);
        console.log(value);
        onChange(value ? value.trim() : '');
    };


    const css = {
        display: 'flex !important',
    }
    const cssSelect = {
        width: "100%"
    }
    return (
        <Input.Group
            css={css}
            compact
        >
            <Input.TextArea
                allowClear
                placeholder='输入关键字检索'
                size="small"
                value={value}
                onChange={handleChange}
                {...props}
            />
        </Input.Group>
    )
}


const ModeSelect = ({
                        setMode,
                        mode,
                        options,
                        ...props
                    }) => {
    const modeChange = (e) => {
        let value = (e && e.target ? e.target.value : e);
        setMode(value)
    };

    const css = {
        display: 'flex !important',
    }
    const cssSelect = {
        width: "100%"
    }
    return (
        <Input.Group
            css={css}
            compact
        >
            <Select
                css={cssSelect}
                size="small"
                options={options}
                value={mode}
                onChange={(e) => {
                    modeChange(e)
                }}
            />
        </Input.Group>
    )
}

const CheckboxesText = ({
                            value,
                            values,
                            onChange,
                            numberRD,
                            ...props
                        }) => {
    const options = values.map((value) => [value])

    const isCheckedAll = (
        options.length <= value.length &&
        options.every(([v]) => value.includes(v))
    )

    const isCheckedSome = !isCheckedAll && 0 < value.length

    const handleChangeSelectAll = ({target: {checked}}) => {
        if (checked) {
            onChange(values)
        } else {
            onChange([])
        }
    }

    const styleCheckboxes = {
        display: 'flex',
        flexDirection: 'column',
        height: 200,
        padding: 6,
        border: '1px solid #d9d9d9',
        overflow: 'hidden',
    }

    const styleList = {
        overflow: 'hidden',
    }

    return (
        <div
            style={styleCheckboxes}
            {...props}
        >
            <Checkbox
                checked={isCheckedAll}
                indeterminate={isCheckedSome}
                onChange={handleChangeSelectAll}
            >全选</Checkbox>

            <Divider style={{margin: '8px 0'}}/>

            <CheckboxList
                style={styleList}
                options={options}
                value={value}
                onChange={onChange}
                numberRD={numberRD}
            />
        </div>
    )
}

const kewordsToList = (keyword)=>{
    let list = [];
    if (keyword) {
        //替换换行符
        let value = keyword.replace(/\r\n/g, ",");
        value = value.replace(/\n/g, ",");
        //替换中英文空格
        value = value ? value.replace(/\s/g, ",") : '';

        let values = value.split(',');
        for (let item of values) {
            //去掉前后空格
            if (item.trim().length > 0) {
                list.push(item.trim());
            }
        }
    }
    return list;
}

const CheckboxFilterText = ({
                                keyword,
                                value,
                                values,
                                onChange,
                                onChangeKeyword,
                                numberRD,
                            }) => {
    //匹配模式 默认为模糊匹配
    const [mode, setMode] = useState('default');
    //模糊匹配，等于，不等于，开头是，结尾是，包含，不包含
    const options = [
        {label: '模糊匹配', value: 'default'},
        {label: '等于', value: 'eq'},
        {label: '不等于', value: 'neq'},
        {label: '开头是', value: 'startwith'},
        {label: '结尾是', value: 'endwith'},
        {label: '包含', value: 'include'},
        {label: '不包含', value: 'exclude'},
    ]

    //不同匹配模式返回不同过滤方法
    function matchByMode(keywordString, mode) {
        if (keywordString && keywordString?.trim()) {
            const keyword = keywordString?.trim();

            switch (mode) {
                case 'eq':
                    return (text) => text === keyword;
                case 'neq':
                    return (text) => text !== keyword;
                case 'startwith':
                    return (text) => text.startsWith(keyword);
                case 'endwith':
                    return (text) => text.endsWith(keyword);
                case 'include':
                    return (text) => {
                        let list = kewordsToList(keyword);
                        return list.includes(text)
                    };
                case 'exclude':
                    return (text) => {
                        let list = kewordsToList(keyword);
                        return !list.includes(text)
                    };
                default:
                    // 默认模糊匹配
                    return makeKeywordsMatcher(keyword);
            }
        } else {
            return null;
        }

    }

    const match = matchByMode(keyword, mode);
    const refinedValues = match ? values.filter(match) : values;

    const style = {
        display: 'flex',
    }

    return (
        <Space
            style={style}
            direction="vertical"
        >
            <ModeSelect
                mode={mode}
                setMode={setMode}
                options={options}
            />

            {
                !(mode === 'include' || mode === 'exclude') && <InputKeywordsText
                    value={keyword}
                    onChange={onChangeKeyword}
                />
            }

            {
                (mode === 'include' || mode === 'exclude') &&
                <InputKeywordsTextArea
                    value={keyword}
                    onChange={onChangeKeyword}
                />
            }


            <CheckboxesText
                value={value}
                values={refinedValues}
                onChange={onChange}
                numberRD={numberRD}
            />
        </Space>
    )
}

export default CheckboxFilterText
