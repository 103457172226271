/** @jsxImportSource @emotion/react */

import {createContext, useContext, useState} from 'react'
import {Spin as AntdSpin} from 'antd'

const SpinContext = createContext({spinning: false})

const Spin = (props) => {
    const upperSpin = useSpin()
    const [spin, setSpin] = useState({spinning: false})

    const provider = {
        setSpin,

        spinning: (
            ! upperSpin.spinning &&
            (props.spinning ?? spin.spinning)
        ),
    }

    return (
        <SpinContext.Provider value={provider}>
            <AntdSpin
                {...props}
                {...spin}
                spinning={provider.spinning}
            />
        </SpinContext.Provider>
    )
}


export default Spin

export const useSpin = () => useContext(SpinContext)
