/** @jsxImportSource @emotion/react */

import {useState} from 'react'
import {css} from '@emotion/react'
import useEventTarget from 'hooks/useEventTarget.mjs'
import FrameHead from './FrameHead.jsx'
import FrameMain from './FrameMain.jsx'
import FrameNav from './FrameNav.jsx'
import Navigation from './Navigation.jsx'
export {useFrameContentContext} from './FrameContent.jsx'

const useActiveKey = ({defaultKey, frames}) => {
    const [activeKey, setActiveKey] = useState(() => {
        if (defaultKey) {
            return defaultKey
        }
        else {
            const findFirstLeafKey = (nodes) => {
                for (const {children, key} of nodes) {
                    if (children) {
                        return findFirstLeafKey(children)
                    }
                    else {
                        return key
                    }
                }
            }

            return findFirstLeafKey(frames)
        }
    })

    return [activeKey, setActiveKey]
}

const cssContainer = css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
})

const cssHeader = css({
    flexShrink: 0,
})

const cssBody = css({
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
})

const cssNav = css({
    flexShrink: 0,
})

const cssMain = css({
    flexGrow: 1,
})

const Frame = ({defaultKey, frames = [], navigation}) => {
    const eventTarget = useEventTarget()
    const [activeKey, setActiveKey] = useActiveKey({defaultKey, frames})
    const [isNavOpen, setIsNavOpen] = useState(true)

    const handleChange = async (key) => {
        await eventTarget.dispatchEvent('hide', activeKey)
        setActiveKey(key)
        eventTarget.dispatchEvent('show', key)
    }

    const handleClickToggleNavOpen = () => {
        setIsNavOpen((isNavOpen) => ! isNavOpen)
    }

    return (
        <div css={cssContainer}>
            <FrameHead
                css={cssHeader}
                isNavOpen={isNavOpen}
                onClickToggleNavOpen={handleClickToggleNavOpen}
            >
                <Navigation navigation={navigation} />
            </FrameHead>

            <div css={cssBody}>
                <FrameNav
                    css={cssNav}
                    activeKey={activeKey}
                    frames={frames}
                    isNavOpen={isNavOpen}
                    onChange={handleChange}
                />

                <FrameMain
                    css={cssMain}
                    activeKey={activeKey}
                    frame={eventTarget}
                    frames={frames}
                />
            </div>
        </div>
    )
}

export default Frame
