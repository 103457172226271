/** @jsxImportSource @emotion/react */

import Modal from './Modal.jsx'

const Alert = (props) => {
    return (
        <Modal
            okButtonProps={{style: {display: 'none'}}}
            {...props}
        />
    )
}

export default Alert
