/** @jsxImportSource @emotion/react */
;
import { useEffect, useState} from 'react';
import {Form, Space, Select, Tag, Checkbox, Typography, Tooltip, Modal, Divider, Popover, Spin} from 'antd';
import {SettingOutlined, PlusOutlined,} from "@ant-design/icons";
import ColorPicker from "../ColorPicker/ColorPicker";
import List from 'rc-virtual-list'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd' ;
import Tools from "script/sweet-tools.mjs";
import useGlobalData from "hooks/useGlobalData.mjs";

const mapToConfig = ({key, title, width, fixed = false, hide = false}) => {
    return {
        key, width, fixed, hide
    }
}

/**
 * 列展示控制组件
 * @param columns
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
const Pane = ({tableCode, columns, onChange, ...props}) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    //可选择客户列表
    const [custs, setCusts] = useState([])
    const [custMap, setCustMap] = useState(new Map())
    //当前所有配置
    const [customConfigs, setCustomConfigs] = useState([]);
    //当前选中客户项
    const [selectCustId, setSelectCustId] = useState(null);
    //当前选中客户的配置
    const [selectConfig, setSelectConfig] = useState({}); //原始列数据

    const [custPopVisible, setCustPopVisible] = useState(false); //原始列数据

    const {Text, Link} = Typography;
    const {Option} = Select;
    const [form] = Form.useForm();

    const cssContentItem = {
        padding: '4px 8px',
        '&:hover': {
            background: '#f0f0f0',
            cursor: 'pointer'
        }
    }

    const handlerTagClick = (custId) => {
        setSelectCustId(custId);
    }

    const handlerContentItemClick = (cust) => {
        if (!customConfigs.find(({custId}) => custId == cust.custId)) {
            //不存在，新建客户配置
            setCustomConfigs([...customConfigs,
                {
                    tableCode,
                    custId: cust.custId,
                    tcContent: {
                        striped: false,
                        oddColor: '#fff',
                        evenColor: '#f0f0f0',
                        columns: columns.map(mapToConfig),
                    }
                }
            ]);
        }
        setSelectCustId(cust.custId);
        setCustPopVisible(false);
    }

    const content = (
        <List
            data={custs}
            height={200}
            itemHeight={30}
            {...props}
        >
            {
                (value) => (<div css={cssContentItem} onClick={() => handlerContentItemClick(value)}>{value.pnr.pnrNameCn}</div>)
            }
        </List>
    );

    const openSettingModal = () => {
        setVisible(true);
    }

    const handlerRowColorChange = ({target: {checked}}) => {
        selectConfig.tcContent.striped = checked;
        setCustomConfigs([...customConfigs]);
    }

    const handlerOddRowColorChange = (hex) => {
        selectConfig.tcContent.oddColor = hex;
        setCustomConfigs([...customConfigs]);
    }

    const handlerEvenRowColorChange = (hex) => {
        selectConfig.tcContent.evenColor = hex;
        setCustomConfigs([...customConfigs]);
    }

    const handlerHideChange = ({target: {checked}}, key) => {
        const cfg = selectConfig.tcContent.columns.find((ic) => ic.key == key);
        if (cfg) {
            if (checked) {
                delete cfg.hide;
            } else {
                cfg.hide = true;
            }
        }
        setCustomConfigs([...customConfigs]);
    }

    const handlerFixedChange = ({target: {checked}}, key) => {
        const cfg = selectConfig.tcContent.columns.find((ic) => ic.key == key);
        if (cfg) {
            if (checked) {
                cfg.fixed = true
            } else {
                delete cfg.fixed;
            }
        }
        setCustomConfigs([...customConfigs]);
    }

    const handlerOk = async () => {
        setLoading(true)
        try {
            const configs = customConfigs.map(item => ({...item, tcContent: JSON.stringify(item.tcContent)}))
            await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/bas/sysTableConfig/saveSysTableConfig", configs)
                },
                {
                    error: {content: (err) => `保存失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: true},
                }
            );

            //刷新缓存
            await useGlobalData.refreshTableConfig();

            //保存配置，并且反馈给外部表格
            if (onChange) {
                onChange()
            }
        } finally {
            setLoading(false)
        }
    }

    const handlerCancel = () => {
        setVisible(false)
    }

    const handlerReset = () => {
        doQuery();
    }

    //读取用户的表格配置
    const doQuery = async () => {
        //先查询出用户拥有的客户列表
        setLoading(true)
        try {
            const custs = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pnr/pnrCust/listPnrCust", {})
                },
                {
                    error: {content: (err) => `查询客户失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );

            //获得客户 ID 与 客户名称的映射对象
            custMap.clear();
            custs.forEach(({custId, pnr: {pnrNameCn}}) => {
                custMap.set(custId, pnrNameCn);
            })
            custMap.set(null, "全部");
            setCustMap(new Map(custMap));
            setCusts(custs);

            //再查询出用户已有的客户配置
            // const data = useGlobalData.getTableConfigGroup({tableCode})
            // if (data) {
            //     setCustomConfigs([...data.reverse()]);
            // } else {
            //     //当不存在配置时，初始化一份默认配置
            //     setCustomConfigs([
            //         {
            //             tableCode,
            //             custId: null,
            //             tcContent: {
            //                 striped: false,
            //                 oddColor: '#fff',
            //                 evenColor: '#f0f0f0',
            //                 columns: columns.map(mapToConfig),
            //             }
            //         }
            //     ])
            // }
            setSelectCustId(null)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            if (visible) {
                doQuery();
            }
        })();
    }, [visible, columns]);

    useEffect(() => {
        (async () => {
            //自定义配置数据源有变动时，重新设置选中项的配置
            if (customConfigs && customConfigs.length > 0) {
                const config = customConfigs.find(({custId}) => custId == selectCustId);

                if (!config) {
                    //没有配置，创建一份默认配置
                    setCustomConfigs([...customConfigs,
                        {
                            tableCode,
                            custId: selectCustId,
                            tcContent: {
                                striped: false,
                                oddColor: '#fff',
                                evenColor: '#f0f0f0',
                                columns: columns.map(mapToConfig),
                            }
                        }
                    ]);
                } else {
                    setSelectConfig(config);
                }
            }
        })();
    }, [customConfigs, selectCustId]);

    const css = {
        '&:hover': {
            color: '#1890ff',
            cursor: 'pointer',
        }
    }

    // 重新记录数组顺序
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        //删除并记录 删除元素
        const [removed] = selectConfig.tcContent.columns.splice(startIndex, 1);
        //将原来的元素添加进数组
        selectConfig.tcContent.columns.splice(endIndex, 0, removed);

        setCustomConfigs([...customConfigs]);
    }

    const cssDroppable = {
        display: 'flex',
        overflow: 'auto',
        padding: '12px',
        '& > div': {
            minWidth: '100px',
            background: '#fff',
            padding: '12px 12px',
            flexShrink: 0,
            userSelect: "none",
            marginRight: '12px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
        },
        '&:last-child': {
            marginRight: 0
        }
    }

    return (
        <>
            <Tooltip placement="top" title='表格配置'>
                <Text type="secondary" css={css} onClick={openSettingModal}><SettingOutlined/></Text>
                {/*<SettingOutlined style={{fontSize: '18px'}} css={css} onClick={openSettingModal}/>*/}
            </Tooltip>
            <Modal
                title={
                    <Space>
                        <Text>表格配置</Text>
                        {/*<a onClick={handlerReset}>重置</a>*/}
                    </Space>
                }
                size="small"
                centered
                visible={visible}
                // bodyStyle={{height: '600px'}}
                okText="保存并应用"
                onOk={handlerOk}
                cancelText="关闭"
                onCancel={handlerCancel}
                width={'90%'}
                confirmLoading={loading}
            >
                <Spin spinning={loading}>
                    <div style={{overflow: 'hidden'}}>
                        <div style={{display: 'flex'}}>
                            <Text strong>配置适用客户</Text>
                        </div>
                        <Divider style={{margin: '12px 0'}}/>
                        <div style={{display: 'flex', marginBottom: '12px'}}>
                            <Space>
                                {customConfigs && customConfigs.map(({tcId, tableCode, custId, custName, tcContent}) => <Tag onClick={() => handlerTagClick(custId)} closable={custId} color={custId == selectCustId ? 'blue' : null}>{custMap.get(custId)}</Tag>)}


                                <Popover placement="bottom" trigger="click" content={content} visible={custPopVisible}
                                         onVisibleChange={visible => setCustPopVisible(visible)} overlayClassName="popover-overlay-inner-no-padding">
                                    <Tag>
                                        <PlusOutlined/> 新增客户配置
                                    </Tag>
                                </Popover>
                            </Space>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Text strong>表级配置</Text>
                        </div>
                        <Divider style={{margin: '12px 0'}}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginBottom: '12px'}}>
                            <Space>
                                <Checkbox checked={selectConfig.tcContent?.striped} onChange={handlerRowColorChange}>奇偶行</Checkbox>
                                <ColorPicker value={selectConfig.tcContent?.oddColor} onChange={handlerOddRowColorChange} title="奇数行颜色"/>
                                <ColorPicker value={selectConfig.tcContent?.evenColor} onChange={handlerEvenRowColorChange} title="偶数行颜色"/>
                            </Space>
                        </div>

                        <div style={{display: 'flex'}}>
                            <Text strong>列级配置</Text>
                        </div>
                        <Divider style={{margin: '12px 0'}}/>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId={"dpid"} direction="horizontal">
                                {
                                    (provided, snapshot) => (
                                        <div
                                            //provided.droppableProps应用的相同元素.
                                            css={cssDroppable}
                                            style={{
                                                background: snapshot.isDragging ? '#ffccc7' : '#f0f0f0'
                                            }}
                                            {...provided.droppableProps}
                                            // 为了使 droppable 能够正常工作必须 绑定到最高可能的DOM节点中provided.innerRef.
                                            ref={provided.innerRef}
                                        >
                                            {
                                                (selectConfig.tcContent?.columns ?? []).map((item, index) => {
                                                    return (
                                                        <Draggable key={item.key} draggableId={item.key} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    // styles we need to apply on draggables
                                                                    style={{
                                                                        ...provided.draggableProps.style
                                                                    }}
                                                                    // 拖拽的时候背景变化
                                                                    className={`${snapshot.isDragging ? 'shadow' : ''}`}
                                                                >
                                                                    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                                                        <Text strong style={{flexGrow: '1'}}>{item.title}</Text>
                                                                        <Divider style={{margin: '12px 0', flexShrink: 0, flexGrow: 0}}/>
                                                                        {/*<div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>*/}
                                                                        {/*    <Text style={{flexShrink: 0, marginRight: '12px'}}>*/}
                                                                        {/*        宽度*/}
                                                                        {/*    </Text>*/}
                                                                        {/*    <Input size="small" style={{width: 80}} value={item.width} placeholder={item.width ?? '自动'} onChange={(e) => handlerWidthChange(e, item.key)}/>*/}
                                                                        {/*</div>*/}
                                                                        <div style={{display: 'flex', flexGrow: 0, flexShrink: 0, justifyContent: 'space-between', alignItems: 'center'}}>
                                                                            <Checkbox checked={!item.hide} onChange={(e) => handlerHideChange(e, item.key)}>显示</Checkbox>
                                                                            <Checkbox checked={item.fixed} onChange={(e) => handlerFixedChange(e, item.key)}>固定</Checkbox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default Pane;
