/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import Tools from "script/sweet-tools.mjs";
import './print.css'

/**
 * 业务审批详情入口页（嵌入到审批弹窗中使用）
 * @param flowInstance 审批实例对象
 * @param params 入参，提交给 FlowAuditModal 组件的入参都会原样透传过来
 * @param visible 当前弹窗是否打开，布尔型
 * @constructor
 */
const PURMATPCCN = ({pcId}) => {

    // 合同
    const [purPc, setPurPc] = useState({});
    // 明细行
    const [purPcMatRows, setPurPcMatRows] = useState([]);
    
    const [pcQtyTotal, setPcQtyTotal] = useState(0);
    
    const [pcAmtTotal, setPcAmtTotal] = useState(0);
    // 合同二维码
    const [pcQrCode, setPcQrCode] = useState({});
    // 合同签章
    const [signList, setSignList] = useState([]);

    useEffect(() => {
        (async () => {
            if (pcId) {
				init();
            }
        })()
    }, [pcId])

	const init = async () => {
		
        try {
            doQueryQrCode();
            doQuerySign();
        	await Promise.all([doQueryPurPc(), doQueryPurPcMatRow()]).then((data) => {
				
				const purPcForMatPrint = data[0];
				const purPcMatRowsForMatPrint = data[1];
				
				if (purPcForMatPrint) {
                	setPurPc(purPcForMatPrint);
            	}
            	let pcQtyTotals = 0;
            	let pcAmtTotals = 0;
				if (purPcMatRowsForMatPrint) {
					purPcMatRowsForMatPrint.forEach(row => {
						// 小数位控制
						row.pcQty = Number(Number(row.pcQty).toFixed(3)); // 数量
						row.pcPrice = numberFormat(row.pcPrice); // 单价
                        row.pcPriceWithoutTax = numberFormat(row.pcPriceWithoutTax, 2, 2); // 不含税单价
                        row.pcAmt = Number(row.pcAmt).toFixed(2); // 金额
						pcQtyTotals += Number(row.pcQty);
						pcAmtTotals += Number(row.pcAmt);
					})
                	setPurPcMatRows(purPcMatRowsForMatPrint);
                	setPcQtyTotal(Number(pcQtyTotals.toFixed(3)));
                	setPcAmtTotal(pcAmtTotals.toFixed(2));
            	}
			})
        } finally {
					
		}
	}
	
	const numberFormat = (num, toFixedNum=8, minFractionNum=2) => {
		let fraction = String(Number(num)).split(".")[1];
		
		if (!fraction) {
			return Number(num).toFixed(minFractionNum);
		}
		let fractionNum = fraction.length;
	
		if (fractionNum < minFractionNum) {
			return Number(num).toFixed(minFractionNum);
		} else if (fractionNum > toFixedNum) {
			return Number(num).toFixed(toFixedNum);
		} else {
			return String(Number(num));
		}
	}
	
    // 查询合同信息
    const doQueryPurPc = async () => {
        try {
            let data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/purpc/queryPurPcDataForMatPrint", {itemLangType:"CN", pcId:pcId})
                },
                {
                    error: {content: (err) => `查询生产采购合同失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            return data ?? []
            
        } finally {
			
        }
    }

	//查询合同明细
    const doQueryPurPcMatRow = async () => {
        try {
            // 查询合同明细
            const data = await Tools.runAsync(
                async () => {									  
                    return await Tools.http.post("/pur/purpcmatrow/queryPurPcMatRowDataForMatPrint", {itemLangType:"CN", pcId:pcId})
                },
                {
                    error: {content: (err) => `查询物料采购合同明细行失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            )
            
            return data ?? []
        } finally {
			
        }
    }
    
    // 查询合同二维码
    const doQueryQrCode = async () => {
        try {
            let pcQrCodeData = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/pcQrCode", {pcId})
                },
                {
                    error: {content: (err) => `查询合同二维码失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            if (pcQrCodeData) {
                setPcQrCode(pcQrCodeData);
            }
        } finally {
        }
    }

    // 查询合同签章
    const doQuerySign = async () => {
        try {
            let signList = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/listPcSign", {pcId})
                },
                {
                    error: {content: (err) => `查询合同签章失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            if (signList) {
                //let signBlobList = signList.map(r => {
                //    let blob = new Blob([r.empSign], {type: "img/png"});
                //    let objectUrl = URL.createObjectURL(blob);
                //    return {
                //        src: objectUrl
                //    }
                //})
                setSignList(signList);
            }
        } finally {
        }
    }

	
    const printCss = {
        /*@media print {
                    body {zoom: 0.62}
                        .no-print{
                display:none;
            }
        }*/

        "@media print": {
            "body":{zoom: "0.62"}
            //"& .print-container": {
            //    overflow: "hidden"
            //},
            /*"& .order-edit-content":{
                display: "none"
            }*/
        },
        // 去除页眉页脚
        //"@page": {
        //    marginBottom: "0mm",
        //    marginTop: "0mm"
        //},
        /*display: "flex",
        flexDirection: "column",
        width: "1075px",
        margin: "auto",*/
        //height: "100vh",
        //overflow: "auto",
    }


    return (
        <div id={"printContent"} css={printCss} style={{height: '100%'}}>
            <div className={"print-content"}>
                <div className="order-edit-content" style={{width: "1075px"}}>
                    <table width="1075" className="print-table">
                        <thead>
                        <tr>
                            <td>
                                <div style={{paddingTop: "15px"}}>
                                    <div style={{textAlign: "center", fontSize: "28px", fontWeight: "bold"}}>{purPc.coName}</div>
                                    <div style={{
                                        textAlign: "center",
                                        fontSize: "22px",
                                        fontWeight: "bold"
                                    }}>采购合同</div>
                                </div>
                                <div style={{padding: "10px 0px", position: "relative", width: "1075px"}}>
                                    <div style={{position: "absolute", top: "-40px", left: "180px"}}>
                                        {purPc.matQrCode && pcQrCode && <img
                                            title={purPc.matQrCode}
                                            src={pcQrCode}
                                            height="130" width="130"/>}
                                    </div>
                                    <div className="r" style={{width: "320px"}}>
                                        <div>合同编号: {purPc.pcNo}</div>
                                        <div>签约地点: {purPc.signAddr}</div>
                                        <div>签约日期: {purPc.signDate}</div>
                                        <div>&nbsp;</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {/*<tr>
                            <td>
                                <div style={{padding: "10px 0px", fontSize: "16px"}}>
                                    <div className="l" style={{width: "360px"}}>
                                        款号：{purPc.styleNos}
                                    </div>
                                    <div className="l" style={{width: "360px"}}>
                                        订单号：{purPc.soNos}
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>*/}
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px", width: "1075px"}}>
                                    <div className="l"><b>一.标的：</b></div>
                                   <div className="r" style={{width: "240px"}}>原币制: {purPc.currCode}</div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <table className="table-edit"
                                           style={{width: "1074px"}}>
                                       <thead>
	                                        <tr>
	                                            <th style={{width: "40px", fontWeight: "bold"}}>序号</th>
	                                            <th style={{width: "130px", fontWeight: "bold"}}>内部款号</th>
	                                            <th style={{fontWeight: "bold"}}>品名</th>
	                                            <th style={{width: "130px", fontWeight: "bold"}}>规格</th>
	                                            <th style={{width: "110px", fontWeight: "bold"}}>数量</th>
	                                            <th style={{width: "50px", fontWeight: "bold"}}>单位</th>
	                                            <th style={{width: "90px", fontWeight: "bold"}}>含税单价</th>
	                                            <th style={{width: "90px", fontWeight: "bold"}}>不含税单价</th>
	                                            <th style={{width: "60px", fontWeight: "bold"}}>税率%</th>
	                                            <th style={{width: "90px", fontWeight: "bold"}}>含税金额</th>
	                                            <th style={{width: "90px", fontWeight: "bold"}}>交货日期</th>
	                                        </tr>
                                    	</thead>
                                        <tbody>
                                        {purPcMatRows?.map((item, index) => {
                                            return <tr>
                                                <td align="center">{index + 1}</td>
                                                <td>&nbsp; {item.styleInsideNo}</td>
                                                <td>&nbsp; {item.goodsName}</td>
                                                <td>&nbsp; {item.goodsSpec}</td>
                                                <td align="right">&nbsp;{item.pcQty}</td>
                                                <td align="center">&nbsp;{item.uomName}</td>
                                                <td align="right">&nbsp;{item.pcPrice}</td>
                                                <td align="right">&nbsp;{item.pcPriceWithoutTax}</td>
                                                <td align="center">&nbsp;{item.vatRate}</td>
                                                <td align="right">{item.pcAmt}</td>
                                                <td align="center">&nbsp;{item.purDyDate}</td>
                                            </tr>
                                        })}
                                        <tr>
                                            <td align="center">合计</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td align="right">{pcQtyTotal}</td>
                                            <td align="center">&nbsp;</td>
                                            <td align="right">&nbsp;</td>
                                            <td align="right">&nbsp;</td>
                                            <td align="center">&nbsp;</td>
                                            <td align="right">{pcAmtTotal}</td>
                                            <td align="center">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px"}}>
                                    <div className="l" style={{width: "1075px"}}></div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{width: "1075px"}}></div>
                            </td>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <b>二、备注：</b>
                                    <br/>
                                    <div dangerouslySetInnerHTML={{__html: purPc?.pcMemo?.replaceAll("\n", "<br\>")}}/>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <b>三、质量要求</b>
                                    <br/>
                                    <div
                                        dangerouslySetInnerHTML={{__html: purPc?.qcItemMemo?.replaceAll("\n", "<br\>")}}/>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <b>四、付款方式：</b>
                                    <br/>
                                    <div
                                        dangerouslySetInnerHTML={{__html: purPc?.payItemText?.replaceAll("\n", "<br\>")}}/>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <b>五、其他条款：</b>
                                    <br/>
                                    <div dangerouslySetInnerHTML={{__html: purPc?.pcOther?.replaceAll("\n", "<br\>")}}/>
                                </td>
                            </div>
                        </tr>
                        {/*<tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>1、乙方指定联系人： ，职务： ，电话： ，邮箱： ，qq：
                                    <br/>2、
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <td>本补充条款为对前述合同条款的补充，如与前述合同条款存在冲突，双方应友好协商解决，如无法达成一致意见，则以前述条款为准。</td>
                        </tr>*/}
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px", width: "1075px"}}>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <tr>
                                            <td width="100" style={{fontWeight: "bold"}}> 需方(甲方)</td>
                                            <td>&nbsp;</td>
                                            <td width="100" style={{fontWeight: "bold"}}>供方(乙方)</td>
                                            <td width="450">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>单位名称(章)：</td>
                                            <td>&nbsp;{purPc.coName}</td>
                                            <td>单位名称(章)：</td>
                                            <td>&nbsp;{purPc.suppName}</td>
                                        </tr>
                                        <tr>
                                            <td>单位地址:</td>
                                            <td>&nbsp;{purPc.coBizAddr}</td>
                                            <td>单位地址:</td>
                                            <td>&nbsp;{purPc.pnrCoRegAddr}</td>
                                        </tr>
                                        <tr>
                                            <td>电话:</td>
                                            <td>&nbsp;{purPc.coRegPhone}</td>
                                            <td>电话:</td>
                                            <td>&nbsp;{purPc.pnrPhone}</td>
                                        </tr>
                                        <tr>
                                            <td>开户银行:</td>
                                            <td>&nbsp;{purPc.coBankName}</td>
                                            <td>开户银行:</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>帐号：</td>
                                            <td>&nbsp;{purPc.coBankAn}</td>
                                            <td>帐号:</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>纳税人识别号:</td>
                                            <td>&nbsp;{purPc.coUscc}</td>
                                            <td>纳税人识别号:</td>
                                            <td>&nbsp;{purPc.suppCoUscc}</td>
                                        </tr>
                                        <tr>
                                            <td>委托代理人：</td>
                                            <td>&nbsp;</td>
                                            <td>委托代理人：</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </table>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{float: "left",width:'537px'}} >
                                    {
                                        signList.map(sign=>{
                                            return <img
                                                src={sign?.signBase64} height="70"
                                                style={{margin: "5px"}}/>
                                        })
                                    }
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PURMATPCCN;
