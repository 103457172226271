/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import Tools from "script/sweet-tools.mjs";
import './print.css'
import moment from "moment";
import {round} from "lodash";

/**
 * 业务审批详情入口页（嵌入到审批弹窗中使用）
 * @param flowInstance 审批实例对象
 * @param params 入参，提交给 FlowAuditModal 组件的入参都会原样透传过来
 * @param visible 当前弹窗是否打开，布尔型
 * @constructor
 */
const PURMATPCEN = ({pcId}) => {

    // 合同
    const [purPc, setPurPc] = useState({});
    // 明细行
    const [purPcMatRows, setPurPcMatRows] = useState([]);
    
    const [pcQtyTotal, setPcQtyTotal] = useState(0);
    
    const [pcAmtTotal, setPcAmtTotal] = useState(0);
    // 合同二维码
    const [pcQrCode, setPcQrCode] = useState({});
    // 合同签章
    const [signList, setSignList] = useState([]);

    useEffect(() => {
        (async () => {
            if (pcId) {
				init();
            }
        })()
    }, [pcId])

	const init = async () => {
		
        try {
            doQueryQrCode();
            doQuerySign();
        	await Promise.all([doQueryPurPc(), doQueryPurPcMatRow()]).then((data) => {
				
				const purPcForMatPrint = data[0];
				const purPcMatRowsForMatPrint = data[1];
				
				if (purPcForMatPrint) {
                	setPurPc(purPcForMatPrint);
            	}
            	let pcQtyTotals = 0;
            	let pcAmtTotals = 0;
				if (purPcMatRowsForMatPrint) {
					purPcMatRowsForMatPrint.forEach(row => {
						// 小数位控制
						row.pcQty = Number(Number(row.pcQty).toFixed(3)); // 数量
						row.pcPrice = numberFormat(row.pcPrice); // 单价
						row.purPriceTotal = Number(row.purPriceTotal).toFixed(2); // 金额
						pcQtyTotals += Number(row.pcQty);
						pcAmtTotals += Number(row.purPriceTotal);
					})
                	setPurPcMatRows(purPcMatRowsForMatPrint);
                	setPcQtyTotal(Number(pcQtyTotals.toFixed(3)));
                	setPcAmtTotal(pcAmtTotals.toFixed(2));
            	}
			})
        } finally {
					
		}
	}

	const numberFormat = (num, toFixedNum=8, minFractionNum=2) => {
		let fraction = String(Number(num)).split(".")[1];
		
		if (!fraction) {
			return Number(num).toFixed(minFractionNum);
		}
		let fractionNum = fraction.length;
	
		if (fractionNum < minFractionNum) {
			return Number(num).toFixed(minFractionNum);
		} else if (fractionNum > toFixedNum) {
			return Number(num).toFixed(toFixedNum);
		} else {
			return String(Number(num));
		}
	}
	
    // 查询合同信息
    const doQueryPurPc = async () => {
        try {
            let data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/purpc/queryPurPcDataForMatPrint", {itemLangType:"EN", pcId:pcId})
                },
                {
                    error: {content: (err) => `查询生产采购合同失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            
            return data ?? []
            
        } finally {
			
        }
    }

	//查询合同明细
    const doQueryPurPcMatRow = async () => {
        try {
            // 查询合同明细
            const data = await Tools.runAsync(
                async () => {									  
                    return await Tools.http.post("/pur/purpcmatrow/queryPurPcMatRowDataForMatPrint", {itemLangType:"EN", pcId:pcId})
                },
                {
                    error: {content: (err) => `查询物料采购合同明细行失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            )
            
            return data ?? []
        } finally {
			
        }
    }
    
    // 查询合同二维码
    const doQueryQrCode = async () => {
        try {
            let pcQrCodeData = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/pcQrCode", {pcId})
                },
                {
                    error: {content: (err) => `查询合同二维码失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            if (pcQrCodeData) {
                setPcQrCode(pcQrCodeData);
            }
        } finally {
        }
    }

    // 查询合同签章
    const doQuerySign = async () => {
        try {
            let signList = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/pur/procpurpc/listPcSign", {pcId})
                },
                {
                    error: {content: (err) => `查询合同签章失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            if (signList) {
                //let signBlobList = signList.map(r => {
                //    let blob = new Blob([r.empSign], {type: "img/png"});
                //    let objectUrl = URL.createObjectURL(blob);
                //    return {
                //        src: objectUrl
                //    }
                //})
                setSignList(signList);
            }
        } finally {
        }
    }

    const printCss = {
        /*@media print {
                    body {zoom: 0.62}
                        .no-print{
                display:none;
            }
        }*/

        "@media print": {
            "body":{zoom: "0.62"}
            //"& .print-container": {
            //    overflow: "hidden"
            //},
            /*"& .order-edit-content":{
                display: "none"
            }*/
        },
        // 去除页眉页脚
        //"@page": {
        //    marginBottom: "0mm",
        //    marginTop: "0mm"
        //},
        /*display: "flex",
        flexDirection: "column",
        width: "1075px",
        margin: "auto",*/
        //height: "100vh",
        //overflow: "auto",
    }


    return (
        <div id={"printContent"} css={printCss} style={{height: '100%'}}>
            <div className={"print-content"}>
                <div className="order-edit-content" style={{width: "1075px"}}>
                    <table width="1075" className="print-table">
                        <thead>
                        <tr>
                            <td>
                                <div style={{paddingTop: "15px", position: "relative"}} >
                                    <div style={{position: "absolute", top: "40px", left: "20px"}}>
                                        {purPc.matQrCode && pcQrCode && <img
                                            title={purPc.pcQrcode}
                                            src={pcQrCode}
                                            height="130" width="130"/>}
                                    </div>
                                    <div style={{
                                        textAlign: "center",
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                        height: "150px",
                                        lineHeight: "150px"
                                    }}>PURCHASE ORDER</div>
                                </div>
                                <div style={{padding: "10px 0px", position: "relative", width: "1075px"}}>
                                    <div className="l" style={{width: "450px"}}>
                                    	<table>
                                    	<tr><td width="90px">Date:</td><td>{purPc.signDate}</td></tr>
                                    	<tr><td width="90px">Buyer:</td><td>{purPc.coName}</td></tr>
                                    	<tr><td width="90px">Add:</td><td>{purPc.coBizAddr}</td></tr></table>
                                    </div>
                                    <div className="r" style={{width: "450px"}}>
                                    	<table>
                                    	<tr><td width="90px">Contract no:</td><td>{purPc.pcNo}</td></tr>
                                    	<tr><td width="90px">Seller:</td><td>{purPc.suppName}</td></tr>
                                    	<tr><td width="90px">Add:</td><td>{purPc.pnrCoRegAddr}</td></tr></table>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>The BUYER and the SUPPLIER above named have entered into this Contract the following terms and conditions.</tr>
                        <tr>&nbsp;</tr>
                        </thead>
                        <tbody>
                        {/*<tr>
                            <td>
                                <div style={{padding: "10px 0px", fontSize: "16px"}}>
                                    <div className="l" style={{width: "360px"}}>
                                        款号：{purPc.styleNos}
                                    </div>
                                    <div className="l" style={{width: "360px"}}>
                                        订单号：{purPc.soNos}
                                    </div>
                                    <div className="l">
                                        订单流水号：DWP05XE2211001
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>*/}
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px", width: "1075px"}}>
                                    <div className="l" style={{fontWeight:"bold"}}>I.Commodity:</div>
                                    <div className="r" style={{width: "290px"}}>Currency:{purPc.currCode}</div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <table className="table-edit"
                                           style={{width: "1074px"}}>
                                       <thead>
	                                        <tr>
	                                            <th style={{width: "40px"}}>Item</th>
	                                            <th style={{width: "150px"}}>Style</th>
	                                            <th>Name</th>
	                                            <th style={{width: "220px"}}>Description</th>
	                                            <th style={{width: "60px"}}>Unit</th>
	                                            <th style={{width: "100px"}}>Qty</th>
	                                            <th style={{width: "100px"}}>Unit price</th>
	                                            <th style={{width: "110px"}}>Amount</th>
	                                            <th style={{width: "110px"}}>Del date</th>
	                                        </tr>
                                        </thead>
                                        <tbody>
                                        {purPcMatRows?.map((item, index) => {
                                            return <tr>
                                                <td align="center">{index + 1}</td>
                                                <td>&nbsp; {item.styleInsideNo}</td>
                                                <td>&nbsp; {item.goodsName}</td>
                                                <td>&nbsp; {item.goodsSpec}</td>
                                                <td align="right">&nbsp;{item.uomName}</td>
                                                <td align="right">&nbsp;{item.pcQty}</td>
                                                <td align="right">&nbsp;{item.pcPrice}</td>
                                                <td align="right">{item.purPriceTotal}</td>
                                                <td align="center">&nbsp;{item.purDyDate}</td>
                                            </tr>
                                        })}
                                        <tr>
                                            <td align="center" colSpan={"4"}>Total</td>
                                            <td>&nbsp;</td>
                                            <td align="right">{pcQtyTotal}</td>
                                            <td align="right">&nbsp;</td>
                                            <td align="right">{pcAmtTotal}</td>
                                            <td align="center">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px"}}>
                                    <div className="l" style={{width: "1075px"}}></div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{width: "1075px"}}></div>
                            </td>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <div style={{fontWeight:"bold"}}>II. Remark:</div>
                                    <div dangerouslySetInnerHTML={{__html: purPc?.pcMemo?.replaceAll("\n", "<br\>")}}/>
                                    <br/>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <div style={{fontWeight:"bold"}}>III. Quality requirement:</div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: purPc?.qcItemMemo?.replaceAll("\n", "<br\>")}}/>
                                	<br/>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <div style={{fontWeight:"bold"}}>IV. Payment terms：</div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: purPc?.payItemText?.replaceAll("\n", "<br\>")}}/>
                                	<br/>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <div style={{width: "1075px"}}>
                                <td style={{wordBreak: "break-all"}}>
                                    <div style={{fontWeight:"bold"}}>V. Other clauses：</div>
                                    <div dangerouslySetInnerHTML={{__html: purPc?.pcOther?.replaceAll("\n", "<br\>")}}/>
                                	<br/>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px", width: "1075px"}}>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <tr>
                                            <td width="100" style={{fontSize: "18px", fontWeight: "bold"}}>Buyer</td>
                                            <td>&nbsp;</td>
                                            <td width="100" style={{fontSize: "18px", fontWeight: "bold"}}>Supplier</td>
                                            <td width="450">&nbsp;</td>
                                        </tr>
                                    </table>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{float: "left",width:'537px'}} >
                                    {
                                        signList.map(sign=>{
                                            return <img
                                                src={sign?.signBase64} height="70"
                                                style={{margin: "5px"}}/>
                                        })
                                    }
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PURMATPCEN;
