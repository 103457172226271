import {Modal} from "antd";
import useGlobalData from "../../../../../hooks/useGlobalData.mjs";

// 校验预算数据
export const budgetValid = (so) =>{

    const soType = so?.soTypeCode; // 外贸单款：FSS  外贸多款：FMS    内贸单款：DSS  内贸多款：DMS
    const isForeign = ['FSS', 'FMS', 'FMM'].includes(soType); // 外贸

    if (!so?.soModeCode) {
        Modal.error({content: '请先在首页界面维护订单模式！'});
        return false;
    }

    if (so?.poDVer == 0 && isForeign) {
        Modal.error({content: '请先提交PO明细！'});
        return false;
    }

    if (so?.poDVer == 0 && !isForeign) {
        Modal.error({content: '请先提交订单明细！'});
        return false;
    }

    return true;

}

// 校验接单信息
export const roValid = (so) =>{

    if (!so?.soModeCode) {
        Modal.error({content: '请先在首页界面维护订单模式！'});
        return false;
    }

    return true;

}

// 校验订单
export const smSoValid = (so) =>{

    if (!so?.soModeCode) {
        Modal.error({content: '请先在首页界面维护订单模式！'});
        return false;
    }

    return true;

}

export const frameName = (soTypeCode) => {

    switch (soTypeCode) {
        case 'FSS':
            return "外贸单款销售订单"
        case 'FMS':
            return "外贸多款销售订单"
        case 'FMM':
            return "外贸面辅料销售订单"
        case 'FO':
            return "外贸其他销售订单"
        case 'DSS':
            return "内贸单款销售订单"
        case 'DMS':
            return "内贸多款销售订单"
        case 'DMM':
            return "内贸面辅料销售订单"
        case 'DO':
            return "内贸其他销售订单"
    }

}


export const getGroup = (data, key) => {
    let groups = {};
    data.forEach(c => {
        let value = c[key];
        groups[value] = groups[value] || [];
        groups[value].push(c);
    });
    return groups;
}

// 获取页签标题
export const tabTitle = (soStyle) => {
    const {suiteFlag = false, styleNo, suiteSeq} = soStyle

    if (!soStyle) return

    if (suiteFlag) {
        return `${styleNo}[${suiteSeq}]`
    } else {
        return styleNo;
    }

}

export const dictSoAttribute = (soAttribute)=>{
    return useGlobalData.getDictDtlCn("SO_ATTRIBUTE" , soAttribute);
}

export const dictSoBomType = (soAttribute)=>{

    if(soAttribute == 'SSPSO'){
        return useGlobalData.getDictDtlCn("SPB_TYPE" , 'SSPBOM');
    }
    if (soAttribute == 'BPCSO'){
        return useGlobalData.getDictDtlCn("SPB_TYPE" , 'BPCBOM');
    }

}

export const dictSoBomTypeCode = (soAttribute)=>{

    if(soAttribute == 'SSPSO'){
        return 'SSPBOM';
    }
    if (soAttribute == 'BPCSO'){
        return 'BPCBOM';
    }

}