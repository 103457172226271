
const toGroup = (data, key) => {
    let groups = {};
    data.forEach(c => {
        let value = c[key];
        if (value) {
            groups[value] = groups[value] || [];
            groups[value].push(c);
        }
    });
    return groups;
}

export default toGroup;
