/** @jsxImportSource @emotion/react */

import {Tooltip} from 'antd'
import ContextMenu from 'components/ContextMenu/ContextMenu.jsx'
import {useMainTabsContext} from './contexts.mjs'

const menuItemClose = ({mainTabs, tabKey}) => {
    const tab = mainTabs.tabs.find((e) => e.key === tabKey)

    const handleClick = async () => {
        await mainTabs.dispatchEvent('close-tab', tabKey)
        mainTabs.closeTab(tabKey)
    }

    return {
        disabled: ! tab.closable,
        label: '关闭',
        key: 'close',
        onClick: handleClick,
    }
}

const menuItemCloseAll = ({mainTabs}) => {
    const handleClick = () => {
        for (const {key} of mainTabs.tabs) {
            (async () => {
                await mainTabs.dispatchEvent('close-tab', key)
                mainTabs.closeTab(key)
            })()
        }
    }

    return {
        label: '关闭全部标签页',
        key: 'close-all',
        onClick: handleClick,
    }
}

const menuItemCloseOthers = ({mainTabs, tabKey}) => {
    const handleClick = () => {
        for (const {key} of mainTabs.tabs) {
            if (key !== tabKey) {
                (async () => {
                    await mainTabs.dispatchEvent('close-tab', key)
                    mainTabs.closeTab(key)
                })()
            }
        }
    }

    return {
        label: '关闭其他标签页',
        key: 'close-others',
        onClick: handleClick,
    }
}

const menuItemPin = ({mainTabs, tabKey}) => {
    const tab = mainTabs.tabs.find((e) => e.key === tabKey)

    const handleClick = () => {
        mainTabs.configTab(tabKey, {closable: ! tab.closable})
    }

    return {
        label: tab.closable ? '锁定' : '解除锁定',
        key: 'pin',
        onClick: handleClick,
    }
}

const menuItemReload = ({mainTabs, tabKey}) => {
    const handleClick = async () => {
        await mainTabs.dispatchEvent('close-tab', tabKey)
        const tab = mainTabs.tabs.find((e) => e.key === tabKey)
        const [comPath, comProps] = tab.component

        mainTabs.configTab(tabKey, {
            component: [comPath, {...comProps}]
        })
    }

    return {
        label: '重新加载',
        key: 'reload',
        onClick: handleClick,
    }
}

const LabelContextMenu = ({mainTabs, tabKey, ...props}) => {
    const items = [
        menuItemReload({mainTabs, tabKey}),
        menuItemPin({mainTabs, tabKey}),
        {type: 'divider'},
        menuItemClose({mainTabs, tabKey}),
        menuItemCloseAll({mainTabs}),
        menuItemCloseOthers({mainTabs, tabKey}),
    ]

    return (
        <ContextMenu
            items={items}
            {...props}
        />
    )
}

const TabLabel = ({closable, name, tabKey, title, ...props}) => {
    const mainTabs = useMainTabsContext()

    const style = {
        padding: closable ? '8px 0 8px 16px' : '8px 16px',
    }

    const label = (
        <div
            style={style}
            {...props}
        >{name}</div>
    )

    return (
        <LabelContextMenu
            mainTabs={mainTabs}
            tabKey={tabKey}
        >
            {
                title ?
                    <Tooltip
                        color="#108ee9"
                        title={title}
                    >
                        {label}
                    </Tooltip>
                    :
                    label
            }
        </LabelContextMenu>
    )
}

export default TabLabel
