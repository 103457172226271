/** @jsxImportSource @emotion/react */

import Input from 'components/Form/InputText.jsx'
import {DEFAULT_CHANGE_DELAY} from '../config.mjs'

const InputText = ({
    changeDelay = DEFAULT_CHANGE_DELAY,
    ...props
}) => {
    return (
        <Input
            bordered={false}
            changeDelay={changeDelay}
            {...props}
        />
    )
}

export default InputText
