/** @jsxImportSource @emotion/react */
import roundUp from "../../script/roundUp.mjs";

;
import { message, Input, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import {declPlaceTemStr,textTemStr,forNumberJ,forNumberD} from "pages/BizPage/COMM/MathComm.mjs";
/**
 * 只读输入框组件
 * @param copy 开启复制剪贴板功能
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Pane = ({ multiline = false, copy = false,forStrMatRD=-1, ...props }) => {
    const onCopyed = (values) => {
        message.success("复制成功")
    }
    let _props = { ...props }
    _props.value=forStrMatRD>0? roundUp(props.value,forStrMatRD):props.value;

    if (copy) {
        _props = {
            suffix: (
                <Tooltip title="复制">
                    <CopyToClipboard text={props.value} onCopy={onCopyed}>
                        <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </CopyToClipboard>
                </Tooltip>
            ),
            ..._props
        }
    }

    const css = {
        border: 'none',
        backgroundColor: '#F7F7F7',

        'input, textarea': {
            backgroundColor: 'inherit',

            '&:hover': {
                borderColor: '#d9d9d9'
            }
        }
    }

    // borderBottom: '1px solid #d9d9d9' ,
    return (
        multiline ?
            <Input.TextArea size="small" readOnly css={css}  {..._props}/>
                :
                <Input size="small" readOnly css={css}  {..._props}  />

    )
}

export default Pane;
