/** @jsxImportSource @emotion/react */
import {Button} from "antd";
import React, {useContext, useState} from 'react';
import Modal, {useOpenModal} from "../../../../components/Modal/Modal";
import {EditContext} from "../../DNG/DNG10_DT_DM_STYLE_DGN_MAT/Edit";
import {useMainTabContext} from "../../../../components/MainTabs/contexts.mjs";
import {createBeSo} from "../../SM/SM30_DT_SM_CSO_LIST/Valid/soApi.mjs";
import {dictSoAttribute} from "../../SM/SM30_DT_SM_CSO_LIST/Valid/SoValid";
import useGlobalData from "../../../../hooks/useGlobalData.mjs";

const ButtonCreateBeSo = ({record, onCall, ...props}) => {

    const openModal = useOpenModal()
    const {isEditing, setIsEditing} = useContext(EditContext)
    const [loading, setLoading] = useState(false)
    const mainTab = useMainTabContext()


    // 进入详情页
    const handleRow = (record) => {

        const isForeign = ['FSS', 'FMS', 'FMM', 'FO'].includes(record?.soTypeCode);
        const isMore = ['FMS', 'DMS', 'FMM', 'DMM'].includes(record?.soTypeCode);
        mainTab.openTab({
            name: record.soNo,
            component: [
                'Frames/SMCSODtlFrame/CSODtlFrame',
                {record: record, initMainQry: null, smSoType: record?.smSoType}
            ],
            title: isMore ?
                `${dictSoAttribute(record?.soAttribute)}【 ${record.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", record.soStsCode)}] 款数 ${record.styleNos != null ? record.styleNos.split(",").length : ""}`
                : `${dictSoAttribute(record?.soAttribute)}【 ${record.soNo}】 状态 [${useGlobalData.getDictDtlCn("SO_STS", record.soStsCode)}] 款号${record.styleNos}`
        });

    };


    const handleClick = async () => {

        Modal.confirm({
            title: '是否确定生成关联订单？',
            okText: '是',
            cancelText: '否',
            type: 'danger',
            loading: loading,

            onOk: async () => {

                try {
                    setLoading(true)
                    const result = await createBeSo({
                        soId: record?.soId
                    });

                    await handleRow(result.so);

                } catch (err) {
                    console.log(err);
                } finally {
                    setLoading(false)
                }


                // onCall();
            }
        })

    }

    return (
        <Button
            //disabled={!hasPermissionEdit}
            //ghost
            size="small"
            type="primary"
            onClick={handleClick}
            {...props}
        />
    )

}

export default ButtonCreateBeSo