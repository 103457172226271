/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Divider, Spin, Space, Typography, Modal,} from 'antd';
import Tools from "script/sweet-tools.mjs";
import {createHashRouter, RouterProvider, Navigate, useSearchParams, useNavigate} from 'react-router-dom';
import md5 from 'crypto-js/md5';

const {Text} = Typography;

const Pane = () => {
    const [loading, setLoading] = useState(false);
    const [spinTip, setSpinTip] = useState('')
    const [form] = Form.useForm();
    const navigate = useNavigate();
    //当前用户
    const [user, setUser] = useState(Tools.authenticator.getUser());
    const rootCss = {
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100wh',
        height: '100vh',
        background: '#f0f0f0'
    }

    const cssFormContainer = {
        background: 'white',
        padding: 12,
        width: '600px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 1px 2px -2px rgba(0, 0, 0, 0.16),0px 3px 6px 0px rgba(0, 0, 0, 0.12),0px 5px 12px 4px rgba(0, 0, 0, 0.09)'
    }

    const cssTitle = {
        // fontSize: 16,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const doSubmit = async () => {
        form.validateFields().then(async ({userPwd,userPwd2}) => {
            setSpinTip('修改密码中...')
            setLoading(true);
            try {

                if (!Tools.authenticator.checkStrong(userPwd)) {
                    Modal.info({title: '提示信息', content: '密码长度至少8位，密码必须同时包含数字、大写字母、小写字母、特殊字符(!@#$%^&*?)'});
                    return;
                }

                if (userPwd && userPwd2 && userPwd != userPwd2) {
                    Modal.info({title: '提示信息', content: '两次密码输入不一致'});
                    return;
                }



                const userPwdSalt = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/bas/auth/dopweb/login/userpwdsalt", {userNo:user.userNo});
                    },
                    {
                        error: {
                            content: (err) => `获取用户信息失败: ${err.message}`
                        },
                        loading: {
                            show: false,
                        },
                        success: {
                            show: false,
                        },
                    }
                )
                let password = md5(userPwd + userPwdSalt).toString();
                const data = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/bas/sysUser/modifyPwd", {userPwd:password})
                    },
                    {
                        error: {content: (err) => `修改密码失败: ${err.message}`},
                        loading: {show: false},
                        success: {show: false},
                    }
                );
                setSpinTip('刷新缓存中...')
                //刷新用户缓存
                const {tokenInfo: {token, refreshToken}} = Tools.authenticator.get();
                const newSession = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post("/bas/auth/dopweb/login/refreshToken", {token, refreshToken})
                    },
                    {
                        error: {content: (err) => `刷新缓存失败: ${err.message}`},
                        loading: {show: false},
                        success: {show: true},
                    }
                );
                Tools.authenticator.set(newSession);

                navigate("/", {replace: true});
            } finally {
                setLoading(false)
            }
        })
    }

    const validPwd = ()=>{
        let userPwd = form.getFieldValue('userPwd');
        let userPwd2 = form.getFieldValue('userPwd2');
        if (userPwd && userPwd2 && userPwd != userPwd2) {
            Modal.info({title: '提示信息', content: '两次密码输入不一致'});
            return;
        }
    }

    return (
        <div css={rootCss}>
            <div css={cssFormContainer}>
                <div css={cssTitle}>
                    <Text strong type="danger">请修改初始密码</Text>
                </div>
                <Divider style={{margin: '12px 0'}}/>
                <Spin spinning={loading} tip={spinTip}>
                    <Form form={form} layout="vertical" preserve={false}>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name='userPwd'
                                    label={
                                        <Space>
                                            <Text>新密码</Text>
                                            <Text type="secondary" style={{fontSize: 10}}>密码长度至少8位，密码必须同时包含数字、大写字母、小写字母、特殊字符(!@#$%^&*?)</Text>
                                        </Space>
                                    }
                                    rules={[{required: true, message: '请输入新密码!',}]}>
                                    <Input.Password onBlur={validPwd}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='userPwd2' label='确认密码' rules={[{required: true, message: '请确认密码!',}]}>
                                    <Input.Password onBlur={validPwd}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button type='primary' block onClick={doSubmit}>确认修改</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </div>
        </div>
    )

}

export default Pane;
