/** @jsxImportSource @emotion/react */

import {
    createContext,
    Fragment,
    useCallback,
    useState,
} from 'react'

import throttle from 'raf-throttle'

export const DraggableManagerContext = createContext()

const DraggableManager = ({children}) => {
    const [drag, setDrag] = useState(null)

    const startDrag = useCallback(
        (key, clientX, clientY) => {
            const timestamp = Date.now()

            setDrag({
                deltaTime: 0,
                deltaX: 0,
                deltaY: 0,
                initialTimestamp: timestamp,
                initialX: clientX,
                initialY: clientY,
                key,
                offsetX: 0,
                offsetY: 0,
                timestamp,
                x: clientX,
                y: clientY,
            })
        },

        []
    )

    const context = {startDrag, ...drag}

    const handleMouseMove = throttle((e) => {
        const {clientX, clientY} = e
        const timestamp = Date.now()

        setDrag((drag) => ({
            ...drag,
            deltaTime: timestamp - drag.timestamp,
            deltaX: clientX - drag.x,
            deltaY: clientY - drag.y,
            offsetX: clientX - drag.initialX,
            offsetY: clientY - drag.initialY,
            timestamp,
            x: clientX,
            y: clientY,
        }))
    })

    const handleMouseUp = () => {
        setDrag(({key}) => ({key}))
    }

    const cssDrag = {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0,
        zIndex: 10000,
        pointerEvents: drag?.x ? 'unset' : 'none',
    }

    return (
        <Fragment>
            <DraggableManagerContext.Provider value={context}>
                {children}
            </DraggableManagerContext.Provider>

            <div
                css={cssDrag}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            ></div>
        </Fragment>
    )
}

export default DraggableManager
