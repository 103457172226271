/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {Spin, Typography, Form, Tabs, Input, Button, Row, Col, Space} from 'antd';
import {
    FlagOutlined,
    QuestionCircleOutlined,
    MobileOutlined
} from '@ant-design/icons';
import favicon from 'public/favicon.svg';
import banner from 'public/login_banner.png'
import backgroundIcon from 'public/login_icon.png'
import backgroundImg from 'public/login_bg.png';
import Tools from "script/sweet-tools.mjs";
// import useDOM from "hooks/useDOM.mjs";

const Pane = (props) => {
    const {Text, Link} = Typography;
    const {TabPane} = Tabs;

    const location = useLocation();
    const navigate = useNavigate();

    //Loading 控制变量
    const [loadingSend, setLoadingSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const countDownRef = useRef()//设置延时器
    const [form] = Form.useForm();

    const [searchParams, setSearchParams] = useSearchParams();

    //倒计时
    useEffect(() => {
        //如果设置倒计时且倒计时不为0
        if (counter && counter !== 0) {
            countDownRef.current = setTimeout(() => {
                setCounter(counter => counter - 1)
            }, 1000)
        }
        //清楚延时器
        return () => {
            clearTimeout(countDownRef.current)
        }
    }, [counter])

    const handleSendCode = async () => {
        form.validateFields(['mobile']).then(async (values) => {
            setLoadingSend(true)
            try {
                await Tools.runAsync(
                    async () => {
                        return await Tools.http.post(`/bas/auth/dopweb/login/guest/mobile/sendMessage`, {...values});
                    },
                    {
                        error: {
                            content: (err) => err.message,
                        },

                        loading: {
                            show: false,
                        },

                        success: {
                            show: true,
                        },
                    }
                );
            } finally {
                setLoadingSend(false)
            }
            setCounter(60);
        })
    }

    //真实登录
    const handlerLogin = async () => {
        form.validateFields().then(async (values) => {
            setLoading(true);
            try {
                const loginSession = await Tools.runAsync(
                    async () => {
                        return await Tools.http.post(`/bas/auth/dopweb/login/guest/mobile`, {...values});
                    },
                    {
                        error: {
                            content: (err) => err.message,
                        },

                        loading: {
                            show: false,
                        },

                        success: {
                            show: false,
                        },
                    }
                );

                setLoading(false);
                Tools.authenticator.set(loginSession);
                navigate(location.pathname + location.search, {replace: true});
            } catch (e) {
                console.log(e)
                setLoading(false);
            }
        })
    }

    //组件挂载执行一次
    useEffect(() => {
        (async () => {
            // useDOM.setTitle({
            //     prefix: "供应商登录"
            // })

            const urlMobile = searchParams.get("mobile")

            form.setFieldsValue({'mobile': urlMobile});
        })();
    }, [])

    const rootCss = {
        display: 'flex',
        flexDirection: 'column',
        width: '100wh',
        height: '100vh',
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }

    const topbarCss = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 1px 2px -2px rgba(0, 0, 0, 0.16),0px 3px 6px 0px rgba(0, 0, 0, 0.12),0px 5px 12px 4px rgba(0, 0, 0, 0.09)',
        backgroundColor: 'rgb(255,255,255,0.9)',

        '& .topbar-title': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            '& img': {
                height: 32,
                margin: '6px 12px'
            }
        },

        '& .topbar-buttons': {
            margin: '0 12px',
            '& .contributor': {
                color: '#C7000B',
                '&:hover': {
                    color: '#C7000B'
                }
            },
            '& .link-mobile': {
                color: 'black',
                '&:hover': {
                    color: '#52c41a'
                }
            },
            '& .help-doc': {
                color: 'black',
                '&:hover': {
                    color: '#1890ff'
                }
            },
        },
    }

    const containerCss = {
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'center',
        alignItems: 'center',

        '& .icon': {
            width: '720px',
            marginRight: '64px'
        },
        '& .login-panel': {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            background: 'white',
            border: '1px solid #f0f0f0',
            borderRadius: '4px',
            boxShadow: '0px 1px 2px -2px rgba(0, 0, 0, 0.16),0px 3px 6px 0px rgba(0, 0, 0, 0.12),0px 5px 12px 4px rgba(0, 0, 0, 0.09)',
            '& .logo': {
                display: 'flex',
                justifyContent: 'center',
                padding: '24px 24px 0 24px',
                '& img': {width: '120px'},
            },
            '& .user-panel': {padding: '8px 24px 24px 24px', width: '360px'},
            '& .qr-panel': {padding: '8px 24px 24px 24px', width: '360px', height: '232px'},
        }
    }

    return (
        <div css={rootCss}>
            <div css={topbarCss}>
                <div className="topbar-title">
                    <img
                        alt="logo"
                        src={favicon}
                    />
                    数字化运营平台
                </div>
                <Space className="topbar-buttons">
                    <a className="contributor"><FlagOutlined/> 贡献者名单</a>
                    {/*<a className="link-mobile"><WechatOutlined/> 访问小程序</a>*/}
                    <a className="help-doc"><QuestionCircleOutlined/> 帮助文档</a>
                </Space>
            </div>
            <div css={containerCss}>
                <img src={backgroundIcon} className="icon"/>
                <div className="login-panel">
                    <div className="logo">
                        <img src={banner}/>
                    </div>
                    <Tabs defaultActiveKey="0" centered>
                        <TabPane tab="供应商登录" key="1" className="user-panel">
                            <Spin spinning={loading}>
                                <Form
                                    form={form}
                                    name="normal_login"
                                    initialValues={{remember: true}}
                                >
                                    <Form.Item
                                        name="mobile"
                                        rules={[{required: true, message: '请输入手机号!'}]}
                                    >
                                        <Input prefix={<MobileOutlined/>} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Row gutter={8}>
                                            <Col span={16}>
                                                <Form.Item
                                                    name="code"
                                                    noStyle
                                                    rules={[{required: true, message: '请输入获取的验证码!'}]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Button block onClick={handleSendCode} disabled={counter !== 0} loading={loadingSend}>
                                                    {counter !== 0 ? counter + '秒' : '获取验证码'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item noStyle>
                                        <Button type="primary" style={{width: '100%'}} onClick={handlerLogin}>
                                            快速登录
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Spin>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>

    )
}

export default Pane;
