/** @jsxImportSource @emotion/react */
import DraggableModal from "components/DraggableModal/DraggableModal";
import {useEffect, useState} from 'react';
import {Col, Form, Input, Row, Tooltip} from 'antd';
import Table from 'components/Bizs/Table/Table.jsx';
import Tools from "script/sweet-tools.mjs";
import SearchForm from "components/SearchForm/SearchForm.jsx";

/**
 * 弹出式唛头选择组件
 * @param extra 自定义扩展属性
 * @param props Modal 组件原生属性
 * @returns {JSX.Element}
 * @constructor
 */
const Pane = ({record, visible, onOk, onCancel}) => {
    //表单对象
    const [form] = Form.useForm();
    const tableInstance = {}
    //表格数据定义
    const [data, setData] = useState([]);

    //表格 Loading 控制变量
    const [loading, setLoading] = useState(false);
    const [selectedKeys, setSelectKeys] = useState([]);
    const [selectedRows, setSelectRows] = useState([]);

    const _props = {
        width: '90%',
    }

    const _onOk = (e) => {
        onOk(selectedRows);
    };

    const _onCancel = (e) => {
        onCancel();
    };

    //开启多选
    const rowSelection = {
        onChange: (keys, rows) => {
            setSelectKeys(keys);
            setSelectRows(rows)
        },
    }

    const onRow = (record) => {
        return {
            /*onDoubleClick: event => {
                onOk([{...record}]);
            },*/
        };
    }

    //表格列头定义
    const columns = [
        {
            title: '通知单',
            dataIndex: 'psnNo',
            width: 130,
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
            filter: <Table.FilterText/>
        },
        {
            title: '唛头序号',
            dataIndex: 'bosMarkHead',
            key: 'bosMarkHead',
            width: 110,
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
            filter: <Table.FilterText/>
        },
        {
            title: '唛头内容',
            dataIndex: 'bosMarkCont',
            key: 'bosMarkCont',
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    {text}
                </Tooltip>
            ),
            filter: <Table.FilterText/>
        },
    ];

    //查询
    const doQuery = async () => {
        setLoading(true)
        try {
            const params = {
                ...form.getFieldsValue(),
                bosId: record.bosId,
                billTypeCode: record.billTypeCode
            }
            const data = await Tools.runAsync(
                async () => {
                    return await Tools.http.post("/dlv/bosmark/listDlvPsnMarkDRs", params)
                },
                {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                }
            );
            if (data) {
                setData(data ?? []);
            }
            tableInstance?.reset?.()
        } finally {
            setLoading(false)
        }
    }
    //查询数据
    useEffect(() => {
        if (visible) {
            doQuery();
        } else {
            form.resetFields();
        }
    }, [visible]);


    return (
        <DraggableModal
            bodyStyle={{
                padding: '0',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                height: '600px',
            }}
            width="60%"
            title="从出运单复制唛头" visible={visible} onOk={_onOk}
            onCancel={_onCancel} destroyOnClose={true}>
            <SearchForm form={form} onFinish={doQuery}>
                <Row gutter={12}>
                    <Col span={6}>
                        {/*<Form.Item name="bosNo" label="出运单">*/}
                        {/*    <BosNoAutoComplete searchParam={{billTypeCode:record.billTypeCode}}/>*/}
                        {/*</Form.Item>*/}

                        <Form.Item name="psnNo" label="通知单">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </SearchForm>
            <div className="components-table-container">
                <Table table={tableInstance}
                       columns={columns} dataSource={data} size="small"
                       loading={loading}
                       rowKey="bosMarkId"
                       bordered
                       onRow={onRow}
                       rowSelection={{
                           type: 'checkbox',
                           ...rowSelection,
                       }}
                />
            </div>

        </DraggableModal>
    )
}

export default Pane;