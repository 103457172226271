/** @jsxImportSource @emotion/react */

import {Input} from 'antd'
import DatePicker from 'components/Form/DatePicker.jsx'

const DateRangePicker = ({
    allowClear,
    placeholderMax = '结束',
    placeholderMin = '开始',
    size,
    value: [min, max] = [],
    onChange,
    ...props
}) => {
    const handleChangeMax = (value) => {
        onChange([min, value])
    }

    const handleChangeMin = (value) => {
        onChange([value, max])
    }

    const css = {
        display: 'flex !important',
    }

    return (
        <Input.Group
            css={css}
            compact
            {...props}
        >
            <DatePicker
                allowClear={allowClear}
                placeholder={placeholderMin}
                size={size}
                value={min}
                onChange={handleChangeMin}
            />

            <DatePicker
                allowClear={allowClear}
                placeholder={placeholderMax}
                size={size}
                value={max}
                onChange={handleChangeMax}
            />
        </Input.Group>
    )
}

export default DateRangePicker
