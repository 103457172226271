/** @jsxImportSource @emotion/react */
;
import {useEffect, useState} from 'react';
import {Spin} from 'antd';
import TabRoute from "TabRoute.mjs";

const Pane = ({router, searchParams}) => {
    const [pane, setPane] = useState(null);
    //组件挂载执行一次
    useEffect(() => {
        (async () => {
            if (router) {
                const Pane = await TabRoute.loadModule(router);
                setPane(<Pane searchParams={searchParams}/>)
            }
        })();
    }, [router])

    return (
        <>
            {pane && pane}
            {
                !pane
                &&
                <div style={{width: '100wh', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="bg-grey">
                    <Spin tip="努力加载页面中..."/>
                </div>
            }
        </>
    )
}

export default Pane;
