import http from 'script/http.mjs'
import runAsync from 'script/runAsync.mjs'

export const soOne = ({soId}) => runAsync(
    () => http.post('/sm/so/oneSo', {soId}),

    {
        error: {
            content: (err) => `读取销售订单数据失败: ${err.message}`,
        },

        loading: {
            content: '正在读取销售订单数据，请稍候',
            show: false
        },

        success: {
            content: () => '销售订单数据成功',
            show: false,
        }
    }
)

// 采购需求单
export const onePurReq = ({prId}) => runAsync(
    () => http.post('/pur/purreq/onePurPurReqDR', {prId}),

    {
        error: {
            content: (err) => `读取采购单数据失败: ${err.message}`,
        },

        loading: {
            content: '正在读取采购单信息，请稍候',
            show: false
        },

        success: {
            content: () => '读取采购单信息数据成功',
            show: false,
        }
    }
)

// 查询物料采购合同生产合同
export const onePurPc = ({pcId}) => runAsync(
    () => http.post('/pur/purpc/onePurPcDR', {pcId}),

    {
        error: {
            content: (err) => `读取合同数据失败: ${err.message}`,
        },

        loading: {
            content: '正在读取合同单信息，请稍候',
            show: false
        },

        success: {
            content: () => '读取合同信息数据成功',
            show: false,
        }
    }
)

// ---------询问确认结案----------
export const validCaseDone = ({soId}) => runAsync(
    () => http.post('/sm/sobudget/validCaseDone', {soId}),

    {
        error: {
            content: (err) => `读取校验能否结案失败: ${err.message}`,
            show: false,
        },

        loading: {
            content: '正在校验能否结案，请稍候',
            show: false
        },

        success: {
            content: () => '读取校验能否结案成功',
            show: false,
        }
    }
)

// --------------校验ITS 同步状态
export const validItsSts = (param) => runAsync(
    () => http.post('/sm/so/validItsSts', {...param}),

    {
        error: {
            content: (err) => `操作提醒: ${err.message}`,
            //show: false,
        },

        loading: {
            content: '正在同步ITS状态，请稍候',
            show: false
        },

        success: {
            content: () => '读取同步ITS状态成功',
            show: false,
        }
    }
)

// 查询销售订单对单列表
export const listSmSoCheckRow = (param) => runAsync(
    () => http.post('/sm/smSoCheck/listSmSoCheckRow', {...param}),

    {
        error: {
            content: (err) => `操作提醒: ${err.message}`,
            //show: false,
        },

        loading: {
            content: '正在查询销售订单对单信息，请稍候',
            show: false
        },

        success: {
            content: () => '读取销售订单对单信息成功',
            show: false,
        }
    }
)

// 新增销售订单对单信息
export const addSmSoCheckRows = (param) => runAsync(
    () => http.post('/sm/smSoCheck/addSmSoCheckRows', {...param}),

    {
        error: {
            content: (err) => `操作提醒: ${err.message}`,
            //show: false,
        },

        loading: {
            content: '正在保存销售订单对单信息，请稍候',
            show: false
        },

        success: {
            content: () => '保存销售订单对单信息成功',
            show: false,
        }
    }
)

export const listSmSoStyles = (param) => runAsync(
    () => http.post('/sm/so/listSmSoStyles', {...param}),

    {
        error: {
            content: (err) => `操作提醒: ${err.message}`,
            //show: false,
        },

        loading: {
            content: '正在查询销售订单款信息，请稍候',
            show: false
        },

        success: {
            content: () => '查询销售订单款信息成功',
            show: false,
        }
    }
)


export const initTrans = () => runAsync(
    () => http.post('/bas/dict/listDictDlt', {code: 'TRANS_MODE'}),

    {
        error: {
            content: (err) => `读取运输方式失败: ${err.message}`,
            //show: false,
        },

        loading: {
            content: '正在查询运输方式信息，请稍候',
            show: false
        },

        success: {
            content: () => '查询运输方式信息成功',
            show: false,
        }
    }
)

export const listSoTrades = (param) => runAsync(
    () => http.post('/bas/busdefcfg/listBasBusDefConfigDRsForSo', {...param}),

    {
        error: {
            content: (err) => `查询贸易方式失败: ${err.message}`,
            //show: false,
        },

        loading: {
            content: '正在查询贸易方式，请稍候',
            show: false
        },

        success: {
            content: () => '查询贸易方式成功',
            show: false,
        }
    }
)

export const listSoBeCoAccts = (param) => runAsync(
    () => http.post('/lib/basBeCoAcct/listBasBeCoAccts', {...param}),
    {
        error: {
            content: (err) => `查询订单主体失败: ${err.message}`,
            //show: false,
        },

        loading: {
            show: false
        },

        success: {
            show: false,
        }
    }
)

export const soBudgettnpChgWarn = (param) => runAsync(
    () => http.post('/sm/so/soBudgettnpChgWarn', {...param}),

    {
        error: {
            content: (err) => `查询订单预算数据预警值失败: ${err.message}`,
            //show: false,
        },

        loading: {
            show: false
        },

        success: {
            show: false,
        }
    }
)

export const oneFlowBillState = (param) => runAsync(
    () => http.post('/bas/flowBillState/oneFlowBillState', {...param}),

    {
        error: {
            content: (err) => `数据异常: ${err.message}`,
            //show: false,
        },

        loading: {
            show: false
        },

        success: {
            show: false,
        }
    }
)

// 同步销售订单取消结案FromITS
export const itsDone = (param) => runAsync(
    () => http.post('/sm/so/itsDone', {...param}),

    {
        error: {
            content: (err) => `获取ITS取消结案状态异常: ${err.message}`,
            //show: false,
        },

        loading: {
            show: false
        },

        success: {
            show: false,
        }
    }
)

// 是否所有的付款单财务都已经确认
export const validSoPay = ({soId}) => runAsync(
    () => http.post('/sm/so/validSoPay', {soId}),

    {
        error: {
            content: (err) => `${err.message}`,
            show: true,
        },

        loading: {
            content: '',
            show: false
        },

        success: {
            content: () => '',
            show: false,
        }
    }
)
export const addSmSo = (param) => runAsync(
    () => http.post('/sm/so/addSoDTO', {...param}),

    {
        error: {
            content: (err) => `新建订单失败: ${err.message}`,
            show: true,
        },

        loading: {
            content: '',
            show: false
        },

        success: {
            content: () => '新建订单成功',
            show: false,
        }
    }
)

export const cpSos = (param) => runAsync(
    () => http.post('/sm/so/cpSos', {...param}),

    {
        error: {
            content: (err) => `复制订单失败: ${err.message}`,
            show: true,
        },

        loading: {
            content: '',
            show: false
        },

        success: {
            content: () => '复制订单成功',
            show: false,
        }
    }
)

export const cpSo = (param) => runAsync(
    () => http.post('/sm/so/cpSo', {...param}),

    {
        error: {
            content: (err) => `复制订单失败: ${err.message}`,
            show: true,
        },

        loading: {
            content: '',
            show: false
        },

        success: {
            content: () => '复制订单成功',
            show: false,
        }
    }
)

export const setSoSrptRate = ({soIds, srptTxcRmbRate}) => runAsync(
    () => http.post('/sm/so/setSoSrptRate', {soIds, srptTxcRmbRate}),

    {
        error: {
            content: (err) => `设置原币统计汇率失败: ${err.message}`,
            show: true,
        },

        loading: {
            content: '',
            show: false
        },

        success: {
            content: () => '设置原币统计汇率成功',
            show: false,
        }
    }
)

// 修改订单
export const modifySoDTO = (soDTO) => runAsync(
    () => http.post('/sm/so/modifySoDTO', {...soDTO}),
    {
        error: {content: (err) => `保存失败: ${err.message}`},
        loading: {show: true, content: '保存中...'},
        success: {show: true, content: () => '保存成功'},

    }
)

export const modifySosDTO = (soDTO) => runAsync(
    () => http.post('/sm/so/modifySosDTO', {...soDTO}),
    {
        error: {content: (err) => `保存失败: ${err.message}`},
        loading: {show: true, content: '保存中...'},
        success: {show: true, content: () => '保存成功'},

    }
)

// 刷新销售订单款的包装箱唛
export const refreshSoCmp = ({sosId, opt}) => runAsync(
    () => http.post('/sm/so/refreshSoCmp', {sosId, opt}),
    {
        error: {content: (err) => `刷新包装箱唛失败: ${err.message}`},
        loading: {show: false,},
        success: {show: true, content: () => '刷新包装箱唛成功'},

    }
)

export const refreshSosCmp = ({soId, opt}) => runAsync(
    () => http.post('/sm/so/refreshSosCmp', {soId, opt}),
    {
        error: {content: (err) => `刷新包装箱唛失败: ${err.message}`},
        loading: {show: false,},
        success: {show: true, content: () => '刷新包装箱唛成功'},

    }
)

export const onePnrCust = ({custId}) => runAsync(
    () => http.post('/pnr/pnrCust/onePnrCust', {custId}),
    {
        error: {content: (err) => `查询客户信息失败: ${err.message}`},
        loading: {show: false,},
        success: {show: false, content: () => '查询客户信息成功'},

    }
)

export const oneCompanyCust = ({mainCompanyCustId}) => runAsync(
    () => http.post('/pnr/pnrCust/queryPnrCust', {mainCompanyCustId , noPermissionFlag: true}),
    {
        error: {content: (err) => `查询客户信息失败: ${err.message}`},
        loading: {show: false,},
        success: {show: false, content: () => '查询客户信息成功'},

    }
)


// 查询包装箱唛
export const oneCustCpm = ({cpmId}) => runAsync(
    () => http.post('/pnr/custpackmark/selectOne', {cpmId}),
    {
        error: {content: (err) => `查询包装箱唛信息失败: ${err.message}`},
        loading: {show: false,},
        success: {show: false, content: () => '查询包装箱唛信息成功'},

    }
)

export const listMrpRowRevLogs = ({mrpRowId}) => runAsync(
    () => http.post('/lib/mrpRowRevLog/listMrpRowRevLogs', {mrpRowId}),
    {
        error: {content: (err) => `查询MRP计划行变更日志失败: ${err.message}`},
        loading: {show: false,},
        success: {show: false, content: () => '查询MRP计划行变更日志成功'},

    }
)

// 设置BOM
export const updateSetBom = ({soId, sosId, styleId, spbId, opt}) => runAsync(
    () => http.post('/sm/soData/updateChagBom', {soId, sosId, styleId, spbId, opt}),
    {
        error: {content: (err) => `设置 款式资料 失败: ${err.message}`},
        loading: {show: true, content: '正在设置 款式资料， 请稍后'},
        success: {show: false, content: () => '设置 款式资料 成功'},

    }
)

// 变更BOM
export const updateChgBom = ({soId, sosId, styleId, spbId, opt}) => runAsync(
    () => http.post('/sm/soData/updateChagBom', {soId, sosId, styleId, spbId, opt}),
    {
        error: {content: (err) => `变更 款式资料 失败: ${err.message}`},
        loading: {show: true, content: '正在变更 款式资料， 请稍后'},
        success: {show: false, content: () => '变更 款式资料 成功'},

    }
)

// 查询销售订单款信息
export const oneSoStyle = ({soId, sosId}) => runAsync(
    () => http.post('/sm/so/oneSoStyle', {soId, sosId}),
    {
        error: {content: (err) => `查询 销售订单款 失败: ${err.message}`},
        loading: {show: false, content: '正在查询 销售订单款， 请稍后'},
        success: {show: false, content: () => '查询 销售订单款 成功'},
    }
)

export const getDmSCost = ({soId, styleId}) => runAsync(
    () => http.post('/sm/so/getDmSCost', {soId, styleId}),
    {
        error: {content: (err) => `查询 单件成本 失败: ${err.message}`},
        loading: {show: false, content: '正在查询 单件成本， 请稍后'},
        success: {show: false, content: () => '查询 单件成本 成功'},
    }
)

export const addSos = (param) => runAsync(
    () => http.post('/sm/so/addSos', param),
    {
        error: {content: (err) => `新增 订单款 失败: ${err.message}`},
        loading: {show: false, content: '正在新增 订单款， 请稍后'},
        success: {show: false, content: () => '新增 订单款 成功'},
    }
)

export const listRoPlan = (param) => runAsync(
    () => http.post('/sm/so/listRoPlanDtl', param),
    {
        error: {content: (err) => `查询 接单计划 失败: ${err.message}`},
        loading: {show: false, content: '正在查询 接单计划， 请稍后'},
        success: {show: false, content: () => '查询 接单计划 成功'},
    }
)

export const importPoDtl = (param) => runAsync(
    () => http.post('/sm/podata/importPoDtl', param),
    {
        error: {content: (err) => `导入 PO 失败: ${err.message}`},
        loading: {show: false, content: '正在导入 PO， 请稍后'},
        success: {show: false, content: () => '导入 PO 成功'},
    }
)

export const oneSmPoDataDTO = (param) => runAsync(
    () => http.post('/sm/podata/oneSmPoDataDTO', param),
    {
        error: {content: (err) => `查询 PO 失败: ${err.message}`},
        loading: {show: false, content: '正在查询 PO， 请稍后'},
        success: {show: false, content: () => '查询 PO 成功'},
    }
)

export const saveChildPos = (param) => runAsync(
    () => http.post('/sm/podata/saveChildPos', param),
    {
        error: {content: (err) => `保存 PO 失败: ${err.message}`},
        loading: {show: false, content: '正在保存 PO， 请稍后'},
        success: {show: false, content: () => '保存 PO 成功'},
    }
)
export const soUsed = ({soId}) => runAsync(
    () => http.post('/sm/so/soUsed', {soId}),
    {
        error: {content: (err) => `查询 订单 失败: ${err.message}`},
        loading: {show: false, content: '正在查询 订单， 请稍后'},
        success: {show: false, content: () => '查询 订单 成功'},
    }
)

export const listSmPoDataPpMp = ( param ) => runAsync(
    () => http.post('/sm/podata/listSmPoDataPpMp', param ),
    {
        error: {content: (err) => `查询 PO表生产排产 失败: ${err.message}`},
        loading: {show: false, content: '正在查询 PO表生产排产， 请稍后'},
        success: {show: false, content: () => '查询 PO表生产排产 成功'},
    }
)
export const qryOneBasEr = ( param ) => runAsync(
        () => http.post('/bas/basEr/qryOneBasEr', param ),
        {
            error: {content: (err) => `查询 币制汇率 失败: ${err.message}`},
            loading: {show: false, content: '正在查询 币制汇率， 请稍后'},
            success: {show: false, content: () => '查询 币制汇率 成功'},
        }
    )
export const updateCurrRmbRate = ( param ) => runAsync(
    () => http.post('/sm/so/updateCurrRmbRate', param ),
    {
        error: {content: (err) => `更新 币制汇率 失败: ${err.message}`},
        loading: {show: false, content: '正在更新 币制汇率， 请稍后'},
        success: {show: false, content: () => '更新 币制汇率 成功'},
    }
)

export const oneSmSoDR = ( param ) => runAsync(
    () => http.post('/sm/so/oneSmSoDR', param ),
    {
        error: {content: (err) => `查询订单 币制汇率 失败: ${err.message}`},
        loading: {show: false, content: '正在查询订单 币制汇率， 请稍后'},
        success: {show: false, content: () => '查询 币制汇率 成功'},
    }
)

export const updateSoModeCoAcc = ( param ) => runAsync(
    () => http.post('/sm/so/updateSoModeCoAcc', param ),
    {
        error: {content: (err) => `更新订单 模式、主体 失败: ${err.message}`},
        loading: {show: false, content: '正在更新订单 模式、主体， 请稍后'},
        success: {show: false, content: () => '更新订单 模式、主体 成功'},
    }
)

export const listPnrCustPayMode = ( { custId } ) => runAsync(
        () => http.post('/pnr/pnrCust/listPnrCustPayModeDTO', {custId } ),
        {
            error: {content: (err) => `查询 远期天数 失败: ${err.message}`},
            loading: {show: false, content: '正在查询 远期天数， 请稍后'},
            success: {show: false, content: () => '查询 远期天数 成功'},
        }
    )

export const oneSoPayModeDTO = ( { soId } ) => runAsync(
    () => http.post('/sm/so/oneSoPayModeDTO', {soId } ),
    {
        error: {content: (err) => `查询 收款方式 失败: ${err.message}`},
        loading: {show: false, content: '正在查询 收款方式， 请稍后'},
        success: {show: false, content: () => '查询 收款方式 成功'},
    }
)

export const updateSoPayMode = ( param ) => runAsync(
    () => http.post('/sm/so/updateSoPayMode', param ),
    {
        error: {content: (err) => `更新 收款方式 失败: ${err.message}`},
        loading: {show: false, content: '正在更新 收款方式， 请稍后'},
        success: {show: false, content: () => '更新 收款方式 成功'},
    }
)

export const listSoDoSubmit = ( param ) => runAsync(
    () => http.post('/sm/so/listSoDoSubmit', param ),
    {
        error: {show: false,content: (err) => `查询 待提交物料需求的订单  失败: ${err.message}`},
        loading: {show: false, content: '正在查询 待提交物料需求的订单， 请稍后'},
        success: {show: false, content: () => '查询 待提交物料需求的订单 成功'},
    }
)

// 终稿
export const validSpbFversion = ( param ) => runAsync(
    () => http.post('/sm/so/validSpbFversion', param ),
    {
        error: {show: true ,content: (err) => `校验 订单款 : ${err.message}`},
        loading: {show: false, content: '正在校验 订单款， 请稍后'},
        success: {show: false, content: () => '校验 订单款 成功'},
    }
)

export const validSoMode = ( param ) => runAsync(
    () => http.post('/sm/so/validSoMode', param ),
    {
        error: {show: true ,content: (err) => `校验 订单模式 : ${err.message}`},
        loading: {show: false, content: '正在校验 订单模式， 请稍后'},
        success: {show: false, content: () => '校验 订单模式 成功'},
    }
)

export const createBeSo = ( param ) => runAsync(
    () => http.post('/sm/so/createBeSo', param ),
    {
        error: {show: true ,content: (err) => `生成 关联订单 : ${err.message}`},
        loading: {show: false, content: '正在生成 关联订单， 请稍后'},
        success: {show: false, content: () => '生成 关联订单 成功'},
    }
)

