/** @jsxImportSource @emotion/react */
;
import {createContext} from 'react';
import 'antd/dist/antd.css';
import RootPage from "./pages/RootPage/RootPage";
import RouterPage from "./pages/RouterPage/RouterPage";
import DraggableManager from 'components/Draggable/DraggableManager.jsx'
import ErrorBoundary from 'components/ErrorBoundry/ErrorBoundry.jsx'
import ModalManager from 'components/Modal/ModalManager.jsx'
import Spin from 'components/Spin/Spin.jsx'
import AntdConfig from './AntdConfig.jsx'

window.global = window.global ?? window.globalThis

export const AppContext = createContext([]);

const App = () => {
    // const [session, setSession] = useState(null);

    const clear = () => {
        // Tools.authenticator.clear();
        // setSession(null);
    }

    // const refresh = async () => {
    //     const {tokenInfo: {token, refreshToken}} = session;
    //     console.log("使用旧 Token 换取新 Token")
    //     console.log("token", token)
    //     console.log("refreshToken", refreshToken)
    //     const newSession = await Tools.runAsync(
    //         async () => {
    //             return await Tools.http.post("/bas/auth/dopweb/login/refreshToken", {token, refreshToken})
    //         },
    //         {
    //             error: {content: (err) => `刷新令牌失败: ${err.message}`},
    //             loading: {show: false},
    //             success: {show: false},
    //         }
    //     );
    //     console.log("获得新 Token", newSession);
    //     setSession(newSession);
    //     setTimeout(refresh, 1000 * 60);
    // }

    // //session 处理
    // useEffect(async () => {
    //     // console.log("触发useEffect([session])", session)
    //     if (Tools.authenticator.verify()) {
    //
    //     } else {
    //
    //     }
    //     if (!session) {
    //         if (Tools.authenticator.verify()) {
    //             setSession(Tools.authenticator.get());
    //         }
    //     } else {
    //         Tools.authenticator.set(session);
    //
    //         //开启定时任务：刷新令牌
    //         // setTimeout(refresh, 1000 * 60);
    //     }
    //     // console.log(session)
    // }, [session]);

    return (
        <ErrorBoundary>
            <AntdConfig>
                <RootPage>
                    {/*<AppContext.Provider value={[clear]}>*/}
                    <DraggableManager>
                        <ModalManager>
                            <Spin>
                                <RouterPage></RouterPage>
                            </Spin>
                        </ModalManager>
                    </DraggableManager>
                    {/*</AppContext.Provider>*/}
                </RootPage>
            </AntdConfig>
        </ErrorBoundary>
    );
}

export default App;
