/** @jsxImportSource @emotion/react */

import FrameContent from './FrameContent.jsx'

const FrameMain = ({activeKey, frame, frames, ...props}) => {
    const iterNode = function* (nodes) {
        for (const {children, frame, key, props} of nodes) {
            if (children) {
                yield* iterNode(children)
            }
            else {
                yield [key, [frame, props]]
            }
        }
    }

    const children = Array.from(iterNode(frames)).map(
        ([key, component]) => (
            <FrameContent
                key={key}
                activeKey={activeKey}
                component={component}
                frame={frame}
                frameKey={key}
            />
        )
    )

    const css = {
        overflow: 'hidden',
    }

    return (
        <div
            css={css}
            {...props}
        >
            {children}
        </div>
    )
}

export default FrameMain
