const getProptotypeChain = (x) => {
    const chain = []
    let prototype = Object.getPrototypeOf(x)

    while (prototype) {
        chain.push(prototype)
        prototype = Object.getPrototypeOf(prototype)
    }

    return chain
}

// TODO: 处理循环引用
const deepEqual = (a, b) => {
    if (a === b) {
        return true
    }

    const typeA = typeof a
    const typeB = typeof b

    if (typeA !== typeB) {
        return false
    }

    if ('symbol' === typeA) {
        return a === b
    }

    if ('function' === typeA) {
        return a.toString() === b.toString()
    }

    if (Object.is(a, b)) {
        return true
    }

    if ('object' !== typeA) {
        return false
    }

    if (
        Date === a.constructor ||
        RegExp === a.constructor
    ) {
        return a.toString() === b.toString()
    }

    const keysA = new Set(Object.getOwnPropertyNames(a))
    const keysB = new Set(Object.getOwnPropertyNames(b))

    if (keysA.size !== keysB.size) {
        return false
    }

    for (const key of keysA) {
        if (! keysB.has(key)) {
            return false
        }
    }

    // 比较值可能比较耗时，故重启一个循环
    for (const key of keysA) {
        if (! deepEqual(a[key], b[key])) {
            return false
        }
    }

    const prototypeChainA = getProptotypeChain(a)
    const prototypeChainB = getProptotypeChain(b)

    if (prototypeChainA.length !== prototypeChainB.length) {
        return false
    }

    for (let i = 0; i < prototypeChainA.length; i += 1) {
        if (prototypeChainA[i] === prototypeChainB[i]) {
            const props = Object.getOwnPropertyNames(prototypeChainA[i])

            for (const prop of props) {
                if (a[prop] !== b[prop]) {
                    return false
                }
            }
        }
        else {
            return false
        }
    }

    return true
}

export default deepEqual
