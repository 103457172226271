/** @jsxImportSource @emotion/react */

import {Dropdown, Menu} from 'antd'

const stopPropagation = (e) => e.stopPropagation()

const ContextMenu = ({children, ...props}) => {
    return (
        <Dropdown
            overlay={<Menu {...props} />}
            trigger={['contextMenu']}
            onContextMenu={stopPropagation}
        >
            {children}
        </Dropdown>
    )
}

export default ContextMenu
