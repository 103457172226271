/** @jsxImportSource @emotion/react */

import {useState} from 'react'
import {Select as AntdSelect} from 'antd'
import useCachedValue from 'hooks/useCachedValue.mjs'
import stopPropagation from './stopPropagation.mjs'
import useSelectable from './useSelectable.mjs'

const defaultObsolete = (option) => false

const Select = ({
    obsolete = defaultObsolete,
    hottestFirst,
    label,
    options,
    searchText,
    title,
    value,
    writeBack,
    onChange,
    ...props
}) => {
    const [inputValue, setInputValue] = useCachedValue(value)
    const [isOpen, setIsOpen] = useState(false)

    const {
        antdOptions,
        filterOption,
        filterSort,
        handleChange,
    } = useSelectable({
        hottestFirst,
        label,
        options,
        searchText,
        setInputValue,
        title,
        writeBack,
        onChange,
    })

    const finalOptions = isOpen ?
        antdOptions.filter(
            ({data}) => ! obsolete(data)
        )
        :
        antdOptions

    const handleDropdownVisibleChange = (open) => {
        setIsOpen(open)
    }

    const style = {
        display: 'block',
        width: '100%',
        color: 'inherit',
        overflow: 'hidden',
        ...props.style,
    }

    return (
        <AntdSelect
            filterOption={filterOption}
            filterSort={filterSort}
            optionLabelProp="writeBack"
            options={finalOptions}
            showSearch
            size="small"
            value={inputValue}
            onChange={handleChange}
            onClick={stopPropagation}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            {...props}
            style={style}
        />
    )
}

export default Select
