export const chooseFiles = async (options) => {
    try {
        const fileHandles = await window.showOpenFilePicker({
            excludeAcceptAllOption: true,
            ...options,
        })

        const files = await Promise.all(
            fileHandles.map((fileHandle) => fileHandle.getFile())
        )

        return files
    }
    catch (err) {
        if ('AbortError' === err.name) {
            return []
        }
        else {
            throw err
        }
    }
}

chooseFiles.image = (options) => {
    return chooseFiles({
        types: [
            {
                description: 'Images',

                accept: {
                    'image/*': [
                        '.png',
                        '.gif',
                        '.jpeg',
                        '.jpg',

                    ]
                }
            },
            {
                description: 'Excel',

                accept: {
                    'application/*': [
                        '.xlsx',
                        '.txt',
                        '.xls',
                    ]
                }
            },
        ],

        ...options,
    })
}

chooseFiles.excel = (options) => {
    return chooseFiles({
        types: [
            {
                description: 'Excel',

                accept: {
                    'application/*': [
                        '.xlsx',
                    ]
                }
            },
        ],

        ...options,
    })
}

chooseFiles.file = (options) => {
    return chooseFiles({
        types: [
            {
                description: 'Images',

                accept: {
                    'image/*': [
                        '.png',
                        '.gif',
                        '.jpeg',
                        '.jpg',

                    ]
                }
            },
            {
                description: 'Excel',

                accept: {
                    'application/*': [
                        '.xlsx',
                        '.txt',
                        '.xls',
                    ]
                }
            },
        ],

        ...options,
    })
}

export const fileFromClipboard = async (type, filename) => {
    const items = await navigator.clipboard.read()

    for (const item of items) {
        if (! item.types.includes(type)) {
            throw new Error('未找到数据')
        }

        const blob = await item.getType(type)
        return new File([blob], filename, {type})
    }
}

export const writeFileToDisk = async (data, options) => {
    try {
        const fileHandle = await window.showSaveFilePicker(options)
        const ws = await fileHandle.createWritable()
        await ws.write(data)
        await ws.close()
    } catch (err) {
        if ('AbortError' !== err.name) {
            throw err
        }
    }
}
