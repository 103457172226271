/** @jsxImportSource @emotion/react */

import {css} from '@emotion/react'

const cssViewNumber = css({
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const ViewNumber = ({value, ...props}) => {
    return (
        <div
            css={cssViewNumber}
            title={value}
            {...props}
        >
            {value}
        </div>
    )
}

export default ViewNumber
