import {useRef} from 'react'

const useEventTarget = () => {
    const eventTarget = useRef()

    if (! eventTarget.current) {
        const listeners = new Map()

        const addEventListener = (type, listener) => {
            if (! listeners.has(type)) {
                listeners.set(type, new Set())
            }

            listeners.get(type).add(listener)
        }

        const removeEventListener = (type, listener) => {
            listeners.get(type)?.delete(listener)
        }

        const dispatchEvent = (type, data) => {
            return new Promise((resolve) => {
                (async () => {
                    const typeListeners = listeners.get(type)

                    if (typeListeners) {
                        const listenersSnapshot = new Set(typeListeners)

                        for (const listener of listenersSnapshot) {
                            const result = await listener(data)

                            if (false === result) {
                                return
                            }
                        }
                    }

                    resolve()
                })()
            })
        }

        eventTarget.current = {
            addEventListener,
            dispatchEvent,
            removeEventListener,
        }
    }

    return eventTarget.current
}

export default useEventTarget
